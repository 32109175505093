.spacer {
  background-color: #fb68ff4c;
}

.spacer.sixty-four {
  height: 62px;
  width: 62px;
}

.spacer.ninety-six {
  height: 93px;
  width: 93px;
}

.spacer.thirty-two {
  height: 31px;
  width: 31px;
}

.spacer.eight {
  height: 8px;
  width: 8px;
}

.spacer.forty {
  height: 39px;
  width: 39px;
}

.spacer.seventy-two {
  height: 70px;
  width: 70px;
}

.spacer.eighty {
  height: 77px;
  width: 77px;
}

.spacer.fifty-six {
  height: 54px;
  width: 54px;
}

.spacer.sixteen {
  height: 15px;
  width: 15px;
}

.spacer.forty-eight {
  height: 46px;
  width: 46px;
}

.spacer.twenty-four {
  height: 23px;
  width: 23px;
}

.spacer.eighty-eight {
  height: 85px;
  width: 85px;
}
