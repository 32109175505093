.frame {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

body{
  overflow-x: hidden;
}
.frame .div-6 {
  background-color: #ffffff;
  height: 6700px;
  /* overflow: hidden; */
  position: relative;
  width: 1912px;
}

.frame .overlap-2 {
  height: 1440px;
  left: -6px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.frame .background {
  background-color: #f9f9f9;
  height: 1440px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.frame .overlap-3 {
  height: 1722px;
  position: relative;
  width: 2119px;
}

.frame .components {
  height: 1270px;
  left: 365px;
  overflow: hidden;
  position: absolute;
  top: 452px;
  width: 1754px;
}

.frame .overlap-4 {
  height: 1184px;
  position: relative;
  width: 1562px;
}

.frame .line-4 {
  height: 801px;
  left: 433px;
  position: absolute;
  top: 187px;
  width: 832px;
}

.frame .line-5 {
  height: 767px;
  left: 788px;
  position: absolute;
  top: 221px;
  width: 765px;
}

.frame .line-6 {
  height: 664px;
  left: 1041px;
  position: absolute;
  top: 324px;
  width: 512px;
}

.frame .line-7 {
  height: 313px;
  left: 482px;
  position: absolute;
  top: 391px;
  width: 1071px;
}

.frame .line-8 {
  height: 229px;
  left: 717px;
  position: absolute;
  top: 736px;
  width: 836px;
}

.frame .line-9 {
  height: 308px;
  left: 0;
  position: absolute;
  top: 680px;
  width: 1200px;
}

.frame .card-design-option {
  align-items: flex-start;
  border: 0.57px solid;
  border-color: #f8f8f88f;
  border-radius: 4.59px;
  display: flex;
  flex-direction: column;
  gap: 52.21px;
  height: 115px;
  justify-content: space-around;
  left: 940px;
  overflow: hidden;
  position: absolute;
  top: 411px;
  transform: rotate(15.75deg);
  width: 208px;
}

.frame .folder-card-4 {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 49, 89, 0) 100%);
  background-image: url(../../../static/img/folder-card-5.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4.59px;
  height: 114.74px;
  position: relative;
  width: 100%;
}

.frame .div-7 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0px 9.18px;
  position: absolute;
  top: 14px;
  width: 200px;
}

.frame .div-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.59px;
  position: relative;
}

.frame .div-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.88px;
  position: relative;
}

.frame .frame-wrapper-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.18px;
  position: relative;
}

.frame .div-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.88px;
  position: relative;
}

.frame .text-wrapper-18 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 12.4px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .star-3 {
  height: 14.59px;
  margin-bottom: -1.29px;
  margin-top: -1.29px;
  position: relative;
  transform: rotate(-15.75deg);
  width: 11.04px;
}

.frame .element-5 {
  background-color: #ffeaea;
  border-radius: 14.2px;
  height: 13.77px;
  overflow: hidden;
  position: relative;
  width: 13.77px;
}

.frame .overlap-group-6 {
  border-radius: 14.2px;
  height: 14px;
  position: relative;
  width: 14px;
}

.frame .vector {
  height: 8px;
  left: 4px;
  position: absolute;
  top: 3px;
  transform: rotate(-15.75deg);
  width: 7px;
}

.frame .element-6 {
  background-color: #f5f5f5;
  border-radius: 14.2px;
  height: 14px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 14px;
}

.frame .group-2 {
  height: 9px;
  left: 4px;
  position: absolute;
  top: 3px;
  transform: rotate(-15.75deg);
  width: 7px;
}

.frame .ellipse-12 {
  background-color: #f1f1f1;
  border-radius: 1.15px;
  height: 2.29px;
  position: relative;
  width: 2.29px;
}

.frame .subheading-wrapper-2 {
  align-items: flex-start;
  background-color: var(--accent-99);
  border-radius: 2.29px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.74px;
  overflow: hidden;
  padding: 1.15px 2.29px;
  position: relative;
}

.frame .subheading-3 {
  color: #047857;
  font-family: "Inter", Helvetica;
  font-size: 6.9px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 8.3px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .supporting-text-3 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 6.9px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .more-horiz-2 {
  height: 14.16px;
  margin-top: -2.05px;
  position: relative;
  transform: rotate(-15.75deg);
  width: 17.67px;
}

.frame .file-details {
  background-color: #d3d3d33d;
  border: 0.65px solid;
  border-color: #f8f8f88f;
  border-radius: 5.19px;
  height: 271px;
  left: 1047px;
  position: absolute;
  top: 592px;
  transform: rotate(15deg);
  width: 258px;
}

.frame .file-details-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.65px solid;
  border-color: #f8f8f8;
  border-radius: 5.19px;
  box-shadow: 0px 14.11px 3.89px -1.3px #10182808, 0px 5.94px 10.38px -2.59px #10182814;
  display: flex;
  flex-direction: column;
  gap: 15.56px;
  height: 271px;
  left: 1051px;
  overflow: hidden;
  padding: 17.82px 20.75px 20.75px;
  position: absolute;
  top: 580px;
  transform: rotate(15deg);
  width: 250px;
}

.frame .div-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .div-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.38px;
  position: relative;
  width: 100%;
}

.frame .img-8 {
  align-self: stretch;
  height: 125.42px;
  margin-left: -15.9px;
  margin-right: -48.54px;
  margin-top: -7.75px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.frame .div-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.19px;
  position: relative;
  width: 100%;
}

.frame .details-basic-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.78px;
  position: relative;
  width: 100%;
}

.frame .details-basic {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.59px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-19 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 15.6px;
  margin-top: -0.65px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-14 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.19px;
  position: relative;
}

.frame .text-wrapper-20 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .ellipse-13 {
  background-color: var(--x01-neutral70);
  border-radius: 1.3px;
  height: 2.59px;
  position: relative;
  width: 2.59px;
}

.frame .label {
  align-items: center;
  background-color: var(--x01-neutral98);
  border-radius: 20.75px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.59px;
  overflow: hidden;
  padding: 2.59px;
  position: relative;
}

.frame .file-type {
  height: 10.02px;
  margin-left: -3.49px;
  position: relative;
  transform: rotate(-15deg);
  width: 17.36px;
}

.frame .text-2 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 7.8px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-21 {
  color: var(--x01-neutral10);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.59px;
  position: relative;
}

.frame .div-16 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.59px;
  position: relative;
}

.frame .avatar-w-photo {
  height: 15.03px;
  margin-left: -5.24px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 26.04px;
}

.frame .div-17 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-22 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 7.8px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.65px;
  position: relative;
}

.frame .div-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.frame .outside-stroke-wrapper {
  background-image: url(../../../static/img/avatar-w-photo-9.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 647.83px;
  height: 23.35px;
  position: relative;
  width: 23.35px;
}

.frame .outside-stroke {
  border: 1.3px solid;
  border-color: var(--x02-neutral95);
  border-radius: 12.97px;
  height: 26px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 26px;
}

.frame .avatar-w-photo-2 {
  background-image: url(../../../static/img/avatar-w-photo-8.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 647.83px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
  width: 23.35px;
}

.frame .avatar-w-photo-3 {
  background-image: url(../../../static/img/avatar-w-photo-7.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 647.83px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
  width: 23.35px;
}

.frame .avatar-w-photo-4 {
  background-image: url(../../../static/img/avatar-w-photo-6.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 647.83px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
  width: 23.35px;
}

.frame .avatar-w-photo-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.19px;
  margin-left: -3.89px;
  position: relative;
}

.frame .avatar-w-photo-5 {
  background-image: url(../../../static/img/avatar-w-photo-5.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 647.83px;
  height: 23.35px;
  position: relative;
  width: 23.35px;
}

.frame .overflow {
  background-color: var(--x01-neutral10);
  border: 1.3px solid;
  border-color: var(--x02-neutral95);
  border-radius: 647.83px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
  width: 23.35px;
}

.frame .number-2 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 7.8px;
  font-weight: 700;
  height: 9px;
  left: 5px;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.frame .element-notification-tab {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  border: 0.53px solid;
  border-color: #f8f8f88f;
  border-radius: 4.23px;
  box-shadow: 0px 2.12px 3.17px -1.06px #10182808, 0px 6.35px 8.47px -2.12px #10182814;
  display: flex;
  flex-direction: column;
  gap: 12.7px;
  height: 232px;
  justify-content: center;
  left: 509px;
  overflow: hidden;
  padding: 12.7px 16.93px;
  position: absolute;
  top: 858px;
  transform: rotate(15deg);
  width: 215px;
}

.frame .div-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.47px;
  margin-top: -0.15px;
  position: relative;
  width: 100%;
}

.frame .title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 4.23px;
  margin-right: -6.35px;
  position: relative;
  width: 187.82px;
}

.frame .div-wrapper-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.23px;
  position: relative;
}

.frame .text-wrapper-23 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 10.6px;
  font-weight: 600;
  letter-spacing: -0.02px;
  line-height: 12.7px;
  margin-top: -0.53px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .line-10 {
  align-self: stretch;
  height: 47.15px;
  margin-bottom: -22.03px;
  margin-left: -2.3px;
  margin-top: -3.66px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.frame .div-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.35px;
  margin-bottom: -0.15px;
  position: relative;
  width: 100%;
}

.frame .notification {
  -webkit-backdrop-filter: blur(28.76px) brightness(100%);
  align-items: flex-end;
  align-self: stretch;
  backdrop-filter: blur(28.76px) brightness(100%);
  border: 0.53px solid;
  border-color: var(--x01-neutral90);
  border-radius: 8.47px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .content-wrapper {
  -webkit-backdrop-filter: blur(42.33px) brightness(100%);
  align-items: center;
  align-self: stretch;
  backdrop-filter: blur(42.33px) brightness(100%);
  background-color: var(--x01-neutral98);
  border-radius: 2.12px 2.12px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.29px;
  overflow: hidden;
  padding: 5.29px 8.47px;
  position: relative;
  width: 100%;
}

.frame .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.23px;
  position: relative;
  width: 100%;
}

.frame .notification-2 {
  border-radius: 5.29px;
  height: 21.16px;
  overflow: hidden;
  position: relative;
  width: 21.16px;
}

.frame .overlap-group-7 {
  height: 22px;
  left: -4px;
  position: relative;
  width: 29px;
}

.frame .icon-2 {
  height: 16px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  transform: rotate(-15deg);
  width: 25px;
}

.frame .ellipse-14 {
  background-color: var(--accent-50);
  border: 1.06px solid;
  border-color: var(--x01-neutral100);
  border-radius: 2.65px;
  height: 5px;
  left: 20px;
  position: absolute;
  top: 16px;
  width: 5px;
}

.frame .notification-content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.12px;
  position: relative;
}

.frame .title-time {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.29px;
  position: relative;
  width: 100%;
}

.frame .title-2 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 10.2px;
  margin-top: -0.53px;
  position: relative;
}

.frame .time {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 6.3px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 7.6px;
  margin-top: -0.53px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.frame .description {
  align-self: stretch;
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.9px;
  position: relative;
}

.frame .image {
  align-self: stretch;
  height: 63.82px;
  margin-left: -28.47px;
  margin-right: -5.48px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.frame .notification-3 {
  -webkit-backdrop-filter: blur(28.76px) brightness(100%);
  align-items: flex-end;
  align-self: stretch;
  backdrop-filter: blur(28.76px) brightness(100%);
  background-color: var(--x01-neutral98);
  border: 0.53px solid;
  border-color: var(--x01-neutral90);
  border-radius: 2.12px;
  box-shadow: 4.23px 7.41px 7.41px #1557d80a;
  display: flex;
  flex-direction: column;
  height: 73.95px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .notification-4 {
  -webkit-backdrop-filter: blur(42.33px) brightness(100%);
  align-items: center;
  align-self: stretch;
  backdrop-filter: blur(42.33px) brightness(100%);
  background-color: var(--x01-neutral98);
  border-radius: 2.12px;
  display: flex;
  flex-direction: column;
  gap: 8.47px;
  height: 71.95px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 8.47px;
  position: relative;
  width: 100%;
}

.frame .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.23px;
  margin-top: -3.19px;
  position: relative;
  width: 100%;
}

.frame .icon-3 {
  height: 7px;
  left: -3px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  transform: rotate(-15deg);
  width: 25px;
}

.frame .ellipse-15 {
  background-color: var(--accent-50);
  border: 1.06px solid;
  border-color: var(--x01-neutral100);
  border-radius: 2.65px;
  height: 5px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 5px;
}

.frame .div-wrapper-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral100);
  border: 0.53px solid;
  border-color: var(--x01-neutral80);
  border-radius: 4.23px;
  display: flex;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 5.29px 6.35px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-24 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.9px;
  margin-top: -0.53px;
  position: relative;
}

.frame .image-2 {
  align-self: stretch;
  border-radius: 0px 0px 4.23px 4.23px;
  height: 62.43px;
  position: relative;
  width: 100%;
}

.frame .members {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  border: 0.68px solid;
  border-color: #f8f8f88f;
  border-radius: 8.14px;
  display: flex;
  flex-direction: column;
  gap: 16.27px;
  height: 418px;
  justify-content: flex-end;
  left: 1160px;
  overflow: hidden;
  padding: 10.85px 0px;
  position: absolute;
  top: 189px;
  transform: rotate(15deg);
  width: 260px;
}

.frame .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 15.56px 0px 7.78px;
  position: relative;
  width: 100%;
}

.frame .column {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.frame .table-cell {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.68px;
  border-color: var(--x01-neutral90);
  display: flex;
  flex: 0 0 auto;
  gap: 8.14px;
  padding: 8.14px;
  position: relative;
  width: 100%;
}

.frame .avatar {
  background-image: url(../../../static/img/avatar-12.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .div-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame .text-3 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 11.4px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .supporting-text-4 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .avatar-2 {
  background-image: url(../../../static/img/avatar-11.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .avatar-3 {
  background-color: var(--secondary-50);
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .text-4 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 13.6px;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 22px;
}

.frame .avatar-4 {
  background-image: url(../../../static/img/avatar-10.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .avatar-5 {
  background-image: url(../../../static/img/avatar-9.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .avatar-6 {
  background-image: url(../../../static/img/avatar-8.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .avatar-7 {
  background-image: url(../../../static/img/avatar-7.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .table-cell-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8.14px;
  padding: 8.14px;
  position: relative;
  width: 100%;
}

.frame .avatar-8 {
  background-image: url(../../../static/img/avatar-6.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 135.59px;
  height: 21.69px;
  position: relative;
  width: 21.69px;
}

.frame .column-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral100);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame .table-cell-3 {
  all: unset;
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.68px;
  border-color: var(--x01-neutral90);
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 8.14px 10.85px;
  position: relative;
  width: 100%;
}

.frame .button-base-wrapper {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.frame .button-base {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.71px;
  justify-content: center;
  position: relative;
}

.frame .delete {
  height: 20.48px;
  margin-bottom: -2.11px;
  margin-top: -2.11px;
  position: relative;
  transform: rotate(-15deg);
  width: 15.72px;
}

.frame .text-5 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 11.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .table-cell-4 {
  all: unset;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 8.14px 10.85px;
  position: relative;
  width: 100%;
}

.frame .widget-leaderboard {
  align-items: center;
  border: 0.32px solid;
  border-color: #f8f8f88f;
  border-radius: 7.78px;
  display: flex;
  flex-direction: column;
  gap: 20.75px;
  height: 295px;
  justify-content: center;
  left: 829px;
  overflow: hidden;
  position: absolute;
  top: 508px;
  transform: rotate(15deg);
  width: 213px;
}

.frame .container-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral100);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.56px;
  padding: 15.56px 12.97px;
  position: relative;
  width: 100%;
}

.frame .div-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.19px;
  position: relative;
}

.frame .text-wrapper-25 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 15.6px;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .bar {
  align-items: center;
  border: 0.32px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.59px;
  display: flex;
  gap: 1.3px;
  padding: 5.19px;
  position: relative;
  width: 45.39px;
}

.frame .text-6 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 7.8px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chevron {
  height: 9.56px;
  margin-right: -7.92px;
  position: relative;
  transform: rotate(-15deg);
  width: 16.9px;
}

.frame .line-11 {
  align-self: stretch;
  height: 48.57px;
  margin-right: -37.38px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.frame .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 181.57px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .contestant {
  background-color: #ffffff;
  border-radius: 5.19px;
  height: 36.31px;
  position: relative;
  width: 186.76px;
}

.frame .contestant-2 {
  align-items: center;
  align-self: stretch;
  border-radius: 7.78px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 5.19px 7.78px;
  position: relative;
  width: 100%;
}

.frame .div-23 {
  height: 25.94px;
  position: relative;
  width: 25.94px;
}

.frame .ellipse-16 {
  height: 20px;
  left: -3px;
  object-fit: cover;
  position: absolute;
  top: 3px;
  transform: rotate(-15deg);
  width: 31px;
}

.frame .text-wrapper-26 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .expand-less {
  height: 10.02px;
  margin-right: -3.49px;
  position: relative;
  transform: rotate(-15deg);
  width: 17.36px;
}

.frame .text-wrapper-27 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .overlap-group-8 {
  height: 25px;
  left: -4px;
  position: relative;
  width: 34px;
}

.frame .polygon {
  height: 18px;
  left: 2px;
  position: absolute;
  top: 4px;
  transform: rotate(-15deg);
  width: 30px;
}

.frame .polygon-2 {
  height: 11px;
  left: 1px;
  position: absolute;
  top: 4px;
  transform: rotate(-15deg);
  width: 31px;
}

.frame .text-wrapper-28 {
  color: var(--x01-neutral100);
  font-family: "Manrope", Helvetica;
  font-size: 11.1px;
  font-weight: 800;
  height: 15px;
  left: 14px;
  letter-spacing: -0.17px;
  line-height: normal;
  position: absolute;
  top: 5px;
}

.frame .overlap-group-9 {
  height: 27px;
  left: -5px;
  position: relative;
  width: 35px;
}

.frame .ellipse-17 {
  height: 20px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  transform: rotate(-15deg);
  width: 31px;
}

.frame .text-wrapper-29 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  height: 12px;
  left: 11px;
  letter-spacing: -0.04px;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.frame .contestant-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-98);
  border-radius: 5.19px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 5.19px 7.78px;
  position: relative;
  width: 100%;
}

.frame .ellipse-18 {
  background-color: var(--accent-50);
  border: 0.93px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.24px;
  height: 6px;
  left: 24px;
  position: absolute;
  top: 21px;
  width: 6px;
}

.frame .text-wrapper-30 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  height: 12px;
  left: 10px;
  letter-spacing: -0.04px;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.frame .text-wrapper-31 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  height: 14px;
  left: 9px;
  letter-spacing: -0.05px;
  line-height: 14px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.frame .ellipse-19 {
  height: 20px;
  left: -3px;
  position: absolute;
  top: 3px;
  transform: rotate(-15deg);
  width: 31px;
}

.frame .button-8 {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-50);
  border-radius: 2.59px;
  display: flex;
  gap: 2.59px;
  height: 25.94px;
  justify-content: center;
  overflow: hidden;
  padding: 7.78px 20.75px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-32 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 800;
  letter-spacing: -0.14px;
  line-height: 11.7px;
  margin-bottom: -0.16px;
  margin-top: -1.46px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .arrow-back-4 {
  height: 8.77px;
  position: relative;
  transform: rotate(-15deg);
  width: 15.19px;
}

.frame .menu-2 {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  border-color: #f8f8f88f;
  border-right-style: solid;
  border-right-width: 0.32px;
  display: flex;
  flex-direction: column;
  height: 604px;
  justify-content: space-between;
  left: 1325px;
  padding: 7.66px 0px;
  position: absolute;
  top: 489px;
  transform: rotate(15deg);
  width: 162px;
}

.frame .div-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.frame .nav-link-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 2.55px;
  padding: 10.21px 25.52px;
  position: relative;
  width: 100%;
}

.frame .icons-28 {
  height: 14.79px;
  margin-bottom: -1.75px;
  margin-left: -4.89px;
  position: relative;
  transform: rotate(-15deg);
  width: 10.05px;
}

.frame .text-wrapper-33 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 12.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .icons-29 {
  height: 14.79px;
  margin-left: -5.15px;
  position: relative;
  transform: rotate(-15deg);
  width: 25.62px;
}

.frame .div-25 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.31px;
  position: relative;
}

.frame .extra-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 2.55px 25.52px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-34 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 9.2px;
  margin-top: -0.64px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .interpunct-2 {
  height: 5.1px;
  position: relative;
  width: 5.1px;
}

.frame .ellipse-20 {
  background-color: #d9d9d9;
  border-radius: 1.28px;
  height: 3px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 3px;
}

.frame .chevron-down-3 {
  height: 19px;
  left: 142px;
  position: absolute;
  top: 292px;
  transform: rotate(-15deg);
  width: 34px;
}

.frame .div-26 {
  align-items: center;
  background-color: var(--x01-neutral98);
  border: 0.65px solid;
  border-color: #f8f8f88f;
  border-radius: 7.78px;
  display: flex;
  gap: 12.97px;
  height: 159px;
  left: 658px;
  padding: 15.56px 20.75px;
  position: absolute;
  top: 784px;
  transform: rotate(15deg);
  width: 483.58px;
}

.frame .overlap-wrapper {
  height: 127.75px;
  position: relative;
  width: 132.29px;
}

.frame .overlap-5 {
  height: 101px;
  left: 10px;
  position: relative;
  top: 12px;
  width: 101px;
}

.frame .overlap-group-wrapper-2 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 0;
  width: 101px;
}

.frame .overlap-group-10 {
  height: 119px;
  left: -28px;
  position: relative;
  top: -8px;
  width: 157px;
}

.frame .ellipse-21 {
  height: 33px;
  left: 15px;
  position: absolute;
  top: 17px;
  transform: rotate(-15deg);
  width: 98px;
}

.frame .ellipse-22 {
  height: 86px;
  left: 9px;
  position: absolute;
  top: 17px;
  transform: rotate(-15deg);
  width: 140px;
}

.frame .text-wrapper-35 {
  color: var(--accent-50);
  font-family: "Inter", Helvetica;
  font-size: 38.9px;
  font-weight: 700;
  height: 54px;
  left: 25px;
  letter-spacing: -0.47px;
  line-height: 54.5px;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
}

.frame .div-27 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20.75px;
  position: relative;
}

.frame .div-28 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.13px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-36 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 15.6px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 18.7px;
  margin-top: -0.65px;
  position: relative;
}

.frame .text-wrapper-37 {
  align-self: stretch;
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 12.5px;
  position: relative;
}

.frame .div-wrapper-4 {
  align-items: center;
  border: 0.65px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.59px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.19px;
  padding: 7.78px 10.38px;
  position: relative;
}

.frame .text-wrapper-38 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 12.5px;
  margin-top: -0.65px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .calendar-container {
  align-items: flex-start;
  background-color: var(--shadeswhite);
  border: 0.65px solid;
  border-color: #f8f8f88f;
  border-radius: 2.59px;
  display: flex;
  flex-direction: column;
  gap: 10.38px;
  height: 272px;
  left: 941px;
  padding: 10.38px;
  position: absolute;
  top: 125px;
  transform: rotate(15.75deg);
  width: 208px;
}

.frame .change-month {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20.75px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .icon-wrapper {
  align-items: center;
  border: 0.65px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.59px;
  display: flex;
  gap: 6.48px;
  height: 15.56px;
  justify-content: center;
  padding: 5.19px;
  position: relative;
  width: 15.56px;
}

.frame .icon-13 {
  height: 13.19px !important;
  margin-bottom: -4px !important;
  margin-left: -2.4px !important;
  margin-right: -2.4px !important;
  margin-top: -4px !important;
  position: relative !important;
  transform: rotate(-15.75deg) !important;
  width: 9.99px !important;
}

.frame .month {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12.5px;
  position: relative;
  text-align: center;
}

.frame .icon-12-wrapper {
  align-items: center;
  border: 0.65px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.59px;
  display: flex;
  gap: 6.48px;
  height: 15.56px;
  justify-content: center;
  padding: 5.19px;
  position: relative;
  transform: rotate(180deg);
  width: 15.56px;
}

.frame .icon-12 {
  height: 13.19px !important;
  margin-bottom: -4px !important;
  margin-left: -2.4px !important;
  margin-right: -2.4px !important;
  margin-top: -4px !important;
  position: relative !important;
  transform: rotate(-195.75deg) !important;
  width: 9.99px !important;
}

.frame .div-29 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.frame .time-picker {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 25.94px;
  position: relative;
}

.frame .div-30 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.frame .calendar-timepicker {
  align-items: flex-start;
  background-color: var(--x01-neutral98);
  border-radius: 3.89px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.48px;
  padding: 2.59px;
  position: relative;
}

.frame .center {
  height: 18.16px;
  position: relative;
  width: 42.35px;
}

.frame .div-31 {
  align-items: center;
  display: inline-flex;
  gap: 2.59px;
  position: relative;
}

.frame .text-wrapper-39 {
  color: var(--secondary-50);
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 600;
  height: 18.16px;
  letter-spacing: -0.03px;
  line-height: 15.6px;
  margin-top: -0.65px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: 18.16px;
}

.frame .text-wrapper-40 {
  color: var(--secondary-50);
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 15.6px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.frame .segmented-picker {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral98);
  border-radius: 5.78px;
  display: flex;
  overflow: hidden;
  padding: 1.3px;
  position: relative;
  width: 62.25px;
}

.frame .segmentedpicker {
  align-self: stretch;
  background-color: var(--secondary-50);
  border-radius: 4.49px;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.frame .label-2 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  height: 10px;
  left: 5px;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: absolute;
  text-align: center;
  top: 5px;
  white-space: nowrap;
  width: 19px;
}

.frame .segmentedpicker-2 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.frame .label-3 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  height: 10px;
  left: 5px;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: absolute;
  text-align: center;
  top: 5px;
  white-space: nowrap;
  width: 20px;
}

.frame .container-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.frame .weekdays {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.89px;
  position: relative;
  width: 100%;
}

.frame .divider {
  align-self: stretch;
  height: 51.52px;
  margin-left: -0.62px;
  margin-top: -25.51px;
  position: relative;
  transform: rotate(-15.75deg);
  width: 100%;
}

.frame .day-instance {
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  height: 25.94px !important;
  padding: 5.19px !important;
  width: 26.77px !important;
}

.frame .day-2 {
  color: var(--x01-neutral50) !important;
  font-family: "Inter", Helvetica !important;
  font-size: 9.7px !important;
  font-weight: 500 !important;
}

.frame .month-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.59px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .week {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 0.65px;
  position: relative;
  width: 100%;
}

.frame .day-3 {
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
  width: 26.22px !important;
}

.frame .day-4 {
  color: var(--x01-neutral70) !important;
  font-family: "Inter", Helvetica !important;
  font-size: 10.4px !important;
  letter-spacing: -0.04px !important;
  line-height: 12.5px !important;
  margin-top: -0.75px !important;
  white-space: nowrap !important;
}

.frame .day-5 {
  color: var(--x01-neutral40) !important;
  font-family: "Inter", Helvetica !important;
  font-size: 10.4px !important;
  letter-spacing: -0.04px !important;
  line-height: 12.5px !important;
  margin-top: -0.75px !important;
  white-space: nowrap !important;
}

.frame .day-6 {
  background-color: var(--secondary-98) !important;
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
  width: 26.22px !important;
}

.frame .day-7 {
  color: var(--secondary-50) !important;
  font-family: "Inter", Helvetica !important;
  font-size: 10.4px !important;
  font-weight: 700 !important;
  letter-spacing: -0.04px !important;
  line-height: 12.5px !important;
  margin-top: -0.75px !important;
  white-space: nowrap !important;
}

.frame .day-8 {
  background-color: unset !important;
  border-radius: unset !important;
  gap: 5.19px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
  width: 26.22px !important;
}

.frame .day-9 {
  background-color: unset !important;
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
  width: 26.22px !important;
}

.frame .buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10.38px;
  position: relative;
  width: 100%;
}

.frame .cancel {
  align-items: center;
  align-self: stretch;
  background-color: var(--x01-neutral98);
  border-radius: 2.59px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 7.78px;
  position: relative;
}

.frame .label-4 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 14px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .set-date {
  align-items: center;
  align-self: stretch;
  background-color: var(--accent-50);
  border-radius: 2.59px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 2.59px;
  justify-content: center;
  padding: 7.78px 5.19px;
  position: relative;
}

.frame .label-5 {
  color: var(--shadeswhite);
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 14px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .line-12 {
  height: 516px;
  left: 937px;
  position: absolute;
  top: 0;
  width: 1px;
}

.frame .line-13 {
  height: 576px;
  left: 1153px;
  position: absolute;
  top: 0;
  width: 1px;
}

.frame .line-14 {
  height: 652px;
  left: 1429px;
  position: absolute;
  top: 0;
  width: 1px;
}

.frame .contestant-4 {
  align-items: center;
  background-color: #ffffff;
  border: 0.65px solid;
  border-color: #f8f8f88f;
  border-radius: 5.19px;
  box-shadow: 3.89px 13.62px 0px #f4f4f4, 0px 5.94px 10.38px -2.59px #10182814;
  display: flex;
  height: 36.31px;
  justify-content: space-between;
  left: 895px;
  padding: 5.19px 7.78px;
  position: absolute;
  top: 576px;
  transform: rotate(15deg);
  width: 186.76px;
}

.frame .text-wrapper-41 {
  color: var(--x01-neutral100);
  font-family: "Manrope", Helvetica;
  font-size: 11.1px;
  font-weight: 800;
  height: 15px;
  left: 15px;
  letter-spacing: -0.17px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.frame .frame-wrapper-4 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border: 0.65px solid;
  border-color: #f8f8f88f;
  border-radius: 5.19px;
  display: flex;
  flex-direction: column;
  gap: 15.56px;
  left: 680px;
  position: absolute;
  top: 121px;
  transform: rotate(14.25deg);
  width: 243px;
}

.frame .div-32 {
  align-items: flex-start;
  border: 0.65px solid;
  border-color: #6b6b6b;
  border-radius: 5.19px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14.32px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  overflow: hidden;
  padding: 14.32px;
  position: relative;
  width: 244.53px;
}

.frame .div-33 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.3px;
  position: relative;
}

.frame .text-wrapper-42 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15.6px;
  margin-top: -0.65px;
  position: relative;
}

.frame .text-wrapper-43 {
  color: #979797;
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15.6px;
  position: relative;
  width: 211.4px;
}

.frame .div-34 {
  align-items: flex-end;
  background-color: #393939;
  border-radius: 2.59px;
  box-shadow: 2.97px 2.97px 2.97px #00000040;
  display: flex;
  flex: 0 0 auto;
  gap: 5.19px;
  opacity: 0.8;
  overflow: hidden;
  padding: 7.78px 5.19px 7.78px 10.38px;
  position: relative;
  width: 211.4px;
}

.frame .checkbox-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.48px;
  padding: 1.95px 0px;
  position: relative;
}

.frame .checkbox {
  height: 10.38px;
  position: relative;
  width: 10.38px;
}

.frame .fill {
  border: 0.65px solid;
  border-color: #bcbcbc;
  border-radius: 1.3px;
  height: 12px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 12px;
}

.frame .text-wrapper-44 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15.6px;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-35 {
  align-items: flex-end;
  background-color: #393939;
  border-radius: 2.59px;
  display: flex;
  flex: 0 0 auto;
  gap: 5.19px;
  opacity: 0.8;
  overflow: hidden;
  padding: 7.78px 5.19px 7.78px 10.38px;
  position: relative;
  width: 211.4px;
}

.frame .div-36 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10.38px;
  position: relative;
  width: 100%;
}

.frame .search-bar {
  align-items: center;
  border: 0.65px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.59px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.19px;
  justify-content: center;
  padding: 7.78px 10.38px;
  position: relative;
}

.frame .text-wrapper-45 {
  color: var(--x01-neutral80);
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .search-bar-2 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 2.59px;
  box-shadow: 9.08px 2.59px 9.08px #0000008f;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.19px;
  justify-content: center;
  padding: 7.78px 10.38px;
  position: relative;
}

.frame .text-wrapper-46 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .element-7 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.56px solid;
  border-color: #efede9;
  border-radius: 4.51px;
  box-shadow: 14.66px -0.56px 18.04px #0000000a;
  display: flex;
  flex-direction: column;
  gap: 5.37px;
  justify-content: center;
  left: 490px;
  padding: 7.16px 0px;
  position: absolute;
  top: 303px;
  transform: rotate(15deg);
  width: 433px;
}

.frame .div-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.51px;
  position: relative;
  width: 100%;
}

.frame .div-38 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.77px;
  position: relative;
  width: 100%;
}

.frame .div-39 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 13.53px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-47 {
  color: #676768;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .img-9 {
  flex: 0 0 auto;
  margin-bottom: -5.55px;
  margin-top: -5.55px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .line-15 {
  align-self: stretch;
  height: 112.35px;
  margin-bottom: -55.48px;
  margin-top: -32.06px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.frame .frame-wrapper-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.77px;
  padding: 0px 13.53px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-6 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.56px;
  border-color: #d4d4d452;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.02px;
  justify-content: center;
  padding: 6.77px 0px;
  position: relative;
  width: 100%;
}

.frame .div-40 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 13.53px;
  position: relative;
  width: 100%;
}

.frame .div-41 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 13.53px;
  position: relative;
}

.frame .div-42 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 7.33px;
  position: relative;
}

.frame .div-43 {
  align-items: center;
  background-color: #efede9;
  border-radius: 3.38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.51px 6.77px;
  position: relative;
  width: 31.57px;
}

.frame .text-wrapper-48 {
  color: #676768;
  font-family: "Inter", Helvetica;
  font-size: 11.3px;
  font-weight: 600;
  letter-spacing: -0.02px;
  line-height: 13.5px;
  margin-top: -0.56px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-49 {
  color: #676768;
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-44 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.26px;
  position: relative;
}

.frame .text-wrapper-50 {
  align-self: stretch;
  color: #676768;
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 500;
  height: 13.53px;
  letter-spacing: -0.02px;
  line-height: 10.8px;
  margin-top: -0.56px;
  position: relative;
}

.frame .text-wrapper-51 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 8.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-45 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.13px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-52 {
  color: #676768;
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.1px;
  margin-top: -0.56px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-46 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.26px;
  position: relative;
}

.frame .chip-speaker-3 {
  align-items: center;
  border-radius: 11.28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.26px;
  padding: 2.26px;
  position: relative;
}

.frame .ellipse-wrapper-2 {
  height: 13.53px;
  position: relative;
  width: 13.53px;
}

.frame .ellipse-23 {
  height: 14px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  transform: rotate(-15deg);
  width: 13px;
}

.frame .name-3 {
  color: #676768;
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 8.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chip-speaker-4 {
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 11.28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.26px;
  padding: 2.26px 4.51px 2.26px 2.26px;
  position: relative;
}

.frame .name-4 {
  color: #6c6c6c;
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.1px;
  margin-top: -0.56px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-47 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.77px;
  position: relative;
}

.frame .div-wrapper-5 {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 2.26px;
  display: flex;
  gap: 5.64px;
  justify-content: center;
  padding: 6.77px;
  position: relative;
  width: 51.87px;
}

.frame .text-wrapper-53 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.9px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.5px;
  margin-top: -0.56px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .more-vert {
  height: 17.03px;
  position: relative;
  transform: rotate(-15deg);
  width: 13.07px;
}

.frame .frame-wrapper-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.02px;
  justify-content: center;
  padding: 6.77px 0px;
  position: relative;
  width: 100%;
}

.frame .add-session {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  border: 0.89px solid;
  border-color: #f5f5f5;
  border-radius: 10.74px;
  box-shadow: 3.89px 13.62px 0px #f4f4f4;
  display: inline-flex;
  gap: 6.11px;
  height: 572px;
  justify-content: center;
  left: 281px;
  padding: 34.2px;
  position: absolute;
  top: 380px;
  transform: rotate(15deg);
}

.frame .container-content {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14.66px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}

.frame .header-create {
  align-items: center;
  display: flex;
  gap: 4.89px;
  height: 25.65px;
  padding: 2.44px 0px;
  position: relative;
  width: 366.42px;
}

.frame .text-wrapper-54 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 14.7px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .container-input-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.43px;
  position: relative;
}

.frame .container-input {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14.66px;
  position: relative;
}

.frame .container-session {
  align-items: center;
  display: flex;
  gap: 9.77px;
  height: 21.98px;
  position: relative;
  width: 366.42px;
}

.frame .text-wrapper-55 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .container-types {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.33px;
  position: relative;
}

.frame .option-live {
  align-items: center;
  align-self: stretch;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  gap: 4.89px;
  justify-content: center;
  padding: 2.44px 0px;
  position: relative;
  width: 68.4px;
}

.frame .checkbox-base-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.frame .checkbox-base {
  background-color: var(--x01-neutral100);
  border: 0.61px solid;
  border-color: var(--x01-neutral50);
  border-radius: 4.89px;
  height: 9.77px;
  position: relative;
  width: 9.77px;
}

.frame .text-7 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .option-recorded {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-98);
  border: 0.61px solid;
  border-color: var(--secondary-50);
  border-radius: 2.44px;
  display: flex;
  gap: 4.89px;
  justify-content: center;
  padding: 2.44px 7.33px;
  position: relative;
  width: 70.84px;
}

.frame .check-wrapper {
  background-color: var(--secondary-98);
  border: 0.61px solid;
  border-color: var(--secondary-50);
  border-radius: 4.89px;
  height: 9.77px;
  overflow: hidden;
  position: relative;
  width: 9.77px;
}

.frame .check {
  background-color: var(--secondary-50);
  border-radius: 3.05px;
  height: 6px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 6px;
}

.frame .input-field-title {
  align-items: flex-start;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.33px;
  justify-content: center;
  overflow: hidden;
  padding: 6.11px 7.33px;
  position: relative;
  width: 366.42px;
}

.frame .text-wrapper-56 {
  align-self: stretch;
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  margin-top: -0.61px;
  position: relative;
}

.frame .input-field-desc {
  align-items: flex-start;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 366.42px;
}

.frame .input-field-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 6.11px 7.33px;
  position: relative;
  width: 100%;
}

.frame .text-8 {
  color: var(--x01-neutral50);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 8.5px;
  margin-top: -0.61px;
  position: relative;
}

.frame .span {
  letter-spacing: -0.03px;
  line-height: 10.3px;
}

.frame .text-wrapper-57 {
  letter-spacing: 0;
  line-height: 12.2px;
}

.frame .toolbar-text {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-98);
  border-color: var(--x01-neutral60);
  border-top-style: solid;
  border-top-width: 0.61px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 4.89px 7.33px;
  position: relative;
  width: 100%;
}

.frame .div-48 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.89px;
  position: relative;
}

.frame .icon-4 {
  flex: 0 0 auto;
  margin-left: -3.7px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .icon-5 {
  flex: 0 0 auto;
  position: relative;
  transform: rotate(-15deg);
}

.frame .format-list-bulleted {
  height: 10.62px;
  position: relative;
  transform: rotate(-15deg);
  width: 18.39px;
}

.frame .format-list-numbered {
  height: 10.62px;
  margin-right: -3.7px;
  position: relative;
  transform: rotate(-15deg);
  width: 18.39px;
}

.frame .label-char-limit {
  align-items: flex-start;
  background-color: var(--secondary-70);
  border-radius: 13.44px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.11px;
  padding: 2.44px;
  position: relative;
}

.frame .text-wrapper-58 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 7.3px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 8.5px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-49 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 14.66px;
  position: relative;
  width: 366.42px;
}

.frame .div-50 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4.89px;
  position: relative;
}

.frame .text-wrapper-59 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .input-field-date {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 7.33px;
  position: relative;
  width: 100%;
}

.frame .div-51 {
  align-items: center;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 24.43px;
  justify-content: space-between;
  padding: 7.33px 9.77px;
  position: relative;
}

.frame .text-wrapper-60 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chevron-down-4 {
  height: 11.8px;
  margin-bottom: -1.01px;
  margin-right: -4.11px;
  margin-top: -1.01px;
  position: relative;
  transform: rotate(-15deg);
  width: 20.43px;
}

.frame .input-field-time {
  align-items: center;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  gap: 6.11px;
  height: 24.43px;
  justify-content: space-around;
  padding: 7.33px 9.77px;
  position: relative;
  width: 65.34px;
}

.frame .chevron-down-5 {
  height: 18.44px;
  position: relative;
  transform: rotate(-15deg);
  width: 32.26px;
}

.frame .container-field {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 7.33px;
  height: 24.43px;
  position: relative;
  width: 100%;
}

.frame .div-52 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 14.66px;
  position: relative;
  width: 100%;
}

.frame .container-timezone {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4.89px;
  position: relative;
  width: 106.26px;
}

.frame .input-field-timezone {
  align-items: center;
  align-self: stretch;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 6.11px 7.33px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-61 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chevron-down-6 {
  height: 11.8px;
  margin-right: -4.11px;
  position: relative;
  transform: rotate(-15deg);
  width: 20.43px;
}

.frame .container-5 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 7.33px;
  position: relative;
}

.frame .container-wrapper {
  align-items: center;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.11px;
  padding: 4.89px 7.33px;
  position: relative;
  width: 366.42px;
}

.frame .chip-speaker-5 {
  align-items: center;
  background-color: var(--primary-98);
  border-radius: 12.21px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.44px;
  padding: 2.44px 4.89px 2.44px 2.44px;
  position: relative;
}

.frame .div-53 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.44px;
  position: relative;
}

.frame .div-54 {
  height: 14.66px;
  position: relative;
  width: 14.66px;
}

.frame .ellipse-24 {
  height: 14px;
  left: -5px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transform: rotate(-15deg);
  width: 25px;
}

.frame .name-5 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .close {
  height: 9.44px;
  margin-right: -3.29px;
  position: relative;
  transform: rotate(-15deg);
  width: 16.35px;
}

.frame .text-wrapper-62 {
  color: var(--secondary-50);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .container-embed-link {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7.33px;
  height: 43.97px;
  position: relative;
  width: 366.42px;
}

.frame .input-field-link {
  align-items: center;
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.11px;
  padding: 7.33px;
  position: relative;
  width: 366.42px;
}

.frame .container-text {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4.89px;
  position: relative;
}

.frame .text-wrapper-63 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .container-auditorium {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.33px;
  overflow: hidden;
  position: relative;
  width: 366.42px;
}

.frame .top {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 7.33px;
  position: relative;
  width: 100%;
}

.frame .title-3 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  margin-top: -0.61px;
  position: relative;
}

.frame .container-6 {
  align-items: center;
  align-self: stretch;
  background-color: var(--x01-neutral98);
  border: 0.61px dashed;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  display: flex;
  flex-direction: column;
  gap: 4.89px;
  height: 53.74px;
  justify-content: center;
  padding: 9.77px 14.66px;
  position: relative;
  width: 100%;
}

.frame .content-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.11px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .icon-6 {
  height: 21.24px;
  position: relative;
  transform: rotate(-15deg);
  width: 36.78px;
}

.frame .paragraph {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  position: relative;
  width: 158.17px;
}

.frame .container-video {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 366.42px;
}

.frame .record {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.89px;
  position: relative;
}

.frame .switch {
  height: 13.44px;
  position: relative;
  width: 24.43px;
}

.frame .overlap-group-11 {
  height: 38px;
  left: -9px;
  position: relative;
  top: -12px;
  width: 52px;
}

.frame .color-l-track {
  background-color: var(--secondary-50);
  border-radius: 6.72px;
  height: 15px;
  left: 8px;
  position: absolute;
  top: 11px;
  width: 26px;
}

.frame .states {
  height: 26px;
  left: 3px;
  position: absolute;
  top: 6px;
  transform: rotate(-15deg);
  width: 47px;
}

.frame .color-l-thumb {
  height: 11px;
  left: 17px;
  position: absolute;
  top: 14px;
  transform: rotate(-15deg);
  width: 18px;
}

.frame .text-wrapper-64 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.7px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-55 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.89px;
  position: relative;
}

.frame .icons-outline {
  height: 10px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 10px;
}

.frame .shape-wrapper {
  background-color: #4f78db;
  border-radius: 1.22px;
  height: 13px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 13px;
}

.frame .shape {
  height: 6px;
  left: 1px;
  position: absolute;
  top: 4px;
  transform: rotate(-15deg);
  width: 11px;
}

.frame .label-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.05px;
  position: relative;
}

.frame .icons-outline-2 {
  border: 0.61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 1.22px;
  height: 13px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 13px;
}

.frame .shape-2 {
  height: 8px;
  left: -1px;
  position: absolute;
  top: 3px;
  transform: rotate(-15deg);
  width: 15px;
}

.frame .overlap-group-12 {
  height: 13px;
  left: -2px;
  position: relative;
  top: 1px;
  width: 18px;
}

.frame .fill-2 {
  background-color: var(--secondary-50);
  border-radius: 1.22px;
  height: 10px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 10px;
}

.frame .icons-outline-3 {
  height: 9px;
  left: 1px;
  position: absolute;
  top: 2px;
  transform: rotate(-15deg);
  width: 16px;
}

.frame .div-56 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.77px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.frame .CTA {
  all: unset;
  align-items: center;
  border: 0.61px solid;
  border-color: var(--x01-neutral40);
  border-radius: 2.44px;
  box-sizing: border-box;
  display: flex;
  gap: 4.89px;
  justify-content: center;
  overflow: hidden;
  padding: 7.33px 0px;
  position: relative;
  width: 64.12px;
}

.frame .text-9 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 15.9px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .CTA-2 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 2.44px;
  box-sizing: border-box;
  display: flex;
  gap: 4.89px;
  justify-content: center;
  overflow: hidden;
  padding: 7.33px 0px;
  position: relative;
  width: 64.12px;
}

.frame .text-10 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 15.9px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .sessions-dropdown {
  align-items: flex-start;
  background-color: var(--white);
  border: 0.83px solid;
  border-color: #f2f3f6;
  border-radius: 6.61px;
  box-shadow: 0px 3.3px 4.96px -1.65px #10182808, 14.32px 1.79px 14.32px -3.3px #10182814;
  display: flex;
  left: 497px;
  overflow: hidden;
  position: absolute;
  top: 66px;
  transform: rotate(15deg);
  width: 168px;
}

.frame .menu-items {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.frame .input-dropdown-menu {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.61px;
  padding: 13.22px;
  position: relative;
  width: 100%;
}

.frame .img-10 {
  height: 24.96px;
  margin-bottom: -2.48px;
  margin-top: -2.48px;
  position: relative;
  transform: rotate(-15deg);
  width: 19.15px;
}

.frame .content-4 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.61px;
  position: relative;
}

.frame .text-11 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 13.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.8px;
  margin-top: -0.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-57 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 7.43px;
  display: flex;
  flex-direction: column;
  gap: 6.2px;
  height: 235px;
  left: 737px;
  padding: 12.39px;
  position: absolute;
  top: 921px;
  transform: rotate(15.75deg);
  width: 235px;
}

.frame .div-58 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 2.48px 0px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-65 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.2px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .dots-horizontal {
  height: 14.87px;
  margin-bottom: -30.12px;
  margin-left: -11382.51px;
  position: relative;
  transform: rotate(-15.75deg);
  width: 14.87px;
}

.frame .div-59 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.frame .overlap-6 {
  height: 59px;
  left: -11px;
  position: absolute;
  top: 4px;
  width: 200px;
}

.frame .overlap-7 {
  height: 59px;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
}

.frame .overlap-group-13 {
  height: 29px;
  left: 98px;
  position: absolute;
  top: 2px;
  width: 96px;
}

.frame .chair-9 {
  height: 13px !important;
  left: 1px !important;
  position: absolute !important;
  top: 8px !important;
  transform: rotate(-15.75deg) !important;
  width: 58px !important;
}

.frame .table {
  height: 3px;
  left: -3px;
  position: absolute;
  top: 28px;
  transform: rotate(-15.75deg);
  width: 207px;
}

.frame .chair-5 {
  height: 5px !important;
  left: 30px !important;
  position: absolute !important;
  top: 37px !important;
  transform: rotate(-15.75deg) !important;
  width: 58px !important;
}

.frame .gradient {
  background: linear-gradient(180deg, rgb(239.4, 237.6, 233.4) 0%, rgba(241.19, 241.19, 241.19, 0) 86.54%);
  height: 1440px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.frame .div-60 {
  align-items: flex-start;
  display: flex;
  left: 120px;
  position: absolute;
  top: 119px;
  width: 1680px;
}

.frame .project-title {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 88px;
  position: relative;
}

.frame .project-title-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-66 {
  color: #999999;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .internal-SAAS-app {
  color: #000000;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 80px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 96px;
  position: relative;
  width: 860px;
}

.frame .scope-of-work {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.frame .text-wrapper-67 {
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.39px;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .labels {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.frame .UIX {
  height: 56px;
  position: relative;
  width: 149px;
}

.frame .frame-wrapper-8 {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.frame .div-61 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.frame .group-3 {
  background-color: #f8f8f8;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.frame .overlap-group-14 {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.frame .line-16 {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.frame .line-17 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.frame .text-wrapper-68 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .UIX-2 {
  height: 56px;
  position: relative;
  width: 173px;
}

.frame .project-info {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 92px;
  justify-content: space-between;
  position: relative;
  width: 576.5px;
}

.frame .text-wrapper-69 {
  color: #484848;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.frame .text-wrapper-70 {
  color: #484848;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: -0.39px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .about {
  align-items: flex-start;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  gap: 96px;
  left: -6px;
  padding: 88px 120px 100px;
  position: absolute;
  top: 1440px;
  width: 1920px;
}

.frame .container-7 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1678px;
}

.frame .about-the-client {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.frame .text-wrapper-71 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-72 {
  color: #f8f8f8;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  position: relative;
  width: 637px;
}

.frame .the-challenge {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: 773px;
}

.frame .text-wrapper-73 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 48px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-74 {
  align-self: stretch;
  color: #f8f8f8;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 32px;
  position: relative;
}

.frame .overlap-8 {
  height: 3621px;
  left: -65px;
  position: absolute;
  top: 1858px;
  width: 2454px;
}

.frame .overlap-9 {
  height: 3621px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2454px;
}

.frame .section-wireframes {
  background: linear-gradient(180deg, rgb(30, 30, 30) 0%, rgb(28, 27, 31) 100%);
  height: 2250px;
  left: 59px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1920px;
}

.frame .resources-drafts {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: -181px;
  overflow: hidden;
  position: absolute;
  top: 1660px;
  width: 705px;
}

.frame .overlap-10 {
  height: 503px;
  left: -1px;
  position: absolute;
  top: 41px;
  width: 688px;
}

.frame .rectangle-3 {
  background-color: #fbfaf8;
  height: 449px;
  left: 136px;
  position: absolute;
  top: 1px;
  width: 553px;
}

.frame .rectangle-4 {
  background-color: #f6f6f6;
  height: 165px;
  left: 136px;
  position: absolute;
  top: 338px;
  width: 553px;
}

.frame .div-62 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 11.45px;
  left: 151px;
  position: absolute;
  top: 351px;
}

.frame .frame-wrapper-9 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 201.39px;
  position: relative;
  width: 511.59px;
}

.frame .div-wrapper-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 68.72px;
  position: relative;
}

.frame .text-wrapper-75 {
  color: #626262;
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .frame-wrapper-10 {
  height: 102.13px;
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
  width: 518.27px;
}

.frame .div-63 {
  align-items: center;
  display: flex;
  gap: 7.64px;
  height: 95.45px;
  position: relative;
  top: 3px;
  width: 518px;
}

.frame .frame-wrapper-11 {
  align-items: flex-start;
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  overflow: hidden;
  padding: 3.82px 11.45px 9.54px;
  position: relative;
  width: 162.26px;
}

.frame .more-horiz-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 68.72px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.frame .img-11 {
  height: 15.27px;
  position: relative;
  width: 15.27px;
}

.frame .frame-wrapper-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.73px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.25px;
  position: relative;
}

.frame .text-and-supporting-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 5.73px;
  position: relative;
  width: 61.09px;
}

.frame .text-and-supporting-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.95px;
  margin-bottom: -0.62px;
  position: relative;
}

.frame .div-64 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  margin-right: -4.19px;
  position: relative;
}

.frame .text-12 {
  color: var(--gray-900);
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 11.7px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-7 {
  align-items: center;
  background-color: #000000;
  border-radius: 1.91px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 0.95px 1.91px;
  position: relative;
}

.frame .text-wrapper-76 {
  color: #f5f5f5;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  letter-spacing: -0.14px;
  line-height: 7.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .img-12 {
  height: 7.64px;
  position: relative;
  width: 7.64px;
}

.frame .div-65 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
}

.frame .element-8 {
  background-color: #ffeaea;
  border-radius: 11.81px;
  height: 11.45px;
  overflow: hidden;
  position: relative;
  width: 11.45px;
}

.frame .overlap-group-15 {
  border-radius: 11.81px;
  height: 11px;
  position: relative;
  width: 11px;
}

.frame .img-13 {
  height: 6px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 6px;
}

.frame .element-9 {
  background-color: #f5f5f5;
  border-radius: 11.81px;
  height: 11px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 11px;
}

.frame .ellipse-25 {
  background-color: #f1f1f1;
  border-radius: 0.95px;
  height: 1.91px;
  position: relative;
  width: 1.91px;
}

.frame .supporting-text-5 {
  color: #a4a4a4;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-14 {
  align-items: flex-start;
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  box-shadow: 0.48px 0.48px 0px #2b2b2b;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
  width: 164.17px;
}

.frame .frame-wrapper-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 43.43px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-77 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .frame-wrapper-16 {
  align-items: flex-start;
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  overflow: hidden;
  padding: 9.54px 11.45px;
  position: relative;
  width: 162.26px;
}

.frame .frame-wrapper-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 43.43px;
  position: relative;
  width: 100%;
}

.frame .name-date {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.11px;
  position: relative;
}

.frame .text-13 {
  color: var(--gray-900);
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  width: 139.35px;
}

.frame .div-66 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
}

.frame .text-wrapper-78 {
  color: #575757;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-67 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.95px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-79 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .chip-speaker-6 {
  align-items: center;
  border-radius: 9.54px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 1.91px;
  position: relative;
}

.frame .div-68 {
  background-image: url(../../../static/img/ellipse-1-8.png);
  background-size: 100% 100%;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .name-6 {
  color: #464646;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 8.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chip-speaker-7 {
  align-items: center;
  border-radius: 9.54px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 1.91px 3.82px 1.91px 1.91px;
  position: relative;
}

.frame .div-69 {
  background-image: url(../../../static/img/ellipse-1-7.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .chip-speaker-8 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 9.54px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 1.91px 3.82px 1.91px 1.91px;
  position: relative;
}

.frame .name-7 {
  color: #464646;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 8.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-18 {
  align-items: flex-start;
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  margin-right: -155.58px;
  overflow: hidden;
  padding: 9.54px 11.45px;
  position: relative;
  width: 162.26px;
}

.frame .text-14 {
  color: var(--gray-900);
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11.7px;
  margin-top: -0.48px;
  position: relative;
  width: 139.35px;
}

.frame .chip-speaker-9 {
  border-radius: 9.54px;
  height: 15.27px;
  position: relative;
  width: 47.25px;
}

.frame .div-70 {
  background-image: url(../../../static/img/ellipse-1-6.png);
  background-size: 100% 100%;
  height: 11px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 11px;
}

.frame .name-8 {
  color: #464646;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  height: 9px;
  left: 15px;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.frame .div-71 {
  background-image: url(../../../static/img/ellipse-1-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .name-9 {
  color: #464646;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .name-10 {
  color: #464646;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .line-18 {
  height: 1px;
  left: 180px;
  position: absolute;
  top: 49px;
  width: 311px;
}

.frame .div-72 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 7.64px;
  left: 151px;
  overflow: hidden;
  position: absolute;
  top: 61px;
}

.frame .div-73 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.27px;
  position: relative;
  width: 334.06px;
}

.frame .div-74 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 22.91px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .div-75 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  position: relative;
  width: 146.03px;
}

.frame .div-wrapper-8 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.95px;
  border-color: #575757;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  justify-content: center;
  padding: 3.82px 5.73px;
  position: relative;
}

.frame .text-wrapper-80 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.95px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  justify-content: center;
  opacity: 0.32;
  padding: 3.82px;
  position: relative;
}

.frame .text-wrapper-81 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  justify-content: center;
  margin-right: -0.69px;
  opacity: 0.32;
  padding: 3.82px;
  position: relative;
}

.frame .div-76 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 11.45px;
  position: relative;
}

.frame .text-input {
  align-items: center;
  border: 0.24px solid;
  border-color: #9f9f9f;
  border-radius: 1.91px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 2.86px;
  padding: 3.82px 5.73px;
  position: relative;
}

.frame .img-14 {
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .value {
  color: #9f9f9f;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.5px;
  margin-top: -0.24px;
  position: relative;
  width: 73.49px;
}

.frame .frame-wrapper-19 {
  align-items: center;
  background-color: #4b4b4b;
  border: 0.24px solid;
  border-color: #4b4b4b;
  border-radius: 14.32px;
  display: flex;
  flex-direction: column;
  gap: 0.95px;
  height: 19.09px;
  justify-content: center;
  overflow: hidden;
  padding: 5.73px 9.54px 5.73px 5.73px;
  position: relative;
  width: 41.52px;
}

.frame .div-77 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  margin-bottom: -1.91px;
  margin-left: -2.06px;
  margin-right: -2.06px;
  margin-top: -1.91px;
  position: relative;
}

.frame .text-wrapper-82 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .line-19 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.76px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.45px;
  position: relative;
  width: 100%;
}

.frame .div-78 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 230.98px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .dropdown {
  align-items: flex-start;
  border-radius: 2.86px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.64px;
  justify-content: center;
  padding: 1.91px 0px;
  position: relative;
}

.frame .div-79 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  position: relative;
}

.frame .text-wrapper-83 {
  color: #9f9f9f;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-80 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.95px;
  position: relative;
}

.frame .text-wrapper-84 {
  color: #828282;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .img-15 {
  flex: 0 0 auto;
  position: relative;
}

.frame .div-81 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.54px;
  position: relative;
  width: 100%;
}

.frame .div-82 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.54px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-21 {
  align-items: flex-start;
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.73px;
  height: 95.45px;
  overflow: hidden;
  padding: 3.82px 11.45px 9.54px;
  position: relative;
}

.frame .frame-wrapper-22 {
  align-items: flex-start;
  display: flex;
  gap: 5.25px;
  position: relative;
  width: 60.61px;
}

.frame .text-and-supporting-wrapper-3 {
  align-items: flex-start;
  display: flex;
  gap: 5.73px;
  margin-right: -0.48px;
  position: relative;
  width: 61.09px;
}

.frame .text-and-supporting-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.95px;
  position: relative;
}

.frame .img-16 {
  height: 6px;
  left: -4446px;
  position: absolute;
  top: -3882px;
  width: 6px;
}

.frame .frame-wrapper-23 {
  align-items: flex-start;
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #ff6c7d;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.73px;
  height: 95.45px;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
}

.frame .img-17 {
  height: 6px;
  left: -4446px;
  position: absolute;
  top: -3988px;
  width: 6px;
}

.frame .more-horiz-3 {
  height: 15.27px;
  margin-left: -4325.22px;
  margin-top: -4024.53px;
  position: relative;
  width: 15.27px;
}

.frame .star-4 {
  height: 7.64px;
  margin-left: -4391.67px;
  margin-top: -4080.1px;
  position: relative;
  width: 7.64px;
}

.frame .img-18 {
  height: 6px;
  left: -4446px;
  position: absolute;
  top: -4093px;
  width: 6px;
}

.frame .more-horiz-4 {
  height: 15.27px;
  margin-left: -4497.03px;
  margin-top: -4024.53px;
  position: relative;
  width: 15.27px;
}

.frame .star-5 {
  height: 7.64px;
  margin-left: -4563.47px;
  margin-top: -4080.1px;
  position: relative;
  width: 7.64px;
}

.frame .img-19 {
  height: 6px;
  left: -4618px;
  position: absolute;
  top: -4093px;
  width: 6px;
}

.frame .frame-wrapper-24 {
  align-items: flex-start;
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.73px;
  height: 95.45px;
  overflow: hidden;
  padding: 9.54px 11.45px;
  position: relative;
}

.frame .more-horiz-5 {
  height: 15.27px;
  margin-left: -4325.22px;
  margin-top: -4135.25px;
  position: relative;
  width: 15.27px;
}

.frame .frame-wrapper-25 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 5.25px;
  position: relative;
  width: 60.61px;
}

.frame .text-and-supporting-wrapper-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 5.73px;
  margin-right: -0.48px;
  position: relative;
  width: 61.09px;
}

.frame .star-6 {
  height: 7.64px;
  margin-left: -4391.67px;
  margin-top: -4184.47px;
  position: relative;
  width: 7.64px;
}

.frame .img-20 {
  height: 6px;
  left: -4446px;
  position: absolute;
  top: -4197px;
  width: 6px;
}

.frame .more-horiz-6 {
  height: 15.27px;
  margin-left: -4497.03px;
  margin-top: -4135.25px;
  position: relative;
  width: 15.27px;
}

.frame .star-7 {
  height: 7.64px;
  margin-left: -4563.47px;
  margin-top: -4184.47px;
  position: relative;
  width: 7.64px;
}

.frame .img-21 {
  height: 6px;
  left: -4618px;
  position: absolute;
  top: -4197px;
  width: 6px;
}

.frame .frame-wrapper-26 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 15.11px;
  height: 276px;
  left: 516px;
  position: absolute;
  top: 50px;
}

.frame .frame-wrapper-27 {
  align-items: flex-start;
  border: 0.24px solid;
  border-color: #9f9f9f;
  border-radius: 5.66px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 26.44px;
  overflow: hidden;
  padding: 11.33px;
  position: relative;
  width: 154.84px;
}

.frame .div-83 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.55px;
  position: relative;
  width: 100%;
}

.frame .div-wrapper-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.78px;
  position: relative;
}

.frame .text-wrapper-85 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 600;
  letter-spacing: -0.14px;
  line-height: 15.1px;
  margin-top: -0.47px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .bar-2 {
  align-items: center;
  border: 0.24px solid;
  border-color: #555555;
  border-radius: 1.89px;
  display: flex;
  gap: 0.94px;
  padding: 3.78px;
  position: relative;
  width: 33.04px;
}

.frame .text-15 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.2px;
  position: relative;
  width: fit-content;
}

.frame .chevron-2 {
  height: 7.08px;
  margin-right: -3.01px;
  position: relative;
  width: 7.55px;
}

.frame .line-20 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.frame .div-84 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.94px;
  position: relative;
  width: 100%;
}

.frame .div-85 {
  align-items: center;
  align-self: stretch;
  border-radius: 5.66px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 3.78px 5.66px;
  position: relative;
  width: 100%;
}

.frame .div-86 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.78px;
  position: relative;
}

.frame .div-87 {
  background-color: #00000033;
  border-radius: 9.44px;
  height: 18.88px;
  margin-left: -0.09px;
  position: relative;
  width: 18.88px;
}

.frame .ellipse-26 {
  background-color: #047857;
  border: 0.67px solid;
  border-color: #ffffff;
  border-radius: 2.36px;
  height: 5px;
  left: 14px;
  position: relative;
  top: 15px;
  width: 5px;
}

.frame .div-88 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.89px;
  position: relative;
}

.frame .div-89 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.89px;
  position: relative;
}

.frame .text-wrapper-86 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 6.6px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 8.5px;
  margin-top: -0.47px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .expand-less-2 {
  height: 7.55px;
  position: relative;
  width: 7.55px;
}

.frame .text-wrapper-87 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: -0.08px;
  line-height: 7.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-12 {
  height: 18.88px;
  position: relative;
  width: 18.88px;
}

.frame .overlap-group-16 {
  background-image: url(../../../static/img/polygon-2-9.svg);
  background-size: 100% 100%;
  height: 18px;
  left: 1px;
  position: relative;
  width: 16px;
}

.frame .polygon-3 {
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.frame .text-wrapper-88 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 8.1px;
  font-weight: 800;
  height: 11px;
  left: 6px;
  letter-spacing: -0.12px;
  line-height: normal;
  position: absolute;
  top: 3px;
}

.frame .text-wrapper-89 {
  color: #808080;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: -0.08px;
  line-height: 7.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .overlap-group-17 {
  background-image: url(../../../static/img/polygon-2-8.svg);
  background-size: 100% 100%;
  height: 18px;
  left: 1px;
  position: relative;
  width: 16px;
}

.frame .text-wrapper-90 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 8.1px;
  font-weight: 800;
  height: 11px;
  left: 5px;
  letter-spacing: -0.12px;
  line-height: normal;
  position: absolute;
  top: 3px;
}

.frame .text-wrapper-91 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 6.1px;
  font-weight: 700;
  height: 8px;
  left: 6px;
  letter-spacing: -0.09px;
  line-height: normal;
  position: absolute;
  top: 1px;
}

.frame .overlap-group-18 {
  background-image: url(../../../static/img/polygon-2-7.svg);
  background-size: 100% 100%;
  height: 18px;
  left: 1px;
  position: relative;
  width: 16px;
}

.frame .div-90 {
  align-items: center;
  align-self: stretch;
  background-color: #f4f4f4;
  border-radius: 3.78px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 3.78px 5.66px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-92 {
  color: #555555;
  font-family: "Manrope", Helvetica;
  font-size: 8.1px;
  font-weight: 800;
  height: 11px;
  left: 7px;
  letter-spacing: -0.12px;
  line-height: normal;
  position: absolute;
  top: 3px;
}

.frame .div-91 {
  align-items: center;
  align-self: stretch;
  background-color: #555555;
  border-radius: 1.89px;
  display: flex;
  gap: 1.89px;
  height: 18.88px;
  justify-content: center;
  overflow: hidden;
  padding: 5.66px 15.11px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-93 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.6px;
  font-weight: 800;
  letter-spacing: -0.1px;
  line-height: 8.5px;
  margin-bottom: -0.25px;
  margin-top: -1.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .arrow-back-5 {
  height: 6.61px;
  position: relative;
  width: 6.61px;
}

.frame .div-92 {
  align-items: center;
  display: inline-flex;
  gap: 163.21px;
  left: 157px;
  position: absolute;
  top: 17px;
}

.frame .div-93 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 334.06px;
}

.frame .text-wrapper-94 {
  color: #626262;
  font-family: "Inter", Helvetica;
  font-size: 15.3px;
  font-weight: 600;
  letter-spacing: -0.38px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-94 {
  align-items: center;
  border-radius: 1.91px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.73px;
  position: relative;
}

.frame .div-wrapper-13 {
  align-items: center;
  border: 0.48px solid;
  border-color: #626262;
  border-radius: 1.91px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 5.73px 7.64px;
  position: relative;
}

.frame .text-wrapper-95 {
  color: #626262;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: 9.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .icon-button-2 {
  height: 21px;
  position: relative;
  width: 21px;
}

.frame .div-95 {
  align-items: flex-start;
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: 0.24px;
  display: flex;
  flex-direction: column;
  gap: 19.09px;
  height: 449px;
  left: 0;
  padding: 5.73px 0px;
  position: absolute;
  top: 0;
  width: 137px;
}

.frame .div-96 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  overflow: hidden;
  padding: 7.64px 19.09px;
  position: relative;
  width: 134.58px;
}

.frame .img-22 {
  height: 15.27px;
  margin-left: -4288px;
  margin-top: -3692.72px;
  position: relative;
  width: 15.27px;
}

.frame .text-wrapper-96 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-97 {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex: 0 0 auto;
  gap: 3.82px;
  overflow: hidden;
  padding: 7.64px 19.09px;
  position: relative;
  width: 134.58px;
}

.frame .img-23 {
  height: 15.27px;
  margin-left: -4288px;
  margin-top: -3723.27px;
  position: relative;
  width: 15.27px;
}

.frame .div-98 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 7.64px 19.09px;
  position: relative;
  width: 134.58px;
}

.frame .rectangle-5 {
  border: 0.95px solid;
  border-color: #575757;
  border-radius: 0.95px;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 12px;
}

.frame .polygon-4 {
  height: 4px;
  left: -4300px;
  position: absolute;
  top: -3756px;
  width: 3px;
}

.frame .img-24 {
  height: 15.27px;
  margin-left: -4288px;
  margin-top: -3784.35px;
  position: relative;
  width: 15.27px;
}

.frame .group-4 {
  height: 10px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 10px;
}

.frame .overlap-group-19 {
  height: 12px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 12px;
}

.frame .ellipse-27 {
  border: 0.95px solid;
  border-color: #575757;
  border-radius: 2.43px;
  height: 5px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 5px;
}

.frame .ellipse-28 {
  border: 0.95px solid;
  border-color: #575757;
  border-radius: 2.43px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 5px;
}

.frame .ellipse-29 {
  border: 0.95px solid;
  border-color: #575757;
  border-radius: 2.43px;
  height: 5px;
  left: 7px;
  position: absolute;
  top: 3px;
  width: 5px;
}

.frame .ellipse-30 {
  border: 0.95px solid;
  border-color: #575757;
  border-radius: 2.43px;
  height: 5px;
  left: 3px;
  position: absolute;
  top: 7px;
  width: 5px;
}

.frame .group-5 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 11px;
  left: -4305px;
  position: relative;
  top: -3851px;
  width: 11px;
}

.frame .div-99 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f3f3f3;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.27px;
  justify-content: center;
  padding: 11.45px 19.09px 15.27px;
  position: relative;
  width: 100%;
}

.frame .div-100 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.73px;
  position: relative;
}

.frame .div-101 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
  width: 96.4px;
}

.frame .text-wrapper-97 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .arrow-drop-down {
  height: 11.45px;
  margin-left: -4277.18px;
  margin-top: -3906.52px;
  position: relative;
  width: 11.45px;
}

.frame .div-102 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.64px;
  position: relative;
}

.frame .frame-wrapper-28 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 0px 5.73px;
  position: relative;
  width: 96.4px;
}

.frame .text-wrapper-98 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .arrow-drop-down-2 {
  height: 11.45px;
  margin-left: -4278.91px;
  margin-top: -3923.7px;
  position: relative;
  width: 11.45px;
}

.frame .img-25 {
  flex: 0 0 auto;
  margin-left: -4307.09px;
  margin-top: -3906.52px;
  position: relative;
  width: 96.4px;
}

.frame .arrow-drop-down-3 {
  height: 9.54px;
  margin-left: -4293.91px;
  margin-top: -3965.7px;
  position: relative;
  width: 9.54px;
}

.frame .img-26 {
  flex: 0 0 auto;
  margin-left: -4307.09px;
  margin-top: -3950.43px;
  position: relative;
  width: 96.4px;
}

.frame .div-wrapper-14 {
  align-items: center;
  align-self: stretch;
  background-color: #555555;
  border-radius: 1.91px;
  display: flex;
  gap: 1.91px;
  height: 19.09px;
  justify-content: center;
  overflow: hidden;
  padding: 5.73px 15.27px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-99 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 800;
  letter-spacing: -0.1px;
  line-height: 8.6px;
  margin-bottom: -0.2px;
  margin-top: -1.16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-103 {
  align-items: center;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 0.24px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 7.64px 19.09px;
  position: absolute;
  top: 0;
  width: 687px;
}

.frame .div-wrapper-15 {
  border-radius: 2.52px;
  height: 23.76px;
  position: relative;
  width: 37.17px;
}

.frame .text-wrapper-100 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.3px;
  line-height: 15.1px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.frame .div-104 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.73px;
  position: relative;
}

.frame .div-105 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  padding: 0px 3.82px;
  position: relative;
}

.frame .ellipse-wrapper-3 {
  background-color: #d9d9d9;
  border-radius: 11.45px;
  height: 22.91px;
  position: relative;
  width: 22.91px;
}

.frame .ellipse-31 {
  background-color: #000000;
  border: 0.48px solid;
  border-color: #ffffff;
  border-radius: 2.86px;
  height: 6px;
  left: 17px;
  position: relative;
  top: 17px;
  width: 6px;
}

.frame .text-wrapper-101 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .resources-user-view {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: 822px;
  overflow: hidden;
  position: absolute;
  top: 1656px;
  width: 705px;
}

.frame .overlap-11 {
  height: 449px;
  left: -1px;
  position: absolute;
  top: 41px;
  width: 689px;
}

.frame .rectangle-6 {
  background-color: #fbfaf8;
  height: 447px;
  left: 136px;
  position: absolute;
  top: 1px;
  width: 553px;
}

.frame .div-106 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.24px solid;
  border-color: #d9d9d9;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 11.46px;
  justify-content: center;
  left: 514px;
  overflow: hidden;
  padding: 7.64px 7.64px 11.46px;
  position: absolute;
  top: 110px;
  width: 156.6px;
}

.frame .div-107 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-102 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .subtitle-wrapper {
  align-items: flex-start;
  background-color: #a4a4a4;
  border-radius: 15.28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  overflow: hidden;
  padding: 2.86px;
  position: relative;
}

.frame .subtitle {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .frame-wrapper-29 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.73px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-30 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 19.1px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.frame .div-108 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.frame .avatar-w-photo-6 {
  background-image: url(../../../static/img/avatar-w-photo-4.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 0.48px solid;
  border-color: #0000001a;
  border-radius: 476.96px;
  height: 17.19px;
  position: relative;
  width: 17.19px;
}

.frame .outside-stroke-2 {
  border: 0.95px solid;
  border-color: var(--black-white-white);
  border-radius: 9.55px;
  height: 19px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 19px;
}

.frame .avatar-w-photo-7 {
  background-image: url(../../../static/img/avatar-w-photo-3.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 0.48px solid;
  border-color: #0000001a;
  border-radius: 476.96px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
  width: 17.19px;
}

.frame .avatar-w-photo-8 {
  background-image: url(../../../static/img/avatar-w-photo-2.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 0.48px solid;
  border-color: #0000001a;
  border-radius: 476.96px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
  width: 17.19px;
}

.frame .avatar-w-photo-9 {
  background-image: url(../../../static/img/avatar-w-photo-1.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 0.48px solid;
  border-color: #0000001a;
  border-radius: 476.96px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
  width: 17.19px;
}

.frame .avatar-w-photo-wrapper-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  margin-left: -2.86px;
  position: relative;
}

.frame .avatar-w-photo-10 {
  background-image: url(../../../static/img/avatar-w-photo.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 0.48px solid;
  border-color: #0000001a;
  border-radius: 476.96px;
  height: 17.19px;
  position: relative;
  width: 17.19px;
}

.frame .number-wrapper {
  background-color: #ffffff;
  border: 0.48px solid;
  border-color: var(--light-gray-light-gray-1);
  border-radius: 476.96px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
  width: 17.19px;
}

.frame .number-3 {
  color: var(--dark-gray-dark-gray-1);
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: 7.6px;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
}

.frame .frame-wrapper-31 {
  align-items: center;
  background-color: #4b4b4b;
  border: 0.24px solid;
  border-color: #4b4b4b;
  border-radius: 14.32px;
  display: flex;
  flex-direction: column;
  gap: 0.95px;
  height: 19.1px;
  justify-content: center;
  overflow: hidden;
  padding: 5.73px 9.55px 5.73px 5.73px;
  position: relative;
  width: 41.54px;
}

.frame .div-109 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  margin-bottom: -1.91px;
  margin-left: -1.05px;
  margin-right: -1.05px;
  margin-top: -1.91px;
  position: relative;
}

.frame .add-5 {
  height: 11.46px;
  position: relative;
  width: 11.46px;
}

.frame .comments {
  align-items: flex-start;
  border-radius: 5.73px;
  display: flex;
  flex-direction: column;
  gap: 7.64px;
  left: 514px;
  overflow: hidden;
  padding: 7.64px 5.73px;
  position: absolute;
  top: 178px;
  width: 157px;
}

.frame .div-110 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.55px;
  position: relative;
  width: 100%;
}

.frame .title-wrapper {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 5.73px;
  position: relative;
  width: 141.32px;
}

.frame .title-4 {
  color: var(--neutral-800);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
}

.frame .div-111 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.64px;
  position: relative;
  width: 100%;
}

.frame .thread-line {
  height: 161px;
  left: 7px;
  position: absolute;
  top: 18px;
  width: 1px;
}

.frame .div-112 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.82px;
  position: relative;
  width: 100%;
}

.frame .comment {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
  width: 100%;
}

.frame .avatar-9 {
  height: 15.28px;
  object-fit: cover;
  position: relative;
  width: 15.28px;
}

.frame .content-5 {
  align-items: flex-start;
  background-color: var(--neutral-50);
  border-radius: 4.77px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.73px;
  padding: 3.82px 7.64px;
  position: relative;
}

.frame .paragraph-2 {
  align-self: stretch;
  color: var(--neutral-800);
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 7.6px;
  margin-top: -0.48px;
  position: relative;
}

.frame .text-wrapper-103 {
  color: var(--neutral-500);
  font-family: "Manrope", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 8.6px;
  margin-top: -0.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .settings {
  height: 9.55px;
  position: relative;
  width: 14.8px;
}

.frame .group-6 {
  height: 2px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 10px;
}

.frame .ellipse-32 {
  background-color: var(--neutral-300);
  border-radius: 1.19px;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.frame .ellipse-33 {
  background-color: var(--neutral-300);
  border-radius: 1.19px;
  height: 2px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 2px;
}

.frame .ellipse-34 {
  background-color: var(--neutral-300);
  border-radius: 1.19px;
  height: 2px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 2px;
}

.frame .dropdown-2 {
  align-items: center;
  align-self: stretch;
  border-radius: 3.82px;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 0px 15.28px;
  position: relative;
  width: 100%;
}

.frame .label-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 1.91px;
  position: relative;
}

.frame .label-7 {
  color: #818181;
  font-family: "Manrope", Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  letter-spacing: 0.11px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame .icons-30 {
  height: 9.55px;
  position: relative;
  width: 9.55px;
}

.frame .comment-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 0px 0px 0px 11.46px;
  position: relative;
  width: 100%;
}

.frame .input-field {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.82px;
  position: relative;
  width: 100%;
}

.frame .search-bar-3 {
  align-items: center;
  border: 0.48px solid;
  border-color: #d9d9d9;
  border-radius: 1.91px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 3.82px;
  height: 19.1px;
  padding: 3.82px 5.73px;
  position: relative;
}

.frame .text-wrapper-104 {
  color: #909090;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 7.6px;
  position: relative;
}

.frame .CTA-3 {
  align-items: center;
  background-color: #575757;
  border-radius: 2.86px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  height: 19.1px;
  justify-content: center;
  padding: 2.86px 7.64px;
  position: relative;
}

.frame .text-wrapper-105 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 7.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .slider {
  height: 122px;
  left: 153px;
  position: absolute;
  top: -1px;
  width: 3px;
}

.frame .rectangle-7 {
  background-color: #b8c0cc;
  border-radius: 47.74px;
  height: 20px;
  left: 1px;
  position: relative;
  top: 28px;
  width: 1px;
}

.frame .div-113 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 151px;
  position: absolute;
  top: 16px;
  width: 339px;
}

.frame .div-wrapper-16 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 68.75px;
  position: relative;
}

.frame .text-wrapper-106 {
  color: #626262;
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .img-27 {
  height: 15.28px;
  position: relative;
  width: 15.28px;
}

.frame .div-114 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 63.02px;
}

.frame .icon-button-3 {
  height: 21.01px;
  position: relative;
  width: 21.01px;
}

.frame .rectangle-8 {
  background-color: #d9d9d9;
  border-radius: 5.73px;
  height: 354px;
  left: 151px;
  position: absolute;
  top: 60px;
  width: 339px;
}

.frame .line-21 {
  height: 447px;
  left: 502px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.frame .line-22 {
  height: 1px;
  left: 136px;
  position: absolute;
  top: 49px;
  width: 366px;
}

.frame .div-115 {
  align-items: flex-start;
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: 0.24px;
  display: flex;
  flex-direction: column;
  height: 449px;
  left: 0;
  padding: 5.73px 0px;
  position: absolute;
  top: 0;
  width: 137px;
}

.frame .div-116 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 7.64px 19.1px;
  position: relative;
  width: 100%;
}

.frame .div-117 {
  align-items: center;
  align-self: stretch;
  background-color: #f5f5f5;
  display: flex;
  flex: 0 0 auto;
  gap: 3.82px;
  padding: 7.64px 19.1px;
  position: relative;
  width: 100%;
}

.frame .polygon-wrapper {
  border: 0.95px solid;
  border-color: #575757;
  border-radius: 0.95px;
  height: 11px;
  left: 1px;
  position: relative;
  top: 2px;
  width: 13px;
}

.frame .polygon-5 {
  height: 3px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 3px;
}

.frame .div-118 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11.46px;
  left: 514px;
  overflow: hidden;
  padding: 0px 7.64px;
  position: absolute;
  top: 16px;
  width: 157px;
}

.frame .top-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.73px;
  justify-content: center;
  position: relative;
  width: 141.32px;
}

.frame .title-5 {
  align-self: stretch;
  color: var(--neutral-800);
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
}

.frame .paragraph-3 {
  align-self: stretch;
  color: var(--neutral-800);
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 9.5px;
  position: relative;
}

.frame .text-wrapper-107 {
  color: #9f9f9f;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 9.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .base-status-badge {
  align-items: flex-start;
  background-color: var(--infosurface);
  border-radius: 47.74px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 2.86px 3.82px;
  position: relative;
}

.frame .text-wrapper-108 {
  color: var(--infotext);
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 6.7px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-119 {
  align-items: center;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 0.24px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 7.64px 19.1px;
  position: absolute;
  top: 0;
  width: 688px;
}

.frame .ellipse-wrapper-4 {
  background-color: #d9d9d9;
  border-radius: 11.46px;
  height: 22.92px;
  position: relative;
  width: 22.92px;
}

.frame .speaker {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: 554px;
  overflow: hidden;
  position: absolute;
  top: 1656px;
  width: 237px;
}

.frame .overlap-12 {
  height: 400px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.frame .rectangle-9 {
  background-color: var(--x01-neutral10);
  height: 391px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.frame .div-120 {
  background-image: url(../../../static/img/base-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 391px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.frame .div-121 {
  align-items: flex-start;
  display: inline-flex;
  gap: 7.33px;
  left: 7px;
  position: absolute;
  top: 15px;
}

.frame .frame-wrapper-32 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.77px;
  position: relative;
}

.frame .heading-and {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.01px;
  position: relative;
  width: 173.64px;
}

.frame .heading-and-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.01px;
  position: relative;
  width: 100%;
}

.frame .heading-and-icon {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.68px;
  position: relative;
  width: 100%;
}

.frame .heading {
  color: #000000;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 13.4px;
  margin-top: -0.42px;
  position: relative;
}

.frame .div-122 {
  height: 93px;
  left: 2px;
  position: absolute;
  top: 307px;
  width: 216px;
}

.frame .overlap-13 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.frame .rectangle-10 {
  background-color: #00000033;
  border-radius: 2.44px;
  height: 70px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 62px;
}

.frame .arrow-back-ios-new-wrapper {
  background-color: #2b2b2b;
  border-radius: 9.77px;
  height: 15px;
  left: 0;
  position: absolute;
  top: 20px;
  width: 15px;
}

.frame .arrow-back-ios-new {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.frame .dots-horizontal-2 {
  height: 12px;
  left: 56px;
  position: absolute;
  top: 12px;
  width: 12px;
}

.frame .dots-horizontal-wrapper {
  background-color: #00000033;
  border-radius: 2.44px;
  height: 70px;
  left: 77px;
  position: absolute;
  top: 0;
  width: 62px;
}

.frame .dots-horizontal-3 {
  height: 12px;
  left: 49px;
  position: absolute;
  top: 12px;
  width: 12px;
}

.frame .overlap-14 {
  height: 70px;
  left: 147px;
  position: absolute;
  top: 0;
  width: 69px;
}

.frame .rectangle-11 {
  background-color: #00000033;
  border-radius: 2.44px;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 62px;
}

.frame .arrow-back-ios-new-wrapper-2 {
  background-color: var(--x01-neutral20);
  border-radius: 9.77px;
  height: 15px;
  left: 54px;
  position: absolute;
  top: 20px;
  transform: rotate(-180deg);
  width: 15px;
}

.frame .arrow-back-ios-new-2 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 15px;
}

.frame .div-123 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  left: 13px;
  position: absolute;
  top: 75px;
}

.frame .div-124 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.61px;
  position: relative;
}

.frame .ellipse-wrapper-5 {
  height: 9.77px;
  position: relative;
  width: 9.77px;
}

.frame .ellipse-35 {
  height: 4px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 4px;
}

.frame .text-wrapper-109 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12.2px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-110 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 5.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-125 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  left: 83px;
  position: absolute;
  top: 75px;
}

.frame .div-126 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  left: 153px;
  position: absolute;
  top: 75px;
}

.frame .div-127 {
  background-color: #00000033;
  border-radius: 4.89px;
  height: 240px;
  left: 10px;
  position: absolute;
  top: 54px;
  width: 200px;
}

.frame .div-128 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 2.44px;
  justify-content: center;
  left: 63px;
  position: absolute;
  top: 206px;
}

.frame .div-129 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.07px;
  position: relative;
}

.frame .ellipse-wrapper-6 {
  height: 10.71px;
  position: relative;
  width: 10.71px;
}

.frame .text-wrapper-111 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 7.3px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10.7px;
  margin-top: -0.54px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.35px;
  position: relative;
}

.frame .text-wrapper-112 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10.7px;
  margin-top: -0.54px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-18 {
  align-items: center;
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 2.14px;
  display: inline-flex;
  gap: 5.35px;
  justify-content: center;
  left: 160px;
  overflow: hidden;
  padding: 3.21px 4.28px;
  position: absolute;
  top: 6px;
}

.frame .text-wrapper-113 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10.7px;
  margin-top: -0.54px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .toolbar {
  align-items: center;
  background-color: #000000a3;
  display: flex;
  gap: 7.33px;
  height: 40px;
  left: 0;
  padding: 7.33px 14.66px;
  position: absolute;
  top: 450px;
  width: 222px;
}

.frame .frame-wrapper-33 {
  align-items: center;
  align-self: stretch;
  background-color: #a3a3a3;
  border-radius: 2.64px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  padding: 7.33px 4.76px;
  position: relative;
}

.frame .div-130 {
  height: 11.91px;
  margin-bottom: -0.62px;
  margin-top: -0.62px;
  position: relative;
  width: 11.89px;
}

.frame .img-28 {
  height: 10px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 10px;
}

.frame .keyboard-voice-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #5d5d5d;
  border-radius: 2.64px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 7.33px 4.76px;
  position: relative;
}

.frame .keyboard-voice {
  height: 11.89px;
  margin-bottom: -1.22px;
  position: relative;
  width: 11.89px;
}

.frame .frame-wrapper-34 {
  align-items: center;
  align-self: stretch;
  background-color: #5d5d5d;
  border-radius: 2.64px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  padding: 7.33px 4.76px;
  position: relative;
}

.frame .overlap-group-20 {
  height: 11px;
  left: 1px;
  position: relative;
  width: 11px;
}

.frame .chat-bubble {
  height: 10px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 10px;
}

.frame .div-wrapper-19 {
  background-color: #d9d9d9;
  border-radius: 2.38px;
  height: 5px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 5px;
}

.frame .text-wrapper-114 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 3.6px;
  font-weight: 700;
  height: 3px;
  left: 1px;
  letter-spacing: -0.01px;
  line-height: 3.6px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 2px;
}

.frame .div-131 {
  align-items: center;
  display: inline-flex;
  gap: 4.89px;
  left: 68px;
  position: absolute;
  top: 424px;
}

.frame .overlap-group-wrapper-3 {
  background-color: #dededea1;
  border-radius: 6.11px;
  height: 12.22px;
  position: relative;
  width: 12.22px;
}

.frame .overlap-group-21 {
  background-color: #dededea1;
  border-radius: 5.09px;
  height: 10px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 10px;
}

.frame .ellipse-36 {
  background-color: #000000;
  border-radius: 3.56px;
  height: 7px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 7px;
}

.frame .div-132 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.05px;
  position: relative;
}

.frame .text-wrapper-115 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 7.3px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 8.8px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .course-details {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: 1696px;
  overflow: hidden;
  position: absolute;
  top: 1112px;
  width: 249px;
}

.frame .div-133 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.15px;
  left: 10px;
  overflow: hidden;
  position: absolute;
  top: 11px;
  width: 211px;
}

.frame .frame-wrapper-35 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.3px;
  position: relative;
}

.frame .arrow-back-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.15px;
  position: relative;
}

.frame .arrow-back-6 {
  height: 15.45px;
  position: relative;
  width: 15.45px;
}

.frame .div-134 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.14px;
  padding: 0px 4.07px;
  position: relative;
  width: 210.74px;
}

.frame .div-wrapper-20 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.15px;
  position: relative;
}

.frame .text-wrapper-116 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  letter-spacing: -0.02px;
  line-height: 14.4px;
  margin-top: -0.64px;
  position: relative;
  width: 190.58px;
}

.frame .content-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.58px;
  position: relative;
}

.frame .avatar-10 {
  background-image: url(../../../static/img/subtract.svg);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.frame .name-11 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .container-menu {
  align-items: center;
  display: flex;
  height: 21px;
  left: 11px;
  overflow-x: scroll;
  position: absolute;
  top: 227px;
  width: 207px;
}

.frame .navlink {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.15px;
  justify-content: center;
  padding: 5.15px;
  position: relative;
}

.frame .text-wrapper-117 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 9.3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .navlink-2 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.58px;
  justify-content: center;
  padding: 5.15px;
  position: relative;
}

.frame .navlink-3 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.29px;
  border-color: #000000;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.15px;
  justify-content: center;
  padding: 5.15px;
  position: relative;
}

.frame .text-wrapper-118 {
  color: #1c1b1f;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  letter-spacing: -0.02px;
  line-height: 9.3px;
  margin-top: -0.25px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-36 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10.3px;
  left: 12px;
  position: absolute;
  top: 256px;
  width: 207px;
}

.frame .div-135 {
  align-items: center;
  align-self: stretch;
  background-color: var(--x01-neutral98);
  border: 0.64px solid;
  border-color: var(--x02-neutral80);
  border-radius: 7.73px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.88px;
  justify-content: center;
  padding: 15.45px 24.59px;
  position: relative;
  width: 100%;
}

.frame .overlap-wrapper-2 {
  height: 116.89px;
  position: relative;
  width: 121.04px;
}

.frame .overlap-15 {
  height: 89px;
  left: 14px;
  position: relative;
  top: 13px;
  width: 92px;
}

.frame .overlap-group-wrapper-4 {
  height: 89px;
  left: 0;
  position: absolute;
  top: 0;
  width: 92px;
}

.frame .overlap-group-22 {
  height: 107px;
  left: -7px;
  position: relative;
  top: -7px;
  width: 107px;
}

.frame .ellipse-37 {
  height: 53px;
  left: 0;
  position: absolute;
  top: 0;
  width: 53px;
}

.frame .ellipse-38 {
  height: 107px;
  left: 0;
  position: absolute;
  top: 0;
  width: 107px;
}

.frame .text-wrapper-119 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 36.1px;
  font-weight: 700;
  height: 48px;
  left: 24px;
  letter-spacing: -0.43px;
  line-height: 50.5px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 45px;
}

.frame .div-136 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.3px;
  position: relative;
  width: 100%;
}

.frame .div-137 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.08px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-120 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  letter-spacing: -0.02px;
  line-height: 12.4px;
  margin-top: -0.64px;
  position: relative;
  text-align: center;
}

.frame .text-wrapper-121 {
  align-self: stretch;
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 9.3px;
  position: relative;
  text-align: center;
}

.frame .div-wrapper-21 {
  align-items: center;
  align-self: stretch;
  border: 0.64px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.58px;
  display: flex;
  flex: 0 0 auto;
  gap: 5.15px;
  justify-content: center;
  padding: 10.3px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-122 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 10.8px;
  margin-top: -0.64px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .video-player {
  background-color: #0000000a;
  border-radius: 5.27px;
  height: 120px;
  left: 12px;
  overflow: hidden;
  position: absolute;
  top: 95px;
  width: 207px;
}

.frame .div-138 {
  align-items: center;
  display: inline-flex;
  gap: 5.15px;
  left: 10px;
  position: absolute;
  top: 100px;
}

.frame .pause {
  height: 10.3px;
  position: relative;
  width: 10.3px;
}

.frame .overlap-group-wrapper-5 {
  background-color: #5555554c;
  border-radius: 2.64px;
  height: 3.86px;
  position: relative;
  width: 177.27px;
}

.frame .overlap-group-23 {
  border-radius: 2.58px;
  height: 4px;
  position: relative;
  width: 177px;
}

.frame .buffering {
  border-radius: 2.58px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 177px;
}

.frame .buffering-progress {
  background-color: #555555;
  border-radius: 2.58px;
  height: 4px;
  opacity: 0.5;
  width: 133px;
}

.frame .progress-line {
  background-color: #555555;
  border-radius: 2.58px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.frame .div-139 {
  align-items: center;
  display: inline-flex;
  gap: 5.15px;
  left: 129px;
  position: absolute;
  top: 5px;
}

.frame .div-wrapper-22 {
  align-items: center;
  background-color: var(--x01-neutral40);
  border-radius: 16.74px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.44px;
  justify-content: center;
  padding: 3.86px 5.15px;
  position: relative;
}

.frame .text-wrapper-123 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 6.4px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 7.7px;
  margin-top: -0.64px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .open-in-full-wrapper {
  background-color: var(--x01-neutral40);
  border-radius: 15.94px;
  height: 15.45px;
  position: relative;
  width: 15.45px;
}

.frame .open-in-full {
  height: 10px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 10px;
}

.frame .auditorium-sessions {
  background-color: var(--x01-neutral10);
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: 1557px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 1656px;
  width: 705px;
}

.frame .overlap-16 {
  height: 1047px;
  left: 119px;
  position: absolute;
  top: 38px;
  width: 568px;
}

.frame .base {
  background-color: #ffffff;
  height: 912px;
  left: 0;
  position: absolute;
  top: 0;
  width: 568px;
}

.frame .header {
  align-items: center;
  display: flex;
  gap: 88.29px;
  left: 23px;
  overflow: hidden;
  position: absolute;
  top: 19px;
  width: 526px;
}

.frame .text-wrapper-124 {
  color: #575757;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.frame .div-140 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.64px;
  position: relative;
}

.frame .text-input-2 {
  align-items: center;
  align-self: stretch;
  border: 0.48px solid;
  border-color: #a3a3a3;
  border-radius: 1.91px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 5.73px;
  position: relative;
}

.frame .div-141 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  position: relative;
}

.frame .search {
  height: 9.54px;
  position: relative;
  width: 9.54px;
}

.frame .value-2 {
  color: #a3a3a3;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 9.2px;
  margin-top: -0.48px;
  position: relative;
  width: 73.49px;
}

.frame .holder-wrapper {
  align-items: center;
  background-color: #000000;
  border-radius: 2.39px;
  display: flex;
  flex-direction: column;
  height: 22.91px;
  justify-content: center;
  overflow: hidden;
  padding: 7.64px 9.54px;
  position: relative;
  width: 74.45px;
}

.frame .holder {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.95px;
  margin-bottom: -1.91px;
  margin-left: -2.02px;
  margin-right: -2.02px;
  margin-top: -1.91px;
  position: relative;
}

.frame .text-wrapper-125 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-142 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 11.45px;
  height: 990px;
  left: 23px;
  position: absolute;
  top: 57px;
  width: 526px;
}

.frame .nav-bar {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.48px;
  border-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  height: 21.95px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.frame .nav-link-11 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.95px;
  border-color: #575757;
  display: flex;
  gap: 3.82px;
  height: 21.95px;
  justify-content: center;
  padding: 3.82px;
  position: relative;
  width: 58.22px;
}

.frame .text-wrapper-126 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .nav-link-12 {
  align-items: center;
  display: flex;
  gap: 3.82px;
  height: 21.95px;
  justify-content: center;
  opacity: 0.5;
  padding: 3.82px;
  position: relative;
  width: 58.22px;
}

.frame .text-wrapper-127 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .nav-link-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  height: 21.95px;
  justify-content: center;
  opacity: 0.5;
  padding: 3.82px 7.64px;
  position: relative;
}

.frame .nav-link-14 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.82px;
  justify-content: center;
  opacity: 0.5;
  padding: 5.73px 11.45px;
  position: relative;
}

.frame .text-wrapper-128 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 10.3px;
  margin-top: -0.95px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-143 {
  align-items: center;
  border-radius: 1.91px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  justify-content: center;
  padding: 2.86px 1.91px;
  position: relative;
}

.frame .text-wrapper-129 {
  color: #c6c6c6;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-144 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.73px;
  position: relative;
  width: 100%;
}

.frame .dropdown-wrapper {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.73px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-130 {
  color: #a1a1a1;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-131 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .container-events {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.45px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .event-2 {
  align-items: center;
  align-self: stretch;
  background-color: #f1f1f1;
  border-radius: 5.73px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.77px;
  justify-content: center;
  padding: 15.27px;
  position: relative;
  width: 100%;
}

.frame .element-10 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.73px;
  position: relative;
}

.frame .div-wrapper-23 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.73px;
  position: relative;
}

.frame .live {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 2.39px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 0.95px 1.91px;
  position: relative;
}

.frame .text-wrapper-132 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 7.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .holder-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.27px;
  position: relative;
}

.frame .text-wrapper-133 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: 181.35px;
}

.frame .text-wrapper-134 {
  color: #575757;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .speakers-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.82px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-135 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-145 {
  background-color: #d9d9d9;
  border-radius: 5.73px;
  height: 11px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 11px;
}

.frame .name-12 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  height: 9px;
  left: 15px;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.frame .div-146 {
  background-color: #d9d9d9;
  border-radius: 5.73px;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .name-13 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chip-speaker-10 {
  align-items: center;
  align-self: stretch;
  background-color: #575757;
  border-radius: 9.54px;
  display: flex;
  gap: 1.91px;
  justify-content: center;
  padding: 1.91px 3.82px 1.91px 1.91px;
  position: relative;
  width: 15.27px;
}

.frame .name-14 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-147 {
  align-items: flex-end;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.frame .div-148 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.73px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.frame .calendar-add-on-wrapper {
  background-color: #a4a4a41f;
  border-radius: 18.13px;
  height: 19.09px;
  position: relative;
  width: 19.09px;
}

.frame .calendar-add-on {
  height: 11px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 11px;
}

.frame .CTA-4 {
  all: unset;
  align-items: center;
  border: 0.48px solid;
  border-color: #a3a3a3;
  border-radius: 1.91px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 5.73px;
  position: relative;
}

.frame .text-wrapper-136 {
  color: #a3a3a3;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.2px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .CTA-5 {
  all: unset;
  align-items: center;
  background-color: #1c1b1f;
  border-radius: 1.91px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 5.73px;
  position: relative;
}

.frame .text-wrapper-137 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.2px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .speakers-5 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.82px;
  justify-content: flex-end;
  position: relative;
}

.frame .attending {
  align-items: center;
  display: inline-flex;
  height: 15.27px;
  position: relative;
}

.frame .avatar-w-photo-11 {
  background-color: #575757;
  border: 0.95px solid;
  border-color: #f1f1f1;
  border-radius: 476.75px;
  height: 13.36px;
  position: relative;
  width: 12.89px;
}

.frame .avatar-w-photo-12 {
  background-color: #575757;
  border: 0.95px solid;
  border-color: #f1f1f1;
  border-radius: 476.75px;
  height: 13.36px;
  margin-left: -1.91px;
  position: relative;
  width: 13.36px;
}

.frame .avatar-w-photo-13 {
  background-color: #575757;
  border: 0.95px solid;
  border-color: #f1f1f1;
  border-radius: 476.75px;
  height: 13.36px;
  margin-left: -1.91px;
  position: relative;
  width: 12.89px;
}

.frame .avatar-w-photo-14 {
  background-color: #a3a3a3;
  border: 0.95px solid;
  border-color: #f1f1f1;
  border-radius: 476.75px;
  height: 15.36px;
  margin-bottom: -0.05px;
  margin-left: -1.91px;
  margin-right: -1px;
  margin-top: -0.05px;
  position: relative;
  width: 15.36px;
}

.frame .text-wrapper-138 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 4.8px;
  font-weight: 600;
  height: 6px;
  left: 2px;
  letter-spacing: -0.21px;
  line-height: normal;
  position: absolute;
  top: 1px;
}

.frame .menu-3 {
  align-items: flex-start;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  height: 451px;
  justify-content: space-between;
  left: 0;
  padding: 0px 0px 11.45px;
  position: absolute;
  top: 38px;
  width: 119px;
}

.frame .div-149 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 119.31px;
}

.frame .nav-link-15 {
  flex: 0 0 auto !important;
  gap: 2.04px !important;
  padding: 7.64px 18.33px !important;
  width: 119.31px !important;
}

.frame .nav-link-16 {
  font-family: "Inter", Helvetica !important;
  font-size: 7.6px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.02px !important;
  line-height: 9.2px !important;
}

.frame .nav-link-17 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 2.04px;
  padding: 7.64px 18.33px;
  position: relative;
  width: 119.31px;
}

.frame .icons-31 {
  height: 11.45px !important;
  width: 11.45px !important;
}

.frame .icons-32 {
  height: 10px !important;
  left: 2px !important;
  top: 1px !important;
  width: 8px !important;
}

.frame .dashboard-4 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 9.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .nav-link-18 {
  background-color: #555555 !important;
  flex: 0 0 auto !important;
  gap: 2.04px !important;
  padding: 7.64px 18.33px !important;
  width: 119.31px !important;
}

.frame .nav-link-19 {
  color: #ffffff !important;
  font-family: "Inter", Helvetica !important;
  font-size: 7.6px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.02px !important;
  line-height: 9.2px !important;
}

.frame .icons-33 {
  height: 9px !important;
  width: 9px !important;
}

.frame .icons-34 {
  border: 0.95px solid !important;
  border-radius: 2.07px !important;
  height: 4px !important;
  left: 3px !important;
  width: 4px !important;
}

.frame .icons-35 {
  border: 0.95px solid !important;
  border-radius: 2.07px !important;
  height: 4px !important;
  top: 3px !important;
  width: 4px !important;
}

.frame .icons-36 {
  border: 0.95px solid !important;
  border-radius: 2.07px !important;
  height: 4px !important;
  left: 5px !important;
  top: 3px !important;
  width: 4px !important;
}

.frame .icons-37 {
  border: 0.95px solid !important;
  border-radius: 2.07px !important;
  height: 4px !important;
  left: 3px !important;
  top: 5px !important;
  width: 4px !important;
}

.frame .icons-38 {
  height: 7px !important;
  left: 2px !important;
  top: 2px !important;
  width: 7px !important;
}

.frame .nav-link-20 {
  flex: 0 0 auto !important;
  gap: 2.04px !important;
  padding: 7.64px 18.33px !important;
  width: 117.88px !important;
}

.frame .extra-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.43px;
  padding: 1.91px 19.09px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-139 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 6.9px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .interpunct-3 {
  height: 3.82px;
  position: relative;
  width: 3.82px;
}

.frame .ellipse-39 {
  background-color: #d9d9d9;
  border-radius: 0.95px;
  height: 2px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 2px;
}

.frame .div-150 {
  align-items: center;
  background-color: #f1f1f1;
  border-bottom-style: solid;
  border-bottom-width: 0.24px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 9.54px 19.09px;
  position: absolute;
  top: 0;
  width: 687px;
}

.frame .text-wrapper-140 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 15.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-151 {
  align-items: center;
  display: flex;
  gap: 11.45px;
  justify-content: flex-end;
  padding: 1.91px 0px;
  position: relative;
  width: 101.17px;
}

.frame .div-152 {
  background-color: #00000033;
  border-radius: 7.64px;
  height: 15.27px;
  position: relative;
  width: 15.27px;
}

.frame .text-wrapper-141 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 9.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .dashboard-admin-view {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: -44px;
  overflow: hidden;
  position: absolute;
  top: 1114px;
  width: 705px;
}

.frame .line-23 {
  height: 448px;
  left: 547px;
  position: absolute;
  top: 42px;
  width: 1px;
}

.frame .overlap-17 {
  height: 507px;
  left: -1px;
  position: absolute;
  top: 41px;
  width: 546px;
}

.frame .overlap-18 {
  height: 449px;
  left: 0;
  position: absolute;
  top: 0;
  width: 546px;
}

.frame .div-153 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 131px;
  padding: 0px 15.27px;
  position: absolute;
  top: 77px;
  width: 416px;
}

.frame .div-wrapper-24 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 3.82px 0px;
  position: relative;
}

.frame .text-wrapper-142 {
  color: #3f394061;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.95px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-25 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 0.95px;
  border-color: #3f3a40;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 3.82px 0px;
  position: relative;
}

.frame .text-wrapper-143 {
  color: #3f3a40;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.95px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 3.82px 0px;
  position: relative;
}

.frame .text-wrapper-144 {
  color: #3f394061;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-37 {
  align-items: center;
  display: flex;
  position: relative;
  width: 122.65px;
}

.frame .text-input-3 {
  align-items: center;
  border: 0.24px solid;
  border-color: #3f3a408f;
  border-radius: 1.91px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 2.86px;
  padding: 5.73px 3.82px;
  position: relative;
}

.frame .value-3 {
  color: #3f3a408f;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.5px;
  margin-top: -0.24px;
  position: relative;
  width: 73.49px;
}

.frame .line-24 {
  height: 1px;
  left: 132px;
  position: absolute;
  top: 237px;
  width: 415px;
}

.frame .line-25 {
  height: 1px;
  left: 132px;
  position: absolute;
  top: 375px;
  width: 415px;
}

.frame .div-154 {
  align-items: center;
  background-color: #f5f5f5;
  border-bottom-style: solid;
  border-bottom-width: 0.48px;
  border-color: #dadada;
  display: flex;
  gap: 4.77px;
  left: 131px;
  padding: 9.54px 15.27px;
  position: absolute;
  top: 1px;
  width: 415px;
}

.frame .div-155 {
  background-color: #00000033;
  border-radius: 39.61px;
  height: 57.27px;
  position: relative;
  width: 57.27px;
}

.frame .intro {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.73px;
  height: 57.27px;
  justify-content: center;
  position: relative;
}

.frame .text-wrapper-145 {
  color: #3e3940;
  font-family: "Manrope", Helvetica;
  font-size: 11.5px;
  font-weight: 800;
  letter-spacing: -0.06px;
  line-height: 16px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .labels-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 7.64px;
  position: relative;
  width: 280.61px;
}

.frame .risk {
  align-items: center;
  align-self: stretch;
  background-color: #3e3940;
  border-radius: 1.43px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 0.95px 1.91px;
  position: relative;
}

.frame .text-wrapper-146 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-156 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 0.95px 0px;
  position: relative;
}

.frame .ellipse-40 {
  background-color: #3e3940;
  border-radius: 0.95px;
  height: 1.91px;
  position: relative;
  width: 1.91px;
}

.frame .text-wrapper-147 {
  color: #3e3940;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9.4px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-157 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 5.73px;
  height: 15.75px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-148 {
  color: #3e3940;
  font-family: "Manrope", Helvetica;
  font-size: 5.7px;
  font-weight: 800;
  letter-spacing: -0.03px;
  line-height: 8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-27 {
  -webkit-backdrop-filter: blur(1.91px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(1.91px) brightness(100%);
  background-color: #3f3a4052;
  border-radius: 11.93px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: flex-end;
  padding: 1.91px 3.82px;
  position: relative;
}

.frame .text-wrapper-149 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 8px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-150 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 8px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .menu-4 {
  background-color: #e6e6e6;
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: 0.24px;
  height: 449px;
  left: 0;
  position: absolute;
  top: 0;
  width: 132px;
}

.frame .div-158 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 129.81px;
}

.frame .div-159 {
  align-items: center;
  align-self: stretch;
  background-color: #cfcfcf;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 5.73px 11.45px 5.73px 19.09px;
  position: relative;
  width: 100%;
}

.frame .img-29 {
  height: 15.27px;
  margin-left: -4425px;
  margin-top: -3141px;
  position: relative;
  width: 15.27px;
}

.frame .text-wrapper-151 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-160 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.82px;
  padding: 5.73px 11.45px 5.73px 19.09px;
  position: relative;
  width: 100%;
}

.frame .img-30 {
  height: 15.27px;
  margin-left: -4425px;
  margin-top: -3167.72px;
  position: relative;
  width: 15.27px;
}

.frame .text-wrapper-152 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-161 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  padding: 5.73px 11.45px 5.73px 19.09px;
  position: relative;
  width: 100%;
}

.frame .polygon-6 {
  height: 4px;
  left: -4437px;
  position: absolute;
  top: -3194px;
  width: 3px;
}

.frame .img-31 {
  height: 15.27px;
  margin-left: -4425px;
  margin-top: -3221.17px;
  position: relative;
  width: 15.27px;
}

.frame .team {
  align-items: flex-start;
  background-color: #cfcfcf;
  display: flex;
  flex-direction: column;
  gap: 9.54px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 9.54px 0px;
  position: absolute;
  top: 149px;
  width: 129.81px;
}

.frame .div-162 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.45px;
  padding: 0px 11.45px 0px 19.09px;
  position: relative;
  width: 100%;
}

.frame .div-163 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 1.91px;
  margin-right: -12.41px;
  position: relative;
  width: 111.67px;
}

.frame .div-164 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 1.91px;
  position: relative;
  width: 100%;
}

.frame .arrow-drop-down-4 {
  height: 11.45px;
  margin-left: -4446px;
  margin-top: -3318.52px;
  position: relative;
  width: 11.45px;
}

.frame .div-165 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 0px 3.82px;
  position: relative;
  width: 100%;
}

.frame .avatar-w-photo-15 {
  background-color: #575757;
  border-radius: 476.75px;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .avatar-w-photo-16 {
  background-color: #555555;
  border-radius: 476.75px;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .text-wrapper-153 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: normal;
  margin-top: -0.16px;
  position: relative;
  width: fit-content;
}

.frame .arrow-drop-down-5 {
  height: 11.45px;
  margin-left: -4446px;
  margin-top: -3354.79px;
  position: relative;
  width: 11.45px;
}

.frame .div-166 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 0.95px;
  position: relative;
  width: 100%;
}

.frame .add-6 {
  height: 11.45px;
  margin-left: -4444.09px;
  margin-top: -3377.7px;
  position: relative;
  width: 11.45px;
}

.frame .div-wrapper-28 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 0.95px;
  padding: 3.82px 11.45px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-154 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-167 {
  align-items: center;
  display: flex;
  gap: 1.91px;
  left: 0;
  padding: 7.64px 19.09px;
  position: absolute;
  top: 395px;
  width: 130px;
}

.frame .text-wrapper-155 {
  color: #544d56;
  font-family: "Manrope", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.13px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .div-168 {
  align-items: center;
  display: inline-flex;
  gap: 3.82px;
  left: 15px;
  position: absolute;
  top: 428px;
}

.frame .text-wrapper-156 {
  color: #544d56;
  font-family: "Manrope", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .ellipse-41 {
  background-color: #544d56;
  border-radius: 0.95px;
  height: 1.91px;
  position: relative;
  width: 1.91px;
}

.frame .div-169 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 11.45px;
  left: 146px;
  overflow: hidden;
  position: absolute;
  top: 388px;
}

.frame .text-wrapper-157 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: -0.13px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-38 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.45px;
  position: relative;
  width: 381.78px;
}

.frame .frame-wrapper-39 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 3.82px;
  height: 98.31px;
  position: relative;
  width: 100%;
}

.frame .div-170 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11.45px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-40 {
  align-items: flex-start;
  background-color: #c4c4c4;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #000000;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
  width: 165.6px;
}

.frame .text-wrapper-158 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-159 {
  color: #c4c4c4;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 7.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .frame-wrapper-41 {
  align-items: flex-start;
  background-color: #c4c4c4;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #c9def8;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  margin-right: -136.96px;
  padding: 7.64px 11.45px;
  position: relative;
  width: 164.64px;
}

.frame .more-horiz-7 {
  height: 15.27px;
  margin-left: -9649.17px;
  margin-top: -3556.68px;
  position: relative;
  width: 15.27px;
}

.frame .div-171 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 7.64px;
  left: 146px;
  position: absolute;
  top: 250px;
}

.frame .frame-wrapper-42 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.82px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-43 {
  align-items: flex-start;
  background-color: #d9d9d9;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  overflow: hidden;
  padding: 9.54px 11.45px;
  position: relative;
  width: 162.26px;
}

.frame .div-172 {
  background-color: #9f9f9fa3;
  border-radius: 5.73px;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .frame-wrapper-44 {
  align-items: flex-start;
  background-color: #d9d9d9;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  margin-right: -127.9px;
  overflow: hidden;
  padding: 9.54px 11.45px;
  position: relative;
  width: 162.26px;
}

.frame .div-173 {
  background-image: url(../../../static/img/ellipse-1-3.svg);
  background-size: 100% 100%;
  height: 11.45px;
  position: relative;
  width: 11.45px;
}

.frame .div-174 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 11.45px;
  margin-left: -4973.95px;
  margin-top: -3480.33px;
  position: relative;
  width: 11.45px;
}

.frame .frame-wrapper-45 {
  align-items: flex-start;
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #806cff;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  margin-right: -304.95px;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
  width: 165.6px;
}

.frame .text-wrapper-160 {
  color: #f5f5f5;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 7.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .more-horiz-8 {
  height: 15.27px;
  margin-left: -10016.88px;
  margin-top: -3480.33px;
  position: relative;
  width: 15.27px;
}

.frame .frame-wrapper-46 {
  align-items: flex-start;
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #ff6c7d;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  margin-right: -482px;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
  width: 165.6px;
}

.frame .more-horiz-9 {
  height: 15.27px;
  margin-left: -10329.29px;
  margin-top: -3480.33px;
  position: relative;
  width: 15.27px;
}

.frame .frame-wrapper-47 {
  align-items: flex-start;
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #1658d7;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  margin-right: -658.1px;
  padding: 7.64px 11.45px;
  position: relative;
  width: 164.64px;
}

.frame .more-horiz-10 {
  height: 15.27px;
  margin-left: -10691.3px;
  margin-top: -3480.33px;
  position: relative;
  width: 15.27px;
}

.frame .div-175 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 7.64px;
  height: 114px;
  left: 146px;
  position: absolute;
  top: 112px;
}

.frame .course {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  height: 95.45px;
  overflow: hidden;
  position: relative;
  width: 162.26px;
}

.frame .overlap-19 {
  border-radius: 5.73px 5.73px 0px 0px;
  height: 95px;
  position: relative;
  width: 163px;
}

.frame .image-3 {
  border-radius: 5.73px 5.73px 0px 0px;
  height: 95px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 163px;
}

.frame .icon-button-4 {
  height: 11px;
  left: 147px;
  position: absolute;
  top: 4px;
  width: 11px;
}

.frame .line-wrapper {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 1px;
  left: -4570px;
  position: absolute;
  top: -3155px;
  width: 163px;
}

.frame .line-26 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 63px;
}

.frame .frame-wrapper-48 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 43.43px;
  height: 76.36px;
  left: 11px;
  position: absolute;
  top: 10px;
  width: 139.35px;
}

.frame .text-16 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  width: 139.35px;
}

.frame .speakers-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 23.38px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .div-176 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.95px;
  position: relative;
}

.frame .text-wrapper-161 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .chip-speaker-wrapper {
  -webkit-backdrop-filter: blur(1.91px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(1.91px) brightness(100%);
  background-color: #0000009e;
  border-radius: 11.93px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
}

.frame .name-15 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 8.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-29 {
  align-items: center;
  align-self: stretch;
  background-color: #1c1b1f;
  border-radius: 3.82px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 5.73px 9.54px;
  position: relative;
}

.frame .text-wrapper-162 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .overlap-group-24 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 1px;
  left: -4744px;
  position: absolute;
  top: -3155px;
  width: 163px;
}

.frame .course-2 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  height: 95.45px;
  margin-right: -127.9px;
  overflow: hidden;
  position: relative;
  width: 162.26px;
}

.frame .icon-button-5 {
  height: 11px;
  left: -4770px;
  position: absolute;
  top: -3266px;
  width: 11px;
}

.frame .overlap-group-25 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 1px;
  left: -4917px;
  position: absolute;
  top: -3155px;
  width: 163px;
}

.frame .div-177 {
  align-items: flex-start;
  background-color: #fbfdfd;
  display: flex;
  flex-direction: column;
  gap: 9.54px;
  left: 130px;
  padding: 11.45px 19.09px 11.45px 15.27px;
  position: absolute;
  top: 567px;
  width: 556px;
}

.frame .text-wrapper-163 {
  color: #152728;
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .frame-wrapper-49 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7.64px;
  height: 98.31px;
  margin-right: -1.43px;
  position: relative;
  width: 523.52px;
}

.frame .frame-wrapper-50 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 3.82px;
  height: 95.45px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .div-178 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 7.64px;
  position: relative;
  width: 100%;
}

.frame .frame-wrapper-51 {
  align-items: flex-start;
  background-color: #edf3f3;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #3c6e71;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
  width: 165.6px;
}

.frame .div-wrapper-30 {
  align-items: center;
  background-color: #3c6e71;
  border-radius: 1.91px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  justify-content: center;
  padding: 0.95px 1.91px;
  position: relative;
}

.frame .more-horiz-11 {
  height: 15.27px;
  margin-left: -8974.75px;
  margin-top: -3708.63px;
  position: relative;
  width: 15.27px;
}

.frame .frame-wrapper-52 {
  align-items: flex-start;
  background-color: #edf3f3;
  border-radius: 3.82px;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  justify-content: center;
  overflow: hidden;
  padding: 9.54px 11.45px;
  position: relative;
  width: 162.26px;
}

.frame .text-17 {
  color: #0f1728;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  width: 139.35px;
}

.frame .div-179 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 11.45px;
  margin-left: -4756.67px;
  margin-top: -3773.53px;
  position: relative;
  width: 11.45px;
}

.frame .div-wrapper-31 {
  align-items: center;
  background-color: #3c6e71;
  border-radius: 3.82px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 5.73px;
  position: relative;
}

.frame .text-wrapper-164 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .course-3 {
  border-radius: 3.82px;
  height: 95.45px;
  overflow: hidden;
  position: relative;
  width: 162.26px;
}

.frame .image-4 {
  height: 115px;
  left: -4913px;
  object-fit: cover;
  position: absolute;
  top: -3720px;
  width: 163px;
}

.frame .text-18 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11.5px;
  margin-top: -0.48px;
  position: relative;
  width: 139.35px;
}

.frame .text-wrapper-165 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .div-wrapper-32 {
  align-items: center;
  background-color: #000000;
  border-radius: 3.82px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  padding: 5.73px 7.64px;
  position: relative;
}

.frame .frame-wrapper-53 {
  align-items: flex-start;
  background-color: #edf3f3;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #ff6c7d;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  margin-right: -155.1px;
  overflow: hidden;
  padding: 7.64px 11.45px;
  position: relative;
  width: 165.6px;
}

.frame .more-horiz-12 {
  height: 15.27px;
  margin-left: -9959.13px;
  margin-top: -3773.53px;
  position: relative;
  width: 15.27px;
}

.frame .frame-wrapper-54 {
  align-items: flex-start;
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  box-shadow: 1.91px 1.91px 0px #1658d7;
  display: flex;
  flex-direction: column;
  gap: 5.73px;
  height: 95.45px;
  margin-right: -327.38px;
  padding: 7.64px 11.45px;
  position: relative;
  width: 164.64px;
}

.frame .more-horiz-13 {
  height: 15.27px;
  margin-left: -10313.32px;
  margin-top: -3773.53px;
  position: relative;
  width: 15.27px;
}

.frame .div-180 {
  background-color: #575757;
  border-radius: 3.82px;
  box-shadow: 0px 6.2px 15.27px #9696961f;
  height: 144px;
  left: 555px;
  overflow: hidden;
  position: absolute;
  top: 268px;
  width: 115px;
}

.frame .div-181 {
  align-items: flex-start;
  display: inline-flex;
  gap: 5.73px;
  left: 10px;
  position: absolute;
  top: 10px;
}

.frame .img-wrapper-2 {
  background-color: #b0b0b0;
  border-radius: 13.36px;
  height: 26.72px;
  position: relative;
  width: 26.72px;
}

.frame .sticky-note {
  height: 9px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 9px;
}

.frame .div-182 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3.82px;
  position: relative;
  width: 67.77px;
}

.frame .text-wrapper-166 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .arrow-back-7 {
  height: 9.07px;
  position: relative;
  width: 9.07px;
}

.frame .text-wrapper-167 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: -0.09px;
  line-height: 7.6px;
  margin-right: -1.43px;
  position: relative;
  width: 69.2px;
}

.frame .div-183 {
  align-items: center;
  display: flex;
  gap: 5.73px;
  left: 10px;
  position: absolute;
  top: 49px;
  width: 99px;
}

.frame .mark-chat-unread {
  height: 11px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 10px;
}

.frame .div-184 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.82px;
  position: relative;
}

.frame .text-wrapper-168 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: -0.09px;
  line-height: 7.6px;
  position: relative;
  width: 54.88px;
}

.frame .frame-wrapper-55 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 11.27px;
  height: 206px;
  left: 555px;
  position: absolute;
  top: 52px;
}

.frame .frame-wrapper-56 {
  align-items: flex-start;
  border: 0.18px solid;
  border-color: #9f9f9f;
  border-radius: 4.23px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 19.72px;
  overflow: hidden;
  padding: 8.45px;
  position: relative;
  width: 115.49px;
}

.frame .div-185 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.63px;
  position: relative;
  width: 100%;
}

.frame .div-wrapper-33 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.82px;
  position: relative;
}

.frame .text-wrapper-169 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 7px;
  font-weight: 600;
  letter-spacing: -0.11px;
  line-height: 11.3px;
  margin-top: -0.35px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .bar-3 {
  align-items: center;
  border: 0.18px solid;
  border-color: #555555;
  border-radius: 1.41px;
  display: flex;
  gap: 0.7px;
  padding: 2.82px;
  position: relative;
  width: 24.65px;
}

.frame .text-19 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 4.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.04px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chevron-3 {
  height: 5.28px;
  margin-right: -2.32px;
  position: relative;
  width: 5.63px;
}

.frame .div-186 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.7px;
  position: relative;
  width: 100%;
}

.frame .div-187 {
  align-items: center;
  align-self: stretch;
  border-radius: 4.23px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 2.82px 4.23px;
  position: relative;
  width: 100%;
}

.frame .div-188 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.82px;
  position: relative;
}

.frame .div-189 {
  background-color: #00000033;
  border-radius: 7.04px;
  height: 14.08px;
  margin-left: -0.07px;
  position: relative;
  width: 14.08px;
}

.frame .ellipse-42 {
  background-color: #047857;
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 1.76px;
  height: 4px;
  left: 10px;
  position: relative;
  top: 11px;
  width: 4px;
}

.frame .div-190 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.41px;
  position: relative;
}

.frame .div-191 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.41px;
  position: relative;
}

.frame .text-wrapper-170 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 4.9px;
  font-weight: 600;
  letter-spacing: -0.07px;
  line-height: 6.3px;
  margin-top: -0.35px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .expand-less-3 {
  height: 5.63px;
  position: relative;
  width: 5.63px;
}

.frame .text-wrapper-171 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 4.2px;
  font-weight: 400;
  letter-spacing: -0.06px;
  line-height: 5.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-34 {
  height: 14.08px;
  position: relative;
  width: 14.08px;
}

.frame .overlap-group-26 {
  height: 15px;
  left: 1px;
  position: relative;
  top: -1px;
  width: 12px;
}

.frame .polygon-7 {
  height: 14px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 12px;
}

.frame .polygon-8 {
  height: 11px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 12px;
}

.frame .text-wrapper-172 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 6px;
  font-weight: 800;
  height: 8px;
  left: 5px;
  letter-spacing: -0.09px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-173 {
  color: #808080;
  font-family: "Inter", Helvetica;
  font-size: 4.2px;
  font-weight: 400;
  letter-spacing: -0.06px;
  line-height: 5.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .overlap-group-27 {
  height: 14px;
  left: 1px;
  position: relative;
  top: -1px;
  width: 12px;
}

.frame .text-wrapper-174 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 6px;
  font-weight: 800;
  height: 8px;
  left: 4px;
  letter-spacing: -0.09px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-175 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 4.5px;
  font-weight: 700;
  height: 6px;
  left: 4px;
  letter-spacing: -0.07px;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.frame .div-192 {
  align-items: center;
  align-self: stretch;
  background-color: #f4f4f4;
  border-radius: 2.82px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 2.82px 4.23px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-176 {
  color: #555555;
  font-family: "Manrope", Helvetica;
  font-size: 6px;
  font-weight: 800;
  height: 8px;
  left: 5px;
  letter-spacing: -0.09px;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.frame .div-193 {
  align-items: center;
  align-self: stretch;
  background-color: #555555;
  border-radius: 1.41px;
  display: flex;
  gap: 1.41px;
  height: 14.08px;
  justify-content: center;
  overflow: hidden;
  padding: 4.23px 11.27px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-177 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 4.9px;
  font-weight: 800;
  letter-spacing: -0.07px;
  line-height: 6.3px;
  margin-bottom: -0.33px;
  margin-top: -1.04px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .arrow-back-8 {
  height: 4.93px;
  position: relative;
  width: 4.93px;
}

.frame .menu-5 {
  height: 11.45px;
  margin-left: -4444.09px;
  margin-top: -3107.82px;
  position: relative;
  width: 11.45px;
}

.frame .text-wrapper-178 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 15.1px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .auditorium-analytics {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: 960px;
  overflow: hidden;
  position: absolute;
  top: 1112px;
  width: 705px;
}

.frame .overlap-20 {
  background-image: url(../../../static/img/base.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 448px;
  left: 0;
  position: absolute;
  top: 42px;
  width: 689px;
}

.frame .header-2 {
  align-items: center;
  display: flex;
  gap: 88.47px;
  left: 60px;
  position: absolute;
  top: 22px;
  width: 568px;
}

.frame .controls {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.65px;
  position: relative;
}

.frame .CTA-6 {
  align-items: center;
  background-color: #000000;
  border: 0.24px solid;
  border-color: #575757;
  border-radius: 2.39px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 22.95px;
  justify-content: center;
  overflow: hidden;
  padding: 7.65px 9.56px;
  position: relative;
}

.frame .holder-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.96px;
  margin-bottom: -1.91px;
  margin-top: -1.91px;
  position: relative;
}

.frame .img-32 {
  height: 11.48px;
  position: relative;
  width: 11.48px;
}

.frame .text-wrapper-179 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-194 {
  align-items: center;
  border: 0.48px solid;
  border-color: #d9d9d9;
  border-radius: 5.26px;
  display: flex;
  gap: 5.74px;
  left: 60px;
  overflow: hidden;
  position: absolute;
  top: 61px;
  width: 568px;
}

.frame .div-195 {
  align-items: flex-start;
  border-radius: 3.83px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 11.48px 15.3px;
  position: relative;
}

.frame .text-wrapper-180 {
  align-self: stretch;
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 17.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 26.8px;
  margin-top: -0.48px;
  position: relative;
}

.frame .text-wrapper-181 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 9.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .line-27 {
  height: 50.69px;
  position: relative;
  width: 1px;
}

.frame .div-196 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.74px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-182 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 9.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-183 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 17.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26.8px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-184 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 9.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chart {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.48px solid;
  border-color: #d9d9d9;
  border-radius: 4.78px;
  display: flex;
  flex-direction: column;
  gap: 5.74px;
  left: 60px;
  padding: 15.3px;
  position: absolute;
  top: 137px;
  width: 345px;
}

.frame .div-197 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.74px;
  position: relative;
  width: 100%;
}

.frame .heading-2 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.83px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-185 {
  align-self: stretch;
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
}

.frame .text-wrapper-186 {
  align-self: stretch;
  color: #a4a4a4;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.frame .divider-2 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.52px;
  position: relative;
  width: 100%;
}

.frame .chip-speaker-11 {
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 9.56px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  overflow: hidden;
  padding: 1.91px 3.83px;
  position: relative;
}

.frame .name-wrapper-2 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.91px;
  position: relative;
}

.frame .name-16 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 8.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .search-bar-4 {
  align-items: center;
  border: 0.48px solid;
  border-color: #d6d6d6;
  border-radius: 1.91px;
  display: flex;
  gap: 3.83px;
  height: 19.13px;
  padding: 3.83px 5.74px;
  position: relative;
  width: 151.12px;
}

.frame .icons-31-instance {
  height: 7.65px !important;
  position: relative !important;
  width: 7.65px !important;
}

.frame .text-wrapper-187 {
  color: #b6b6b6;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.frame .div-198 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.65px;
  position: relative;
  width: 100%;
}

.frame .stats-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.83px;
  padding: 5.74px;
  position: relative;
  width: 100%;
}

.frame .stats {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 11.48px;
  position: relative;
}

.frame .stat {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.74px;
  position: relative;
}

.frame .line-28 {
  align-self: stretch;
  margin-left: -1.43px;
  position: relative;
  width: 1.43px;
}

.frame .text-wrapper-188 {
  color: #b0b0b0;
  font-family: "Manrope", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame .text-wrapper-189 {
  color: #818181;
  font-family: "Manrope", Helvetica;
  font-size: 10.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame .graph {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11.48px;
  position: relative;
  width: 100%;
}

.frame .data {
  flex: 1;
  flex-grow: 1;
  height: 120.99px;
  position: relative;
}

.frame .overlap-group-28 {
  height: 116px;
  left: 16px;
  position: absolute;
  top: 6px;
  width: 298px;
}

.frame .lines {
  height: 95px;
  left: 0;
  position: absolute;
  top: 0;
  width: 298px;
}

.frame .line-29 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 298px;
}

.frame .line-30 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 298px;
}

.frame .line-31 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 38px;
  width: 298px;
}

.frame .line-32 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 57px;
  width: 298px;
}

.frame .line-33 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 76px;
  width: 298px;
}

.frame .line-34 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 95px;
  width: 298px;
}

.frame .graph-2 {
  align-items: flex-end;
  display: flex;
  height: 113px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 298px;
}

.frame .column-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 11.96px;
  padding: 0px 5.74px;
  position: relative;
}

.frame .credit-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.48px;
  height: 56.43px;
  position: relative;
  width: 100%;
}

.frame .credit {
  align-self: stretch;
  background-color: #a1a1a1;
  border-radius: 0.72px;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-190 {
  color: #b0b0b0;
  font-family: "Manrope", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .column-4 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 11.96px;
  position: relative;
}

.frame .data-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.48px;
  height: 65.52px;
  justify-content: flex-end;
  padding: 0px 5.74px;
  position: relative;
  width: 100%;
}

.frame .data-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.48px;
  height: 22px;
  position: relative;
  width: 100%;
}

.frame .data-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.48px;
  height: 42.08px;
  position: relative;
  width: 100%;
}

.frame .data-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.48px;
  height: 16.74px;
  position: relative;
  width: 100%;
}

.frame .y-axis {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  height: 104px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-191 {
  color: #b0b0b0;
  font-family: "Manrope", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.frame .text-wrapper-192 {
  color: #b0b0b0;
  font-family: "Manrope", Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: right;
  width: fit-content;
}

.frame .key {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 3.83px;
  left: 264px;
  position: absolute;
  top: 0;
}

.frame .ellipse-43 {
  background-color: #a1a1a1;
  border-radius: 1.91px;
  height: 3.83px;
  position: relative;
  width: 3.83px;
}

.frame .text-wrapper-193 {
  color: #818181;
  font-family: "Manrope", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .div-199 {
  align-items: flex-end;
  border: 0.48px solid;
  border-color: #d9d9d9;
  border-radius: 3.83px;
  display: flex;
  flex-direction: column;
  height: 254px;
  left: 423px;
  position: absolute;
  top: 137px;
  width: 207px;
}

.frame .div-200 {
  align-items: center;
  align-self: stretch;
  background-color: #e0e0e0;
  border-radius: 3.83px 3.83px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 7.65px 11.48px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-194 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-195 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-201 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 226.2px;
  position: relative;
  width: 100%;
}

.frame .div-202 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.74px;
  justify-content: center;
  padding: 8.61px 11.48px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-196 {
  align-self: stretch;
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  letter-spacing: -0.11px;
  line-height: 9.6px;
  position: relative;
}

.frame .bar-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 4.78px 0px 0.96px;
  position: relative;
}

.frame .line-35 {
  align-self: stretch;
  height: 3.83px;
  margin-top: -1.7px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-197 {
  color: #818181;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  letter-spacing: -0.11px;
  line-height: 9.6px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .bar-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.78px 0px 0.96px;
  position: relative;
  width: 86.08px;
}

.frame .bar-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.78px 0px 0.96px;
  position: relative;
  width: 52.6px;
}

.frame .bar-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4.78px 0px 0.96px;
  position: relative;
  width: 39.21px;
}

.frame .menu-6 {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 0.48px;
  border-color: #c6c6c6;
  height: 42px;
  left: 0;
  position: absolute;
  top: 0;
  width: 689px;
}

.frame .div-203 {
  align-items: center;
  display: inline-flex;
  gap: 5.74px;
  left: 19px;
  position: absolute;
  top: 7px;
}

.frame .menu-7 {
  height: 15.3px;
  position: relative;
  width: 15.3px;
}

.frame .div-wrapper-35 {
  background-color: #f9f9f9;
  border: 0.24px dashed;
  border-color: #9f9f9f;
  border-radius: 3.83px;
  height: 26.78px;
  position: relative;
  width: 70.78px;
}

.frame .text-wrapper-198 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  left: 9px;
  letter-spacing: -0.19px;
  line-height: 11.5px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.frame .frame-wrapper-57 {
  align-items: center;
  display: flex;
  gap: 3.83px;
  justify-content: flex-end;
  left: 554px;
  position: absolute;
  top: 11px;
  width: 116px;
}

.frame .div-204 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.83px;
  position: relative;
}

.frame .ellipse-wrapper-7 {
  background-color: #d9d9d9;
  border-radius: 9.56px;
  height: 19.13px;
  margin-top: -0.24px;
  position: relative;
  width: 19.13px;
}

.frame .ellipse-44 {
  background-color: #000000;
  border: 0.96px solid;
  border-color: #ffffff;
  border-radius: 2.87px;
  height: 6px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 6px;
}

.frame .text-wrapper-199 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-205 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 274px;
  justify-content: space-between;
  left: 174px;
  position: absolute;
  top: 198px;
}

.frame .text-wrapper-200 {
  color: #f1f1f1;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  width: 350px;
}

.frame .text-wrapper-201 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(255, 108.37, 125.97) 0%, rgb(254, 180, 63) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 64px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 72px;
  position: relative;
  text-fill-color: transparent;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-202 {
  color: #f1f1f1;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: normal;
  position: relative;
  width: 350px;
}

.frame .about-the-client-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  height: 24px;
  justify-content: space-around;
  left: 173px;
  position: absolute;
  top: 142px;
}

.frame .first-on-the-list {
  color: #a3a3a3;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .group-7 {
  height: 972px;
  left: 589px;
  position: absolute;
  top: 88px;
  width: 1369px;
}

.frame .overlap-21 {
  height: 942px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1361px;
}

.frame .dashboard-home {
  height: 889px;
  left: 121px;
  position: absolute;
  top: 53px;
  width: 1208px;
}

.frame .line-instance {
  height: 59px !important;
  left: 1197px !important;
  position: absolute !important;
  top: 10px !important;
  transform: rotate(180deg) !important;
  width: 66px !important;
}

.frame .line-36 {
  height: 53px !important;
  left: 3px !important;
  top: 7px !important;
  transform: rotate(-180deg) !important;
  width: 56px !important;
}

.frame .endpoint-2-3 {
  height: 7px !important;
  left: 59px !important;
  position: absolute !important;
  top: 55px !important;
  transform: rotate(-180deg) !important;
  width: 7px !important;
}

.frame .line-37 {
  background-color: #ffffff !important;
  border-radius: 86.61px !important;
  height: 7px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 7px !important;
}

.frame .annotation-text-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 69px;
  left: 1280px;
  position: absolute;
  top: 0;
  width: 81px;
}

.frame .annotation-text-instance {
  background-color: var(--lightwhite-100) !important;
  border-radius: 10.39px !important;
  flex: 0 0 auto !important;
  gap: 6.93px !important;
  padding: 1.3px 10.39px 2.17px !important;
}

.frame .annotation-text-3 {
  color: var(--darkblack-100) !important;
  font-family: "Inter", Helvetica !important;
  font-size: 12.1px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: -0.24px !important;
  line-height: 17.3px !important;
  margin-top: -0.87px !important;
}

.frame .line-38 {
  object-fit: unset !important;
}

.frame .endpoint-2-2 {
  height: 6.93px !important;
  position: relative !important;
  width: 6.93px !important;
}

.frame .line-39 {
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
}

.frame .annotation-text-4 {
  align-items: center;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
  display: flex;
  height: 20px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 172px;
}

.frame .annotation-text-5 {
  background-color: var(--lightwhite-100) !important;
  border-radius: 10.39px !important;
  flex: 0 0 auto !important;
  gap: 6.93px !important;
  margin-bottom: -0.55px !important;
  margin-top: -0.55px !important;
  padding: 1.3px 10.39px 2.17px !important;
}

.frame .line-40 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.frame .endpoint-instance {
  background-color: #ffffff !important;
  border-radius: 86.61px !important;
  height: 6.93px !important;
  position: relative !important;
  width: 6.93px !important;
}

.frame .annotation-text-6 {
  align-items: center;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
  display: flex;
  height: 22px;
  left: 0;
  position: absolute;
  top: 549px;
  width: 190px;
}

.frame .annotation-text-7 {
  align-items: center;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
  display: flex;
  height: 22px;
  left: 0;
  position: absolute;
  top: 275px;
  width: 396px;
}

.frame .annotation-text-8 {
  align-items: center;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
  display: flex;
  height: 20px;
  left: 0;
  position: absolute;
  top: 349px;
  width: 405px;
}

.frame .annotation-text-9 {
  border-radius: 10.39px !important;
  display: flex !important;
  gap: 6.93px !important;
  height: 21px !important;
  justify-content: center !important;
  left: 1093px !important;
  padding: 2.35px 9.39px !important;
  position: absolute !important;
  top: 0 !important;
  width: 103px !important;
}

.frame .annotation-text-10 {
  font-family: "Inter", Helvetica !important;
  font-size: 12.1px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: -0.24px !important;
  line-height: 17.3px !important;
  margin-top: -1.65px !important;
}

.frame .measure-2 {
  align-self: stretch !important;
  height: unset !important;
  left: unset !important;
  margin-left: -0.43px !important;
  position: relative !important;
  top: unset !important;
}

.frame .measure-3 {
  color: #a3a3a3 !important;
  font-size: 13.6px !important;
  margin-top: -0.87px !important;
}

.frame .measure-4 {
  flex: 0 0 auto !important;
  margin-bottom: -0.96px !important;
  margin-top: -0.96px !important;
  padding: 0px 3.46px !important;
}

.frame .measure-5 {
  align-self: stretch !important;
  height: unset !important;
  left: unset !important;
  margin-right: -0.57px !important;
  position: relative !important;
  top: unset !important;
}

.frame .measure-6 {
  height: 14px !important;
  left: 333px !important;
  position: absolute !important;
  top: 957px !important;
  width: 712px !important;
}

.frame .measure-7 {
  height: 14px !important;
  left: 1077px !important;
  position: absolute !important;
  top: 957px !important;
  width: 256px !important;
}

.frame .measure-8 {
  align-self: stretch !important;
  height: unset !important;
  object-fit: unset !important;
}

.frame .measure-9 {
  color: #9f9f9f !important;
  font-size: 13.6px !important;
  margin-top: -0.87px !important;
}

.frame .measure-10 {
  flex: 0 0 auto !important;
  margin-bottom: -1.21px !important;
  margin-top: -1.21px !important;
  padding: 0px 3.46px !important;
}

.frame .measure-11 {
  align-self: stretch !important;
  height: unset !important;
  left: unset !important;
  margin-left: -5870.8px !important;
  margin-top: -3022.44px !important;
  position: relative !important;
  top: unset !important;
}

.frame .login {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: 692px;
  overflow: hidden;
  position: absolute;
  top: 1112px;
  width: 236px;
}

.frame .frame-wrapper-58 {
  background-color: #f5f5f5;
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.frame .div-wrapper-36 {
  border-radius: 2.52px;
  height: 24px;
  left: 12px;
  position: relative;
  top: 5px;
  width: 37px;
}

.frame .div-206 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4.88px;
  left: 16px;
  position: absolute;
  top: 127px;
  width: 186px;
}

.frame .text-wrapper-203 {
  color: var(--x01-neutral10);
  font-family: "Inter", Helvetica;
  font-size: 14.6px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 20.5px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-204 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-207 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14.63px;
  left: 16px;
  position: absolute;
  top: 183px;
  width: 186.43px;
}

.frame .div-208 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.93px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-205 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 10.2px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-input-4 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-030);
  border: 0.61px solid;
  border-color: var(--x01-neutral70);
  border-radius: 1.22px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.1px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  padding: 8.54px 9.76px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-206 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.1px;
  position: relative;
}

.frame .text-20 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 7.3px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 8.8px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-input-5 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-030);
  border: 0.61px solid;
  border-color: var(--x01-neutral70);
  border-radius: 1.22px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.1px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  overflow: hidden;
  padding: 8.54px 9.76px 7.32px;
  position: relative;
  width: 100%;
}

.frame .text-21 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 7.3px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: normal;
  margin-top: -0.61px;
  position: relative;
  width: fit-content;
}

.frame .div-209 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 16px;
  position: absolute;
  top: 298px;
  width: 186px;
}

.frame .div-210 {
  align-items: center;
  display: flex;
  gap: 4.88px;
  position: relative;
  width: 77.44px;
}

.frame .checkbox-2 {
  background-color: var(--shadeswhite);
  height: 12.19px;
  position: relative;
  width: 12.19px;
}

.frame .icons-outline-wrapper {
  border: 0.76px solid;
  border-color: #e5e2db;
  border-radius: 1.52px;
  height: 14px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 14px;
}

.frame .icons-outline-checkmark {
  height: 12px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 12px !important;
}

.frame .text-22 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 10.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-23 {
  color: #a3a3a3;
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 10.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .button-9 {
  all: unset;
  align-items: center;
  background-color: #000000;
  border-radius: 2.04px;
  box-sizing: border-box;
  display: flex;
  gap: 2.44px;
  justify-content: center;
  left: 16px;
  overflow: hidden;
  padding: 7.32px 19.51px;
  position: absolute;
  top: 418px;
  width: 186px;
}

.frame .text-wrapper-207 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 10.2px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-24 {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  left: 42px;
  line-height: 8.5px;
  position: absolute;
  top: 452px;
  width: 135px;
}

.frame .text-wrapper-208 {
  color: #363636;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 10.2px;
}

.frame .text-wrapper-209 {
  color: #929185;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 13.4px;
}

.frame .text-wrapper-210 {
  color: #676768;
  font-weight: 700;
  letter-spacing: -0.02px;
  line-height: 10.2px;
}

.frame .verify-e-mail {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #ffffff52;
  border-radius: 12px;
  height: 506px;
  left: -165px;
  overflow: hidden;
  position: absolute;
  top: 568px;
  width: 704px;
}

.frame .overlap-22 {
  height: 492px;
  left: 0;
  position: absolute;
  top: 0;
  width: 340px;
}

.frame .rectangle-12 {
  background-color: #d9d9d9;
  height: 488px;
  left: 0;
  position: absolute;
  top: 0;
  width: 337px;
}

.frame .overlap-group-wrapper-6 {
  height: 492px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 339px;
}

.frame .overlap-group-29 {
  background-color: #c7c7c7;
  height: 492px;
  left: -1px;
  position: relative;
  width: 340px;
}

.frame .line-41 {
  height: 479px;
  left: 163px;
  position: absolute;
  top: 11px;
  width: 177px;
}

.frame .line-42 {
  height: 477px;
  left: 163px;
  position: absolute;
  top: 8px;
  width: 174px;
}

.frame .text-wrapper-211 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 11.4px;
  font-weight: 900;
  left: 18px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.frame .div-211 {
  height: 213px;
  left: 404px;
  position: absolute;
  top: 138px;
  width: 219px;
}

.frame .div-212 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5.72px;
  left: 0;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-212 {
  color: #444444;
  font-family: "Inter", Helvetica;
  font-size: 16.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.48px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-213 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-213 {
  align-items: center;
  display: flex;
  gap: 8.1px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 61px;
  width: 213px;
}

.frame .text-input-6 {
  align-items: center;
  background-color: var(--primary-030);
  border: 0.48px solid;
  border-color: var(--neutral-03);
  border-radius: 2.86px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4.77px;
  justify-content: center;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  padding: 12.28px 16.37px;
  position: relative;
}

.frame .text-wrapper-214 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.77px;
  position: relative;
}

.frame .text-25 {
  color: var(--primary-0340);
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 10.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-input-7 {
  align-items: center;
  background-color: var(--primary-030);
  border: 0.48px solid;
  border-color: var(--neutral-03);
  border-radius: 2.86px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4.77px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  padding: 12.28px 16.37px;
  position: relative;
}

.frame .text-26 {
  color: var(--primary-0340);
  font-family: "General Sans-Medium", Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 10.5px;
  margin-top: -0.48px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-input-8 {
  align-items: center;
  background-color: var(--primary-030);
  border: 0.48px solid;
  border-color: var(--neutral-03);
  border-radius: 2.86px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4.77px;
  justify-content: center;
  margin-bottom: -1px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 12.28px 16.37px;
  position: relative;
}

.frame .text-27 {
  color: var(--primary-0340);
  font-family: "Inter", Helvetica;
  font-size: 7.6px;
  font-weight: 400;
  left: -1px;
  letter-spacing: -0.15px;
  line-height: 10.5px;
  position: absolute;
  text-align: center;
  top: 202px;
  white-space: nowrap;
}

.frame .text-wrapper-215 {
  font-weight: 500;
}

.frame .text-wrapper-216 {
  font-weight: 700;
}

.frame .text-28 {
  color: var(--primary-0340);
  font-family: "Inter", Helvetica;
  font-size: 8.2px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.16px;
  line-height: 10.5px;
  position: absolute;
  top: 108px;
  white-space: nowrap;
}

.frame .div-wrapper-37 {
  background-color: #707070;
  border-radius: 4.09px;
  height: 27px;
  left: 0;
  position: absolute;
  top: 166px;
  width: 213px;
}

.frame .text-wrapper-217 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 700;
  left: 51px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.frame .group-8 {
  height: 1020px;
  left: 60px;
  position: absolute;
  top: 1789px;
  width: 1920px;
}

.frame .div-214 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 178px;
  position: absolute;
  top: 2346px;
  width: 1681px;
}

.frame .div-215 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.frame .text-wrapper-218 {
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 513px;
}

.frame .component-based {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(180deg, rgb(255, 108.37, 125.97) 0%, rgb(22, 88, 216) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 52px;
  font-weight: 500;
  letter-spacing: -0.52px;
  line-height: 56px;
  position: relative;
  text-fill-color: transparent;
}

.frame .that-s-responsive {
  color: #555555;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.43px;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  width: 524px;
}

.frame .typography {
  align-items: flex-start;
  background-color: #ffffff;
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 6.85px;
  display: inline-flex;
  flex-direction: column;
  gap: 31.95px;
  left: 0;
  overflow: hidden;
  padding: 55.72px 54.78px;
  position: absolute;
  top: 2579px;
}

.frame .div-216 {
  align-self: stretch;
  height: 140.94px;
  position: relative;
  width: 100%;
}

.frame .div-217 {
  height: 141px;
  left: 0;
  position: absolute;
  top: 0;
  width: 132px;
}

.frame .text-wrapper-219 {
  color: #000000;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 18.3px;
  font-weight: 400;
  height: 22px;
  left: 0;
  letter-spacing: -0.18px;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.frame .text-wrapper-220 {
  color: #a3a3a3;
  font-family: "Inter", Helvetica;
  font-size: 107.3px;
  font-weight: 400;
  height: 115px;
  left: 0;
  letter-spacing: -1.07px;
  line-height: 114.1px;
  position: absolute;
  top: 43px;
  white-space: nowrap;
}

.frame .div-218 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 141px;
  justify-content: space-between;
  left: 196px;
  position: absolute;
  top: 0;
}

.frame .aa-bb-cc-dd-ee-ff-gg {
  color: #a2a2a2;
  font-family: "Inter", Helvetica;
  font-size: 22.8px;
  font-weight: 300;
  letter-spacing: -0.23px;
  line-height: normal;
  margin-top: -0.57px;
  position: relative;
  width: 388.02px;
}

.frame .div-219 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20.54px;
  position: relative;
}

.frame .text-wrapper-221 {
  color: #a2a2a2;
  font-family: "Inter", Helvetica;
  font-size: 18.3px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-top: -0.57px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-222 {
  color: #a2a2a2;
  font-family: "Inter", Helvetica;
  font-size: 18.3px;
  font-weight: 700;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-top: -0.57px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-223 {
  color: #a2a2a2;
  font-family: "Inter", Helvetica;
  font-size: 18.3px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-top: -0.57px;
  position: relative;
  width: fit-content;
}

.frame .line-43 {
  height: 1px;
  position: relative;
  width: 587.22px;
}

.frame .div-220 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.frame .div-221 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 27.39px;
  position: relative;
}

.frame .div-222 {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18.26px;
  overflow: hidden;
  position: relative;
}

.frame .div-223 {
  align-items: flex-end;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2.28px;
  position: relative;
  width: 36.52px;
}

.frame .text-wrapper-224 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.57px;
  opacity: 0.4;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-225 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.4;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 34.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 41.1px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type-2 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 27.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32.9px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type-3 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 22.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26.7px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-224 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 344.08px;
}

.frame .type-4 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 17.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21.2px;
  margin-top: -0.57px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-225 {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18.26px;
  position: relative;
}

.frame .type-5 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 14.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type-6 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type-7 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type-8 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 8.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .type-9 {
  color: #707070;
  font-family: "Inter", Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 6.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .button-10 {
  background-color: var(--neutral-0);
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 11.31px;
  box-shadow: 0px 2.83px 31.1px #1822310a;
  height: 475px;
  left: 741px;
  overflow: hidden;
  position: absolute;
  top: 2579px;
  width: 620px;
}

.frame .overlap-23 {
  height: 409px;
  position: relative;
  top: 33px;
}

.frame .key-2 {
  height: 60px;
  left: 41px;
  position: absolute;
  top: 0;
  width: 507px;
}

.frame .text-wrapper-226 {
  color: var(--neutral-40);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  left: 75px;
  letter-spacing: 0;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .text-wrapper-227 {
  color: var(--neutral-40);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  left: 200px;
  letter-spacing: 0;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .text-wrapper-228 {
  color: var(--neutral-40);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  left: 324px;
  letter-spacing: 0;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .text-wrapper-229 {
  color: var(--neutral-40);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  left: 440px;
  letter-spacing: 0;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .text-wrapper-230 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  text-align: right;
  top: 39px;
  white-space: nowrap;
}

.frame .button-11 {
  border-radius: 3.28px;
  height: 384px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 26px;
  width: 620px;
}

.frame .type-primary-size {
  left: 117px !important;
  position: absolute !important;
  top: 222px !important;
}

.frame .type-primary-size-2 {
  left: 117px !important;
  position: absolute !important;
  top: 14px !important;
}

.frame .type-primary-size-3 {
  left: 117px !important;
  position: absolute !important;
  top: 262px !important;
}

.frame .type-primary-size-4 {
  left: 117px !important;
  position: absolute !important;
  top: 52px !important;
}

.frame .type-primary-size-5 {
  left: 117px !important;
  position: absolute !important;
  top: 301px !important;
}

.frame .type-primary-size-6 {
  left: 117px !important;
  position: absolute !important;
  top: 92px !important;
}

.frame .type-primary-size-7 {
  left: 118px !important;
  position: absolute !important;
  top: 340px !important;
}

.frame .type-primary-size-8 {
  left: 117px !important;
  position: absolute !important;
  top: 131px !important;
}

.frame .type-secondary-size {
  left: 241px !important;
  position: absolute !important;
  top: 340px !important;
}

.frame .type-secondary-size-2 {
  left: 241px !important;
  position: absolute !important;
  top: 131px !important;
}

.frame .type-secondary-size-3 {
  left: 241px !important;
  position: absolute !important;
  top: 222px !important;
}

.frame .type-secondary-size-4 {
  left: 241px !important;
  position: absolute !important;
  top: 13px !important;
}

.frame .type-secondary-size-5 {
  left: 241px !important;
  position: absolute !important;
  top: 262px !important;
}

.frame .type-secondary-size-6 {
  left: 241px !important;
  position: absolute !important;
  top: 52px !important;
}

.frame .type-secondary-size-7 {
  left: 241px !important;
  position: absolute !important;
  top: 301px !important;
}

.frame .type-secondary-size-8 {
  left: 241px !important;
  position: absolute !important;
  top: 92px !important;
}

.frame .type-outline-size {
  left: 366px !important;
  position: absolute !important;
  top: 222px !important;
}

.frame .type-outline-size-2 {
  left: 366px !important;
  position: absolute !important;
  top: 13px !important;
}

.frame .type-outline-size-3 {
  left: 366px !important;
  position: absolute !important;
  top: 262px !important;
}

.frame .type-outline-size-4 {
  left: 366px !important;
  position: absolute !important;
  top: 52px !important;
}

.frame .type-outline-size-5 {
  left: 366px !important;
  position: absolute !important;
  top: 301px !important;
}

.frame .type-outline-size-6 {
  left: 366px !important;
  position: absolute !important;
  top: 92px !important;
}

.frame .type-outline-size-7 {
  left: 366px !important;
  position: absolute !important;
  top: 340px !important;
}

.frame .type-outline-size-8 {
  left: 366px !important;
  position: absolute !important;
  top: 131px !important;
}

.frame .type-text-size-small {
  left: 471px !important;
  position: absolute !important;
  top: 340px !important;
}

.frame .type-text-size-large {
  left: 471px !important;
  position: absolute !important;
  top: 131px !important;
}

.frame .type-text-size-small-2 {
  left: 471px !important;
  position: absolute !important;
  top: 222px !important;
}

.frame .type-text-size-large-2 {
  left: 471px !important;
  position: absolute !important;
  top: 13px !important;
}

.frame .type-text-size-small-3 {
  left: 471px !important;
  position: absolute !important;
  top: 262px !important;
}

.frame .type-text-size-large-3 {
  left: 471px !important;
  position: absolute !important;
  top: 52px !important;
}

.frame .type-text-size-small-4 {
  left: 471px !important;
  position: absolute !important;
  top: 301px !important;
}

.frame .type-text-size-large-4 {
  left: 471px !important;
  position: absolute !important;
  top: 92px !important;
}

.frame .element-11 {
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 6.67px;
  height: 165px;
  left: 1393px;
  position: absolute;
  top: 2579px;
  width: 832px;
}

.frame .button-default-instance {
  left: 129px !important;
  position: absolute !important;
  top: 41px !important;
}

.frame .button-hover-instance {
  left: 129px !important;
  position: absolute !important;
  top: 103px !important;
}

.frame .button-add-team-default {
  left: 218px !important;
  position: absolute !important;
  top: 41px !important;
}

.frame .button-add-team-default-hover {
  left: 218px !important;
  position: absolute !important;
  top: 103px !important;
}

.frame .button-variant5 {
  left: 539px !important;
  position: absolute !important;
  top: 41px !important;
}

.frame .button-variant6 {
  left: 539px !important;
  position: absolute !important;
  top: 103px !important;
}

.frame .button-variant7 {
  left: 64px !important;
  position: absolute !important;
  top: 41px !important;
}

.frame .button-variant-instance {
  left: 64px !important;
  position: absolute !important;
  top: 103px !important;
}

.frame .button-variant9-instance {
  left: 24px !important;
  position: absolute !important;
  top: 41px !important;
}

.frame .button-variant10-instance {
  left: 24px !important;
  position: absolute !important;
  top: 103px !important;
}

.frame .button-variant11-instance {
  left: 399px !important;
  position: absolute !important;
  top: 41px !important;
}

.frame .button-variant12-instance {
  left: 399px !important;
  position: absolute !important;
  top: 103px !important;
}

.frame .text-wrapper-231 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 500;
  left: 24px;
  letter-spacing: -0.2px;
  line-height: normal;
  position: absolute;
  top: 22px;
  white-space: nowrap;
}

.frame .text-wrapper-232 {
  color: #555555;
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 500;
  left: 24px;
  letter-spacing: -0.2px;
  line-height: normal;
  position: absolute;
  top: 85px;
  white-space: nowrap;
}

.frame .spacer-2 {
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 4.84px;
  height: 856px;
  left: 1395px;
  overflow: hidden;
  position: absolute;
  top: 2765px;
  width: 133px;
}

.frame .size {
  left: 19px !important;
  position: absolute !important;
  top: 19px !important;
}

.frame .spacer-instance {
  left: 19px !important;
  position: absolute !important;
  top: 46px !important;
}

.frame .size-2 {
  left: 19px !important;
  position: absolute !important;
  top: 81px !important;
}

.frame .size-3 {
  left: 19px !important;
  position: absolute !important;
  top: 124px !important;
}

.frame .size-4 {
  left: 19px !important;
  position: absolute !important;
  top: 174px !important;
}

.frame .size-5 {
  left: 19px !important;
  position: absolute !important;
  top: 232px !important;
}

.frame .size-6 {
  left: 19px !important;
  position: absolute !important;
  top: 298px !important;
}

.frame .size-7 {
  left: 19px !important;
  position: absolute !important;
  top: 371px !important;
}

.frame .size-8 {
  left: 19px !important;
  position: absolute !important;
  top: 453px !important;
}

.frame .size-9 {
  left: 19px !important;
  position: absolute !important;
  top: 542px !important;
}

.frame .size-10 {
  left: 19px !important;
  position: absolute !important;
  top: 638px !important;
}

.frame .size-11 {
  left: 19px !important;
  position: absolute !important;
  top: 743px !important;
}

.frame .checkbox-3 {
  background-color: var(--neutral-0);
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 16px;
  box-shadow: 0px 4px 44px #1822310a;
  height: 377px;
  left: 1562px;
  overflow: hidden;
  position: absolute;
  top: 2765px;
  width: 892px;
}

.frame .key-3 {
  height: 32px;
  left: 65px;
  position: absolute;
  top: 41px;
  width: 93px;
}

.frame .text-wrapper-233 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .key-4 {
  height: 106px;
  left: 65px;
  position: absolute;
  top: 214px;
  width: 158px;
}

.frame .group-9 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 58px;
}

.frame .radio-instance {
  left: 20px !important;
  position: absolute !important;
  top: 0 !important;
}

.frame .text-wrapper-234 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 0;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 36px;
  white-space: nowrap;
}

.frame .text-wrapper-235 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .group-10 {
  height: 60px;
  left: 73px;
  position: absolute;
  top: 46px;
  width: 85px;
}

.frame .radio-2 {
  height: 20px !important;
  left: -2px !important;
  top: -2px !important;
  width: 20px !important;
}

.frame .radio-3 {
  left: 34px !important;
  position: absolute !important;
  top: 0 !important;
}

.frame .text-wrapper-236 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 65px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 88px;
  white-space: nowrap;
}

.frame .group-11 {
  height: 53px;
  left: 65px;
  position: absolute;
  top: 129px;
  width: 201px;
}

.frame .radio-partial {
  height: 16px !important;
  left: 98px !important;
  position: absolute !important;
  top: 0 !important;
  width: 16px !important;
}

.frame .text-wrapper-237 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 88px;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 29px;
  white-space: nowrap;
}

.frame .radio-no-instance {
  left: 170px !important;
  position: absolute !important;
  top: 1px !important;
}

.frame .text-wrapper-238 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 160px;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 29px;
  white-space: nowrap;
}

.frame .radio-yes {
  height: 16px !important;
  left: 17px !important;
  position: absolute !important;
  top: 2px !important;
  width: 16px !important;
}

.frame .text-wrapper-239 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 0;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 29px;
  white-space: nowrap;
}

.frame .text-wrapper-240 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 311px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 86px;
  white-space: nowrap;
}

.frame .text-wrapper-241 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 311px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 214px;
  white-space: nowrap;
}

.frame .group-12 {
  height: 56px;
  left: 311px;
  position: absolute;
  top: 126px;
  width: 44px;
}

.frame .design-component-instance-node-2 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.frame .text-wrapper-242 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 11px;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.frame .group-13 {
  height: 56px;
  left: 385px;
  position: absolute;
  top: 126px;
  width: 44px;
}

.frame .slider-false-instance {
  left: 311px !important;
  position: absolute !important;
  top: 264px !important;
}

.frame .slider-true {
  height: 52px;
  left: 474px;
  position: absolute;
  top: 232px;
  width: 152px;
}

.frame .overlap-24 {
  height: 52px;
  position: relative;
}

.frame .rectangle-13 {
  background-color: var(--neutral-30);
  border-radius: 100px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 152px;
}

.frame .div-226 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 88px;
}

.frame .overlap-group-30 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 80px;
}

.frame .rectangle-14 {
  background-color: var(--defaultprimary-light);
  border-radius: 100px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 74px;
}

.frame .ellipse-45 {
  background-color: var(--defaultwhite);
  border: 1px solid;
  border-color: var(--neutral-30);
  border-radius: 8px;
  box-shadow: var(--box-shadows-x-small);
  height: 16px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 16px;
}

.frame .div-227 {
  height: 28px;
  left: 58px;
  position: absolute;
  top: 4px;
  width: 29px;
}

.frame .div-wrapper-38 {
  align-items: center;
  background-color: var(--neutral-100);
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 0px 6px 1px;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-243 {
  color: var(--defaultwhite);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .polygon-9 {
  height: 6px;
  left: -6477px;
  position: absolute;
  top: -4950px;
  width: 9px;
}

.frame .text-wrapper-244 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 367px;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 296px;
  white-space: nowrap;
}

.frame .text-wrapper-245 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  left: 497px;
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  position: absolute;
  top: 296px;
  white-space: nowrap;
}

.frame .colour-palate {
  background-color: #ffffff;
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 9.56px;
  height: 546px;
  left: 2px;
  overflow: hidden;
  position: absolute;
  top: 3075px;
  width: 498px;
}

.frame .overlap-25 {
  height: 483px;
  left: 31px;
  position: relative;
  top: 31px;
  width: 437px;
}

.frame .overlap-26 {
  height: 441px;
  left: 0;
  position: absolute;
  top: 0;
  width: 437px;
}

.frame .div-228 {
  height: 220px;
  left: 0;
  position: absolute;
  top: 0;
  width: 437px;
}

.frame .div-229 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.99px;
  left: 0;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-246 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.42px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-230 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 127.43px;
}

.frame .element-efeee {
  background-color: #efede9;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .div-231 {
  align-items: center;
  display: flex;
  height: 21px;
  justify-content: space-between;
  padding: 0px 6.8px;
  position: relative;
  width: 127px;
}

.frame .text-wrapper-247 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 5.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .element-eaee {
  background-color: #eae7e2;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-eedb {
  background-color: #e5e2db;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .div-232 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.99px;
  left: 155px;
  position: absolute;
  top: 0;
}

.frame .element-fffae {
  background-color: #fefae5;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fffcc {
  background-color: #fef5cc;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fffb {
  background-color: #fef0b2;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ffeb {
  background-color: #feeb99;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fee {
  background-color: #fee166;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fed {
  background-color: #fed733;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fece {
  background-color: #fece00;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .div-233 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.99px;
  left: 309px;
  position: absolute;
  top: 0;
}

.frame .tertiary {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 191.14px;
  overflow: hidden;
  position: relative;
  width: 127.43px;
}

.frame .element-feff {
  background-color: #fef5f2;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fdece {
  background-color: #fdebe6;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fcedb {
  background-color: #fce1da;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fbdcf {
  background-color: #fbd8ce;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-facb {
  background-color: #f9c4b6;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fbe {
  background-color: #f7b19e;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fe {
  background-color: #f69e86;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-dc {
  background-color: #d4826c;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-b {
  background-color: #b26752;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .text-wrapper-248 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-234 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.99px;
  left: 155px;
  position: absolute;
  top: 199px;
}

.frame .element-ffffff {
  background-color: #ffffff;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ebebeb {
  background-color: #ebebeb;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ddd {
  background-color: #d6d6d6;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ccc {
  background-color: #c2c2c2;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-afafaf {
  background-color: #aeaeae;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-12 {
  background-color: #868686;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-eee {
  background-color: #5d5d5d;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-13 {
  background-color: #363636;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-bbb {
  background-color: #2b2b2b;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-14 {
  background-color: #1f1f1f;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .div-235 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.99px;
  left: 0;
  position: absolute;
  top: 135px;
}

.frame .element-edffb {
  background-color: #edf1fb;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-dcef {
  background-color: #dbe4f7;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .div-236 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 7px;
  position: relative;
  top: 8px;
  width: 114px;
}

.frame .text-wrapper-249 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 5.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.42px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .element-cadf {
  background-color: #cad6f4;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-bcf {
  background-color: #b8c9f0;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-aee {
  background-color: #95aee9;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-e {
  background-color: #7293e2;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fdb {
  background-color: #4f78db;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fb {
  background-color: #3f60af;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-f {
  background-color: #2f4884;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-15 {
  background-color: #1f3058;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-16 {
  background-color: #172443;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .div-237 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.99px;
  left: 309px;
  position: absolute;
  top: 242px;
}

.frame .element-efcf {
  background-color: #e5fbf3;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ccfe {
  background-color: #ccf8e8;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-bfdd {
  background-color: #b2f4dc;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-fd {
  background-color: #99f1d1;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ebbb {
  background-color: #66eaba;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-ea {
  background-color: #33e3a3;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .label-ddd-wrapper {
  background-color: #00dd8d;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .label-b-wrapper {
  background-color: #00b070;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-17 {
  background-color: #008454;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .element-18 {
  background-color: #005838;
  height: 21.24px;
  position: relative;
  width: 127.43px;
}

.frame .colour-palate-2 {
  background-color: #ffffff;
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 9.98px;
  height: 538px;
  left: 524px;
  overflow: hidden;
  position: absolute;
  top: 3083px;
  width: 837px;
}

.frame .element-19 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 26.61px;
  left: 43px;
  position: absolute;
  top: 80px;
}

.frame .div-238 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 33.26px;
  position: relative;
}

.frame .div-239 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  position: relative;
  width: 162.98px;
}

.frame .div-240 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.16px;
  position: relative;
}

.frame .text-wrapper-250 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Medium", Helvetica;
  font-size: 13.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.83px;
  position: relative;
  width: fit-content;
}

.frame .div-241 {
  align-items: center;
  align-self: stretch;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 0px 0px 0.83px #cdd0db;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: relative;
  width: 100%;
}

.frame .div-242 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.32px;
  position: relative;
}

.frame .text-wrapper-251 {
  color: var(--neutral-30);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 13.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -0.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-243 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.32px;
  position: relative;
}

.frame .div-244 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.99px;
  position: relative;
}

.frame .text-wrapper-252 {
  color: var(--neutral-60);
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -0.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-245 {
  align-items: center;
  align-self: stretch;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 0px 0px 0.83px #fb2047;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: relative;
  width: 100%;
}

.frame .element-general-alert {
  height: 13.3px !important;
  position: relative !important;
  width: 13.3px !important;
}

.frame .text-wrapper-253 {
  color: #f69e86;
  font-family: "Helvetica Neue-Regular", Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -0.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-246 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 33.26px;
  position: relative;
  width: 100%;
}

.frame .div-247 {
  align-items: center;
  align-self: stretch;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 1.66px 3.33px #0510370f, inset 0px 0px 0px 0.83px #cdd0dc;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: relative;
  width: 100%;
}

.frame .element-arrow-chevron {
  height: 16.63px !important;
  position: relative !important;
  width: 16.63px !important;
}

.frame .div-248 {
  align-items: center;
  align-self: stretch;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 1.66px 4.99px #0510371a, 0px 0px 0px 0.83px #fb2047;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: relative;
  width: 100%;
}

.frame .text-area {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 88.14px;
  position: relative;
  width: 162.98px;
}

.frame .overlap-group-31 {
  border-radius: 4.99px;
  height: 64px;
  position: relative;
  width: 163px;
}

.frame .frame-wrapper-59 {
  align-items: flex-start;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 0px 0px 0.83px #cdd0db;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 64.2px;
  left: 0;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: absolute;
  top: 0;
  width: 162.98px;
}

.frame .div-249 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8.32px;
  position: relative;
  width: 100%;
}

.frame .img-33 {
  height: 8px;
  left: 154px;
  position: absolute;
  top: 54px;
  width: 8px;
}

.frame .text-area-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 113.09px;
  position: relative;
  width: 162.98px;
}

.frame .overlap-group-32 {
  border-radius: 4.99px;
  height: 60px;
  position: relative;
  width: 163px;
}

.frame .frame-wrapper-60 {
  align-items: flex-start;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 0px 0px 0.83px #cdd0db;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 59.89px;
  left: 0;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: absolute;
  top: 0;
  width: 162.98px;
}

.frame .img-34 {
  height: 8px;
  left: 154px;
  position: absolute;
  top: 50px;
  width: 8px;
}

.frame .frame-wrapper-61 {
  align-items: flex-start;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 0px 0px 0.83px #fb2047;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 59.89px;
  left: 0;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: absolute;
  top: 0;
  width: 162.98px;
}

.frame .text-area-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 138.04px;
  position: relative;
  width: 162.98px;
}

.frame .overlap-group-33 {
  border-radius: 4.99px;
  height: 57px;
  position: relative;
  width: 163px;
}

.frame .frame-wrapper-62 {
  align-items: flex-start;
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  box-shadow: 0px 0px 0px 0.83px #fb2047;
  display: flex;
  flex-direction: column;
  gap: 8.32px;
  height: 56.59px;
  left: 0;
  overflow: hidden;
  padding: 6.65px 9.15px;
  position: absolute;
  top: 0;
  width: 162.98px;
}

.frame .img-35 {
  height: 8px;
  left: 154px;
  position: absolute;
  top: 47px;
  width: 8px;
}

.frame .text-wrapper-254 {
  color: var(--neutral-100);
  font-family: "Helvetica Neue-Bold", Helvetica;
  font-size: 12px;
  font-weight: 700;
  left: 42px;
  letter-spacing: 0;
  line-height: 17.4px;
  position: absolute;
  text-align: right;
  top: 38px;
  white-space: nowrap;
}

.frame .menu-8 {
  border: 0.5px solid;
  border-color: #c6c6c6;
  border-radius: 4.05px;
  height: 445px;
  left: 1562px;
  overflow: hidden;
  position: absolute;
  top: 3176px;
  width: 360px;
}

.frame .property-default {
  left: 16px !important;
  position: absolute !important;
  top: 16px !important;
}

.frame .icons-39 {
  height: 19.46px !important;
  position: relative !important;
  width: 19.46px !important;
}

.frame .icons-40 {
  height: 25.95px !important;
  position: relative !important;
  width: 25.95px !important;
}

.frame .property-collapsed {
  left: 240px !important;
  position: absolute !important;
  top: 16px !important;
}

.frame .div-250 {
  background-color: #1c1b1f;
  height: 1062px;
  left: -6px;
  position: absolute;
  top: 5575px;
  width: 1920px;
}

.frame .overlap-27 {
  height: 827px;
  left: 394px;
  position: absolute;
  top: 298px;
  width: 2274px;
}

.frame .resources-all {
  background-color: var(--x01-neutral100);
  border: 12px solid;
  border-color: #8181818f;
  border-radius: 8px;
  height: 802px;
  left: 1156px;
  overflow: hidden;
  position: absolute;
  top: 6px;
  width: 1118px;
}

.frame .div-251 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18.23px;
  justify-content: center;
  left: 214px;
  position: absolute;
  top: 144px;
  width: 564px;
}

.frame .frame-wrapper-63 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.31px;
  position: relative;
  width: 100%;
}

.frame .div-252 {
  align-items: center;
  display: flex;
  gap: 12.16px;
  height: 36.47px;
  margin-right: -3.04px;
  overflow: hidden;
  position: relative;
  width: 566.79px;
}

.frame .text-input-9 {
  align-items: center;
  align-self: stretch;
  border: 0.76px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.04px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 9.12px;
  position: relative;
}

.frame .div-253 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.08px;
  position: relative;
}

.frame .star-8 {
  height: 15.2px;
  position: relative;
  width: 15.2px;
}

.frame .value-4 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.6px;
  margin-top: -0.76px;
  position: relative;
  width: 117px;
}

.frame .tune {
  height: 18.23px;
  margin-left: -11754.96px;
  margin-top: -6153.6px;
  position: relative;
  width: 18.23px;
}

.frame .button-12 {
  flex: 0 0 auto !important;
  height: 36.47px !important;
}

.frame .button-13 {
  height: 18.23px !important;
  margin-left: -6720.77px !important;
  margin-top: -6162.71px !important;
  width: 18.23px !important;
}

.frame .button-14 {
  align-self: stretch !important;
  background-color: var(--secondary-50) !important;
  border: unset !important;
  border-color: unset !important;
  border-radius: 6.08px !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  gap: 1.52px !important;
  height: unset !important;
  padding: 9.12px 9.12px 9.12px 6.08px !important;
  width: 100% !important;
}

.frame .button-15 {
  font-size: 12.2px !important;
  font-weight: 700 !important;
  letter-spacing: -0.3px !important;
  white-space: unset !important;
}

.frame .button-16 {
  gap: 3.04px !important;
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
}

.frame .div-254 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.2px;
  position: relative;
  width: 100%;
}

.frame .container-nav-wrapper {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.23px;
  position: relative;
  width: 100%;
}

.frame .container-nav {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .container-menu-2 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.frame .navlink-4 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.52px;
  border-color: var(--secondary-50);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.08px;
  justify-content: center;
  padding: 6.08px;
  position: relative;
}

.frame .text-wrapper-255 {
  color: var(--secondary-40);
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.6px;
  margin-top: -2.52px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .navlink-5 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.04px;
  justify-content: center;
  padding: 6.08px;
  position: relative;
}

.frame .text-wrapper-256 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.6px;
  margin-bottom: -0.24px;
  margin-top: -1.76px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-39 {
  background-color: #eb5757;
  border-radius: 6.08px;
  height: 12.16px;
  position: relative;
  width: 12.16px;
}

.frame .text-wrapper-257 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  height: 7px;
  left: 4px;
  letter-spacing: -0.04px;
  line-height: 9.1px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
  width: 5px;
}

.frame .navlink-6 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.08px;
  justify-content: center;
  padding: 6.08px;
  position: relative;
}

.frame .div-255 {
  align-items: center;
  align-self: stretch;
  border: 0.76px solid;
  border-color: var(--x01-neutral50);
  border-radius: 6.08px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.52px;
  justify-content: center;
  padding: 6.08px;
  position: relative;
}

.frame .value-5 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 10.6px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12.8px;
  margin-top: -0.76px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chevron-4 {
  height: 12.16px;
  margin-left: -6745.77px;
  margin-top: -6207.88px;
  position: relative;
  width: 12.16px;
}

.frame .div-256 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15.2px;
  position: relative;
  width: 100%;
}

.frame .card-design-instance {
  border-radius: 6.08px !important;
  gap: 69.14px !important;
  height: 151.95px !important;
  width: 274.28px !important;
}

.frame .card-design-2 {
  background-image: url(../../../static/img/folder-card-4.png) !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-3 {
  padding: 0px 12.16px !important;
  top: 18px !important;
  width: 264px !important;
}

.frame .card-design-4 {
  gap: 6.08px !important;
}

.frame .card-design-5 {
  gap: 9.12px !important;
}

.frame .card-design-6 {
  gap: 12.16px !important;
}

.frame .card-design-7 {
  font-family: "Inter", Helvetica !important;
  font-size: 13.7px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: -0.05px !important;
  line-height: 16.4px !important;
  margin-top: -0.76px !important;
}

.frame .card-design-8 {
  border-radius: 3.04px !important;
  gap: 7.6px !important;
  padding: 1.52px 3.04px !important;
}

.frame .card-design-9 {
  font-family: "Inter", Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: -0.04px !important;
  line-height: 10.9px !important;
  margin-top: -0.76px !important;
}

.frame .card-design-10 {
  gap: 3.04px !important;
}

.frame .card-design-11 {
  border-radius: 1.52px !important;
  height: 3.04px !important;
  width: 3.04px !important;
}

.frame .card-design-12 {
  font-family: "Inter", Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: -0.04px !important;
  line-height: 10.9px !important;
}

.frame .card-design-13 {
  height: 12.16px !important;
  margin-left: -12212.04px !important;
  margin-top: -6266.88px !important;
  width: 24.31px !important;
}

.frame .card-design-14 {
  background-image: url(../../../static/img/arrow-back-3.png) !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-option-2 {
  height: 12.16px !important;
  margin-left: -12701.27px !important;
  margin-top: -6266.88px !important;
  width: 24.31px !important;
}

.frame .card-design-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 18.23px;
  position: relative;
  width: 100%;
}

.frame .card-design-15 {
  border-radius: 6.08px !important;
  gap: 69.14px !important;
  height: 151.95px !important;
  width: 275.79px !important;
}

.frame .card-design-16 {
  background-image: url(../../../static/img/folder-card-2.png) !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-17 {
  height: 12.16px !important;
  margin-left: -12121.8px !important;
  margin-top: -6434.03px !important;
  width: 24.31px !important;
}

.frame .widget-leaderboard-2 {
  align-items: center;
  border: 0.38px solid;
  border-color: var(--x01-neutral70);
  border-radius: 9.12px;
  display: flex;
  flex-direction: column;
  gap: 24.31px;
  justify-content: center;
  left: 814px;
  overflow: hidden;
  position: absolute;
  top: 141px;
  width: 249px;
}

.frame .container-8 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral100);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.23px;
  padding: 18.23px 15.2px;
  position: relative;
  width: 100%;
}

.frame .main-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.16px;
  position: relative;
  width: 100%;
}

.frame .div-wrapper-40 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.08px;
  position: relative;
}

.frame .text-wrapper-258 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 18.2px;
  margin-top: -0.76px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .bar-8 {
  align-items: center;
  border: 0.38px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.04px;
  display: flex;
  gap: 1.52px;
  padding: 6.08px;
  position: relative;
  width: 53.18px;
}

.frame .text-29 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  margin-top: -0.18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .chevron-5 {
  height: 11.4px;
  margin-left: -6972.74px;
  margin-top: -6157.39px;
  position: relative;
  width: 12.16px;
}

.frame .line-44 {
  align-self: stretch;
  height: 1px;
  margin-left: -6852.79px;
  margin-top: -6133.08px;
  position: relative;
  width: 100%;
}

.frame .contestant-5 {
  align-items: center;
  align-self: stretch;
  border-radius: 9.12px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 6.08px 9.12px;
  position: relative;
  width: 100%;
}

.frame .div-257 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.08px;
  position: relative;
}

.frame .image-5 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 30.39px;
  margin-left: -6861.91px;
  margin-top: -6222.73px;
  position: relative;
  width: 30.39px;
}

.frame .ellipse-46 {
  background-color: var(--accent-50);
  border: 1.09px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.8px;
  height: 8px;
  left: 23px;
  position: relative;
  top: 24px;
  width: 8px;
}

.frame .div-258 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.04px;
  position: relative;
}

.frame .div-259 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.04px;
  position: relative;
}

.frame .text-wrapper-259 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 10.6px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 12.8px;
  margin-top: -0.76px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .expand-less-4 {
  height: 12.16px;
  margin-left: -6847.34px;
  margin-top: -6223.99px;
  position: relative;
  width: 12.16px;
}

.frame .text-wrapper-260 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .position {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 30.39px;
  margin-left: -13822.57px;
  margin-top: -6222.73px;
  position: relative;
  width: 30.39px;
}

.frame .polygon-10 {
  height: 23px;
  left: -7030px;
  position: absolute;
  top: -6223px;
  width: 26px;
}

.frame .text-wrapper-261 {
  color: var(--x01-neutral100);
  font-family: "Manrope", Helvetica;
  font-size: 13px;
  font-weight: 800;
  height: 18px;
  left: 12px;
  letter-spacing: -0.2px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.frame .div-260 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 30.39px;
  margin-left: -6861.91px;
  margin-top: -6265.28px;
  position: relative;
  width: 30.39px;
}

.frame .expand-less-5 {
  height: 12.16px;
  margin-left: -6859.34px;
  margin-top: -6254.38px;
  position: relative;
  width: 12.16px;
}

.frame .div-261 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 30.39px;
  margin-left: -13810.57px;
  margin-top: -6265.28px;
  position: relative;
  width: 30.39px;
}

.frame .polygon-11 {
  height: 23px;
  left: -7030px;
  position: absolute;
  top: -6265px;
  width: 26px;
}

.frame .text-wrapper-262 {
  color: var(--x01-neutral100);
  font-family: "Manrope", Helvetica;
  font-size: 13px;
  font-weight: 800;
  height: 18px;
  left: 11px;
  letter-spacing: -0.2px;
  line-height: normal;
  position: absolute;
  top: 4px;
}

.frame .div-wrapper-41 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 30.39px;
  margin-left: -6861.91px;
  margin-top: -6307.83px;
  position: relative;
  width: 30.39px;
}

.frame .text-wrapper-263 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  height: 15px;
  left: 8px;
  letter-spacing: -0.05px;
  line-height: 14.6px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.frame .expand-less-6 {
  height: 12.16px;
  margin-left: -6862.18px;
  margin-top: -6309.08px;
  position: relative;
  width: 12.16px;
}

.frame .div-262 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 30.39px;
  margin-left: -13810.38px;
  margin-top: -6307.83px;
  position: relative;
  width: 30.39px;
}

.frame .polygon-12 {
  height: 23px;
  left: -7030px;
  position: absolute;
  top: -6308px;
  width: 26px;
}

.frame .contestant-6 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-98);
  border-radius: 6.08px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 6.08px 9.12px;
  position: relative;
  width: 100%;
}

.frame .div-263 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 30.39px;
  margin-left: -6861.91px;
  margin-top: -6350.38px;
  position: relative;
  width: 30.39px;
}

.frame .ellipse-47 {
  background-color: var(--accent-50);
  border: 1.09px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.8px;
  height: 8px;
  left: 23px;
  position: absolute;
  top: 24px;
  width: 8px;
}

.frame .text-wrapper-264 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  height: 15px;
  left: 6px;
  letter-spacing: -0.05px;
  line-height: 14.6px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.frame .expand-less-7 {
  height: 12.16px;
  margin-left: -6853.34px;
  margin-top: -6339.47px;
  position: relative;
  width: 12.16px;
}

.frame .div-wrapper-42 {
  height: 30.39px;
  position: relative;
  width: 30.39px;
}

.frame .text-wrapper-265 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 13.7px;
  font-weight: 600;
  height: 16px;
  left: 11px;
  letter-spacing: -0.05px;
  line-height: 16.4px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.frame .div-264 {
  background-image: url(../../../static/img/arrow-back-3.png);
  background-size: 100% 100%;
  height: 30.39px;
  margin-left: -6861.91px;
  margin-top: -6392.92px;
  position: relative;
  width: 30.39px;
}

.frame .expand-less-8 {
  height: 12.16px;
  margin-left: -6856.34px;
  margin-top: -6394.18px;
  position: relative;
  width: 12.16px;
}

.frame .button-17 {
  color: var(--x01-neutral100) !important;
  font-size: 10.6px !important;
  letter-spacing: -0.16px !important;
  line-height: 13.7px !important;
  margin-bottom: -0.16px !important;
  margin-top: -1.68px !important;
}

.frame .button-18 {
  height: 10.64px !important;
  margin-left: -6743.86px !important;
  margin-top: -6446.87px !important;
  width: 10.64px !important;
}

.frame .button-19 {
  border-radius: 3.04px !important;
  gap: 3.04px !important;
  height: 30.39px !important;
  padding: 9.12px 24.31px !important;
  width: 218.81px !important;
}

.frame .div-265 {
  align-items: flex-start;
  background-color: var(--x01-neutral98);
  display: flex;
  flex-direction: column;
  gap: 7.6px;
  justify-content: center;
  left: 190px;
  padding: 24.31px 24.31px 36.47px;
  position: absolute;
  top: 580px;
  width: 606px;
}

.frame .div-266 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.2px;
  margin-right: -24.31px;
  position: relative;
  width: 581.98px;
}

.frame .frame-wrapper-64 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 320.62px;
  position: relative;
  width: 100%;
}

.frame .div-wrapper-43 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 109.41px;
  position: relative;
}

.frame .div-267 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12.16px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .card-design-option-3 {
  border-radius: 18.8px !important;
  height: 18.23px !important;
  width: 18.23px !important;
}

.frame .card-design-option-4 {
  border-radius: 18.8px !important;
  height: 18px !important;
  width: 18px !important;
}

.frame .card-design-option-5 {
  height: 9px !important;
  left: 5px !important;
  top: 5px !important;
  width: 9px !important;
}

.frame .card-design-option-6 {
  background-image: url(../../../static/img/folder-card-1.png) !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-option-7 {
  height: 12.16px !important;
  margin-left: -12161.31px !important;
  margin-top: -6665.44px !important;
  width: 24.31px !important;
}

.frame .card-design-18 {
  gap: 15.2px !important;
  left: 12px !important;
  top: 12px !important;
}

.frame .card-design-19 {
  gap: 3.04px !important;
  width: 234.01px !important;
}

.frame .card-design-20 {
  height: 12.16px !important;
  margin-left: -6327.79px !important;
  margin-top: -6659.37px !important;
  width: 24.31px !important;
}

.frame .card-design-21 {
  font-family: "Inter", Helvetica !important;
  font-size: 10.6px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: -0.04px !important;
  line-height: 12.8px !important;
  margin-top: -0.76px !important;
}

.frame .card-design-22 {
  gap: 1.52px !important;
  left: 12px !important;
  top: 100px !important;
}

.frame .card-design-23 {
  font-family: "Inter", Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: -0.04px !important;
  line-height: 10.9px !important;
  margin-top: -0.76px !important;
}

.frame .card-design-24 {
  border-radius: 15.2px !important;
  gap: 3.04px !important;
  padding: 3.04px !important;
}

.frame .card-design-25 {
  background-image: url(../../../static/img/arrow-back-3.png) !important;
  height: 21.27px !important;
  margin-left: -6540.53px !important;
  margin-top: -6763.06px !important;
  width: 21.27px !important;
}

.frame .card-design-26 {
  font-size: 9.1px !important;
  letter-spacing: -0.23px !important;
  line-height: 13.7px !important;
}

.frame .card-design-27 {
  border-radius: 15.2px !important;
  gap: 3.04px !important;
  padding: 3.04px 6.08px 3.04px 3.04px !important;
}

.frame .card-design-28 {
  background-image: url(../../../static/img/arrow-back-3.png) !important;
  height: 21.27px !important;
  margin-left: -6612.96px !important;
  margin-top: -6763.06px !important;
  width: 21.27px !important;
}

.frame .card-design-29 {
  border-radius: 6.08px !important;
  gap: 69.14px !important;
  height: 151.95px !important;
  width: 264.4px !important;
}

.frame .all-files-instance {
  flex: 0 0 auto !important;
}

.frame .all-files-2 {
  background-image: url(../../../static/img/arrow-back-3.png) !important;
  height: 151.95px !important;
  margin-left: -6801.88px !important;
  margin-top: -6647.21px !important;
  width: 258.32px !important;
}

.frame .all-files-3 {
  gap: 69.14px !important;
  left: 18px !important;
  top: 12px !important;
  width: 222px !important;
}

.frame .all-files-4 {
  font-family: "Inter", Helvetica !important;
  font-size: 15.2px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: -0.03px !important;
  line-height: 18.2px !important;
}

.frame .all-files-5 {
  height: 24.31px !important;
  margin-left: -13284.58px !important;
  margin-top: -6659.37px !important;
  width: 24.31px !important;
}

.frame .arrow-back-ios-new-wrapper-3 {
  background-color: var(--x01-neutral100);
  border-radius: 24.31px;
  box-shadow: -3.04px -0.76px 18.23px #9393931f;
  height: 36px;
  left: 564px;
  position: absolute;
  top: 112px;
  transform: rotate(180deg);
  width: 36px;
}

.frame .arrow-back-ios-new-3 {
  height: 36px;
  left: 6777px;
  position: absolute;
  top: 6702px;
  transform: rotate(-180deg);
  width: 36px;
}

.frame .comments-2 {
  align-items: flex-start;
  background-color: var(--secondary-100);
  border: 0.76px solid;
  border-color: var(--x01-neutral90);
  border-radius: 6.08px;
  display: flex;
  flex-direction: column;
  gap: 15.2px;
  height: 289px;
  left: 814px;
  overflow-x: scroll;
  padding: 15.2px;
  position: absolute;
  top: 504px;
  width: 249px;
}

.frame .top-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.12px;
  position: relative;
  width: 100%;
}

.frame .title-6 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 18.2px;
  margin-top: -0.76px;
  position: relative;
}

.frame .container-9 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 15.2px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .thread-line-2 {
  height: 476px;
  left: 9px;
  position: absolute;
  top: 18px;
  width: 1px;
}

.frame .comment-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.04px;
  position: relative;
  width: 100%;
}

.frame .div-268 {
  background-image: url(../../../static/img/avatar-2.png);
  background-size: 100% 100%;
  height: 18.23px;
  position: relative;
  width: 18.23px;
}

.frame .content-7 {
  align-items: flex-start;
  background-color: var(--x01-neutral98);
  border-radius: 7.6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 6.08px;
  padding: 6.08px 9.12px;
  position: relative;
}

.frame .paragraph-4 {
  align-self: stretch;
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 10.6px;
  margin-top: -0.76px;
  position: relative;
}

.frame .text-wrapper-266 {
  color: #2b2b2b;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.2px;
  text-decoration: underline;
}

.frame .text-wrapper-267 {
  color: #2b2b2b;
  font-family: "Manrope", Helvetica;
  letter-spacing: 0;
  line-height: 18.2px;
}

.frame .text-wrapper-268 {
  color: #2b2b2b;
  letter-spacing: -0.04px;
  line-height: 12.8px;
}

.frame .rectangle-wrapper {
  align-self: stretch;
  height: 46.35px;
  position: relative;
  width: 100%;
}

.frame .rectangle-15 {
  height: 46px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 176px;
}

.frame .text-wrapper-269 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-270 {
  color: var(--x01-neutral50);
  font-family: "Manrope", Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13.7px;
  margin-top: -0.76px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text-wrapper-271 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  letter-spacing: -0.02px;
  line-height: 12.8px;
  margin-top: -0.26px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.frame .settings-2 {
  height: 15.2px;
  position: relative;
  width: 18.23px;
}

.frame .group-14 {
  height: 3px;
  left: 3px;
  position: relative;
  top: 6px;
  width: 12px;
}

.frame .ellipse-48 {
  background-color: var(--x01-neutral50);
  border-radius: 1.45px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.frame .ellipse-49 {
  background-color: var(--x01-neutral50);
  border-radius: 1.45px;
  height: 3px;
  left: 5px;
  position: absolute;
  top: 0;
  width: 3px;
}

.frame .ellipse-50 {
  background-color: var(--x01-neutral50);
  border-radius: 1.45px;
  height: 3px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 3px;
}

.frame .div-269 {
  background-image: url(../../../static/img/avatar-1.png);
  background-size: 100% 100%;
  height: 18.23px;
  position: relative;
  width: 18.23px;
}

.frame .comment-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.04px;
  padding: 0px 0px 0px 21.27px;
  position: relative;
  width: 100%;
}

.frame .avatar-11 {
  height: 18.23px;
  object-fit: cover;
  position: relative;
  width: 18.23px;
}

.frame .content-8 {
  align-items: flex-start;
  background-color: var(--x02-neutral90);
  border-radius: 7.6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 9.12px;
  overflow: hidden;
  padding: 6.08px 9.12px;
  position: relative;
}

.frame .paragraph-5 {
  align-self: stretch;
  color: #191d23;
  font-family: "Inter", Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 10.6px;
  margin-top: -0.76px;
  position: relative;
}

.frame .text-wrapper-272 {
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18.2px;
  text-decoration: underline;
}

.frame .text-wrapper-273 {
  letter-spacing: 0;
  line-height: 18.2px;
}

.frame .text-wrapper-274 {
  letter-spacing: -0.04px;
  line-height: 12.8px;
}

.frame .div-wrapper-44 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral100);
  border: 0.38px solid;
  border-color: #d9d9d9;
  border-radius: 3.04px;
  display: flex;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 7.6px 9.12px;
  position: relative;
  width: 100%;
}

.frame .text-wrapper-275 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  margin-top: -0.38px;
  position: relative;
}

.frame .dropdown-3 {
  align-items: center;
  align-self: stretch;
  border-radius: 6.08px;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 0px 44.07px;
  position: relative;
  width: 100%;
}

.frame .label-wrapper-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.6px;
  padding: 3.04px;
  position: relative;
}

.frame .label-8 {
  color: var(--x01-neutral10);
  font-family: "Manrope", Helvetica;
  font-size: 10.6px;
  font-weight: 600;
  letter-spacing: 0.21px;
  line-height: normal;
  margin-top: -0.76px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame .overlap-28 {
  height: 65px;
  left: 190px;
  position: absolute;
  top: 61px;
  width: 904px;
}

.frame .line-45 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 166px;
}

.frame .div-270 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.76px;
  border-color: var(--x01-neutral98);
  display: flex;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 15.2px 30.39px 15.2px 24.31px;
  position: absolute;
  top: 0;
  width: 904px;
}

.frame .text-wrapper-276 {
  color: var(--x01-neutral10);
  font-family: "Inter", Helvetica;
  font-size: 24.3px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 34px;
  margin-top: -0.76px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .img-36 {
  height: 24.31px;
  margin-left: -10960.96px;
  margin-top: -6065.94px;
  position: relative;
  width: 24.31px;
}

.frame .line-46 {
  height: 1px;
  left: 190px;
  position: absolute;
  top: 578px;
  width: 166px;
}

.frame .line-47 {
  height: 1px;
  left: -5227px;
  position: absolute;
  top: -5181px;
  width: 704px;
}

.frame .menu-instance {
  height: 717px !important;
  left: 0 !important;
  padding: 9.12px 0px !important;
  position: absolute !important;
  top: 61px !important;
  width: 190px !important;
}

.frame .menu-9 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  gap: 3.04px !important;
  padding: 12.16px 30.39px !important;
  width: 100% !important;
}

.frame .icons-41 {
  height: 18.23px !important;
  position: relative !important;
  width: 18.23px !important;
}

.frame .menu-10 {
  font-family: "Inter", Helvetica !important;
  font-size: 12.2px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.02px !important;
  line-height: 14.6px !important;
}

.frame .menu-11 {
  gap: 3.04px !important;
  padding: 12.16px 30.39px !important;
}

.frame .menu-12 {
  height: 15px !important;
  left: 3px !important;
  width: 12px !important;
}

.frame .menu-13 {
  height: 18.23px !important;
  width: 18.23px !important;
}

.frame .menu-14 {
  height: 11px !important;
  left: 3px !important;
  top: 3px !important;
  width: 11px !important;
}

.frame .menu-15 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  height: 5px !important;
  left: 4px !important;
  top: 8px !important;
  width: 5px !important;
}

.frame .menu-16 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  height: 5px !important;
  left: 4px !important;
  width: 5px !important;
}

.frame .menu-17 {
  height: 13px !important;
  width: 13px !important;
}

.frame .menu-18 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  height: 5px !important;
  left: 8px !important;
  top: 4px !important;
  width: 5px !important;
}

.frame .menu-19 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  height: 5px !important;
  top: 4px !important;
  width: 5px !important;
}

.frame .menu-20 {
  gap: 18.23px !important;
}

.frame .menu-21 {
  gap: 2.28px !important;
  padding: 3.04px 30.39px !important;
}

.frame .menu-22 {
  font-family: "Inter", Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: -0.04px !important;
  line-height: 10.9px !important;
  margin-top: -0.76px !important;
}

.frame .menu-23 {
  height: 6.08px !important;
  width: 6.08px !important;
}

.frame .menu-24 {
  border-radius: 1.52px !important;
  height: 3px !important;
  width: 3px !important;
}

.frame .menu-25 {
  height: 24px !important;
  left: 178px !important;
  top: 347px !important;
  width: 24px !important;
}

.frame .div-271 {
  align-items: center;
  background-color: var(--x02-neutral95);
  border-bottom-style: solid;
  border-bottom-width: 0.38px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 15.2px 30.39px;
  position: absolute;
  top: 0;
  width: 1094px;
}

.frame .div-272 {
  background-image: url(../../../static/img/group-417.png);
  background-size: 100% 100%;
  height: 27.33px;
  position: relative;
  width: 98.77px;
}

.frame .div-273 {
  align-items: center;
  display: flex;
  gap: 18.23px;
  justify-content: flex-end;
  padding: 3.04px 0px;
  position: relative;
  width: 161.07px;
}

.frame .div-274 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.08px;
  overflow: hidden;
  position: relative;
}

.frame .icon-7 {
  height: 24.31px;
  margin-left: -6938.51px;
  margin-top: -6028.23px;
  position: relative;
  width: 24.31px;
}

.frame .notifications {
  height: 24.31px;
  margin-left: -6908.12px;
  margin-top: -6028.23px;
  position: relative;
  width: 24.31px;
}

.frame .ellipse-wrapper-8 {
  background-image: url(../../../static/img/ellipse-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 24.31px;
  margin-left: -7011.45px;
  margin-top: -6028.23px;
  position: relative;
  width: 24.31px;
}

.frame .ellipse-51 {
  background-color: var(--accent-50);
  border: 0.76px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.04px;
  height: 6px;
  left: 18px;
  position: relative;
  top: 18px;
  width: 6px;
}

.frame .text-wrapper-277 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 12.2px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .dashboard-home-2 {
  height: 827px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1224px;
}

.frame .text-wrapper-278 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  height: 128px;
  left: 687px;
  letter-spacing: -0.28px;
  line-height: 64px;
  position: absolute;
  text-align: center;
  top: 75px;
  width: 614px;
}

.frame .text-wrapper-279 {
  color: #d9d9d9;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 24px;
  left: 711px;
  letter-spacing: -0.1px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 227px;
  width: 567px;
}
