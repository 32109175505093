.all-files {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.all-files .class-13 {
  background-image: url(../../../static/img/rectangle-5-12.png) !important;
}

.all-files .class-14 {
  background-image: url(../../../static/img/rectangle-5-11.png) !important;
}

.all-files .class-15 {
  background-image: url(../../../static/img/rectangle-5-8.png) !important;
}

.all-files .class-16 {
  background-image: url(../../../static/img/rectangle-5-5.png) !important;
  box-shadow: 0px 6px 0px #008555 !important;
}

.all-files .class-17 {
  background-image: url(../../../static/img/rectangle-5-4.png) !important;
  box-shadow: 0px 6px 0px #008555 !important;
}

.all-files .class-18 {
  background-image: url(../../../static/img/rectangle-5-3.png) !important;
  box-shadow: 0px 6px 0px #008555 !important;
}

.all-files .class-19 {
  background-image: url(../../../static/img/rectangle-5-2.png) !important;
  box-shadow: 0px 6px 0px #008555 !important;
}

.all-files .class-20 {
  margin-right: unset !important;
}

.all-files .file-name-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
}

.all-files .class-21 {
  margin-top: -1px !important;
}

.all-files .file-name-2 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.all-files .frame-41 {
  background-position: 50% 50%;
  background-size: cover;
  height: 200px;
  position: relative;
  width: 340px;
}

.all-files .frame-42 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 91px;
  left: 24px;
  position: absolute;
  top: 16px;
  width: 292px;
}

.all-files .frame-43 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.all-files .text-wrapper-16 {
  color: var(--secondary-100);
  font-family: var(--h6-MED-font-family);
  font-size: var(--h6-MED-font-size);
  font-style: var(--h6-MED-font-style);
  font-weight: var(--h6-MED-font-weight);
  letter-spacing: var(--h6-MED-letter-spacing);
  line-height: var(--h6-MED-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.all-files .more-horiz {
  height: 32px;
  position: relative;
  width: 32px;
}

.all-files.property-1-variant-8 {
  box-shadow: 0px 6px 0px #008555;
}

.all-files.property-1-variant-13 {
  box-shadow: 0px 6px 0px #008555;
}

.all-files.property-1-variant-8 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-7.png);
}

.all-files.property-1-variant-10 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-11 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-4 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-9 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-file-default .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-3 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-5 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-6 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-variant-13 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-6.png);
}

.all-files.property-1-variant-12 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-9.png);
}

.all-files.property-1-variant-7 .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}

.all-files.property-1-default .frame-41 {
  background-image: url(../../../static/img/rectangle-5-10.png);
}
