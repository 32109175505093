.line .class {
  position: relative !important;
}

.line .class-2 {
  position: relative !important;
  transform: rotate(90deg) !important;
}

.line .class-3 {
  align-self: stretch !important;
  height: unset !important;
  left: unset !important;
  margin-bottom: -0.5px !important;
  position: relative !important;
  top: unset !important;
}

.line .class-4 {
  align-self: stretch !important;
  height: 1px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 100% !important;
}

.line .class-5 {
  align-self: stretch !important;
  height: 1px !important;
  left: unset !important;
  margin-right: -0.5px !important;
  position: relative !important;
  top: unset !important;
  width: 100% !important;
}

.line .class-6 {
  align-self: stretch !important;
  height: unset !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.line .img {
  object-fit: cover;
  position: absolute;
}

.line .overlap-group-2 {
  position: relative;
}

.line .element-2 {
  object-fit: cover;
  position: absolute;
}

.line .endpoint-2-4 {
  height: 8px !important;
  left: 64px !important;
  position: absolute !important;
  top: 124px !important;
  width: 8px !important;
}

.line .endpoint-2-66 {
  height: 4px !important;
  left: 124px !important;
  position: absolute !important;
  top: 0 !important;
  width: 8px !important;
}

.line .class-7 {
  left: 0 !important;
  position: absolute !important;
  top: 60px !important;
}

.line .class-8 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  transform: rotate(90deg) !important;
}

.line .img-2 {
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.line .img-3 {
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.line .img-4 {
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
}

.line .endpoint-2-5 {
  height: 8px !important;
  position: relative !important;
  width: 8px !important;
}

.line.dotted {
  align-items: center;
  display: flex;
  position: relative;
}

.line.cut {
  align-items: center;
  display: flex;
  position: relative;
}

.line.numbers {
  align-items: center;
  display: flex;
  position: relative;
}

.line.arrow {
  align-items: center;
  position: relative;
}

.line.equal {
  align-items: center;
  display: flex;
  position: relative;
}

.line.squiggle {
  align-items: center;
  display: flex;
  position: relative;
}

.line.measure-gap {
  align-items: center;
  display: flex;
  position: relative;
}

.line.measure {
  align-items: center;
  display: flex;
  position: relative;
}

.line.dashed {
  position: relative;
}

.line.solid {
  position: relative;
}

.line.zigzag {
  align-items: center;
  display: flex;
  position: relative;
}

.line.horizontal {
  width: 132px;
}

.line.vertical {
  height: 132px;
}

.line.measure-gap.horizontal {
  height: 16px;
}

.line.dotted.vertical {
  flex-direction: column;
  width: 16px;
}

.line.horizontal.measure {
  height: 16px;
}

.line.horizontal.arrow {
  display: flex;
}

.line.cut.horizontal {
  height: 16px;
}

.line.horizontal.equal {
  height: 16px;
}

.line.horizontal.numbers {
  height: 16px;
}

.line.squiggle.horizontal {
  height: 16px;
}

.line.ninety.vertical {
  width: 68px;
}

.line.arrow.vertical {
  display: inline-flex;
  flex-direction: column;
}

.line.horizontal.zigzag {
  height: 16px;
}

.line.cut.vertical {
  flex-direction: column;
  width: 16px;
}

.line.measure.vertical {
  flex-direction: column;
  width: 16px;
}

.line.measure-gap.vertical {
  flex-direction: column;
  width: 16px;
}

.line.horizontal.dotted {
  height: 16px;
}

.line.zigzag.vertical {
  flex-direction: column;
  width: 16px;
}

.line.numbers.vertical {
  flex-direction: column;
  width: 16px;
}

.line.ninety.horizontal {
  height: 68px;
}

.line.vertical.equal {
  flex-direction: column;
  width: 16px;
}

.line.squiggle.vertical {
  flex-direction: column;
  width: 16px;
}

.line.vertical .img {
  height: 120px;
  left: 8px;
  top: 4px;
  width: 60px;
}

.line.horizontal .img {
  height: 60px;
  left: 4px;
  top: 0;
  width: 120px;
}

.line.vertical .overlap-group-2 {
  height: 132px;
  width: 72px;
}

.line.horizontal .overlap-group-2 {
  height: 68px;
}

.line.vertical .element-2 {
  height: 121px;
  left: 8px;
  top: 4px;
  width: 61px;
}

.line.horizontal .element-2 {
  height: 60px;
  left: 4px;
  top: 0;
  width: 121px;
}

.line.dotted .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.cut .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.ninety .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.numbers .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.arrow .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.equal .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.squiggle .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.measure .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.zigzag .img-2 {
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.horizontal .img-2 {
  height: 1px;
  margin-left: -5955px;
  margin-top: -316px;
  width: 1px;
}

.line.measure-gap.vertical .img-2 {
  margin-left: -5947px;
  margin-top: -192px;
  width: 132px;
}

.line.measure.dotted.vertical .img-2 {
  margin-bottom: -0.5px;
}

.line.horizontal.dotted.measure .img-2 {
  margin-right: -0.5px;
}

.line.vertical .img-3 {
  width: 1px;
}

.line.horizontal .img-3 {
  height: 1px;
}

.line.horizontal .img-4 {
  height: 1px;
}

.line.vertical .img-4 {
  width: 1px;
}

.line.horizontal.dotted .img-4 {
  margin-right: -0.5px;
}

.line.dotted.vertical .img-4 {
  margin-bottom: -0.5px;
}
