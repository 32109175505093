.radio {
  border-radius: 8px;
  height: 16px;
  width: 16px;
}

.radio .ellipse-4 {
  border: 3px solid;
  border-color: var(--defaultwhite);
  border-radius: 7px;
  height: 14px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 14px;
}

.radio.checked-false {
  background-color: #8b91ab;
}

.radio.checked-true {
  background-color: #1746ec;
}

.radio.checked-false .ellipse-4 {
  background-color: var(--defaultwhite);
}

.radio.checked-true .ellipse-4 {
  background-color: var(--defaultprimary-light);
}
