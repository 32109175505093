.day {
  align-items: center;
  background-color: var(--shadeswhite);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 40px;
  justify-content: center;
  padding: 8px;
  position: relative;
  width: 40px;
}

.day .element {
  color: var(--shadesblack);
  font-family: "Manrope", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}
