.nav-link {
  align-items: center;
  gap: 4px;
  position: relative;
}

.nav-link .dashboard {
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.nav-link .icons-49 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.nav-link .icons-52 {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.nav-link.default {
  display: flex;
  padding: 16px 40px;
  width: 250px;
}

.nav-link.collapsed {
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
}

.nav-link.collapsed.selected {
  background-color: var(--primary-50);
}

.nav-link.hover.collapsed {
  background-color: var(--primary-95);
}

.nav-link.hover.default {
  background-color: var(--primary-98);
}

.nav-link.default.selected {
  background-color: var(--primary-60);
}

.nav-link.selected .dashboard {
  color: var(--x01-neutral20);
}

.nav-link.default .dashboard {
  color: var(--x01-neutral30);
}

.nav-link.hover .dashboard {
  color: var(--x01-neutral40);
}
