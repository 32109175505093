.button-variant8 {
  all: unset;
  align-items: center;
  background-color: #1f3058;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 3.33px;
  height: 30px;
  justify-content: center;
  overflow: hidden;
  padding: 5px 13.34px;
  position: relative;
}

.button-variant8 .text-wrapper-7 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
