.card-design {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 91px;
  overflow: hidden;
  position: relative;
  width: 348px;
}

.card-design .folder-card {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 49, 89, 0) 100%);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 200px;
  position: relative;
  width: 100%;
}

.card-design .frame-8 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0px 16px;
  position: absolute;
  top: 24px;
  width: 348px;
}

.card-design .frame-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.card-design .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.card-design .frame-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.card-design .frame-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.card-design .div-4 {
  color: var(--secondary-100);
  font-family: var(--subhead-MED-font-family);
  font-size: var(--subhead-MED-font-size);
  font-style: var(--subhead-MED-font-style);
  font-weight: var(--subhead-MED-font-weight);
  letter-spacing: var(--subhead-MED-letter-spacing);
  line-height: var(--subhead-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .frame-12 {
  align-items: center;
  background-color: var(--tertiary-20);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 2px 4px;
  position: relative;
}

.card-design .UNREAD-ITEMS {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .frame-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.card-design .element-3 {
  background-color: #ffeaea;
  border-radius: 24.75px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.card-design .overlap-group-4 {
  border-radius: 24.75px;
  height: 24px;
  position: relative;
}

.card-design .img-5 {
  height: 12px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 12px;
}

.card-design .group-wrapper {
  background-color: #f5f5f5;
  border-radius: 24.75px;
  height: 24px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 24px;
}

.card-design .ellipse-9 {
  background-color: #f1f1f1;
  border-radius: 2px;
  height: 4px;
  position: relative;
  width: 4px;
}

.card-design .subheading-wrapper {
  align-items: flex-start;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 2px 4px;
  position: relative;
}

.card-design .subheading {
  color: #047857;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .supporting-text {
  color: var(--x01-neutral100);
  font-family: var(--tiny-REG-font-family);
  font-size: var(--tiny-REG-font-size);
  font-style: var(--tiny-REG-font-style);
  font-weight: var(--tiny-REG-font-weight);
  letter-spacing: var(--tiny-REG-letter-spacing);
  line-height: var(--tiny-REG-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .img-6 {
  height: 16px;
  position: relative;
  width: 32px;
}

.card-design .folder-card-2 {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 16.2%, rgba(1.06, 1.06, 1.06, 0) 100%);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  position: relative;
  width: 100%;
}

.card-design .line-2 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 196px;
  width: 348px;
}

.card-design .line-3 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 196px;
  width: 134px;
}

.card-design .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 16px;
  position: absolute;
  top: 16px;
}

.card-design .frame-15 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  width: 308px;
}

.card-design .frame-16 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.card-design .frame-17 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  position: relative;
}

.card-design .frame-18 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.card-design .frame-19 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.card-design .p {
  color: var(--secondary-100);
  flex: 1;
  font-family: var(--subhead-MED-font-family);
  font-size: var(--subhead-MED-font-size);
  font-style: var(--subhead-MED-font-style);
  font-weight: var(--subhead-MED-font-weight);
  letter-spacing: var(--subhead-MED-letter-spacing);
  line-height: var(--subhead-MED-line-height);
  margin-top: -1px;
  position: relative;
}

.card-design .frame-20 {
  height: 24px;
  position: relative;
  width: 24px;
}

.card-design .frame-21 {
  height: 24px;
  margin-right: -1px;
  position: relative;
  width: 26px;
}

.card-design .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.card-design .text-wrapper-13 {
  color: var(--x01-neutral90);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .frame-23 {
  align-items: center;
  background-color: var(--secondary-98);
  border-radius: 17px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 4px 8px;
  position: relative;
}

.card-design .subheading-2 {
  color: var(--secondary-50);
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .speakers {
  left: 16px;
  position: absolute;
  top: 132px;
}

.card-design .speakers-2 {
  position: relative;
}

.card-design .div-5 {
  color: var(--x01-neutral100);
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .frame-24 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.card-design .chip-speaker {
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 4px;
  position: relative;
}

.card-design .frame-25 {
  background-size: 100% 100%;
  height: 28px;
  position: relative;
  width: 28px;
}

.card-design .name {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .name-wrapper {
  align-items: center;
  background-color: var(--x01-neutral30);
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 4px 8px 4px 4px;
  position: relative;
}

.card-design .name-2 {
  color: var(--x01-neutral98);
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .frame-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.card-design .frame-27 {
  background-image: url(../../../static/img/ellipse-1-19.svg);
  background-size: 100% 100%;
  height: 28px;
  position: relative;
  width: 28px;
}

.card-design .chip-speaker-2 {
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 4px 8px 4px 4px;
  position: relative;
}

.card-design .frame-28 {
  background-image: url(../../../static/img/ellipse-1-14.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 28px;
  position: relative;
  width: 28px;
}

.card-design .register {
  color: var(--x01-neutral100);
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .arrow-forward {
  height: 16px;
  position: relative;
  width: 16px;
}

.card-design .folder-card-3 {
  align-items: flex-start;
  align-self: stretch;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 16px 20px;
  position: relative;
  width: 100%;
}

.card-design .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.card-design .speakers-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  position: relative;
}

.card-design .frame-30 {
  background-image: url(../../../static/img/ellipse-1-15.svg);
  background-size: 100% 100%;
  height: 28px;
  position: relative;
  width: 28px;
}

.card-design .frame-31 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.card-design .frame-32 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.card-design .text-wrapper-14 {
  color: #ffffff;
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-design .star-26 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.card-design .frame-33 {
  align-items: flex-start;
  background-color: #d7fdf2;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 2px 4px;
  position: relative;
}

.card-design.event-02 {
  height: 200px;
  justify-content: space-around;
}

.card-design.event {
  height: 200px;
  justify-content: space-around;
}

.card-design.state-0-hover.folder {
  box-shadow: 4px 4px 4px #00000040;
}

.card-design.state-0-default.file {
  height: 200px;
  justify-content: space-around;
}

.card-design.state-0-default.folder {
  height: 200px;
  justify-content: space-around;
}

.card-design.course .folder-card {
  background-image: url(../../../static/img/folder-card-13.png);
}

.card-design.event-02 .folder-card {
  background-image: url(../../../static/img/folder-card-13.png);
}

.card-design.file .folder-card {
  background-image: url(../../../static/img/folder-card-12.png);
}

.card-design.event .folder-card {
  background-image: url(../../../static/img/folder-card-13.png);
}

.card-design.folder .folder-card {
  background-image: url(../../../static/img/folder-card-13.png);
}

.card-design.course .frame-11 {
  gap: 8px;
}

.card-design.event-02 .frame-11 {
  gap: 8px;
}

.card-design.file .frame-11 {
  gap: 12px;
}

.card-design.event .frame-11 {
  gap: 8px;
}

.card-design.folder .frame-11 {
  gap: 8px;
}

.card-design.course .UNREAD-ITEMS {
  color: #f5f5f5;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
}

.card-design.event-02 .UNREAD-ITEMS {
  color: #f5f5f5;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
}

.card-design.file .UNREAD-ITEMS {
  color: #ffffff;
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
}

.card-design.event .UNREAD-ITEMS {
  color: #f5f5f5;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
}

.card-design.folder .UNREAD-ITEMS {
  color: #f5f5f5;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
}

.card-design.course .frame-13 {
  gap: 4px;
}

.card-design.event-02 .frame-13 {
  gap: 4px;
}

.card-design.file .frame-13 {
  gap: 8px;
}

.card-design.event .frame-13 {
  gap: 4px;
}

.card-design.folder .frame-13 {
  gap: 4px;
}

.card-design.course .subheading-wrapper {
  background-color: var(--accent-99);
}

.card-design.event-02 .subheading-wrapper {
  background-color: var(--accent-99);
}

.card-design.file .subheading-wrapper {
  background-color: var(--accent-99);
}

.card-design.event .subheading-wrapper {
  background-color: var(--accent-99);
}

.card-design.folder .subheading-wrapper {
  background-color: #d7fdf2;
}

.card-design.course .folder-card-2 {
  background-image: url(../../../static/img/folder-card-9.png);
  flex: 0 0 auto;
}

.card-design.event-02 .folder-card-2 {
  background-image: url(../../../static/img/folder-card-10.png);
  height: 200px;
}

.card-design.file .folder-card-2 {
  background-image: url(../../../static/img/folder-card-10.png);
}

.card-design.event .folder-card-2 {
  background-image: url(../../../static/img/folder-card-10.png);
  height: 200px;
}

.card-design.folder .folder-card-2 {
  background-image: url(../../../static/img/folder-card-10.png);
}

.card-design.course .speakers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 308px;
}

.card-design.event-02 .speakers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 308px;
}

.card-design.file .speakers {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.card-design.event .speakers {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
}

.card-design.folder .speakers {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.card-design.course .speakers-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
}

.card-design.event-02 .speakers-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
}

.card-design.event .speakers-2 {
  color: var(--x01-neutral100);
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.card-design.course .frame-25 {
  background-image: url(../../../static/img/ellipse-1-16.svg);
}

.card-design.event-02 .frame-25 {
  background-image: url(../../../static/img/ellipse-1-17.svg);
}

.card-design.file .frame-25 {
  background-image: url(../../../static/img/ellipse-1-16.svg);
}

.card-design.event .frame-25 {
  background-image: url(../../../static/img/ellipse-1-16.svg);
}

.card-design.folder .frame-25 {
  background-image: url(../../../static/img/ellipse-1-16.svg);
}

.card-design.course .frame-26 {
  background-color: var(--secondary-50);
  border-radius: 4px;
  gap: 2px;
  padding: 12px;
}

.card-design.event-02 .frame-26 {
  background-color: var(--secondary-50);
  border-radius: 4px;
  gap: 2px;
  padding: 12px;
}

.card-design.file .frame-26 {
  gap: 2px;
}

.card-design.event .frame-26 {
  gap: 4px;
}

.card-design.folder .frame-26 {
  gap: 2px;
}

.card-design.course .folder-card-3 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 49, 89, 0) 100%);
  background-image: url(../../../static/img/folder-card-8.png);
}

.card-design.event-02 .folder-card-3 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 49, 89, 0) 100%);
  background-image: url(../../../static/img/folder-card-8.png);
}

.card-design.file .folder-card-3 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 49, 89, 0) 100%);
  background-image: url(../../../static/img/folder-card-6.png);
  gap: 10px;
}

.card-design.event .folder-card-3 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 16.2%, rgba(32, 49, 89, 0) 100%);
  background-image: url(../../../static/img/folder-card-8.png);
  border-radius: 8px;
  justify-content: space-between;
}

.card-design.folder .folder-card-3 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 49, 89, 0) 100%);
  background-image: url(../../../static/img/folder-card-7.png);
  gap: 10px;
}

.card-design.course .frame-32 {
  gap: 8px;
}

.card-design.event-02 .frame-32 {
  gap: 8px;
}

.card-design.file .frame-32 {
  gap: 12px;
}

.card-design.event .frame-32 {
  gap: 8px;
}

.card-design.folder .frame-32 {
  gap: 8px;
}
