.radio-true {
  background-color: #1746ec;
  border-radius: 100px;
  height: 22px;
  width: 42px;
}

.radio-true .ellipse-5 {
  background-color: var(--defaultwhite);
  border-radius: 8.08px/7.86px;
  height: 16px;
  left: 23px;
  position: relative;
  top: 3px;
  width: 16px;
}
