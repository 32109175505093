.radio-false {
  background-color: #8b91ab;
  border-radius: 100px;
  height: 22px;
  width: 42px;
}

.radio-false .ellipse-6 {
  background-color: var(--defaultwhite);
  border-radius: 8.08px/7.86px;
  height: 16px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 16px;
}
