.video-progress {
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 528px;
}

.video-progress .overlap-group-2 {
  height: 8px;
  left: 28px;
  position: absolute;
  top: 4px;
  width: 532px;
}

.video-progress .background {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 500px;
}

.video-progress .buffering {
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 532px;
}

.video-progress .buffering-progress {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  opacity: 0.5;
}

.video-progress .progress-line {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
}

.video-progress .play {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.video-progress .playing-true {
  width: 400px;
}

.video-progress .playing-false {
  width: 80px;
}

.video-progress .playing-0-true {
  width: 200px;
}

.video-progress .playing-0-false {
  width: 8px;
}
