:root {
  --accent-30: rgba(0, 88, 56, 1);
  --accent-40: rgba(0, 133, 85, 1);
  --accent-50: rgba(0, 177, 113, 1);
  --accent-60: rgba(0, 221, 141, 1);
  --accent-70: rgba(51, 228, 164, 1);
  --accent-80: rgba(102, 235, 187, 1);
  --accent-90: rgba(153, 241, 209, 1);
  --accent-95: rgba(178, 245, 221, 1);
  --accent-98: rgba(204, 248, 232, 1);
  --accent-99: rgba(229, 252, 244, 1);
  --black-white-white: rgba(255, 255, 255, 1);
  --box-shadows-x-small: 0px 2px 6px 0px rgba(5, 16, 55, 0.1);
  --caption-BLD-font-family: "Inter-Bold", Helvetica;
  --caption-BLD-font-size: 14px;
  --caption-BLD-font-style: normal;
  --caption-BLD-font-weight: 700;
  --caption-BLD-letter-spacing: -0.056000000834465026px;
  --caption-BLD-line-height: 120.00000476837158%;
  --caption-REG-font-family: "Inter-Regular", Helvetica;
  --caption-REG-font-size: 14px;
  --caption-REG-font-style: normal;
  --caption-REG-font-weight: 400;
  --caption-REG-letter-spacing: -0.056000000834465026px;
  --caption-REG-line-height: 120.00000476837158%;
  --dark-gray-dark-gray-1: rgba(26, 32, 36, 1);
  --darkblack-100: rgba(0, 0, 0, 1);
  --default-body-small-font-family: "HelveticaNeue-Regular", Helvetica;
  --default-body-small-font-size: 14px;
  --default-body-small-font-style: normal;
  --default-body-small-font-weight: 400;
  --default-body-small-letter-spacing: 0px;
  --default-body-small-line-height: 24px;
  --defaultprimary-light: rgba(24, 71, 237, 1);
  --defaultwhite: rgba(255, 255, 255, 1);
  --detail-font-family: "Inter-Regular", Helvetica;
  --detail-font-size: 14px;
  --detail-font-style: normal;
  --detail-font-weight: 400;
  --detail-letter-spacing: -0.28px;
  --detail-line-height: 20px;
  --gray-900: rgba(16, 24, 40, 1);
  --h6-MED-font-family: "Inter-SemiBold", Helvetica;
  --h6-MED-font-size: 20px;
  --h6-MED-font-style: normal;
  --h6-MED-font-weight: 600;
  --h6-MED-letter-spacing: -0.04000000059604645px;
  --h6-MED-line-height: 120.00000476837158%;
  --infosurface: rgba(232, 241, 255, 1);
  --infotext: rgba(2, 80, 236, 1);
  --light-gray-light-gray-1: rgba(213, 218, 221, 1);
  --lightwhite-100: rgba(255, 255, 255, 1);
  --neutral-0: rgba(255, 255, 255, 1);
  --neutral-03: rgba(222, 214, 209, 1);
  --neutral-100: rgba(30, 32, 44, 1);
  --neutral-30: rgba(193, 199, 222, 1);
  --neutral-300: rgba(184, 192, 204, 1);
  --neutral-40: rgba(166, 172, 196, 1);
  --neutral-50: rgba(140, 146, 171, 1);
  --neutral-500: rgba(100, 116, 139, 1);
  --neutral-60: rgba(115, 121, 146, 1);
  --neutral-800: rgba(25, 29, 35, 1);
  --paragraph-MED-font-family: "Inter-Medium", Helvetica;
  --paragraph-MED-font-size: 16px;
  --paragraph-MED-font-style: normal;
  --paragraph-MED-font-weight: 500;
  --paragraph-MED-letter-spacing: -0.03200000047683716px;
  --paragraph-MED-line-height: 120.00000476837158%;
  --paragraph-REG-font-family: "Inter-Regular", Helvetica;
  --paragraph-REG-font-size: 16px;
  --paragraph-REG-font-style: normal;
  --paragraph-REG-font-weight: 400;
  --paragraph-REG-letter-spacing: -0.06400000095367432px;
  --paragraph-REG-line-height: 120.00000476837158%;
  --primary-030: rgba(255, 255, 255, 1);
  --primary-0340: rgba(146, 145, 133, 1);
  --primary-50: rgba(254, 206, 0, 1);
  --primary-60: rgba(254, 216, 51, 1);
  --primary-70: rgba(254, 226, 102, 1);
  --primary-80: rgba(255, 235, 153, 1);
  --primary-90: rgba(255, 240, 178, 1);
  --primary-95: rgba(255, 245, 204, 1);
  --primary-98: rgba(255, 250, 229, 1);
  --secondary-10: rgba(24, 37, 67, 1);
  --secondary-100: rgba(255, 255, 255, 1);
  --secondary-20: rgba(32, 49, 89, 1);
  --secondary-30: rgba(47, 72, 132, 1);
  --secondary-40: rgba(63, 96, 176, 1);
  --secondary-50: rgba(79, 120, 219, 1);
  --secondary-60: rgba(114, 147, 226, 1);
  --secondary-70: rgba(149, 174, 233, 1);
  --secondary-80: rgba(185, 201, 241, 1);
  --secondary-90: rgba(202, 214, 244, 1);
  --secondary-95: rgba(220, 228, 248, 1);
  --secondary-98: rgba(237, 241, 251, 1);
  --shadesblack: rgba(0, 0, 0, 1);
  --shadeswhite: rgba(255, 255, 255, 1);
  --subhead-MED-font-family: "Inter-SemiBold", Helvetica;
  --subhead-MED-font-size: 18px;
  --subhead-MED-font-style: normal;
  --subhead-MED-font-weight: 600;
  --subhead-MED-letter-spacing: -0.07200000107288361px;
  --subhead-MED-line-height: 120.00000476837158%;
  --tertiary-10: rgba(179, 103, 83, 1);
  --tertiary-20: rgba(212, 131, 108, 1);
  --tertiary-30: rgba(246, 158, 134, 1);
  --tertiary-40: rgba(248, 177, 158, 1);
  --tertiary-50: rgba(250, 197, 182, 1);
  --tertiary-60: rgba(251, 216, 207, 1);
  --tertiary-70: rgba(252, 226, 219, 1);
  --tertiary-80: rgba(253, 236, 231, 1);
  --tertiary-90: rgba(254, 245, 243, 1);
  --tiny-BLD-font-family: "Inter-Bold", Helvetica;
  --tiny-BLD-font-size: 12px;
  --tiny-BLD-font-style: normal;
  --tiny-BLD-font-weight: 700;
  --tiny-BLD-letter-spacing: -0.04800000071525574px;
  --tiny-BLD-line-height: 120.00000476837158%;
  --tiny-MED-font-family: "Inter-SemiBold", Helvetica;
  --tiny-MED-font-size: 12px;
  --tiny-MED-font-style: normal;
  --tiny-MED-font-weight: 600;
  --tiny-MED-letter-spacing: -0.04800000071525574px;
  --tiny-MED-line-height: 120.00000476837158%;
  --tiny-REG-font-family: "Inter-Regular", Helvetica;
  --tiny-REG-font-size: 12px;
  --tiny-REG-font-style: normal;
  --tiny-REG-font-weight: 400;
  --tiny-REG-letter-spacing: -0.04800000071525574px;
  --tiny-REG-line-height: 120.00000476837158%;
  --ui-essentialsneutralntrl100: rgba(255, 255, 255, 1);
  --white: rgba(255, 255, 255, 1);
  --x01-neutral10: rgba(32, 32, 32, 1);
  --x01-neutral100: rgba(255, 255, 255, 1);
  --x01-neutral20: rgba(43, 43, 43, 1);
  --x01-neutral30: rgba(54, 54, 54, 1);
  --x01-neutral40: rgba(94, 94, 94, 1);
  --x01-neutral50: rgba(134, 134, 134, 1);
  --x01-neutral60: rgba(175, 175, 175, 1);
  --x01-neutral70: rgba(195, 195, 195, 1);
  --x01-neutral80: rgba(215, 215, 215, 1);
  --x01-neutral90: rgba(235, 235, 235, 1);
  --x01-neutral98: rgba(248, 248, 248, 1);
  --x02-neutral80: rgba(229, 226, 219, 1);
  --x02-neutral90: rgba(234, 232, 226, 1);
  --x02-neutral95: rgba(239, 238, 233, 1);
}
