.button-variant-wrapper {
  align-items: center;
  background-color: #1f3058;
  border-radius: 6.67px;
  display: flex;
  gap: 3.33px;
  height: 40.02px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 26.68px;
  position: relative;
  width: 268px;
}

.button-variant-wrapper .text-wrapper-5 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: -0.23px;
  line-height: 15px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button-variant-wrapper .arrow-back-2 {
  height: 11.67px;
  margin-left: -6195.75px;
  margin-top: -4654.37px;
  position: relative;
  width: 11.67px;
}
