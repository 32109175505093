.chipsoft-case-study {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

/* body{
  overflow-x: hidden;
} */

.chipsoft-case-study .div-5 {
  background-color: #ffffff;
  height: 6556px;
  /* overflow: hidden; */
  position: relative;
  width: 1902px;
}

.chipsoft-case-study .overlap {
  height: 3021px;
  left: -18px;
  position: absolute;
  top: 1186px;
  width: 2061px;
}

.chipsoft-case-study .overlap-2 {
  height: 3021px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2061px;
}

.chipsoft-case-study .overlap-wrapper {
  height: 1996px;
  left: 0;
  position: absolute;
  top: 1025px;
  width: 1920px;
}

.chipsoft-case-study .overlap-3 {
  height: 2005px;
  left: -279px;
  position: relative;
  width: 2269px;
}

.chipsoft-case-study .rectangle {
  background-color: #f5f5f5;
  height: 2005px;
  left: 279px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.chipsoft-case-study .meetings-company {
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  height: 128px;
  left: 405px;
  letter-spacing: -0.28px;
  line-height: 64px;
  position: absolute;
  top: 203px;
  width: 669px;
}

.chipsoft-case-study .auditorium-sessions {
  background-color: var(--x01-neutral10);
  border: 12px solid;
  border-color: #8181818f;
  border-radius: 8px;
  height: 814px;
  left: 1134px;
  overflow: hidden;
  position: absolute;
  top: 224px;
  width: 1135px;
}

.chipsoft-case-study .overlap-4 {
  height: 1049px;
  left: 193px;
  position: absolute;
  top: 62px;
  width: 886px;
}

.chipsoft-case-study .base {
  height: 728px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 860px;
}

.chipsoft-case-study .header {
  align-items: center;
  display: flex;
  gap: 142.72px;
  left: 37px;
  position: absolute;
  top: 31px;
  width: 849px;
}

.chipsoft-case-study .text-wrapper-17 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 24.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.34px;
  position: relative;
}

.chipsoft-case-study .text-input-2 {
  align-items: center;
  align-self: stretch;
  border: 0.77px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.09px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 9.26px;
  position: relative;
}

.chipsoft-case-study .frame-34 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.17px;
  position: relative;
}

.chipsoft-case-study .search-2 {
  height: 15.43px;
  position: relative;
  width: 15.43px;
}

.chipsoft-case-study .value-2 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.8px;
  margin-top: -0.77px;
  position: relative;
  width: 118.81px;
}

.chipsoft-case-study .tune {
  height: 18.52px;
  position: relative;
  width: 1.47px;
}

.chipsoft-case-study .frame-35 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18.52px;
  left: 37px;
  position: absolute;
  top: 93px;
  width: 849px;
}

.chipsoft-case-study .nav-bar {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.77px;
  border-color: #464646;
  display: flex;
  flex-direction: column;
  height: 35.49px;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .nav-bar-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-top: -0.01px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.chipsoft-case-study .nav-link-3 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.54px;
  border-color: #ffffff;
  display: flex;
  gap: 6.17px;
  height: 35.49px;
  justify-content: center;
  padding: 6.17px;
  position: relative;
  width: 94.12px;
}

.chipsoft-case-study .text-wrapper-18 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.chipsoft-case-study .nav-link-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.17px;
  height: 35.49px;
  justify-content: center;
  padding: 6.17px 9.26px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-19 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.chipsoft-case-study .nav-link-5 {
  align-items: center;
  display: flex;
  gap: 6.17px;
  height: 35.49px;
  justify-content: center;
  padding: 6.17px;
  position: relative;
  width: 94.12px;
}

.chipsoft-case-study .nav-link-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.17px;
  height: 35.49px;
  justify-content: center;
  padding: 6.17px 12.34px;
  position: relative;
}

.chipsoft-case-study .nav-link-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.17px;
  justify-content: center;
  padding: 9.26px 18.52px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-20 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: normal;
  margin-top: -1.54px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.chipsoft-case-study .frame-36 {
  align-items: center;
  background-color: var(--x01-neutral30);
  border-radius: 3.09px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.09px;
  justify-content: center;
  padding: 4.63px;
  position: relative;
}

.chipsoft-case-study .icons-11 {
  height: 18.52px;
  position: relative;
  width: 18.52px;
}

.chipsoft-case-study .text-wrapper-21 {
  color: var(--x02-neutral80);
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 13px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.26px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .dropdown-wrapper {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.26px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .dropdown {
  align-items: flex-start;
  border-radius: 4.63px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.34px;
  justify-content: center;
  padding: 3.09px 0px;
  position: relative;
}

.chipsoft-case-study .frame-38 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.17px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-22 {
  color: #a1a1a1;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  letter-spacing: -0.27px;
  line-height: 18.5px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-39 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.54px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-23 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: -0.27px;
  line-height: 18.5px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .chevron-right {
  height: 12.34px;
  margin-left: -779.53px;
  margin-top: -2115.06px;
  position: relative;
  width: 12.34px;
}

.chipsoft-case-study .container-events {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.52px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .event {
  align-items: center;
  align-self: stretch;
  background-color: #3a3a3a;
  border-radius: 9.26px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.71px;
  justify-content: center;
  padding: 24.69px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .element {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.26px;
  position: relative;
}

.chipsoft-case-study .frame-40 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.26px;
  position: relative;
}

.chipsoft-case-study .live {
  align-items: flex-start;
  background-color: #eb5757;
  border-radius: 3.86px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.71px;
  padding: 1.54px 3.09px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-24 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 9.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 12.3px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .holder {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.69px;
  position: relative;
}

.chipsoft-case-study .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.26px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-25 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 15.4px;
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: normal;
  margin-top: -0.77px;
  position: relative;
  width: 293.16px;
}

.chipsoft-case-study .div-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.09px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-26 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -0.77px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .speakers-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.17px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-27 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.77px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .frame-42 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .chip-speaker-2 {
  align-items: center;
  border-radius: 15.43px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.09px;
  padding: 0px 9.26px 0px 0px;
  position: relative;
}

.chipsoft-case-study .frame-43 {
  background-image: url(../../../static/img/ellipse-1-42.svg);
  background-size: 100% 100%;
  height: 21.6px;
  position: relative;
  width: 21.6px;
}

.chipsoft-case-study .name-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  letter-spacing: -0.27px;
  line-height: 13.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-44 {
  background-image: url(../../../static/img/ellipse-1-41.svg);
  background-size: 100% 100%;
  height: 21.6px;
  position: relative;
  width: 21.6px;
}

.chipsoft-case-study .frame-45 {
  background-image: url(../../../static/img/ellipse-1-40.svg);
  background-size: 100% 100%;
  height: 21.6px;
  position: relative;
  width: 21.6px;
}

.chipsoft-case-study .chip-speaker-3 {
  align-items: center;
  background-color: var(--accent-50);
  border-radius: 15.43px;
  display: flex;
  gap: 3.09px;
  height: 23.92px;
  padding: 3.09px 6.17px 3.09px 3.09px;
  position: relative;
  width: 24.69px;
}

.chipsoft-case-study .frame-46 {
  align-items: flex-end;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chipsoft-case-study .frame-47 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.17px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .CTA {
  all: unset;
  align-items: center;
  border: 0.77px solid;
  border-color: var(--x01-neutral90);
  border-radius: 3.09px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.71px;
  justify-content: center;
  padding: 9.26px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-28 {
  color: var(--x01-neutral90);
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.8px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .CTA-2 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 3.09px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.71px;
  justify-content: center;
  padding: 9.26px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-29 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.8px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .speakers-3 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.17px;
  justify-content: flex-end;
  position: relative;
}

.chipsoft-case-study .attending {
  align-items: flex-start;
  display: inline-flex;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo {
  background-image: url(../../../static/img/avatar-w-photo-40.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  position: relative;
  width: 23.92px;
}

.chipsoft-case-study .outside-stroke {
  border: 1.54px solid;
  border-color: #ffffff;
  border-radius: 13.5px/13.89px;
  height: 28px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.chipsoft-case-study .outside-stroke-wrapper {
  background-image: url(../../../static/img/avatar-w-photo-39.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
  width: 23.14px;
}

.chipsoft-case-study .outside-stroke-2 {
  border: 1.54px solid;
  border-color: #ffffff;
  border-radius: 13.12px/13.89px;
  height: 28px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 26px;
}

.chipsoft-case-study .avatar-w-photo-2 {
  background-image: url(../../../static/img/avatar-w-photo-38.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
  width: 23.14px;
}

.chipsoft-case-study .avatar-w-photo-3 {
  background-color: #555555;
  border: 1.54px solid;
  border-color: #ffffff;
  border-radius: 770.71px;
  height: 26.23px;
  margin-bottom: -0.77px;
  margin-left: -3.09px;
  margin-right: -0.77px;
  margin-top: -0.77px;
  position: relative;
  width: 26.23px;
}

.chipsoft-case-study .text-wrapper-30 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.7px;
  font-weight: 600;
  height: 9px;
  left: 5px;
  letter-spacing: -0.35px;
  line-height: normal;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.chipsoft-case-study .live-2 {
  align-items: flex-start;
  background-color: #0060ff;
  border-radius: 3.86px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.71px;
  padding: 1.54px 3.09px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-31 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -0.77px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .speakers-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.17px;
  justify-content: center;
  position: relative;
}

.chipsoft-case-study .div-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.chipsoft-case-study .chip-speaker-4 {
  border-radius: 15.43px;
  height: 24.69px;
  position: relative;
  width: 76.38px;
}

.chipsoft-case-study .frame-48 {
  background-image: url(../../../static/img/ellipse-1-39.svg);
  background-size: 100% 100%;
  height: 19px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 19px;
}

.chipsoft-case-study .name-4 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  height: 14px;
  left: 25px;
  letter-spacing: -0.27px;
  line-height: 13.9px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.chipsoft-case-study .chip-speaker-5 {
  align-items: center;
  border-radius: 15.43px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.09px;
  padding: 3.09px 6.17px 3.09px 3.09px;
  position: relative;
}

.chipsoft-case-study .frame-49 {
  background-image: url(../../../static/img/ellipse-1-38.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 18.52px;
  position: relative;
  width: 18.52px;
}

.chipsoft-case-study .frame-50 {
  background-image: url(../../../static/img/ellipse-1-37.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 18.52px;
  position: relative;
  width: 18.52px;
}

.chipsoft-case-study .chip-speaker-6 {
  align-items: center;
  align-self: stretch;
  background-color: #575757;
  border-radius: 15.43px;
  display: flex;
  gap: 3.09px;
  padding: 3.09px 6.17px 3.09px 3.09px;
  position: relative;
  width: 24.69px;
}

.chipsoft-case-study .frame-51 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.26px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-52 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.26px;
  position: relative;
}

.chipsoft-case-study .calendar-add-on-wrapper {
  background-color: #ffffff1f;
  border-radius: 29.32px;
  height: 30.86px;
  position: relative;
  width: 30.86px;
}

.chipsoft-case-study .calendar-add-on {
  height: 19px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 19px;
}

.chipsoft-case-study .dots-horizontal {
  height: 24.69px;
  position: relative;
  width: 23.07px;
}

.chipsoft-case-study .avatar-w-photo-4 {
  background-image: url(../../../static/img/avatar-w-photo-37.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  position: relative;
  width: 23.92px;
}

.chipsoft-case-study .avatar-w-photo-5 {
  background-image: url(../../../static/img/avatar-w-photo-36.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
  width: 23.14px;
}

.chipsoft-case-study .avatar-w-photo-6 {
  background-image: url(../../../static/img/avatar-w-photo-35.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
  width: 23.14px;
}

.chipsoft-case-study .div-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.09px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-32 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.77px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .ellipse-7 {
  background-color: #ffffff;
  border-radius: 1.54px;
  height: 3.09px;
  position: relative;
  width: 3.09px;
}

.chipsoft-case-study .text-wrapper-33 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -0.77px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .frame-53 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-size: 100% 100%;
  height: 21.6px;
  margin-left: -142.59px;
  margin-top: -2748.34px;
  position: relative;
  width: 21.6px;
}

.chipsoft-case-study .frame-54 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-size: 100% 100%;
  height: 21.6px;
  margin-left: -222.54px;
  margin-top: -2748.34px;
  position: relative;
  width: 21.6px;
}

.chipsoft-case-study .frame-55 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-size: 100% 100%;
  height: 21.6px;
  margin-left: -279.48px;
  margin-top: -2748.34px;
  position: relative;
  width: 21.6px;
}

.chipsoft-case-study .frame-56 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 61.72px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-7 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  position: relative;
  width: 23.92px;
}

.chipsoft-case-study .avatar-w-photo-8 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 770.71px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
  width: 23.14px;
}

.chipsoft-case-study .star-2 {
  height: 18.52px;
  margin-top: -2864.34px;
  position: relative;
  width: 18.52px;
}

.chipsoft-case-study .frame-57 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-size: 100% 100%;
  height: 19px;
  left: -143px;
  position: absolute;
  top: -2968px;
  width: 19px;
}

.chipsoft-case-study .frame-58 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 18.52px;
  margin-left: -222.05px;
  margin-top: -2971.54px;
  position: relative;
  width: 18.52px;
}

.chipsoft-case-study .frame-59 {
  background-image: url(../../../static/img/arrow-forward.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 18.52px;
  margin-left: -275.91px;
  margin-top: -2971.54px;
  position: relative;
  width: 18.52px;
}

.chipsoft-case-study .frame-60 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 128.84px;
}

.chipsoft-case-study .frame-61 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.26px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-62 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 9.26px;
  justify-content: flex-end;
  position: relative;
}

.chipsoft-case-study .calendar-add-on-2 {
  height: 19px;
  left: -826px;
  position: absolute;
  top: -2836px;
  width: 19px;
}

.chipsoft-case-study .CTA-3 {
  all: unset;
  align-items: center;
  background-color: #555555;
  border-radius: 3.09px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.71px;
  justify-content: center;
  padding: 9.26px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-34 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 13.9px;
  font-weight: 700;
  letter-spacing: -0.21px;
  line-height: 18.5px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .menu-2 {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  display: flex;
  flex-direction: column;
  height: 728px;
  justify-content: space-between;
  left: 0;
  padding: 0px 0px 18.52px;
  position: absolute;
  top: 62px;
  width: 193px;
}

.chipsoft-case-study .frame-63 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 192.87px;
}

.chipsoft-case-study .design-component-instance-node {
  flex: 0 0 auto !important;
  gap: 3.09px !important;
  padding: 12.34px 30.86px !important;
  width: 192.87px !important;
}

.chipsoft-case-study .nav-link-8 {
  font-family: "Inter", Helvetica !important;
  font-size: 12.3px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.02px !important;
  line-height: 14.8px !important;
}

.chipsoft-case-study .nav-link-9 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 3.09px;
  padding: 12.34px 30.86px;
  position: relative;
  width: 192.87px;
}

.chipsoft-case-study .icons-13 {
  height: 18.52px !important;
  width: 18.52px !important;
}

.chipsoft-case-study .icons-14 {
  height: 15px !important;
  left: 3px !important;
  width: 12px !important;
}

.chipsoft-case-study .dashboard-2 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .design-component-instance-node-2 {
  height: 13px !important;
  width: 13px !important;
}

.chipsoft-case-study .icons-15 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  height: 5px !important;
  left: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-16 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  height: 5px !important;
  top: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-17 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  height: 5px !important;
  left: 8px !important;
  top: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-18 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  height: 5px !important;
  left: 4px !important;
  top: 8px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-19 {
  height: 12px !important;
  left: 3px !important;
  top: 3px !important;
  width: 12px !important;
}

.chipsoft-case-study .nav-link-10 {
  flex: 0 0 auto !important;
  gap: 3.09px !important;
  padding: 12.34px 30.86px !important;
  width: 190.56px !important;
}

.chipsoft-case-study .extra-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 2.31px;
  padding: 3.09px 30.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-35 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 9.3px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.1px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .interpunct-2 {
  height: 6.17px;
  position: relative;
  width: 6.17px;
}

.chipsoft-case-study .ellipse-8 {
  background-color: #d9d9d9;
  border-radius: 1.54px;
  height: 3px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 3px;
}

.chipsoft-case-study .frame-64 {
  align-items: center;
  background-color: var(--x02-neutral95);
  border-bottom-style: solid;
  border-bottom-width: 0.39px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 15.43px 30.86px;
  position: absolute;
  top: 0;
  width: 1111px;
}

.chipsoft-case-study .frame-65 {
  background-image: url(../../../static/img/group-417-3.png);
  background-size: 100% 100%;
  height: 27.75px;
  position: relative;
  width: 100.29px;
}

.chipsoft-case-study .frame-66 {
  align-items: center;
  display: flex;
  gap: 18.52px;
  justify-content: flex-end;
  padding: 3.09px 0px;
  position: relative;
  width: 163.55px;
}

.chipsoft-case-study .notifications {
  height: 24.69px;
  position: relative;
  width: 24.69px;
}

.chipsoft-case-study .frame-67 {
  background-image: url(../../../static/img/ellipse-5-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 24.69px;
  position: relative;
  width: 24.69px;
}

.chipsoft-case-study .ellipse-9 {
  background-color: var(--accent-50);
  border: 0.77px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.09px;
  height: 6px;
  left: 19px;
  position: relative;
  top: 19px;
  width: 6px;
}

.chipsoft-case-study .text-wrapper-36 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .speaker {
  background-color: #ffffff;
  border: 12px solid;
  border-color: #8181818f;
  border-radius: 8px;
  height: 767px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 404px;
  width: 1069px;
}

.chipsoft-case-study .overlap-5 {
  background-color: #1f1f1f;
  height: 743px;
  position: relative;
  width: 1045px;
}

.chipsoft-case-study .overlap-6 {
  height: 743px;
  left: 91px;
  position: absolute;
  top: 0;
  width: 954px;
}

.chipsoft-case-study .frame-68 {
  align-items: flex-start;
  background-image: url(../../../static/img/frame-237598.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10.89px;
  box-shadow: 0px 4.35px 15.24px #000000d6;
  display: flex;
  flex-direction: column;
  height: 524px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 17.42px;
  position: absolute;
  top: 190px;
  width: 864px;
}

.chipsoft-case-study .frame-69 {
  align-items: flex-start;
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  display: flex;
  gap: 7.26px;
  height: 29.03px;
  overflow: hidden;
  padding: 7.26px;
  position: relative;
  width: 59.91px;
}

.chipsoft-case-study .text-wrapper-37 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.5px;
  margin-right: -1.6px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .speaker-name {
  align-items: flex-start;
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.26px;
  justify-content: center;
  overflow: hidden;
  padding: 11.61px;
  position: relative;
}

.chipsoft-case-study .frame-70 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.9px;
  position: relative;
}

.chipsoft-case-study .frame-71 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.45px;
  position: relative;
}

.chipsoft-case-study .img-wrapper {
  height: 14.51px;
  position: relative;
  width: 14.51px;
}

.chipsoft-case-study .ellipse-10 {
  height: 6px;
  left: 1130px;
  position: absolute;
  top: -2735px;
  width: 6px;
}

.chipsoft-case-study .text-wrapper-38 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-72 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.26px;
  padding: 0px 0px 0px 15.97px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-39 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-73 {
  align-items: flex-start;
  background-image: url(../../../static/img/frame-237603.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 5.81px;
  box-shadow: 0px 4.35px 15.24px #000000d6;
  display: flex;
  flex-direction: column;
  height: 147px;
  justify-content: space-between;
  left: 565px;
  padding: 5.81px;
  position: absolute;
  top: 29px;
  width: 238px;
}

.chipsoft-case-study .frame-74 {
  align-items: flex-start;
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.26px;
  overflow: hidden;
  padding: 2.9px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-40 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .speaker-name-2 {
  align-items: flex-start;
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.26px;
  justify-content: center;
  overflow: hidden;
  padding: 5.81px;
  position: relative;
}

.chipsoft-case-study .frame-75 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.9px;
  justify-content: center;
  position: relative;
}

.chipsoft-case-study .frame-76 {
  height: 10.16px;
  position: relative;
  width: 10.16px;
}

.chipsoft-case-study .ellipse-11 {
  height: 6px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 6px;
}

.chipsoft-case-study .text-wrapper-41 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.7px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 10.5px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .toolbar {
  align-items: center;
  background-color: #000000a3;
  border-radius: 5.81px;
  box-shadow: 0px 4.35px 15.24px #000000d6;
  display: inline-flex;
  gap: 5.81px;
  justify-content: center;
  left: 168px;
  padding: 5.81px;
  position: absolute;
  top: 646px;
}

.chipsoft-case-study .keyboard-voice-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x01-neutral30);
  border-radius: 5.81px;
  display: inline-flex;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 11.61px;
  position: relative;
}

.chipsoft-case-study .keyboard-voice {
  height: 29.03px;
  position: relative;
  width: 20.52px;
}

.chipsoft-case-study .frame-77 {
  align-items: center;
  align-self: stretch;
  background-color: var(--x01-neutral30);
  border-radius: 5.81px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  overflow: hidden;
  padding: 11.61px;
  position: relative;
}

.chipsoft-case-study .frame-78 {
  height: 29.03px;
  position: relative;
  width: 29.03px;
}

.chipsoft-case-study .img-4 {
  height: 23px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 23px;
}

.chipsoft-case-study .overlap-group-4 {
  height: 26px;
  left: 3px;
  position: relative;
  width: 26px;
}

.chipsoft-case-study .chat-bubble {
  height: 23px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 23px;
}

.chipsoft-case-study .ellipse-12 {
  background-color: #eb5757;
  border-radius: 5.81px;
  height: 12px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 12px;
}

.chipsoft-case-study .text-wrapper-42 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  height: 7px;
  left: 18px;
  letter-spacing: -0.03px;
  line-height: 8.7px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 4px;
}

.chipsoft-case-study .frame-79 {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
}

.chipsoft-case-study .frame-80 {
  align-items: center;
  align-self: stretch;
  background-color: #eb5757;
  border-radius: 5.81px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  overflow: hidden;
  padding: 11.61px;
  position: relative;
}

.chipsoft-case-study .arrow-back-ios-new-wrapper {
  background-color: var(--x01-neutral20);
  border-radius: 23.22px;
  height: 35px;
  left: 820px;
  position: absolute;
  top: 85px;
  transform: rotate(180deg);
  width: 35px;
}

.chipsoft-case-study .arrow-back-ios-new {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 35px;
}

.chipsoft-case-study .polls {
  align-items: flex-start;
  background-color: var(--x01-neutral30);
  box-shadow: -2.9px 2.9px 9.43px #0000001a;
  display: inline-flex;
  flex-direction: column;
  gap: 209.73px;
  height: 743px;
  left: 624px;
  padding: 29.03px 29.03px 58.06px;
  position: absolute;
  top: 0;
}

.chipsoft-case-study .frame-81 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23.22px;
  position: relative;
}

.chipsoft-case-study .frame-82 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 29.03px;
  position: relative;
}

.chipsoft-case-study .frame-83 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 271.41px;
}

.chipsoft-case-study .text-wrapper-43 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 23.2px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: normal;
  margin-top: -0.21px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .frame-84 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.73px;
  border-color: var(--x01-neutral50);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.87px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-85 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-86 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.81px;
  justify-content: center;
  padding: 8.71px 5.81px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-44 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.23px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.chipsoft-case-study .frame-87 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.73px;
  border-color: var(--x01-neutral100);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.81px;
  justify-content: center;
  padding: 8.71px 5.81px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-45 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-88 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.81px;
  justify-content: center;
  padding: 8.71px 5.81px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-46 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-89 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17.42px;
  position: relative;
}

.chipsoft-case-study .frame-90 {
  align-items: flex-start;
  border: 0.73px solid;
  border-color: #6b6b6b;
  border-radius: 5.81px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17.42px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  overflow: hidden;
  padding: 17.42px;
  position: relative;
  width: 273.41px;
}

.chipsoft-case-study .frame-91 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.45px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-47 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17.4px;
  margin-top: -0.73px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-48 {
  color: #979797;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17.4px;
  position: relative;
  width: 236.58px;
}

.chipsoft-case-study .frame-92 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.81px;
  position: relative;
}

.chipsoft-case-study .frame-93 {
  align-items: flex-end;
  background-color: #393939;
  border-radius: 2.9px;
  display: flex;
  flex: 0 0 auto;
  gap: 5.81px;
  opacity: 0.8;
  overflow: hidden;
  padding: 8.71px 5.81px 8.71px 11.61px;
  position: relative;
  width: 236.58px;
}

.chipsoft-case-study .checkbox-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.26px;
  padding: 2.18px 0px;
  position: relative;
}

.chipsoft-case-study .checkbox {
  height: 11.61px;
  position: relative;
  width: 11.61px;
}

.chipsoft-case-study .fill {
  border: 0.73px solid;
  border-color: #bcbcbc;
  border-radius: 1.45px;
  height: 14px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 14px;
}

.chipsoft-case-study .text-wrapper-49 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17.4px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-94 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11.61px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .search-bar {
  align-items: center;
  border: 0.73px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.9px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.81px;
  justify-content: center;
  padding: 8.71px 11.61px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-50 {
  color: var(--x01-neutral80);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .search-bar-2 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 2.9px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.81px;
  justify-content: center;
  padding: 8.71px 11.61px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-51 {
  color: #575757;
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 14.5px;
  margin-top: -0.73px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-95 {
  align-items: flex-start;
  background-image: url(../../../static/img/frame-237601.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 5.81px;
  box-shadow: 0px 4.35px 15.24px #000000d6;
  display: flex;
  flex-direction: column;
  height: 147px;
  justify-content: space-between;
  left: 151px;
  padding: 5.81px;
  position: absolute;
  top: 29px;
  width: 238px;
}

.chipsoft-case-study .ellipse-13 {
  height: 6px;
  left: 1086px;
  position: absolute;
  top: -2239px;
  width: 6px;
}

.chipsoft-case-study .overlap-7 {
  height: 152px;
  left: 403px;
  position: absolute;
  top: 23px;
  width: 238px;
}

.chipsoft-case-study .frame-96 {
  align-items: flex-start;
  background-image: url(../../../static/img/frame-237602.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 5.81px;
  box-shadow: 0px 4.35px 15.24px #000000d6;
  display: flex;
  flex-direction: column;
  height: 147px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 5.81px;
  position: absolute;
  top: 6px;
  width: 238px;
}

.chipsoft-case-study .recording {
  align-items: flex-start;
  background-color: #575757;
  border-radius: 5.81px;
  box-shadow: -2.18px 0px 10.16px #4d4d4d52;
  display: inline-flex;
  left: 53px;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.chipsoft-case-study .frame-97 {
  align-items: center;
  background-color: #575757;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.81px;
  justify-content: center;
  padding: 5.81px;
  position: relative;
}

.chipsoft-case-study .overlap-group-wrapper {
  background-color: #dededea1;
  border-radius: 8.71px;
  height: 17.42px;
  position: relative;
  width: 17.42px;
}

.chipsoft-case-study .overlap-group-5 {
  background-color: #dededea1;
  border-radius: 7.26px;
  height: 15px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 15px;
}

.chipsoft-case-study .ellipse-14 {
  background-color: #eb5757;
  border-radius: 5.08px;
  height: 10px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 10px;
}

.chipsoft-case-study .text-wrapper-52 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.2px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-98 {
  align-items: center;
  align-self: stretch;
  background-color: #575757;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.26px;
  justify-content: center;
  padding: 5.81px;
  position: relative;
}

.chipsoft-case-study .frame-99 {
  background-color: var(--x01-neutral20);
  border-radius: 23.22px;
  height: 35px;
  left: 99px;
  position: absolute;
  top: 85px;
  width: 35px;
}

.chipsoft-case-study .arrow-back-ios-new-2 {
  height: 35px;
  left: 1147px;
  position: absolute;
  top: -2172px;
  width: 35px;
}

.chipsoft-case-study .meeting-tables {
  background-color: var(--x01-neutral10);
  border: 12px solid;
  border-color: #8181818f;
  border-radius: 8px;
  height: 814px;
  left: 1132px;
  overflow: hidden;
  position: absolute;
  top: 1088px;
  width: 1136px;
}

.chipsoft-case-study .frame-100 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 293px;
  left: 409px;
  padding: 15.44px;
  position: absolute;
  top: 187px;
  width: 293px;
}

.chipsoft-case-study .frame-101 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 7.72px;
  padding: 3.09px 0px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-53 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 16.7px;
  margin-top: -0.77px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-102 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .overlap-group-6 {
  height: 213px;
  left: 24px;
  position: relative;
  top: 7px;
  width: 213px;
}

.chipsoft-case-study .table {
  align-items: flex-start;
  background-color: var(--x01-neutral30);
  border-radius: 9.27px;
  box-shadow: 0px 3.09px 9.27px #393839b0;
  display: inline-flex;
  gap: 7.72px;
  left: 35px;
  padding: 3.86px;
  position: absolute;
  top: 32px;
}

.chipsoft-case-study .logo-container-wrapper {
  align-items: center;
  background-color: var(--x01-neutral10);
  border-radius: 8.61px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 145.95px;
  justify-content: center;
  padding: 55.6px 30.89px;
  position: relative;
  width: 137.46px;
}

.chipsoft-case-study .logo-container {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-98);
  border-radius: 3.09px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 7.72px;
  justify-content: center;
  padding: 6.18px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .accenture-png {
  height: 15.83px;
  object-fit: cover;
  position: relative;
  width: 60.45px;
}

.chipsoft-case-study .avatar-9 {
  background-image: url(../../../static/img/avatar-43.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 66px;
  position: absolute;
  top: 176px;
  width: 37px;
}

.chipsoft-case-study .avatar-10 {
  background-image: url(../../../static/img/avatar-42.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 112px;
  position: absolute;
  top: 176px;
  width: 37px;
}

.chipsoft-case-study .avatar-11 {
  align-items: center;
  background-color: #da8d6a;
  background-image: url(../../../static/img/avatar-41.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  display: flex;
  flex-direction: column;
  height: 37px;
  justify-content: center;
  left: 176px;
  position: absolute;
  top: 113px;
  width: 37px;
}

.chipsoft-case-study .text-10 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 700;
  letter-spacing: -0.54px;
  line-height: 19.5px;
  position: relative;
  text-align: center;
  width: 30.89px;
}

.chipsoft-case-study .avatar-12 {
  background-image: url(../../../static/img/avatar-40.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 176px;
  position: absolute;
  top: 66px;
  width: 37px;
}

.chipsoft-case-study .avatar-13 {
  background-image: url(../../../static/img/avatar-39.svg);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 39px;
  left: 0;
  position: absolute;
  top: 66px;
  width: 39px;
}

.chipsoft-case-study .avatar-14 {
  background-image: url(../../../static/img/avatar-38.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 39px;
  left: 0;
  position: absolute;
  top: 112px;
  width: 39px;
}

.chipsoft-case-study .avatar-15 {
  background-image: url(../../../static/img/avatar-37.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 112px;
  position: absolute;
  top: 0;
  width: 37px;
}

.chipsoft-case-study .avatar-16 {
  background-image: url(../../../static/img/avatar-36.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 66px;
  position: absolute;
  top: 0;
  width: 37px;
}

.chipsoft-case-study .frame-103 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 293px;
  left: 98px;
  padding: 15.44px;
  position: absolute;
  top: 187px;
  width: 293px;
}

.chipsoft-case-study .overlap-group-7 {
  height: 219px;
  left: 24px;
  position: relative;
  top: 7px;
  width: 213px;
}

.chipsoft-case-study .chair {
  height: 43px !important;
  left: 108px !important;
  position: absolute !important;
  top: 176px !important;
  width: 43px !important;
}

.chipsoft-case-study .avatar-17 {
  background-image: url(../../../static/img/avatar-35.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 66px;
  position: absolute;
  top: 176px;
  width: 37px;
}

.chipsoft-case-study .avatar-18 {
  align-items: center;
  background-color: #da8d6a;
  background-image: url(../../../static/img/avatar-34.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  display: flex;
  flex-direction: column;
  height: 37px;
  justify-content: center;
  left: 176px;
  position: absolute;
  top: 113px;
  width: 37px;
}

.chipsoft-case-study .avatar-19 {
  background-image: url(../../../static/img/avatar-33.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 176px;
  position: absolute;
  top: 66px;
  width: 37px;
}

.chipsoft-case-study .avatar-20 {
  background-image: url(../../../static/img/avatar-32.svg);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 39px;
  left: 0;
  position: absolute;
  top: 66px;
  width: 39px;
}

.chipsoft-case-study .avatar-21 {
  background-image: url(../../../static/img/avatar-31.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 39px;
  left: 0;
  position: absolute;
  top: 112px;
  width: 39px;
}

.chipsoft-case-study .avatar-22 {
  background-image: url(../../../static/img/avatar-30.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 112px;
  position: absolute;
  top: 0;
  width: 37px;
}

.chipsoft-case-study .avatar-23 {
  background-image: url(../../../static/img/avatar-29.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  border-radius: 6.18px;
  box-shadow: 0px 0px 12.36px #0000006e;
  height: 37px;
  left: 66px;
  position: absolute;
  top: 0;
  width: 37px;
}

.chipsoft-case-study .frame-104 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 293px;
  left: 98px;
  padding: 15.44px;
  position: absolute;
  top: 499px;
  width: 293px;
}

.chipsoft-case-study .overlap-group-8 {
  height: 230px;
  left: 21px;
  position: relative;
  width: 221px;
}

.chipsoft-case-study .chair-21 {
  height: 43px !important;
  left: 62px !important;
  position: absolute !important;
  top: 0 !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-4 {
  height: 43px !important;
  left: 63px !important;
  position: absolute !important;
  top: 187px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-23 {
  height: 43px !important;
  left: 0 !important;
  position: absolute !important;
  top: 118px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-24 {
  height: 43px !important;
  left: 178px !important;
  position: absolute !important;
  top: 118px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-2 {
  height: 43px !important;
  left: 0 !important;
  position: absolute !important;
  top: 69px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-22 {
  height: 43px !important;
  left: 178px !important;
  position: absolute !important;
  top: 69px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-27 {
  height: 43px !important;
  left: 111px !important;
  position: absolute !important;
  top: 0 !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-18 {
  height: 43px !important;
  left: 112px !important;
  position: absolute !important;
  top: 187px !important;
  width: 43px !important;
}

.chipsoft-case-study .table-2 {
  height: 172px;
  left: 29px;
  position: absolute;
  top: 32px;
  width: 164px;
}

.chipsoft-case-study .frame-105 {
  align-items: center;
  display: flex;
  gap: 43.25px;
  left: 97px;
  position: absolute;
  top: 93px;
  width: 917px;
}

.chipsoft-case-study .text-wrapper-54 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 24.7px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 34.6px;
  position: relative;
  width: 536.71px;
}

.chipsoft-case-study .text-input-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 397.7px;
  position: relative;
}

.chipsoft-case-study .text-input-3 {
  align-items: center;
  border: 0.77px solid;
  border-color: var(--x01-neutral50);
  border-radius: 3.09px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4.63px;
  padding: 10.81px 6.18px;
  position: relative;
}

.chipsoft-case-study .search-3 {
  height: 15.44px;
  position: relative;
  width: 15.44px;
}

.chipsoft-case-study .value-3 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 12.4px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.8px;
  margin-top: -0.55px;
  position: relative;
  width: 118.92px;
}

.chipsoft-case-study .text-wrapper-55 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  height: 17px;
  left: 98px;
  letter-spacing: -0.06px;
  line-height: 16.7px;
  position: absolute;
  top: 139px;
  white-space: nowrap;
}

.chipsoft-case-study .frame-106 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 293px;
  left: 410px;
  padding: 15.44px;
  position: absolute;
  top: 499px;
  width: 293px;
}

.chipsoft-case-study .frame-107 {
  align-self: stretch;
  height: 222.4px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .overlap-group-9 {
  height: 222px;
  left: 20px;
  position: relative;
  width: 222px;
}

.chipsoft-case-study .chair-13 {
  height: 43px !important;
  left: 90px !important;
  position: absolute !important;
  top: 0 !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-26 {
  height: 43px !important;
  left: 90px !important;
  position: absolute !important;
  top: 179px !important;
  width: 43px !important;
}

.chipsoft-case-study .frame-108 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6.18px;
  left: 0;
  position: absolute;
  top: 65px;
}

.chipsoft-case-study .chair-3 {
  height: 43.25px !important;
  position: relative !important;
  width: 43.25px !important;
}

.chipsoft-case-study .frame-109 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6.18px;
  left: 179px;
  position: absolute;
  top: 65px;
}

.chipsoft-case-study .table-3 {
  height: 172px;
  left: 29px;
  position: absolute;
  top: 29px;
  width: 164px;
}

.chipsoft-case-study .frame-110 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 293px;
  left: 721px;
  padding: 15.44px;
  position: absolute;
  top: 187px;
  width: 293px;
}

.chipsoft-case-study .overlap-group-10 {
  height: 221px;
  left: 19px;
  position: relative;
  top: 4px;
  width: 224px;
}

.chipsoft-case-study .chair-5 {
  height: 43px !important;
  left: 65px !important;
  position: absolute !important;
  top: 0 !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-20 {
  height: 43px !important;
  left: 65px !important;
  position: absolute !important;
  top: 178px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-17 {
  height: 43px !important;
  left: 0 !important;
  position: absolute !important;
  top: 138px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-1 {
  height: 43px !important;
  left: 181px !important;
  position: absolute !important;
  top: 138px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-7 {
  height: 43px !important;
  left: 0 !important;
  position: absolute !important;
  top: 39px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-10 {
  height: 43px !important;
  left: 181px !important;
  position: absolute !important;
  top: 39px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-9 {
  height: 43px !important;
  left: 0 !important;
  position: absolute !important;
  top: 89px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-6 {
  height: 43px !important;
  left: 181px !important;
  position: absolute !important;
  top: 89px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-19 {
  height: 43px !important;
  left: 114px !important;
  position: absolute !important;
  top: 0 !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-12 {
  height: 43px !important;
  left: 114px !important;
  position: absolute !important;
  top: 178px !important;
  width: 43px !important;
}

.chipsoft-case-study .table-4 {
  height: 172px;
  left: 31px;
  position: absolute;
  top: 28px;
  width: 164px;
}

.chipsoft-case-study .frame-111 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  display: flex;
  flex-direction: column;
  gap: 7.72px;
  height: 293px;
  left: 722px;
  padding: 15.44px;
  position: absolute;
  top: 499px;
  width: 293px;
}

.chipsoft-case-study .frame-112 {
  align-self: stretch;
  height: 223.95px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .overlap-group-11 {
  height: 221px;
  left: 23px;
  position: relative;
  width: 216px;
}

.chipsoft-case-study .chair-13-instance {
  height: 43px !important;
  left: 86px !important;
  position: absolute !important;
  top: 0 !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-16 {
  height: 43px !important;
  left: 0 !important;
  position: absolute !important;
  top: 90px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-8 {
  height: 43px !important;
  left: 173px !important;
  position: absolute !important;
  top: 90px !important;
  width: 43px !important;
}

.chipsoft-case-study .chair-4-instance {
  height: 43px !important;
  left: 86px !important;
  position: absolute !important;
  top: 178px !important;
  width: 43px !important;
}

.chipsoft-case-study .table-5 {
  height: 172px;
  left: 26px;
  position: absolute;
  top: 32px;
  width: 164px;
}

.chipsoft-case-study .frame-113 {
  align-items: center;
  background-color: var(--x02-neutral95);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 15.44px 30.89px;
  position: absolute;
  top: 0;
  width: 1112px;
}

.chipsoft-case-study .frame-114 {
  background-image: url(../../../static/img/group-417-2.png);
  background-size: 100% 100%;
  height: 27.78px;
  position: relative;
  width: 100.39px;
}

.chipsoft-case-study .frame-115 {
  align-items: center;
  display: flex;
  gap: 18.53px;
  justify-content: flex-end;
  padding: 3.09px 0px;
  position: relative;
  width: 163.71px;
}

.chipsoft-case-study .frame-116 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.18px;
  overflow: hidden;
  position: relative;
}

.chipsoft-case-study .img-5 {
  height: 24.71px;
  position: relative;
  width: 24.71px;
}

.chipsoft-case-study .frame-117 {
  background-image: url(../../../static/img/ellipse-5-4.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 24.71px;
  position: relative;
  width: 24.71px;
}

.chipsoft-case-study .text-wrapper-56 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 12.4px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .add-session {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  display: inline-flex;
  gap: 7.95px;
  height: 744px;
  justify-content: center;
  left: 279px;
  padding: 44.52px;
  position: absolute;
  top: 1213px;
}

.chipsoft-case-study .container-content {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19.08px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}

.chipsoft-case-study .header-create {
  align-items: center;
  display: flex;
  gap: 6.36px;
  height: 33.39px;
  padding: 3.18px 0px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .text-wrapper-57 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 19.1px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .container {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31.8px;
  position: relative;
}

.chipsoft-case-study .container-input {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19.08px;
  position: relative;
}

.chipsoft-case-study .container-session {
  align-items: center;
  display: flex;
  gap: 12.72px;
  height: 28.62px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .text-wrapper-58 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .container-types {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.54px;
  position: relative;
}

.chipsoft-case-study .option-live {
  align-items: center;
  align-self: stretch;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  gap: 6.36px;
  justify-content: center;
  padding: 3.18px 0px;
  position: relative;
  width: 89.03px;
}

.chipsoft-case-study .checkbox-base-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.chipsoft-case-study .checkbox-base {
  background-color: var(--x01-neutral100);
  border: 0.79px solid;
  border-color: var(--x01-neutral50);
  border-radius: 6.36px;
  height: 12.72px;
  position: relative;
  width: 12.72px;
}

.chipsoft-case-study .text-11 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .option-recorded {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-98);
  border: 0.79px solid;
  border-color: var(--secondary-50);
  border-radius: 3.18px;
  display: flex;
  gap: 6.36px;
  justify-content: center;
  padding: 3.18px 9.54px;
  position: relative;
  width: 92.21px;
}

.chipsoft-case-study .check-wrapper {
  background-color: var(--secondary-98);
  border: 0.79px solid;
  border-color: var(--secondary-50);
  border-radius: 6.36px;
  height: 12.72px;
  overflow: hidden;
  position: relative;
  width: 12.72px;
}

.chipsoft-case-study .check {
  background-color: var(--secondary-50);
  border-radius: 3.97px;
  height: 8px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 8px;
}

.chipsoft-case-study .input-field-title {
  align-items: flex-start;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.54px;
  justify-content: center;
  overflow: hidden;
  padding: 7.95px 9.54px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .text-wrapper-59 {
  align-self: stretch;
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  margin-top: -0.79px;
  position: relative;
}

.chipsoft-case-study .input-field-desc {
  align-items: flex-start;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .input-field-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 7.95px 9.54px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-12 {
  color: var(--x01-neutral50);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 11.1px;
  margin-top: -0.79px;
  position: relative;
}

.chipsoft-case-study .span {
  letter-spacing: -0.04px;
  line-height: 13.4px;
}

.chipsoft-case-study .text-wrapper-60 {
  letter-spacing: 0;
  line-height: 15.9px;
}

.chipsoft-case-study .toolbar-text {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-98);
  border-color: var(--x01-neutral60);
  border-top-style: solid;
  border-top-width: 0.79px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 6.36px 9.54px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-12 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.36px;
  position: relative;
}

.chipsoft-case-study .img-6 {
  flex: 0 0 auto;
  position: relative;
}

.chipsoft-case-study .img-7 {
  height: 14.31px;
  position: relative;
  width: 14.31px;
}

.chipsoft-case-study .label-char-limit {
  align-items: flex-start;
  background-color: var(--secondary-70);
  border-radius: 17.49px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.95px;
  padding: 3.18px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-61 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11.1px;
  margin-top: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .div-13 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 19.08px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .div-14 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 6.36px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-62 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  margin-top: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .input-field-date {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.54px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-15 {
  align-items: center;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 31.8px;
  justify-content: space-between;
  padding: 9.54px 12.72px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-63 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  margin-top: -0.94px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .chevron-down-2 {
  height: 15.9px;
  margin-bottom: -1.59px;
  margin-top: -1.59px;
  position: relative;
  width: 15.9px;
}

.chipsoft-case-study .input-field-time {
  align-items: center;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  gap: 7.95px;
  height: 31.8px;
  justify-content: space-around;
  padding: 9.54px 12.72px;
  position: relative;
  width: 85.06px;
}

.chipsoft-case-study .chevron-down-3 {
  height: 24.64px;
  position: relative;
  width: 25.44px;
}

.chipsoft-case-study .container-field {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 9.54px;
  height: 31.8px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-118 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 19.08px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .container-timezone {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6.36px;
  position: relative;
  width: 138.32px;
}

.chipsoft-case-study .input-field-timezone {
  align-items: center;
  align-self: stretch;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 7.95px 9.54px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-64 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .chevron-down-4 {
  height: 15.9px;
  position: relative;
  width: 15.9px;
}

.chipsoft-case-study .container-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 9.54px;
  position: relative;
}

.chipsoft-case-study .container-wrapper {
  align-items: center;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex: 0 0 auto;
  gap: 7.95px;
  padding: 6.36px 9.54px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .container-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.chipsoft-case-study .chip-speaker-7 {
  align-items: center;
  background-color: var(--primary-98);
  border-radius: 15.9px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.18px;
  padding: 3.18px 6.36px 3.18px 3.18px;
  position: relative;
}

.chipsoft-case-study .frame-119 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.18px;
  position: relative;
}

.chipsoft-case-study .frame-120 {
  background-image: url(../../../static/img/ellipse-1-30.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 19.08px;
  position: relative;
  width: 19.08px;
}

.chipsoft-case-study .name-5 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 14.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .close {
  height: 12.72px;
  position: relative;
  width: 12.72px;
}

.chipsoft-case-study .frame-121 {
  background-image: url(../../../static/img/ellipse-1-29.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 19.08px;
  position: relative;
  width: 19.08px;
}

.chipsoft-case-study .frame-122 {
  background-image: url(../../../static/img/ellipse-1-28.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 19.08px;
  position: relative;
  width: 19.08px;
}

.chipsoft-case-study .text-wrapper-65 {
  color: var(--secondary-50);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .container-embed-link {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9.54px;
  height: 57.24px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .input-field-link {
  align-items: center;
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 7.95px;
  padding: 9.54px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .container-text {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.36px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-66 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  margin-top: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .container-auditorium {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.54px;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .top {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.54px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .title {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  margin-top: -0.79px;
  position: relative;
}

.chipsoft-case-study .content-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--x01-neutral98);
  border: 0.79px dashed;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  display: flex;
  flex-direction: column;
  gap: 6.36px;
  height: 69.96px;
  justify-content: center;
  padding: 12.72px 19.08px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 7.95px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .icon {
  height: 28.62px;
  position: relative;
  width: 28.62px;
}

.chipsoft-case-study .paragraph {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 13.4px;
  position: relative;
  width: 205.89px;
}

.chipsoft-case-study .container-video {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 476.97px;
}

.chipsoft-case-study .record {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.36px;
  position: relative;
}

.chipsoft-case-study .switch {
  height: 17.49px;
  position: relative;
  width: 31.8px;
}

.chipsoft-case-study .overlap-group-12 {
  height: 35px;
  left: -1px;
  position: relative;
  top: -9px;
  width: 42px;
}

.chipsoft-case-study .color-l-track {
  background-color: var(--secondary-50);
  border-radius: 8.74px;
  height: 19px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 34px;
}

.chipsoft-case-study .states {
  height: 35px;
  left: 5px;
  position: absolute;
  top: 0;
  width: 37px;
}

.chipsoft-case-study .color-l-thumb {
  height: 14px;
  left: 17px;
  position: absolute;
  top: 10px;
  width: 14px;
}

.chipsoft-case-study .text-and-supporting {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.chipsoft-case-study .text-wrapper-67 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 12.7px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 15.3px;
  margin-top: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-123 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.36px;
  position: relative;
}

.chipsoft-case-study .checkbox-icon {
  height: 19.08px;
  position: relative;
  width: 19.08px;
}

.chipsoft-case-study .icons-outline {
  height: 13px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 13px;
}

.chipsoft-case-study .shape-wrapper {
  background-color: #4f78db;
  border-radius: 1.59px;
  height: 17px;
  left: -3px;
  position: relative;
  top: -2px;
  width: 17px;
}

.chipsoft-case-study .shape {
  height: 7px;
  left: 4px;
  position: absolute;
  top: 6px;
  width: 10px;
}

.chipsoft-case-study .label {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.97px;
  position: relative;
}

.chipsoft-case-study .icons-outline-2 {
  border: 0.79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 1.59px;
  height: 17px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 17px;
}

.chipsoft-case-study .shape-2 {
  height: 9px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 13px;
}

.chipsoft-case-study .overlap-group-13 {
  background-color: #4f78db;
  background-image: url(../../../static/img/icons-outline-checkmark-1.png);
  background-size: 100% 100%;
  border-radius: 1.59px;
  height: 13px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 13px;
}

.chipsoft-case-study .frame-124 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12.72px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .CTA-4 {
  all: unset;
  align-items: center;
  border: 0.79px solid;
  border-color: var(--x01-neutral40);
  border-radius: 3.18px;
  box-sizing: border-box;
  display: flex;
  gap: 6.36px;
  justify-content: center;
  overflow: hidden;
  padding: 9.54px 0px;
  position: relative;
  width: 83.47px;
}

.chipsoft-case-study .text-13 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 14.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20.7px;
  margin-top: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .CTA-5 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 3.18px;
  box-sizing: border-box;
  display: flex;
  gap: 6.36px;
  justify-content: center;
  overflow: hidden;
  padding: 9.54px 0px;
  position: relative;
  width: 83.47px;
}

.chipsoft-case-study .text-14 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 14.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20.7px;
  margin-top: -0.79px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .add-table {
  align-items: center;
  background-color: var(--x01-neutral30);
  border-radius: 5.91px;
  box-shadow: 0px 2.95px 4.43px -1.48px #10182808, 24px 24px 99px -2.95px #10132870;
  display: flex;
  flex-direction: column;
  gap: 23.63px;
  justify-content: center;
  left: 886px;
  padding: 29.53px;
  position: absolute;
  top: 1215px;
  width: 443px;
}

.chipsoft-case-study .frame-125 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.81px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-126 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17.72px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .title-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 17.72px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-68 {
  color: var(--x01-neutral100);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 17.7px;
  font-weight: 700;
  letter-spacing: -0.27px;
  line-height: 23.6px;
  margin-top: -0.74px;
  position: relative;
}

.chipsoft-case-study .div-17 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.26px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .container-fields {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23.63px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-127 {
  align-items: center;
  align-self: stretch;
  border: 0.74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  flex: 0 0 auto;
  gap: 7.38px;
  padding: 8.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-69 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.74px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-128 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 14.03px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-129 {
  align-items: center;
  border: 0.74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  justify-content: space-between;
  padding: 8.86px 11.81px;
  position: relative;
  width: 109.27px;
}

.chipsoft-case-study .text-wrapper-70 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.17px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-130 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.64px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-71 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.8px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 14.8px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .unfold-more {
  height: 15.14px;
  position: relative;
  width: 15.14px;
}

.chipsoft-case-study .frame-131 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 14.03px;
  position: relative;
}

.chipsoft-case-study .frame-132 {
  align-items: center;
  border: 0.74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 8.86px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-72 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.36px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .icon-12 {
  height: 14.77px;
  position: relative;
  width: 14.77px;
}

.chipsoft-case-study .container-time {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 17.72px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-18 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.91px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-73 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12.4px;
  margin-top: -0.74px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .input-field-date-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-19 {
  align-items: center;
  border: 0.74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 29.53px;
  justify-content: space-between;
  padding: 8.86px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-74 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 13.3px;
  margin-bottom: -0.36px;
  margin-top: -1.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .chevron-down-5 {
  height: 14.77px;
  margin-bottom: -1.48px;
  margin-top: -1.48px;
  position: relative;
  width: 14.77px;
}

.chipsoft-case-study .input-field-time-2 {
  align-items: center;
  border: 0.74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  gap: 7.38px;
  height: 29.53px;
  justify-content: space-around;
  padding: 8.86px 11.81px;
  position: relative;
  width: 79px;
}

.chipsoft-case-study .chevron-down-6 {
  height: 22.89px;
  position: relative;
  width: 23.63px;
}

.chipsoft-case-study .container-field-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 8.86px;
  height: 29.53px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .input-field-time-3 {
  align-items: center;
  border: 0.74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  gap: 7.38px;
  height: 29.53px;
  justify-content: space-around;
  padding: 8.86px;
  position: relative;
  width: 79px;
}

.chipsoft-case-study .text-wrapper-75 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 9.6px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 13.3px;
  margin-bottom: -0.36px;
  margin-top: -1.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .title-wrapper {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 8.86px;
  position: relative;
  width: 229.62px;
}

.chipsoft-case-study .title-3 {
  color: var(--x01-neutral100);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 12.4px;
  margin-top: -0.74px;
  position: relative;
}

.chipsoft-case-study .container-4 {
  align-items: center;
  align-self: stretch;
  border: 0.74px dashed;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.91px;
  justify-content: center;
  padding: 17.72px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.38px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .icon-2 {
  height: 17.72px;
  position: relative;
  width: 17.72px;
}

.chipsoft-case-study .paragraph-2 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 8.9px;
  font-weight: 300;
  letter-spacing: -0.09px;
  line-height: 13.3px;
  position: relative;
  text-align: center;
  width: 158.74px;
}

.chipsoft-case-study .icon-wrapper {
  align-items: center;
  background-color: var(--x01-neutral20);
  border-radius: 2.95px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.38px;
  justify-content: center;
  padding: 4.43px;
  position: relative;
}

.chipsoft-case-study .frame-133 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11.81px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .CTA-6 {
  all: unset;
  align-items: center;
  border: 0.74px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.95px;
  box-sizing: border-box;
  display: flex;
  gap: 5.91px;
  justify-content: center;
  overflow: hidden;
  padding: 8.86px 0px;
  position: relative;
  width: 77.53px;
}

.chipsoft-case-study .text-15 {
  color: var(--x01-neutral90);
  font-family: "Inter", Helvetica;
  font-size: 13.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -0.74px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .CTA-7 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 2.95px;
  box-sizing: border-box;
  display: flex;
  gap: 5.91px;
  justify-content: center;
  overflow: hidden;
  padding: 8.86px 0px;
  position: relative;
  width: 77.53px;
}

.chipsoft-case-study .text-16 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 13.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -0.74px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .pexels-mart {
  height: 1026px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1251px;
}

.chipsoft-case-study .course-details {
  background-color: #ffffff;
  border: 12px solid;
  border-color: #8181818f;
  border-radius: 12px;
  height: 865px;
  left: 854px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 326px;
  width: 1207px;
}

.chipsoft-case-study .row {
  align-items: flex-start;
  display: flex;
  gap: 26.28px;
  justify-content: center;
  left: 1296px;
  position: absolute;
  top: 2406px;
  width: 999px;
}

.chipsoft-case-study .blog-post-card {
  align-items: flex-start;
  background-color: var(--white);
  box-shadow: 0px 3.29px 4.93px -1.64px #10182808, 0px 9.86px 13.14px -3.29px #10182814;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 26.28px;
  height: 476.35px;
  padding: 19.71px 19.71px 26.28px;
  position: relative;
}

.chipsoft-case-study .image {
  align-self: stretch;
  background-image: url(../../../static/img/image-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 197.11px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .content-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .subheading-2 {
  align-self: stretch;
  color: var(--primary-700);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.82px;
  position: relative;
}

.chipsoft-case-study .heading-and-icon {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 13.14px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .heading {
  color: var(--gray-900);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26.3px;
  margin-top: -0.82px;
  position: relative;
}

.chipsoft-case-study .supporting-text-3 {
  align-self: stretch;
  color: var(--gray-500);
  font-family: "Inter", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.7px;
  position: relative;
}

.chipsoft-case-study .avatar-label-group {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.86px;
  position: relative;
}

.chipsoft-case-study .avatar-24 {
  background-image: url(../../../static/img/avatar-25.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 164.26px;
  height: 32.85px;
  position: relative;
  width: 32.85px;
}

.chipsoft-case-study .text-17 {
  color: var(--gray-900);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .supporting-text-4 {
  color: var(--gray-500);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .image-2 {
  align-self: stretch;
  background-image: url(../../../static/img/image-4.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 197.11px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .avatar-25 {
  background-image: url(../../../static/img/avatar-24.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 164.26px;
  height: 32.85px;
  position: relative;
  width: 32.85px;
}

.chipsoft-case-study .image-3 {
  align-self: stretch;
  background-image: url(../../../static/img/image-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 197.11px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .avatar-26 {
  background-image: url(../../../static/img/avatar-23.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 164.26px;
  height: 32.85px;
  position: relative;
  width: 32.85px;
}

.chipsoft-case-study .overlap-8 {
  height: 459px;
  left: 205px;
  position: absolute;
  top: 66px;
  width: 675px;
}

.chipsoft-case-study .frame-134 {
  align-items: flex-start;
  border-color: var(--x01-neutral80);
  border-right-style: solid;
  border-right-width: 0.82px;
  display: flex;
  flex-direction: column;
  gap: 13.14px;
  justify-content: center;
  left: 0;
  padding: 16.43px 19.71px 16.43px 26.28px;
  position: absolute;
  top: 0;
  width: 675px;
}

.chipsoft-case-study .text-wrapper-76 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 23.7px;
  margin-top: -0.82px;
  position: relative;
}

.chipsoft-case-study .div-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.29px;
  position: relative;
}

.chipsoft-case-study .avatar-27 {
  background-image: url(../../../static/img/subtract.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 18.07px;
  position: relative;
  width: 18.07px;
}

.chipsoft-case-study .name-6 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .video-player {
  align-items: flex-start;
  border-color: var(--x01-neutral80);
  border-right-style: solid;
  border-right-width: 0.82px;
  display: flex;
  flex-direction: column;
  gap: 8.21px;
  left: 0;
  padding: 3.29px 19.71px 3.29px 26.28px;
  position: absolute;
  top: 88px;
  width: 675px;
}

.chipsoft-case-study .video-player-2 {
  align-self: stretch;
  background-image: url(../../../static/img/video-player.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 6.73px;
  height: 364.65px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .video-progress-wrapper {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  height: 98px;
  left: 0;
  position: absolute;
  top: 267px;
  width: 630px;
}

.chipsoft-case-study .video-progress-instance {
  border-radius: 6.73px !important;
  height: 13px !important;
  left: 10px !important;
  top: 74px !important;
  width: 575px !important;
}

.chipsoft-case-study .video-progress-2 {
  height: 7px !important;
  left: 24px !important;
  top: 3px !important;
  width: 578px !important;
}

.chipsoft-case-study .video-progress-3 {
  border-radius: 3.36px !important;
  height: 7px !important;
  width: 551px !important;
}

.chipsoft-case-study .video-progress-4 {
  height: 7px !important;
  width: 578px !important;
}

.chipsoft-case-study .video-progress-5 {
  border-radius: 3.36px !important;
  height: 7px !important;
  width: 435px !important;
}

.chipsoft-case-study .video-progress-6 {
  border-radius: 3.36px !important;
  height: 7px !important;
  width: 168px !important;
}

.chipsoft-case-study .frame-135 {
  align-items: flex-start;
  display: inline-flex;
  gap: 9.86px;
  left: 495px;
  position: absolute;
  top: 20px;
}

.chipsoft-case-study .frame-136 {
  align-items: center;
  background-color: var(--x01-neutral40);
  border-radius: 21.35px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.21px;
  justify-content: center;
  padding: 6.57px 8.21px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-77 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .open-in-full-wrapper {
  background-color: var(--x01-neutral40);
  border-radius: 27.1px;
  height: 26.28px;
  position: relative;
  width: 26.28px;
}

.chipsoft-case-study .open-in-full {
  height: 16px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 16px;
}

.chipsoft-case-study .frame-137 {
  align-items: flex-start;
  background-color: var(--secondary-98);
  display: flex;
  flex-direction: column;
  gap: 13.14px;
  left: 205px;
  padding: 19.71px 32.85px 52.56px 26.28px;
  position: absolute;
  top: 1305px;
  width: 977px;
}

.chipsoft-case-study .text-wrapper-78 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 23.7px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .row-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16.43px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .blog-post-card-2 {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  border: 0.82px solid;
  border-color: var(--x02-neutral80);
  border-radius: 6.57px;
  box-shadow: 0px 3.29px 4.93px -1.64px #10182808, 0px 9.86px 13.14px -3.29px #10182814;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 26.28px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  overflow: hidden;
  padding: 0px 0px 26.28px;
  position: relative;
}

.chipsoft-case-study .image-4 {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.16) 100%);
  background-image: url(../../../static/img/image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 197.11px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .icon-button {
  height: 26px;
  left: 262px;
  position: absolute;
  top: 7px;
  width: 26px;
}

.chipsoft-case-study .dots-horizontal-wrapper {
  border-radius: 26.28px;
  height: 26px;
  left: 235px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 26px;
}

.chipsoft-case-study .star-wrapper {
  height: 23px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 23px;
}

.chipsoft-case-study .line-wrapper {
  background-image: url(../../../static/img/line-10.png);
  background-size: 100% 100%;
  height: 1px;
  left: 0;
  position: absolute;
  top: 197px;
  width: 280px;
}

.chipsoft-case-study .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 109px;
}

.chipsoft-case-study .content-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 26.28px;
  padding: 0px 19.71px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-138 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.29px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .subheading-3 {
  color: var(--secondary-30);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .ellipse-15 {
  background-color: var(--x02-neutral80);
  border-radius: 1.64px;
  height: 3.29px;
  position: relative;
  width: 3.29px;
}

.chipsoft-case-study .frame-139 {
  align-items: flex-start;
  background-color: var(--accent-99);
  border-radius: 3.29px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.21px;
  overflow: hidden;
  padding: 3.29px;
  position: relative;
}

.chipsoft-case-study .subheading-4 {
  color: var(--accent-50);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 11.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .div-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.86px;
  position: relative;
}

.chipsoft-case-study .heading-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 13.14px;
  position: relative;
  width: 238.72px;
}

.chipsoft-case-study .heading-2 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16.4px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 19.7px;
  margin-top: -0.82px;
  position: relative;
}

.chipsoft-case-study .supporting-text-5 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  position: relative;
  width: 238.72px;
}

.chipsoft-case-study .avatar-label-group-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.57px;
  position: relative;
}

.chipsoft-case-study .avatar-28 {
  background-image: url(../../../static/img/avatar-22.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 164.26px;
  height: 26.28px;
  position: relative;
  width: 26.28px;
}

.chipsoft-case-study .text-wrapper-79 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .blog-post-card-3 {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  border: 0.82px solid;
  border-color: var(--x02-neutral80);
  border-radius: 6.57px;
  box-shadow: 0px 3.29px 4.93px -1.64px #10182808, 0px 9.86px 13.14px -3.29px #10182814;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 26.28px;
  margin-bottom: -1px;
  margin-top: -1px;
  overflow: hidden;
  padding: 0px 0px 26.28px;
  position: relative;
}

.chipsoft-case-study .frame-140 {
  align-self: stretch;
  border-radius: 9.86px 9.86px 0px 0px;
  height: 197.11px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .overlap-group-14 {
  background-image: url(../../../static/img/image-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 197px;
  position: relative;
  width: 296px;
}

.chipsoft-case-study .icon-button-2 {
  border-radius: 26.28px;
  height: 26px;
  left: 236px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 26px;
}

.chipsoft-case-study .text-wrapper-80 {
  align-self: stretch;
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .avatar-29 {
  background-image: url(../../../static/img/avatar-21.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 164.26px;
  height: 26.28px;
  position: relative;
  width: 26.28px;
}

.chipsoft-case-study .blog-post-card-4 {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  border: 0.82px solid;
  border-color: var(--x02-neutral80);
  border-radius: 6.57px;
  box-shadow: 0px 3.29px 4.93px -1.64px #10182808, 0px 9.86px 13.14px -3.29px #10182814;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 26.28px;
  margin-bottom: -1px;
  margin-right: -1px;
  margin-top: -1px;
  overflow: hidden;
  padding: 0px 0px 26.28px;
  position: relative;
}

.chipsoft-case-study .image-5 {
  align-self: stretch;
  background-image: url(../../../static/img/image.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 197.11px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .avatar-30 {
  background-image: url(../../../static/img/avatar-20.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 164.26px;
  height: 26.28px;
  position: relative;
  width: 26.28px;
}

.chipsoft-case-study .overlap-9 {
  height: 657px;
  left: 880px;
  position: absolute;
  top: 66px;
  width: 302px;
}

.chipsoft-case-study .frame-141 {
  align-items: flex-start;
  background-color: var(--primary-98);
  border-radius: 6.57px;
  display: flex;
  flex-direction: column;
  gap: 26.28px;
  left: 20px;
  padding: 19.71px 16.43px;
  position: absolute;
  top: 493px;
  width: 250px;
}

.chipsoft-case-study .frame-142 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 19.71px;
  height: 55.85px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-143 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 9.86px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-81 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  margin-top: -0.83px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-144 {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-50);
  border: 0.41px solid;
  border-color: #4b4b4b;
  border-radius: 3.29px;
  display: flex;
  flex-direction: column;
  gap: 1.64px;
  height: 42.71px;
  justify-content: center;
  overflow: hidden;
  padding: 9.86px 16.43px 9.86px 9.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-82 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 15.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-145 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13.14px;
  left: 0;
  padding: 19.71px 0px;
  position: absolute;
  top: 0;
  width: 302px;
}

.chipsoft-case-study .frame-146 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.82px;
  border-color: var(--x01-neutral80);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8.21px 32.85px 8.21px 19.71px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-83 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-147 {
  align-items: flex-start;
  background-color: var(--x01-neutral30);
  border-radius: 3.29px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.21px;
  padding: 3.29px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-84 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 11.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-148 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.57px;
  padding: 0px 32.85px 0px 19.71px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-85 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 11.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-149 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-150 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.82px;
  border-color: var(--x01-neutral90);
  display: flex;
  flex: 0 0 auto;
  gap: 9.86px;
  padding: 6.57px 0px 9.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-151 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.57px;
  position: relative;
}

.chipsoft-case-study .frame-152 {
  align-items: center;
  background-color: var(--tertiary-90);
  border-radius: 39.42px;
  display: flex;
  flex-direction: column;
  gap: 12.32px;
  height: 39.42px;
  justify-content: center;
  padding: 12.32px 13.55px;
  position: relative;
  width: 39.42px;
}

.chipsoft-case-study .text-wrapper-86 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  margin-bottom: -0.38px;
  margin-left: -2.84px;
  margin-right: -2.84px;
  margin-top: -2.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-153 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.29px;
  justify-content: center;
  position: relative;
}

.chipsoft-case-study .play-circle {
  height: 11.5px;
  position: relative;
  width: 11.5px;
}

.chipsoft-case-study .text-wrapper-87 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 11.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .check-circle {
  height: 19.71px;
  position: relative;
  width: 19.71px;
}

.chipsoft-case-study .frame-154 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.82px;
  border-color: var(--x01-neutral90);
  display: flex;
  flex: 0 0 auto;
  gap: 13.14px;
  padding: 6.57px 0px 9.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-155 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.57px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-88 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  margin-bottom: -0.38px;
  margin-left: -4.34px;
  margin-right: -4.34px;
  margin-top: -2.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-156 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 3.29px;
  justify-content: center;
  position: relative;
}

.chipsoft-case-study .become-happier-by {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  height: 15.6px;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  margin-top: -0.82px;
  position: relative;
  white-space: nowrap;
}

.chipsoft-case-study .frame-157 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 13.14px;
  padding: 6.57px 0px 9.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-89 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  margin-bottom: -0.38px;
  margin-left: -3.84px;
  margin-right: -3.84px;
  margin-top: -2.84px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .overlap-10 {
  height: 291px;
  left: 205px;
  position: absolute;
  top: 525px;
  width: 675px;
}

.chipsoft-case-study .frame-158 {
  align-items: flex-start;
  border-color: var(--x01-neutral70);
  border-right-style: solid;
  border-right-width: 0.82px;
  display: flex;
  flex-direction: column;
  gap: 19.71px;
  left: 0;
  padding: 9.86px 19.71px 9.86px 26.28px;
  position: absolute;
  top: 51px;
  width: 675px;
}

.chipsoft-case-study .text-wrapper-90 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 23.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-159 {
  align-items: center;
  background-color: var(--secondary-30);
  border-radius: 3.29px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.29px;
  overflow: hidden;
  padding: 9.86px;
  position: relative;
}

.chipsoft-case-study .edit {
  height: 15.6px;
  position: relative;
  width: 15.6px;
}

.chipsoft-case-study .text-wrapper-91 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 13.1px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 15.8px;
  margin-top: -0.82px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .container-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 13.14px;
  height: 105.12px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .thread-line {
  height: 38px;
  left: 13px;
  position: absolute;
  top: 125px;
  width: 1px;
}

.chipsoft-case-study .frame-160 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.57px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .comment {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.29px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .avatar-31 {
  height: 26.28px;
  object-fit: cover;
  position: relative;
  width: 26.28px;
}

.chipsoft-case-study .content-5 {
  align-items: flex-start;
  background-color: var(--x01-neutral98);
  border-radius: 8.21px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 9.86px;
  padding: 6.57px 13.14px;
  position: relative;
}

.chipsoft-case-study .paragraph-3 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 13.8px;
  margin-top: -0.82px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-92 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 11.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .settings {
  height: 16.43px;
  position: relative;
  width: 25.46px;
}

.chipsoft-case-study .group-2 {
  height: 4px;
  left: 4px;
  position: relative;
  top: 6px;
  width: 17px;
}

.chipsoft-case-study .ellipse-16 {
  background-color: var(--x01-neutral50);
  border-radius: 2.05px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.chipsoft-case-study .ellipse-17 {
  background-color: var(--x01-neutral50);
  border-radius: 2.05px;
  height: 4px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 4px;
}

.chipsoft-case-study .ellipse-18 {
  background-color: var(--x01-neutral50);
  border-radius: 2.05px;
  height: 4px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 4px;
}

.chipsoft-case-study .dropdown-2 {
  align-items: center;
  align-self: stretch;
  border-radius: 6.57px;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 0px 26.28px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .label-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.21px;
  padding: 3.29px;
  position: relative;
}

.chipsoft-case-study .label-2 {
  color: var(--secondary-50);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 11.8px;
  margin-top: -0.82px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .icons-20 {
  height: 16.43px;
  position: relative;
  width: 16.43px;
}

.chipsoft-case-study .frame-161 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.57px;
  position: relative;
  width: 629.11px;
}

.chipsoft-case-study .comment-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.57px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .dropdown-3 {
  align-items: center;
  align-self: stretch;
  border-radius: 6.57px;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 0px 32.85px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .comment-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 3.29px;
  padding: 0px 0px 0px 12.32px;
  position: relative;
  width: 629.11px;
}

.chipsoft-case-study .frame-162 {
  height: 26.28px;
  position: relative;
  width: 8.21px;
}

.chipsoft-case-study .line-2 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 8px;
}

.chipsoft-case-study .frame-163 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.57px;
  position: relative;
}

.chipsoft-case-study .input-field {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.57px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .search-bar-3 {
  align-items: center;
  background-color: var(--x01-neutral98);
  border: 0.82px solid;
  border-color: var(--x01-neutral90);
  border-radius: 3.29px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.57px;
  padding: 11.5px 9.86px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-93 {
  color: var(--x01-neutral50);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.4px;
  margin-top: -0.82px;
  position: relative;
}

.chipsoft-case-study .CTA-8 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 4.93px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.29px;
  justify-content: center;
  padding: 13.14px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-94 {
  color: var(--x01-neutral98);
  font-family: "Inter", Helvetica;
  font-size: 13.1px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 13.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-164 {
  align-items: flex-start;
  border-color: var(--x01-neutral80);
  border-right-style: solid;
  border-right-width: 0.82px;
  display: flex;
  flex-direction: column;
  gap: 19.71px;
  justify-content: flex-end;
  left: 0;
  padding: 9.86px 0px 6.57px;
  position: absolute;
  top: 0;
  width: 675px;
}

.chipsoft-case-study .links-wrapper {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.82px;
  border-color: var(--x01-neutral70);
  display: flex;
  flex-direction: column;
  gap: 9.86px;
  height: 39.42px;
  padding: 0px 19.71px 0px 26.28px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .links {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 629.11px;
}

.chipsoft-case-study .link {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 6.57px;
  justify-content: center;
  padding: 6.57px 9.86px;
  position: relative;
  width: 95.27px;
}

.chipsoft-case-study .text-wrapper-95 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .text-wrapper-96 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .q-a-wrapper {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.82px;
  border-color: var(--secondary-50);
  display: flex;
  gap: 6.57px;
  justify-content: center;
  padding: 6.57px 26.28px;
  position: relative;
  width: 95.27px;
}

.chipsoft-case-study .q-a {
  color: var(--secondary-50);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .link-2 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.57px;
  justify-content: center;
  padding: 6.57px 26.28px;
  position: relative;
}

.chipsoft-case-study .link-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 6.57px;
  justify-content: center;
  padding: 6.57px 3.29px;
  position: relative;
  width: 95.27px;
}

.chipsoft-case-study .text-wrapper-97 {
  color: var(--x01-neutral60);
  font-family: "Inter", Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 17.7px;
  margin-left: -0.15px;
  margin-right: -0.15px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .menu-instance {
  background-color: #e5e2db52 !important;
  height: 775px !important;
  left: 0 !important;
  padding: 9.86px 0px 19.71px !important;
  position: absolute !important;
  top: 66px !important;
  width: 205px !important;
}

.chipsoft-case-study .menu-3 {
  font-family: "Inter", Helvetica !important;
  font-size: 13.1px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.03px !important;
  line-height: 15.8px !important;
}

.chipsoft-case-study .menu-4 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  gap: 3.29px !important;
  padding: 13.14px 32.85px !important;
  width: 100% !important;
}

.chipsoft-case-study .menu-5 {
  gap: 3.29px !important;
  padding: 13.14px 32.85px !important;
}

.chipsoft-case-study .menu-6 {
  height: 19.71px !important;
  width: 19.71px !important;
}

.chipsoft-case-study .menu-7 {
  height: 16px !important;
  left: 3px !important;
  width: 13px !important;
}

.chipsoft-case-study .menu-8 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  height: 5px !important;
  top: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .menu-9 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  height: 5px !important;
  left: 9px !important;
  top: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .menu-10 {
  height: 12px !important;
  width: 12px !important;
}

.chipsoft-case-study .menu-11 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  height: 5px !important;
  left: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .menu-12 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  height: 5px !important;
  left: 4px !important;
  top: 9px !important;
  width: 5px !important;
}

.chipsoft-case-study .menu-13 {
  height: 14px !important;
  width: 14px !important;
}

.chipsoft-case-study .menu-14 {
  gap: 19.71px !important;
}

.chipsoft-case-study .menu-15 {
  gap: 2.46px !important;
  padding: 3.29px 32.85px !important;
}

.chipsoft-case-study .menu-16 {
  color: var(--x01-neutral30) !important;
  font-family: "Inter", Helvetica !important;
  font-size: 9.9px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: -0.04px !important;
  line-height: 11.8px !important;
  margin-top: -0.82px !important;
}

.chipsoft-case-study .menu-17 {
  height: 6.57px !important;
  width: 6.57px !important;
}

.chipsoft-case-study .menu-18 {
  background-color: var(--x01-neutral50) !important;
  border-radius: 1.64px !important;
  height: 3px !important;
  width: 3px !important;
}

.chipsoft-case-study .menu-19 {
  height: 25px !important;
  left: 192px !important;
  top: 375px !important;
  width: 26px !important;
}

.chipsoft-case-study .frame-165 {
  align-items: center;
  background-color: #e5e2db52;
  border-bottom-style: solid;
  border-bottom-width: 0.41px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 16.43px 32.85px;
  position: absolute;
  top: 0;
  width: 1183px;
}

.chipsoft-case-study .frame-166 {
  background-image: url(../../../static/img/group-417-1.png);
  background-size: 100% 100%;
  height: 29.55px;
  position: relative;
  width: 106.77px;
}

.chipsoft-case-study .frame-167 {
  align-items: center;
  display: flex;
  gap: 19.71px;
  justify-content: flex-end;
  padding: 3.29px 0px;
  position: relative;
  width: 174.11px;
}

.chipsoft-case-study .frame-168 {
  background-image: url(../../../static/img/ellipse-5-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 26.28px;
  position: relative;
  width: 26.28px;
}

.chipsoft-case-study .ellipse-19 {
  background-color: var(--accent-50);
  border: 0.82px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.29px;
  height: 7px;
  left: 20px;
  position: relative;
  top: 20px;
  width: 7px;
}

.chipsoft-case-study .text-wrapper-98 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 15.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .new-course {
  align-items: center;
  background-color: var(--x01-neutral100);
  border-radius: 5.37px;
  box-shadow: 0px 2.69px 4.03px -1.34px #10182808, 16px 8.06px 32px -2.69px #10182829;
  display: flex;
  gap: 21.5px;
  justify-content: center;
  left: 619px;
  overflow: hidden;
  padding: 21.5px 32.25px 37.62px;
  position: absolute;
  top: 640px;
  width: 445px;
}

.chipsoft-case-study .frame-169 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32.25px;
  position: relative;
}

.chipsoft-case-study .frame-170 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.44px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-171 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.06px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-99 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 16.1px;
  font-weight: 700;
  letter-spacing: -0.13px;
  line-height: 19.3px;
  margin-top: -0.67px;
  position: relative;
}

.chipsoft-case-study .line-3 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.33px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-172 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 249.92px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-173 {
  align-items: center;
  align-self: stretch;
  border: 0.67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.72px;
  padding: 8.06px 10.75px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-100 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.3px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .input-field-desc-2 {
  align-items: flex-start;
  align-self: stretch;
  border: 0.67px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.69px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .input-field-text-2 {
  align-items: flex-start;
  align-self: stretch;
  border: 0.67px solid;
  border-color: var(--x01-neutral70);
  display: flex;
  flex: 0 0 auto;
  padding: 6.72px 8.06px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-18 {
  color: transparent;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.3px;
  margin-top: -0.67px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-101 {
  color: #868686;
}

.chipsoft-case-study .text-wrapper-102 {
  color: #64748b;
}

.chipsoft-case-study .toolbar-text-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--secondary-98);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 5.37px 8.06px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .container-controls {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.37px;
  position: relative;
}

.chipsoft-case-study .img-8 {
  height: 12.09px;
  position: relative;
  width: 12.09px;
}

.chipsoft-case-study .label-char-limit-2 {
  align-items: flex-start;
  background-color: var(--secondary-60);
  border-radius: 14.78px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.72px;
  padding: 2.69px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-103 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .top-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 8.06px;
  position: relative;
  width: 208.94px;
}

.chipsoft-case-study .title-4 {
  color: var(--x01-neutral20);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 11.3px;
  margin-top: -0.67px;
  position: relative;
}

.chipsoft-case-study .container-6 {
  align-items: center;
  align-self: stretch;
  border: 0.67px dashed;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.37px;
  justify-content: center;
  padding: 16.12px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .content-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.72px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .icon-3 {
  height: 16.12px;
  position: relative;
  width: 16.12px;
}

.chipsoft-case-study .paragraph-4 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  position: relative;
  text-align: center;
  width: 144.44px;
}

.chipsoft-case-study .icon-12-wrapper {
  align-items: center;
  background-color: var(--secondary-40);
  border-radius: 2.69px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.72px;
  justify-content: center;
  padding: 4.03px;
  position: relative;
}

.chipsoft-case-study .chevron-down-7 {
  height: 13.44px !important;
  position: relative !important;
  width: 13.44px !important;
}

.chipsoft-case-study .frame-174 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.72px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-175 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10.75px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-176 {
  align-items: center;
  border: 0.67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  display: flex;
  justify-content: space-between;
  padding: 8.06px 10.75px;
  position: relative;
  width: 347.33px;
}

.chipsoft-case-study .text-wrapper-104 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-177 {
  align-items: center;
  border: 0.67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 8.06px 10.75px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-105 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.3px;
  margin-right: -23.33px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .chevron-down-8 {
  height: 13.44px;
  margin-left: -48.76px;
  position: relative;
  width: 13.44px;
}

.chipsoft-case-study .frame-178 {
  align-items: center;
  align-self: stretch;
  border: 0.67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  display: flex;
  height: 29.56px;
  justify-content: space-between;
  padding: 8.06px 10.75px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-179 {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 12.09px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.72px;
  margin-bottom: -0.97px;
  margin-top: -0.97px;
  padding: 2.69px 8.06px 2.69px 5.37px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-106 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-180 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  gap: 10.75px;
  height: 37.62px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .add-button {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 2.69px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.37px;
  height: 38.29px;
  justify-content: center;
  margin-top: -0.67px;
  overflow: hidden;
  padding: 8.06px 0px;
  position: relative;
}

.chipsoft-case-study .text-19 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .add-button-2 {
  all: unset;
  align-items: center;
  align-self: stretch;
  border: 1.34px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.69px;
  box-sizing: border-box;
  display: flex;
  gap: 5.37px;
  justify-content: center;
  overflow: hidden;
  padding: 8.06px 0px;
  position: relative;
  width: 102.79px;
}

.chipsoft-case-study .text-20 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .no-more-switiching {
  color: #000000;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  height: 256px;
  left: 1295px;
  letter-spacing: -0.28px;
  line-height: 64px;
  position: absolute;
  top: 35px;
  width: 493px;
}

.chipsoft-case-study .overlap-11 {
  height: 1135px;
  left: 98px;
  position: absolute;
  top: 0;
  width: 1804px;
}

.chipsoft-case-study .overlap-12 {
  height: 1135px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1804px;
}

.chipsoft-case-study .pexels-mart-2 {
  height: 1135px;
  left: 933px;
  position: absolute;
  top: 0;
  width: 871px;
}

.chipsoft-case-study .resources-all {
  background-color: #ffffff52;
  border: 12px solid;
  border-color: #8181818f;
  border-radius: 9.01px;
  height: 793px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 202px;
  width: 1105px;
}

.chipsoft-case-study .overlap-13 {
  height: 709px;
  left: 188px;
  position: absolute;
  top: 60px;
  width: 894px;
}

.chipsoft-case-study .rectangle-2 {
  background-color: var(--x01-neutral40);
  height: 709px;
  left: 0;
  position: absolute;
  top: 0;
  width: 599px;
}

.chipsoft-case-study .line-4 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 511px;
  width: 599px;
}

.chipsoft-case-study .frame-181 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 15.02px 24.03px;
  position: absolute;
  top: 0;
  width: 599px;
}

.chipsoft-case-study .frame-182 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3px;
  position: relative;
}

.chipsoft-case-study .frame-183 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 108.14px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-107 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 21.6px;
  margin-top: -0.75px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .icon-button-3 {
  height: 24.03px;
  position: relative;
  width: 24.03px;
}

.chipsoft-case-study .frame-184 {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.01px;
  position: relative;
}

.chipsoft-case-study .frame-185 {
  align-items: center;
  border: 0.75px solid;
  border-color: var(--x02-neutral80);
  border-radius: 3px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.51px;
  justify-content: center;
  padding: 9.01px 12.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-108 {
  color: var(--x02-neutral95);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.4px;
  margin-top: -0.75px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .line-5 {
  height: 708px;
  left: 599px;
  position: absolute;
  top: 0;
  width: 1px;
}

.chipsoft-case-study .rectangle-3 {
  height: 631px;
  left: 24px;
  object-fit: cover;
  position: absolute;
  top: 78px;
  width: 551px;
}

.chipsoft-case-study .line-6 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 62px;
  width: 599px;
}

.chipsoft-case-study .frame-186 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18.02px;
  height: 708px;
  left: 599px;
  padding: 36.05px 30.04px 0px 24.03px;
  position: absolute;
  top: 0;
  width: 294px;
}

.chipsoft-case-study .frame-187 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.02px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .div-23 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.01px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .title-5 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 13.5px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 16.2px;
  margin-top: -0.75px;
  position: relative;
}

.chipsoft-case-study .paragraph-5 {
  align-self: stretch;
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 10.5px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 12.6px;
  position: relative;
}

.chipsoft-case-study .frame-188 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.76px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-189 {
  align-items: center;
  align-self: stretch;
  background-color: var(--primary-98);
  border-radius: 3px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 6.01px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-109 {
  color: var(--primary-50);
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-190 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.chipsoft-case-study .frame-191 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.51px;
  padding: 0px 3.75px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-110 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  margin-top: -0.75px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .star-3 {
  align-items: flex-start;
  display: flex;
  height: 18.02px;
  position: relative;
  width: 92.37px;
}

.chipsoft-case-study .star-18-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7.51px;
  height: 18.02px;
  justify-content: center;
  padding: 3px;
  position: relative;
  width: 18.77px;
}

.chipsoft-case-study .star-18 {
  height: 18.02px !important;
  margin-bottom: -3px !important;
  margin-left: -2.63px !important;
  margin-right: -2.63px !important;
  margin-top: -3px !important;
  position: relative !important;
  width: 18.02px !important;
}

.chipsoft-case-study .icon-instance-node-2 {
  height: 18.02px !important;
  position: relative !important;
  width: 18.02px !important;
}

.chipsoft-case-study .text-wrapper-111 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 10.5px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 12.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .base-status-badge {
  align-items: flex-start;
  background-color: var(--accent-50);
  border-radius: 75.1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.51px;
  padding: 4.51px 6.01px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-112 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 10.5px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 12.6px;
  margin-top: -0.75px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-192 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.02px;
  position: relative;
  width: 240.31px;
}

.chipsoft-case-study .frame-193 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--x02-neutral95);
  border: 0.75px solid;
  border-color: var(--x01-neutral98);
  border-radius: 6.01px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.02px;
  justify-content: center;
  overflow: hidden;
  padding: 12.02px 12.02px 18.02px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-113 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .subtitle-wrapper {
  align-items: flex-start;
  background-color: var(--x01-neutral20);
  border-radius: 24.03px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.51px;
  overflow: hidden;
  padding: 4.51px;
  position: relative;
}

.chipsoft-case-study .subtitle {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  margin-top: -0.75px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-194 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 30.04px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-195 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24.03px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .avatar-w-photo-9 {
  background-image: url(../../../static/img/avatar-w-photo-28.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 750.23px;
  height: 27.04px;
  position: relative;
  width: 27.04px;
}

.chipsoft-case-study .outside-stroke-3 {
  border: 1.5px solid;
  border-color: var(--x02-neutral95);
  border-radius: 15.02px;
  height: 30px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 30px;
}

.chipsoft-case-study .avatar-w-photo-10 {
  background-image: url(../../../static/img/avatar-w-photo-27.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 750.23px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
  width: 27.04px;
}

.chipsoft-case-study .avatar-w-photo-11 {
  background-image: url(../../../static/img/avatar-w-photo-26.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 750.23px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
  width: 27.04px;
}

.chipsoft-case-study .avatar-w-photo-12 {
  background-image: url(../../../static/img/avatar-w-photo-25.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 750.23px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
  width: 27.04px;
}

.chipsoft-case-study .avatar-w-photo-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.01px;
  margin-left: -4.51px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-13 {
  background-image: url(../../../static/img/avatar-w-photo-24.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 750.23px;
  height: 27.04px;
  position: relative;
  width: 27.04px;
}

.chipsoft-case-study .overflow {
  background-color: var(--x01-neutral10);
  border: 1.5px solid;
  border-color: var(--x02-neutral95);
  border-radius: 750.23px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
  width: 27.04px;
}

.chipsoft-case-study .number {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 700;
  left: 8px;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
}

.chipsoft-case-study .comments {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 9.01px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18.02px;
  padding: 0px 9.01px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-196 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.02px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .top-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 9.01px;
  position: relative;
  width: 222.29px;
}

.chipsoft-case-study .title-6 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.4px;
  margin-top: -0.75px;
  position: relative;
}

.chipsoft-case-study .container-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.02px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .thread-line-2 {
  height: 238px;
  left: 11px;
  position: absolute;
  top: 28px;
  width: 1px;
}

.chipsoft-case-study .frame-197 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.01px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .comment-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .avatar-32 {
  height: 24.03px;
  object-fit: cover;
  position: relative;
  width: 24.03px;
}

.chipsoft-case-study .content-7 {
  align-items: flex-start;
  background-color: var(--x01-neutral98);
  border-radius: 7.51px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 9.01px;
  padding: 6.01px 12.02px;
  position: relative;
}

.chipsoft-case-study .paragraph-6 {
  align-self: stretch;
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  margin-top: -0.75px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-114 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .group-wrapper {
  height: 15.02px;
  position: relative;
  width: 23.28px;
}

.chipsoft-case-study .group-3 {
  height: 4px;
  left: 4px;
  position: relative;
  top: 5px;
  width: 16px;
}

.chipsoft-case-study .ellipse-20 {
  background-color: var(--x01-neutral50);
  border-radius: 1.88px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.chipsoft-case-study .ellipse-21 {
  background-color: var(--x01-neutral50);
  border-radius: 1.88px;
  height: 4px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 4px;
}

.chipsoft-case-study .ellipse-22 {
  background-color: var(--x01-neutral50);
  border-radius: 1.88px;
  height: 4px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 4px;
}

.chipsoft-case-study .dropdown-4 {
  align-items: center;
  align-self: stretch;
  border-radius: 6.01px;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 0px 24.03px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-198 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.51px;
  padding: 3px;
  position: relative;
}

.chipsoft-case-study .label-3 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  margin-top: -0.75px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .icons-21 {
  height: 15.02px;
  position: relative;
  width: 15.02px;
}

.chipsoft-case-study .label-4 {
  color: var(--x01-neutral40);
  font-family: "Manrope", Helvetica;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: normal;
  margin-top: -0.75px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.chipsoft-case-study .comment-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3px;
  padding: 0px 0px 0px 18.02px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .input-field-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.01px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .search-bar-4 {
  align-items: center;
  border: 0.75px solid;
  border-color: var(--x01-neutral60);
  border-radius: 3px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.01px;
  height: 30.04px;
  padding: 6.01px 9.01px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-115 {
  color: #909090;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 10.5px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 12.6px;
  position: relative;
}

.chipsoft-case-study .button-instance {
  border-radius: 4.51px !important;
  flex: 0 0 auto !important;
  gap: 3px !important;
  height: 30.04px !important;
  padding: 4.51px 12.02px !important;
}

.chipsoft-case-study .button-2 {
  font-family: "Inter", Helvetica !important;
  font-size: 10.5px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: -0.04px !important;
  line-height: 12.6px !important;
}

.chipsoft-case-study .slider {
  height: 192px;
  left: 235px;
  position: absolute;
  top: -2px;
  width: 5px;
}

.chipsoft-case-study .rectangle-4 {
  background-color: #b8c0cc;
  border-radius: 75.1px;
  height: 32px;
  left: 2px;
  position: relative;
  top: 44px;
  width: 2px;
}

.chipsoft-case-study .overlap-14 {
  height: 709px;
  left: 0;
  position: absolute;
  top: 60px;
  width: 188px;
}

.chipsoft-case-study .mini-side-bar {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  border-color: #c6c6c6;
  border-radius: 0.75px;
  border-right-style: solid;
  border-right-width: 0.75px;
  display: flex;
  gap: 7.51px;
  height: 709px;
  left: 0;
  padding: 9.01px 18.02px 9.01px 30.04px;
  position: absolute;
  top: 0;
  width: 89px;
}

.chipsoft-case-study .nav {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.01px;
  margin-right: -0.75px;
  position: relative;
}

.chipsoft-case-study .dashboard-3 {
  align-items: center;
  background-color: var(--neutral-100);
  border-radius: 3px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.01px;
  padding: 12.02px;
  position: relative;
}

.chipsoft-case-study .book-wrapper {
  border: 1.5px solid;
  border-color: #363636;
  border-radius: 0.75px;
  height: 15px;
  left: 3px;
  position: relative;
  top: 2px;
  width: 12px;
}

.chipsoft-case-study .book {
  height: 5px;
  left: 4px;
  position: absolute;
  top: -1px;
  width: 4px;
}

.chipsoft-case-study .polygon-wrapper {
  border: 1.5px solid;
  border-color: #363636;
  border-radius: 1.5px;
  height: 12px;
  left: 2px;
  position: relative;
  top: 3px;
  width: 14px;
}

.chipsoft-case-study .polygon {
  height: 4px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 4px;
}

.chipsoft-case-study .menu-20 {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  display: flex;
  flex-direction: column;
  height: 709px;
  justify-content: space-between;
  left: 0;
  padding: 9.01px 0px 18.02px;
  position: absolute;
  top: 0;
  width: 188px;
}

.chipsoft-case-study .frame-199 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.01px;
  position: relative;
}

.chipsoft-case-study .frame-200 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 187.74px;
}

.chipsoft-case-study .nav-link-11 {
  flex: 0 0 auto !important;
  gap: 3px !important;
  padding: 12.02px 30.04px !important;
  width: 187.74px !important;
}

.chipsoft-case-study .nav-link-12 {
  font-family: "Inter", Helvetica !important;
  font-size: 12px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.02px !important;
  line-height: 14.4px !important;
}

.chipsoft-case-study .nav-link-13 {
  align-items: center;
  background-color: var(--primary-60);
  display: flex;
  flex: 0 0 auto;
  gap: 3px;
  padding: 12.02px 30.04px;
  position: relative;
  width: 187.74px;
}

.chipsoft-case-study .icons-22 {
  height: 18.02px !important;
  width: 18.02px !important;
}

.chipsoft-case-study .dashboard-4 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .nav-link-14 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 3px;
  padding: 12.02px 30.04px;
  position: relative;
  width: 187.74px;
}

.chipsoft-case-study .icons-23 {
  height: 11px !important;
  left: 3px !important;
  top: 3px !important;
  width: 11px !important;
}

.chipsoft-case-study .icons-24 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  height: 5px !important;
  left: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-25 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  height: 5px !important;
  top: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-26 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  height: 5px !important;
  left: 8px !important;
  top: 4px !important;
  width: 5px !important;
}

.chipsoft-case-study .icons-27 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  height: 5px !important;
  left: 4px !important;
  top: 8px !important;
  width: 5px !important;
}

.chipsoft-case-study .nav-link-15 {
  flex: 0 0 auto !important;
  gap: 3px !important;
  padding: 12.02px 30.04px !important;
  width: 185.49px !important;
}

.chipsoft-case-study .extra-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 2.25px;
  padding: 3px 30.04px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-116 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 10.8px;
  margin-top: -0.75px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .interpunct-3 {
  height: 6.01px;
  position: relative;
  width: 6.01px;
}

.chipsoft-case-study .ellipse-23 {
  background-color: #d9d9d9;
  border-radius: 1.5px;
  height: 3px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 3px;
}

.chipsoft-case-study .add-resource {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 5.56px;
  box-shadow: 0px 2.78px 4.17px -1.39px #10182808, -16.73px 5.58px 16.73px -2.78px #10182814;
  display: flex;
  flex-direction: column;
  gap: 16.69px;
  justify-content: center;
  left: 50px;
  overflow: hidden;
  padding: 16.69px 22.25px 22.25px;
  position: absolute;
  top: 639px;
  width: 283px;
}

.chipsoft-case-study .frame-201 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.86px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-202 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.34px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-117 {
  align-self: stretch;
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 16.7px;
  font-weight: 700;
  letter-spacing: -0.13px;
  line-height: 20px;
  margin-top: -0.7px;
  position: relative;
}

.chipsoft-case-study .line-7 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.3px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-203 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.21px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-204 {
  align-items: center;
  align-self: stretch;
  border: 0.7px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.78px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.95px;
  padding: 8.34px 11.13px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-118 {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 13.9px;
  margin-top: -0.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-205 {
  align-items: center;
  align-self: stretch;
  border: 0.7px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.78px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8.34px 11.13px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .chevron-down-9 {
  height: 13.91px;
  position: relative;
  width: 13.91px;
}

.chipsoft-case-study .top-4 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 8.34px;
  position: relative;
  width: 216.25px;
}

.chipsoft-case-study .title-7 {
  color: var(--x01-neutral30);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  letter-spacing: -0.04px;
  line-height: 11.7px;
  margin-top: -0.7px;
  position: relative;
}

.chipsoft-case-study .container-8 {
  align-items: center;
  align-self: stretch;
  border: 0.7px dashed;
  border-color: #d9d9d9;
  border-radius: 2.78px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.56px;
  justify-content: center;
  padding: 16.69px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .content-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.95px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .icon-4 {
  height: 16.69px;
  position: relative;
  width: 16.69px;
}

.chipsoft-case-study .paragraph-7 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.3px;
  font-weight: 300;
  letter-spacing: -0.03px;
  line-height: 10px;
  position: relative;
  text-align: center;
  width: 149.5px;
}

.chipsoft-case-study .button-3 {
  border-radius: 2.78px !important;
  flex: 0 0 auto !important;
  gap: 6.95px !important;
  padding: 4.17px !important;
}

.chipsoft-case-study .frame-206 {
  align-items: flex-start;
  align-self: stretch;
  border: 0.7px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.78px;
  display: flex;
  gap: 6.95px;
  height: 63.97px;
  padding: 8.34px 11.13px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-119 {
  color: var(--x01-neutral50);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 13.9px;
  margin-top: -0.7px;
  position: relative;
}

.chipsoft-case-study .button-4 {
  border-radius: 2.78px !important;
  display: flex !important;
  flex: 0 0 auto !important;
  gap: 2.78px !important;
  height: unset !important;
  padding: 8.34px 11.13px !important;
  width: 238.5px !important;
}

.chipsoft-case-study .button-5 {
  font-family: "Inter", Helvetica !important;
  font-size: 11.1px !important;
  font-style: unset !important;
  font-weight: 700 !important;
  letter-spacing: -0.04px !important;
  line-height: 13.4px !important;
}

.chipsoft-case-study .text-wrapper-120 {
  color: #000000;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  height: 128px;
  left: 1px;
  letter-spacing: -0.28px;
  line-height: 64px;
  position: absolute;
  top: 16px;
  width: 669px;
}

.chipsoft-case-study .customization {
  height: 800px;
  left: -18px;
  overflow: hidden;
  position: absolute;
  top: 4216px;
  width: 1920px;
}

.chipsoft-case-study .overlap-15 {
  height: 1021px;
  position: relative;
  top: -1px;
}

.chipsoft-case-study .rectangle-5 {
  background-color: #f5f5f5;
  height: 1020px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 1920px;
}

.chipsoft-case-study .rectangle-6 {
  height: 404px;
  left: 18px;
  position: absolute;
  top: 196px;
  width: 179px;
}

.chipsoft-case-study .rectangle-7 {
  height: 404px;
  left: 1723px;
  position: absolute;
  top: 237px;
  width: 197px;
}

.chipsoft-case-study .rectangle-8 {
  height: 404px;
  left: 229px;
  object-fit: cover;
  position: absolute;
  top: 198px;
  width: 335px;
}

.chipsoft-case-study .rectangle-9 {
  height: 404px;
  left: 1356px;
  position: absolute;
  top: 239px;
  width: 335px;
}

.chipsoft-case-study .rectangle-10 {
  border: 0.91px solid;
  border-color: #a3a3a3;
  border-radius: 20px;
  height: 600px;
  left: 558px;
  position: absolute;
  top: 117px;
  width: 803px;
}

.chipsoft-case-study .auditorium-sessions-2 {
  background-color: var(--x01-neutral10);
  border: 6.07px solid;
  border-color: #d4d4d4;
  border-radius: 12px;
  height: 530px;
  left: 590px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 152px;
  width: 741px;
}

.chipsoft-case-study .overlap-16 {
  height: 1304px;
  position: relative;
  width: 728px;
}

.chipsoft-case-study .base-2 {
  height: 478px;
  left: 126px;
  object-fit: cover;
  position: absolute;
  top: 40px;
  width: 602px;
}

.chipsoft-case-study .header-2 {
  align-items: center;
  display: flex;
  gap: 93.58px;
  left: 151px;
  position: absolute;
  top: 61px;
  width: 557px;
}

.chipsoft-case-study .text-wrapper-121 {
  color: #ffffff;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .div-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.09px;
  position: relative;
}

.chipsoft-case-study .text-input-4 {
  align-items: center;
  align-self: stretch;
  border: 0.51px solid;
  border-color: #ffffff;
  border-radius: 2.02px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 6.07px;
  position: relative;
}

.chipsoft-case-study .frame-207 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.05px;
  position: relative;
}

.chipsoft-case-study .search-4 {
  height: 10.12px;
  position: relative;
  width: 10.12px;
}

.chipsoft-case-study .value-4 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  margin-top: -0.51px;
  position: relative;
  width: 77.9px;
}

.chipsoft-case-study .img-9 {
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .holder-wrapper {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 2.53px;
  display: flex;
  flex-direction: column;
  height: 24.28px;
  justify-content: center;
  overflow: hidden;
  padding: 8.09px 10.12px;
  position: relative;
  width: 78.91px;
}

.chipsoft-case-study .holder-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.01px;
  margin-bottom: -2.02px;
  margin-left: -1.74px;
  margin-right: -1.74px;
  margin-top: -2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-122 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .frame-208 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12.14px;
  left: 151px;
  position: absolute;
  top: 101px;
  width: 557px;
}

.chipsoft-case-study .nav-bar-wrapper {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.51px;
  border-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  height: 23.27px;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .nav-link-16 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1.01px;
  border-color: #ffffff;
  display: flex;
  gap: 4.05px;
  height: 23.27px;
  justify-content: center;
  padding: 4.05px;
  position: relative;
  width: 61.71px;
}

.chipsoft-case-study .text-wrapper-123 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .nav-link-17 {
  align-items: center;
  display: flex;
  gap: 4.05px;
  height: 23.27px;
  justify-content: center;
  opacity: 0.72;
  padding: 4.05px;
  position: relative;
  width: 61.71px;
}

.chipsoft-case-study .nav-link-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.05px;
  height: 23.27px;
  justify-content: center;
  opacity: 0.72;
  padding: 4.05px 8.09px;
  position: relative;
}

.chipsoft-case-study .nav-link-19 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.05px;
  justify-content: center;
  opacity: 0.72;
  padding: 6.07px 12.14px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-124 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 10.9px;
  margin-top: -1.01px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-209 {
  align-items: center;
  border-radius: 2.02px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.02px;
  justify-content: center;
  padding: 3.04px 2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-125 {
  color: #d9d9d9;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 8.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-210 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.07px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-211 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.07px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .dropdown-5 {
  align-items: flex-start;
  border-radius: 3.04px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.09px;
  justify-content: center;
  padding: 2.02px 0px;
  position: relative;
}

.chipsoft-case-study .frame-212 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.05px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-126 {
  color: #a1a1a1;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 12.1px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-213 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.01px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-127 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 12.1px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .chevron-right-2 {
  height: 8.09px;
  position: relative;
  width: 8.09px;
}

.chipsoft-case-study .container-events-2 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.14px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .event-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--x01-neutral30);
  border-radius: 6.07px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.06px;
  justify-content: center;
  padding: 16.19px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .element-2 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.07px;
  position: relative;
}

.chipsoft-case-study .frame-214 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.07px;
  position: relative;
}

.chipsoft-case-study .live-3 {
  align-items: flex-start;
  background-color: #eb5757;
  border-radius: 2.53px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.06px;
  padding: 1.01px 2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-128 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 6.1px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 8.1px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .holder-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.19px;
  position: relative;
}

.chipsoft-case-study .frame-215 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.07px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-129 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  width: 192.23px;
}

.chipsoft-case-study .div-25 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-130 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 7.1px;
  font-weight: 400;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .speakers-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.05px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-131 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .chip-speaker-8 {
  align-items: center;
  border-radius: 10.12px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.02px;
  padding: 0px 6.07px 0px 0px;
  position: relative;
}

.chipsoft-case-study .frame-216 {
  background-image: url(../../../static/img/ellipse-1-27.svg);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .name-7 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 9.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .frame-217 {
  background-image: url(../../../static/img/ellipse-1-26.svg);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-218 {
  background-image: url(../../../static/img/ellipse-1-25.svg);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .chip-speaker-9 {
  align-items: center;
  background-color: var(--accent-50);
  border-radius: 10.12px;
  display: flex;
  gap: 2.02px;
  height: 15.68px;
  padding: 2.02px 4.05px 2.02px 2.02px;
  position: relative;
  width: 16.19px;
}

.chipsoft-case-study .frame-219 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.05px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .CTA-9 {
  all: unset;
  align-items: center;
  border: 0.51px solid;
  border-color: var(--x01-neutral90);
  border-radius: 2.02px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.06px;
  justify-content: center;
  padding: 6.07px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-132 {
  color: var(--x01-neutral90);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .CTA-10 {
  all: unset;
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 2.02px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.06px;
  justify-content: center;
  padding: 6.07px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-133 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .img-10 {
  height: 16.19px;
  position: relative;
  width: 16.19px;
}

.chipsoft-case-study .speakers-6 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.05px;
  justify-content: flex-end;
  position: relative;
}

.chipsoft-case-study .attending-2 {
  align-items: center;
  display: inline-flex;
  height: 16.19px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-14 {
  background-image: url(../../../static/img/avatar-w-photo-23.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-15 {
  background-image: url(../../../static/img/avatar-w-photo-22.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-16 {
  background-image: url(../../../static/img/avatar-w-photo-21.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-17 {
  background-color: var(--accent-50);
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 15.18px;
  margin-left: -2.02px;
  margin-right: -0.51px;
  position: relative;
  width: 15.18px;
}

.chipsoft-case-study .text-wrapper-134 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 5.1px;
  font-weight: 600;
  height: 6px;
  left: 3px;
  letter-spacing: -0.23px;
  line-height: normal;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.chipsoft-case-study .live-4 {
  align-items: flex-start;
  background-color: #0060ff;
  border-radius: 2.53px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.06px;
  padding: 1.01px 2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-135 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .speakers-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.05px;
  justify-content: center;
  position: relative;
}

.chipsoft-case-study .chip-speaker-10 {
  border-radius: 10.12px;
  height: 16.19px;
  position: relative;
  width: 50.08px;
}

.chipsoft-case-study .frame-220 {
  background-image: url(../../../static/img/ellipse-1-24.svg);
  background-size: 100% 100%;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 12px;
}

.chipsoft-case-study .name-8 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  height: 10px;
  left: 16px;
  letter-spacing: -0.18px;
  line-height: 9.1px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.chipsoft-case-study .chip-speaker-11 {
  align-items: center;
  border-radius: 10.12px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.02px;
  padding: 2.02px 4.05px 2.02px 2.02px;
  position: relative;
}

.chipsoft-case-study .frame-221 {
  background-image: url(../../../static/img/ellipse-1-23.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .frame-222 {
  background-image: url(../../../static/img/ellipse-1-22.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .chip-speaker-12 {
  align-items: center;
  align-self: stretch;
  background-color: #575757;
  border-radius: 10.12px;
  display: flex;
  gap: 2.02px;
  padding: 2.02px 4.05px 2.02px 2.02px;
  position: relative;
  width: 16.19px;
}

.chipsoft-case-study .frame-223 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.07px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-224 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.07px;
  position: relative;
}

.chipsoft-case-study .frame-225 {
  background-color: #ffffff1f;
  border-radius: 19.22px;
  height: 20.23px;
  position: relative;
  width: 20.23px;
}

.chipsoft-case-study .calendar-add-on-3 {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.chipsoft-case-study .avatar-w-photo-18 {
  background-image: url(../../../static/img/avatar-w-photo-20.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-19 {
  background-image: url(../../../static/img/avatar-w-photo-19.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-20 {
  background-image: url(../../../static/img/avatar-w-photo-18.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .div-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-136 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 400;
  letter-spacing: -0.07px;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .ellipse-24 {
  background-color: #ffffff;
  border-radius: 1.01px;
  height: 2.02px;
  position: relative;
  width: 2.02px;
}

.chipsoft-case-study .text-wrapper-137 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 7.1px;
  font-weight: 400;
  letter-spacing: -0.11px;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  width: fit-content;
}

.chipsoft-case-study .frame-226 {
  background-image: url(../../../static/img/ellipse-1-21.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-227 {
  background-image: url(../../../static/img/ellipse-1-20.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-228 {
  background-image: url(../../../static/img/ellipse-1-19.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-229 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40.47px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-21 {
  background-image: url(../../../static/img/avatar-w-photo-17.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-22 {
  background-image: url(../../../static/img/avatar-w-photo-16.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-23 {
  background-image: url(../../../static/img/avatar-w-photo-15.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .frame-230 {
  background-image: url(../../../static/img/ellipse-1-18.png);
  background-size: 100% 100%;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 12px;
}

.chipsoft-case-study .frame-231 {
  background-image: url(../../../static/img/ellipse-1-17.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .frame-232 {
  background-image: url(../../../static/img/ellipse-1-16.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .frame-233 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 121.91px;
}

.chipsoft-case-study .frame-234 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.07px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .frame-235 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 6.07px;
  justify-content: flex-end;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-24 {
  background-image: url(../../../static/img/avatar-w-photo-14.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-25 {
  background-image: url(../../../static/img/avatar-w-photo-13.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-26 {
  background-image: url(../../../static/img/avatar-w-photo-12.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .frame-236 {
  background-image: url(../../../static/img/ellipse-1-15.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-237 {
  background-image: url(../../../static/img/ellipse-1-14.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-238 {
  background-image: url(../../../static/img/ellipse-1-13.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-27 {
  background-image: url(../../../static/img/avatar-w-photo-11.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-28 {
  background-image: url(../../../static/img/avatar-w-photo-10.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-29 {
  background-image: url(../../../static/img/avatar-w-photo-9.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .frame-239 {
  background-image: url(../../../static/img/ellipse-1-12.png);
  background-size: 100% 100%;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 12px;
}

.chipsoft-case-study .frame-240 {
  background-image: url(../../../static/img/ellipse-1-11.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .frame-241 {
  background-image: url(../../../static/img/ellipse-1-10.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .avatar-w-photo-30 {
  background-image: url(../../../static/img/avatar-w-photo-8.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-31 {
  background-image: url(../../../static/img/avatar-w-photo-7.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-32 {
  background-image: url(../../../static/img/avatar-w-photo-6.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .frame-242 {
  background-image: url(../../../static/img/ellipse-1-9.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-243 {
  background-image: url(../../../static/img/ellipse-1-8.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .frame-244 {
  background-image: url(../../../static/img/ellipse-1-7.png);
  background-size: 100% 100%;
  height: 14.16px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-33 {
  background-image: url(../../../static/img/avatar-w-photo-5.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-34 {
  background-image: url(../../../static/img/avatar-w-photo-4.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-35 {
  background-image: url(../../../static/img/avatar-w-photo-3.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .frame-245 {
  background-image: url(../../../static/img/ellipse-1-6.png);
  background-size: 100% 100%;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 12px;
}

.chipsoft-case-study .frame-246 {
  background-image: url(../../../static/img/ellipse-1-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .frame-247 {
  background-image: url(../../../static/img/ellipse-1-4.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 12.14px;
  position: relative;
  width: 12.14px;
}

.chipsoft-case-study .avatar-w-photo-36 {
  background-image: url(../../../static/img/avatar-w-photo-2.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .avatar-w-photo-37 {
  background-image: url(../../../static/img/avatar-w-photo-1.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 14.16px;
}

.chipsoft-case-study .avatar-w-photo-38 {
  background-image: url(../../../static/img/avatar-w-photo.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
  width: 13.66px;
}

.chipsoft-case-study .menu-21 {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  display: flex;
  flex-direction: column;
  height: 478px;
  justify-content: space-between;
  left: 0;
  padding: 0px 0px 12.14px;
  position: absolute;
  top: 40px;
  width: 126px;
}

.chipsoft-case-study .frame-248 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 126.46px;
}

.chipsoft-case-study .nav-link-20 {
  flex: 0 0 auto !important;
  gap: 2.02px !important;
  padding: 8.09px 20.23px !important;
  width: 126.46px !important;
}

.chipsoft-case-study .nav-link-21 {
  font-family: "Inter", Helvetica !important;
  font-size: 8.1px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.02px !important;
  line-height: 9.7px !important;
}

.chipsoft-case-study .nav-link-22 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 2.02px;
  padding: 8.09px 20.23px;
  position: relative;
  width: 126.46px;
}

.chipsoft-case-study .icons-28 {
  height: 12.14px !important;
  width: 12.14px !important;
}

.chipsoft-case-study .icons-29 {
  height: 10px !important;
  left: 2px !important;
  top: 1px !important;
  width: 8px !important;
}

.chipsoft-case-study .dashboard-5 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 9.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .icons-30 {
  height: 8px !important;
  left: 2px !important;
  top: 2px !important;
  width: 8px !important;
}

.chipsoft-case-study .icons-31 {
  height: 9px !important;
  width: 9px !important;
}

.chipsoft-case-study .icons-32 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  height: 3px !important;
  left: 3px !important;
  width: 3px !important;
}

.chipsoft-case-study .icons-33 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  height: 3px !important;
  top: 3px !important;
  width: 3px !important;
}

.chipsoft-case-study .icons-34 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  height: 3px !important;
  left: 5px !important;
  top: 3px !important;
  width: 3px !important;
}

.chipsoft-case-study .icons-35 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  height: 3px !important;
  left: 3px !important;
  top: 5px !important;
  width: 3px !important;
}

.chipsoft-case-study .nav-link-23 {
  flex: 0 0 auto !important;
  gap: 2.02px !important;
  padding: 8.09px 20.23px !important;
  width: 124.95px !important;
}

.chipsoft-case-study .extra-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.52px;
  padding: 2.02px 20.23px;
  position: relative;
  width: 100%;
}

.chipsoft-case-study .text-wrapper-138 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 7.3px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .interpunct-4 {
  height: 4.05px;
  position: relative;
  width: 4.05px;
}

.chipsoft-case-study .ellipse-25 {
  background-color: #d9d9d9;
  border-radius: 1.01px;
  height: 2px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 2px;
}

.chipsoft-case-study .frame-249 {
  align-items: center;
  background-color: var(--x02-neutral80);
  border-bottom-style: solid;
  border-bottom-width: 0.25px;
  border-color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 10.12px 20.23px;
  position: absolute;
  top: 0;
  width: 728px;
}

.chipsoft-case-study .frame-250 {
  background-image: url(../../../static/img/group-417.png);
  background-size: 100% 100%;
  height: 18.2px;
  position: relative;
  width: 65.76px;
}

.chipsoft-case-study .frame-251 {
  align-items: center;
  display: flex;
  gap: 12.14px;
  justify-content: flex-end;
  padding: 2.02px 0px;
  position: relative;
  width: 107.24px;
}

.chipsoft-case-study .frame-252 {
  background-image: url(../../../static/img/ellipse-5-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 16.19px;
  position: relative;
  width: 16.19px;
}

.chipsoft-case-study .ellipse-26 {
  background-color: var(--accent-50);
  border: 0.51px solid;
  border-color: var(--x01-neutral100);
  border-radius: 2.02px;
  height: 4px;
  left: 12px;
  position: relative;
  top: 12px;
  width: 4px;
}

.chipsoft-case-study .text-wrapper-139 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 9.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.chipsoft-case-study .text-wrapper-140 {
  color: #000000;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 48px;
  font-weight: 400;
  height: 64px;
  left: 599px;
  letter-spacing: -0.24px;
  line-height: 64px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.chipsoft-case-study .overlap-17 {
  background-image: url(../../../static/img/pexels-michael-burrows-7129708-1.png);
  background-size: 100% 100%;
  height: 1000px;
  left: 0;
  position: absolute;
  top: 5016px;
  width: 1902px;
}

.chipsoft-case-study .works-on-all {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 56px;
  font-weight: 400;
  height: 256px;
  left: 606px;
  letter-spacing: -0.28px;
  line-height: 64px;
  position: absolute;
  top: 371px;
  width: 391px;
}

.chipsoft-case-study .apple-iphone-11-pro-gold {
  left: 139px !important;
  position: absolute !important;
  top: 73px !important;
}

.chipsoft-case-study .apple-iphone-pro-instance {
  border-color: unset !important;
}

.chipsoft-case-study .overlap-18 {
  background-image: url(../../../static/img/rectangle-123.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 0;
  position: absolute;
  top: 6016px;
  width: 1902px;
}

.chipsoft-case-study .text-wrapper-141 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 80px;
  font-weight: 500;
  left: 670px;
  letter-spacing: -0.24px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 179px;
  white-space: nowrap;
}

.chipsoft-case-study .text-wrapper-142 {
  color: #f52f57;
  font-family: "Neue Haas Grotesk Display Pro-65Medium", Helvetica;
  font-size: 46px;
  font-weight: 500;
  left: 688px;
  letter-spacing: -0.24px;
  line-height: normal;
  position: absolute;
  text-align: center;
  text-decoration: underline;
  top: 287px;
  white-space: nowrap;
}

.chipsoft-case-study .frame-253 {
  height: 46px;
  left: 1555px;
  position: absolute;
  top: 394px;
  width: 212px;
}
