.file-name {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.file-name .frame-34 {
  background-image: url(../../../static/img/rectangle-5-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 200px;
  position: relative;
  width: 340px;
}

.file-name .frame-35 {
  align-items: center;
  display: inline-flex;
  left: 24px;
  position: relative;
  top: 24px;
}

.file-name .frame-36 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  position: relative;
  width: 260px;
}

.file-name .frame-37 {
  align-items: flex-start;
  display: flex;
  gap: 11px;
  position: relative;
  width: 127px;
}

.file-name .text-and-supporting-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  margin-right: -1px;
  position: relative;
  width: 128px;
}

.file-name .text-and-supporting {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
  position: relative;
}

.file-name .frame-38 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  margin-right: -8px;
  position: relative;
}

.file-name .text {
  color: var(--secondary-100);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.file-name .frame-39 {
  align-items: center;
  background-color: var(--accent-50);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 2px 4px;
  position: relative;
}

.file-name .text-wrapper-15 {
  color: #f5f5f5;
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.file-name .star-2 {
  height: 16px;
  position: relative;
  width: 16px;
}

.file-name .frame-40 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.file-name .element-4 {
  background-color: #ffeaea;
  border-radius: 24.75px;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.file-name .overlap-group-5 {
  border-radius: 24.75px;
  height: 24px;
  position: relative;
}

.file-name .img-7 {
  height: 12px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 12px;
}

.file-name .img-wrapper {
  background-color: #f5f5f5;
  border-radius: 24.75px;
  height: 24px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 24px;
}

.file-name .ellipse-10 {
  background-color: var(--secondary-100);
  border-radius: 2px;
  height: 4px;
  position: relative;
  width: 4px;
}

.file-name .supporting-text-2 {
  color: var(--secondary-100);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.file-name .icon-button {
  height: 32px;
  position: relative;
  width: 32px;
}
