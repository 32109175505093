.apple-iphone-pro {
  height: 855px;
  width: 439px;
}

.apple-iphone-pro .overlap-group-3 {
  height: 855px;
  position: relative;
}

.apple-iphone-pro .manage-users {
  background-color: var(--ui-essentialsneutralntrl100);
  height: 778px;
  left: 41px;
  overflow: hidden;
  position: absolute;
  top: 41px;
  width: 356px;
}

.apple-iphone-pro .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15.83px;
  left: 13px;
  position: absolute;
  top: 79px;
  width: 327.46px;
}

.apple-iphone-pro .text-wrapper-6 {
  color: var(--x01-neutral10);
  font-family: "Inter", Helvetica;
  font-size: 19.8px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 27.7px;
  margin-top: -0.99px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11.87px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .text-input {
  align-items: center;
  border: 0.99px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.96px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 3.96px;
  padding: 7.91px 11.87px;
  position: relative;
}

.apple-iphone-pro .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.91px;
  position: relative;
}

.apple-iphone-pro .search {
  height: 19.79px;
  position: relative;
  width: 19.79px;
}

.apple-iphone-pro .value {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  letter-spacing: -0.06px;
  line-height: 16.6px;
  margin-top: -0.99px;
  position: relative;
  width: 152.35px;
}

.apple-iphone-pro .sort-wrapper {
  align-items: center;
  border: 0.99px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.96px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.96px;
  padding: 5.94px;
  position: relative;
}

.apple-iphone-pro .img {
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .frame-wrapper {
  background-color: #efede9;
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 356px;
}

.apple-iphone-pro .frame-8 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 16px;
  position: relative;
  top: 12px;
  width: 324px;
}

.apple-iphone-pro .frame-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 11.87px;
  position: relative;
}

.apple-iphone-pro .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.91px;
  position: relative;
}

.apple-iphone-pro .ellipse-wrapper {
  background-image: url(../../../static/img/ellipse-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 31.66px;
  position: relative;
  width: 31.66px;
}

.apple-iphone-pro .ellipse-5 {
  background-color: #00b070;
  border: 0.99px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.96px;
  height: 8px;
  left: 24px;
  position: relative;
  top: 24px;
  width: 8px;
}

.apple-iphone-pro .frame-11 {
  align-items: center;
  display: inline-flex;
  gap: 42.54px;
  left: 14px;
  position: absolute;
  top: 174px;
}

.apple-iphone-pro .container-menu {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  height: 32.65px;
  overflow-x: scroll;
  position: relative;
}

.apple-iphone-pro .navlink {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.98px;
  border-color: #4f78db;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.91px;
  justify-content: center;
  padding: 7.91px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-7 {
  color: #3f60af;
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  letter-spacing: -0.02px;
  line-height: 14.2px;
  margin-top: -0.57px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .badge-base {
  align-items: center;
  background-color: #4f78db;
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-bottom: -2.05px;
  margin-top: -2.05px;
  padding: 3.96px;
  position: relative;
}

.apple-iphone-pro .text {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 10.9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 13.1px;
  margin-top: -0.99px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .navlink-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 7.91px;
  justify-content: center;
  padding: 7.91px;
  position: relative;
  width: 87.06px;
}

.apple-iphone-pro .navlink-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 7.91px;
  justify-content: center;
  margin-bottom: -11.38px;
  margin-top: -11.38px;
  padding: 7.91px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-8 {
  color: var(--x01-neutral70);
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: -0.02px;
  line-height: 14.2px;
  margin-left: -3.63px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .div-wrapper {
  background-color: #868686;
  border-radius: 11.87px;
  height: 23.74px;
  margin-right: -3.63px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .text-wrapper-9 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  height: 12px;
  left: 5px;
  letter-spacing: -0.05px;
  line-height: 11.9px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.apple-iphone-pro .frame-12 {
  align-items: center;
  border-radius: 3.96px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.96px;
  justify-content: center;
  padding: 5.94px 3.96px;
  position: relative;
}

.apple-iphone-pro .img-2 {
  height: 15.83px;
  position: relative;
  width: 15.83px;
}

.apple-iphone-pro .text-wrapper-10 {
  color: #363636;
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.07px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .frame-13 {
  align-items: flex-start;
  display: flex;
  left: 16px;
  overflow: hidden;
  overflow-x: scroll;
  position: absolute;
  top: 223px;
  width: 324px;
}

.apple-iphone-pro .column {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 210.72px;
}

.apple-iphone-pro .table-header-cell {
  align-items: center;
  align-self: stretch;
  background-color: #e5fbf3;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  gap: 11.87px;
  height: 43.53px;
  padding: 11.87px 23.74px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.96px;
  position: relative;
}

.apple-iphone-pro .text-2 {
  color: #363636;
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.6px;
  margin-top: -0.99px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .table-cell {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  flex: 0 0 auto;
  gap: 11.87px;
  padding: 11.87px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .avatar {
  background-image: url(../../../static/img/avatar-12.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .div-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.apple-iphone-pro .text-3 {
  color: var(--x01-neutral20);
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.99px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .supporting-text {
  color: var(--x01-neutral50);
  font-family: "Inter", Helvetica;
  font-size: 10.9px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 13.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .avatar-2 {
  background-image: url(../../../static/img/avatar-10.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .avatar-3 {
  background-color: #4f78db;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .text-4 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 14.8px;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 24px;
}

.apple-iphone-pro .avatar-4 {
  background-image: url(../../../static/img/avatar-8.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .avatar-5 {
  background-image: url(../../../static/img/avatar-6.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .avatar-6 {
  background-image: url(../../../static/img/avatar-4.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .avatar-7 {
  background-image: url(../../../static/img/avatar-2.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .avatar-8 {
  background-image: url(../../../static/img/avatar.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 148.39px;
  height: 23.74px;
  position: relative;
  width: 23.74px;
}

.apple-iphone-pro .table-header-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #e5fbf3;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  gap: 11.87px;
  height: 43.53px;
  padding: 11.87px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .text-5 {
  color: #363636;
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17.8px;
  margin-top: -0.99px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .table-cell-2 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: inline-flex;
  height: 50.45px;
  padding: 13.85px 11.87px;
  position: relative;
}

.apple-iphone-pro .badge-base-2 {
  align-items: center;
  background-color: #4f78db;
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 3.96px 7.91px;
  position: relative;
}

.apple-iphone-pro .text-6 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.99px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .badge-base-3 {
  align-items: center;
  background-color: var(--tertiary-30);
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 3.96px 7.91px;
  position: relative;
}

.apple-iphone-pro .badge-wrapper {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  height: 50.45px;
  padding: 2.97px 11.87px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .badge {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 11.87px;
  margin-bottom: -0.57px;
  margin-top: -0.57px;
  mix-blend-mode: multiply;
  padding: 11.87px 0px;
  position: relative;
}

.apple-iphone-pro .badge-base-4 {
  align-items: center;
  background-color: var(--primary-50);
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 3.96px 7.91px;
  position: relative;
}

.apple-iphone-pro .badge-base-5 {
  align-items: center;
  background-color: var(--x02-neutral80);
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 3.96px 7.91px;
  position: relative;
}

.apple-iphone-pro .text-7 {
  color: #363636;
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.99px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .table-cell-3 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  height: 50.45px;
  padding: 11.87px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .badge-base-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  margin-bottom: -9.47px;
  margin-top: -9.47px;
  mix-blend-mode: multiply;
  padding: 11.87px 0px;
  position: relative;
}

.apple-iphone-pro .badge-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  margin-bottom: -9.47px;
  margin-top: -9.47px;
  mix-blend-mode: multiply;
  padding: 11.87px 0px;
  position: relative;
}

.apple-iphone-pro .badge-base-6 {
  align-items: center;
  background-color: #00b070;
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 3.96px 7.91px;
  position: relative;
}

.apple-iphone-pro .badge-base-7 {
  align-items: center;
  background-color: var(--x01-neutral98);
  border-radius: 15.83px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 3.96px 7.91px;
  position: relative;
}

.apple-iphone-pro .text-8 {
  color: #363636;
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.99px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .column-2 {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.apple-iphone-pro .table-header-cell-2 {
  align-items: center;
  align-self: stretch;
  background-color: #e5fbf3;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: #e5fbf3;
  display: flex;
  gap: 11.87px;
  height: 43.53px;
  padding: 11.87px 31.66px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .table-cell-4 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  height: 50.45px;
  padding: 11.87px 31.66px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .text-9 {
  color: var(--x01-neutral40);
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .column-3 {
  align-items: flex-start;
  background-color: var(--x01-neutral100);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 150.37px;
}

.apple-iphone-pro .table-header-cell-3 {
  align-items: center;
  align-self: stretch;
  background-color: #e5fbf3;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  gap: 11.87px;
  height: 43.53px;
  padding: 11.87px 31.66px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .table-cell-5 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  height: 50.45px;
  justify-content: center;
  padding: 7.91px 31.66px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .frame-14 {
  align-items: center;
  background-color: var(--secondary-98);
  border-radius: 3.96px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 7.91px 11.87px;
  position: relative;
}

.apple-iphone-pro .element-hours-ago {
  color: #3f60af;
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.6px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .vector {
  height: 3.96px;
  position: relative;
  width: 6.93px;
}

.apple-iphone-pro .table-cell-6 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 0.99px;
  border-color: var(--x01-neutral90);
  display: flex;
  height: 50.45px;
  justify-content: center;
  padding: 11.87px 31.66px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .frame-15 {
  align-items: center;
  background-color: var(--secondary-98);
  border-radius: 3.96px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: -3.06px;
  margin-top: -3.06px;
  padding: 7.91px 11.87px;
  position: relative;
}

.apple-iphone-pro .element-hours-ago-2 {
  color: #3f60af;
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 16.6px;
  margin-right: -1.69px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .vector-2 {
  height: 3.96px;
  margin-left: -8.61px;
  position: relative;
  width: 6.93px;
}

.apple-iphone-pro .frame-16 {
  align-items: flex-start;
  background-color: #e5fbf3;
  display: inline-flex;
  flex-direction: column;
  gap: 15.83px;
  left: 0;
  padding: 15.83px;
  position: absolute;
  top: 794px;
}

.apple-iphone-pro .frame-17 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 324.49px;
}

.apple-iphone-pro .text-wrapper-11 {
  color: #363636;
  font-family: "Inter", Helvetica;
  font-size: 15.8px;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 19px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .frame-18 {
  align-items: center;
  border-radius: 3.96px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.98px;
  padding: 3.96px 1.98px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-12 {
  color: #4f78db;
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.07px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .arrow-forward {
  height: 15.83px;
  margin-right: -501.02px;
  margin-top: -5400.66px;
  position: relative;
  width: 15.83px;
}

.apple-iphone-pro .frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 11.87px;
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
  width: 324.49px;
}

.apple-iphone-pro .card-design {
  align-items: flex-start;
  border-radius: 5.94px;
  display: flex;
  flex-direction: column;
  gap: 61.03px;
  height: 154.33px;
  overflow: hidden;
  position: relative;
  width: 241.39px;
}

.apple-iphone-pro .folder-card {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .frame-20 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0px 7.91px 0px 11.87px;
  position: absolute;
  top: 16px;
  width: 241px;
}

.apple-iphone-pro .frame-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.36px;
  position: relative;
}

.apple-iphone-pro .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.05px;
  position: relative;
}

.apple-iphone-pro .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.apple-iphone-pro .text-wrapper-13 {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 16.6px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .frame-24 {
  align-items: center;
  background-color: var(--tertiary-20);
  border-radius: 1.98px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 2.97px 3.96px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-14 {
  color: #f5f5f5;
  font-family: "Inter", Helvetica;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: -0.03px;
  line-height: 9.7px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .frame-25 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.68px;
  position: relative;
}

.apple-iphone-pro .subheading-wrapper {
  align-items: flex-start;
  background-color: #d7fdf2;
  border-radius: 1.98px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  padding: 3.96px;
  position: relative;
}

.apple-iphone-pro .subheading {
  color: #047857;
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 11.9px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .ellipse-6 {
  background-color: #f1f1f1;
  border-radius: 1.34px;
  height: 2.68px;
  position: relative;
  width: 2.68px;
}

.apple-iphone-pro .supporting-text-2 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .more-horiz {
  height: 10.88px;
  position: relative;
  width: 21.76px;
}

.apple-iphone-pro .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10.88px;
  left: 16px;
  position: absolute;
  top: 1044px;
}

.apple-iphone-pro .arrow-forward-2 {
  height: 15.83px;
  margin-right: -501.02px;
  margin-top: -5634.13px;
  position: relative;
  width: 15.83px;
}

.apple-iphone-pro .card-design-2 {
  align-items: flex-start;
  border-radius: 5.94px;
  display: flex;
  flex-direction: column;
  gap: 63.12px;
  height: 154.33px;
  overflow: hidden;
  position: relative;
  width: 241.39px;
}

.apple-iphone-pro .frame-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11.87px;
  left: 0;
  padding: 0px 11.87px;
  position: absolute;
  top: 11px;
  width: 241px;
}

.apple-iphone-pro .frame-28 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 2.77px;
  position: relative;
  width: 100%;
}

.apple-iphone-pro .frame-29 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.apple-iphone-pro .p {
  color: var(--secondary-100);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 11.9px;
  font-weight: 600;
  letter-spacing: -0.05px;
  line-height: 14.2px;
  margin-top: -0.69px;
  position: relative;
}

.apple-iphone-pro .frame-30 {
  height: 11.1px;
  position: relative;
  width: 22.2px;
}

.apple-iphone-pro .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.77px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-15 {
  color: var(--x01-neutral90);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.9px;
  margin-top: -0.69px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .speakers {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 1.39px;
  justify-content: center;
  left: 11px;
  position: absolute;
  top: 86px;
}

.apple-iphone-pro .text-wrapper-16 {
  color: var(--x01-neutral100);
  font-family: "Inter", Helvetica;
  font-size: 10.9px;
  font-weight: 600;
  letter-spacing: -0.04px;
  line-height: 13.1px;
  margin-top: -0.69px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .chip-speaker {
  align-items: center;
  border-radius: 13.87px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.96px;
  padding: 1.98px 3.96px;
  position: relative;
}

.apple-iphone-pro .frame-32 {
  background-image: url(../../../static/img/ellipse-1-2.png);
  background-size: 100% 100%;
  height: 19.79px;
  position: relative;
  width: 19.79px;
}

.apple-iphone-pro .name {
  color: var(--secondary-100);
  font-family: "Inter", Helvetica;
  font-size: 8.3px;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 12.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .frame-33 {
  background-image: url(../../../static/img/ellipse-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 19.79px;
  position: relative;
  width: 19.79px;
}

.apple-iphone-pro .name-wrapper {
  align-items: center;
  background-color: #363636;
  border-radius: 11.87px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.77px;
  padding: 1.98px 3.96px;
  position: relative;
}

.apple-iphone-pro .name-2 {
  color: var(--x01-neutral98);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 11.9px;
  margin-top: -0.69px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.apple-iphone-pro .img-3 {
  height: 855px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 439px;
}
