.slider-false {
  height: 20px;
  width: 152px;
}

.slider-false .overlap {
  height: 20px;
  position: relative;
}

.slider-false .rectangle {
  background-color: var(--neutral-30);
  border-radius: 100px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 152px;
}

.slider-false .overlap-group-wrapper {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 84px;
}

.slider-false .overlap-group-3 {
  height: 16px;
  position: relative;
  top: 3px;
  width: 80px;
}

.slider-false .rectangle-2 {
  background-color: var(--defaultprimary-light);
  border-radius: 100px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 74px;
}

.slider-false .ellipse-7 {
  background-color: var(--defaultwhite);
  border: 1px solid;
  border-color: var(--neutral-30);
  border-radius: 8px;
  box-shadow: var(--box-shadows-x-small);
  height: 16px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 16px;
}
