.button-add-team-wrapper {
  align-items: center;
  background-color: #1f3058;
  border-radius: 6.67px;
  display: flex;
  gap: 3.33px;
  height: 36.68px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 26.68px;
  position: relative;
  width: 168px;
}

.button-add-team-wrapper .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 800;
  letter-spacing: -0.18px;
  line-height: 15px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
