.namecandidate-name-menu-score-1000-1 {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  flex-direction: column;
  height: 944px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .frame-44 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.namecandidate-name-menu-score-1000-1 .nav-link-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  padding: 16px 40px;
  position: relative;
  width: 100%;
}

.namecandidate-name-menu-score-1000-1 .dashboard-3 {
  color: var(--x01-neutral30);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.namecandidate-name-menu-score-1000-1 .frame-45 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .extra-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3px;
  padding: 4px 40px;
  position: relative;
  width: 100%;
}

.namecandidate-name-menu-score-1000-1 .text-wrapper-17 {
  color: #000000;
  font-family: var(--tiny-REG-font-family);
  font-size: var(--tiny-REG-font-size);
  font-style: var(--tiny-REG-font-style);
  font-weight: var(--tiny-REG-font-weight);
  letter-spacing: var(--tiny-REG-letter-spacing);
  line-height: var(--tiny-REG-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.namecandidate-name-menu-score-1000-1 .ellipse-wrapper {
  height: 8px;
  position: relative;
  width: 8px;
}

.namecandidate-name-menu-score-1000-1 .ellipse-11 {
  background-color: #d9d9d9;
  border-radius: 2px;
  height: 4px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 4px;
}

.namecandidate-name-menu-score-1000-1 .frame-46 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.namecandidate-name-menu-score-1000-1 .icons-18 {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.namecandidate-name-menu-score-1000-1 .nav-link-7 {
  align-items: center;
  background-color: var(--primary-50);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .icons-19 {
  height: 32px !important;
  width: 32px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-20 {
  height: 27px !important;
  left: 5px !important;
  top: 3px !important;
  width: 21px !important;
}

.namecandidate-name-menu-score-1000-1 .nav-link-8 {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .icons-21 {
  height: 20px !important;
  left: 6px !important;
  top: 6px !important;
  width: 20px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-22 {
  height: 22px !important;
  width: 22px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-23 {
  border-radius: 4px !important;
  height: 8px !important;
  left: 7px !important;
  width: 8px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-24 {
  border-radius: 4px !important;
  height: 8px !important;
  top: 7px !important;
  width: 8px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-25 {
  border-radius: 4px !important;
  height: 8px !important;
  left: 14px !important;
  top: 7px !important;
  width: 8px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-26 {
  border-radius: 4px !important;
  height: 8px !important;
  left: 7px !important;
  top: 14px !important;
  width: 8px !important;
}

.namecandidate-name-menu-score-1000-1 .nav-link-9 {
  flex: 0 0 auto !important;
}

.namecandidate-name-menu-score-1000-1 .chevron-down-2 {
  position: absolute;
  width: 32px;
}

.namecandidate-name-menu-score-1000-1 .icons-27 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.namecandidate-name-menu-score-1000-1.property-1-3-default {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  width: 250px;
}

.namecandidate-name-menu-score-1000-1.property-1-3-collapsed {
  display: inline-flex;
  gap: 32px;
  padding: 12px 24px 12px 40px;
}

.namecandidate-name-menu-score-1000-1.property-1-3-default .chevron-down-2 {
  height: 31px;
  left: 234px;
  top: 457px;
}

.namecandidate-name-menu-score-1000-1.property-1-3-collapsed .chevron-down-2 {
  height: 32px;
  left: 112px;
  top: 456px;
}
