:root {
  --accent-30: #005838;
  --accent-40: #008555;
  --accent-50: #00b171;
  --accent-60: #00dd8d;
  --accent-70: #33e4a4;
  --accent-80: #66ebbb;
  --accent-90: #99f1d1;
  --accent-95: #b2f5dd;
  --accent-98: #ccf8e8;
  --accent-99: #e5fcf4;
  --black-white-white: #fff;
  --box-shadows-x-small: 0px 2px 6px 0px #0510371a;
  --caption-BLD-font-family: "Inter-Bold", Helvetica;
  --caption-BLD-font-size: 14px;
  --caption-BLD-font-style: normal;
  --caption-BLD-font-weight: 700;
  --caption-BLD-letter-spacing: -.056px;
  --caption-BLD-line-height: 120%;
  --caption-REG-font-family: "Inter-Regular", Helvetica;
  --caption-REG-font-size: 14px;
  --caption-REG-font-style: normal;
  --caption-REG-font-weight: 400;
  --caption-REG-letter-spacing: -.056px;
  --caption-REG-line-height: 120%;
  --dark-gray-dark-gray-1: #1a2024;
  --darkblack-100: #000;
  --default-body-small-font-family: "HelveticaNeue-Regular", Helvetica;
  --default-body-small-font-size: 14px;
  --default-body-small-font-style: normal;
  --default-body-small-font-weight: 400;
  --default-body-small-letter-spacing: 0px;
  --default-body-small-line-height: 24px;
  --defaultprimary-light: #1847ed;
  --defaultwhite: #fff;
  --detail-font-family: "Inter-Regular", Helvetica;
  --detail-font-size: 14px;
  --detail-font-style: normal;
  --detail-font-weight: 400;
  --detail-letter-spacing: -.28px;
  --detail-line-height: 20px;
  --gray-900: #101828;
  --h6-MED-font-family: "Inter-SemiBold", Helvetica;
  --h6-MED-font-size: 20px;
  --h6-MED-font-style: normal;
  --h6-MED-font-weight: 600;
  --h6-MED-letter-spacing: -.04px;
  --h6-MED-line-height: 120%;
  --infosurface: #e8f1ff;
  --infotext: #0250ec;
  --light-gray-light-gray-1: #d5dadd;
  --lightwhite-100: #fff;
  --neutral-0: #fff;
  --neutral-03: #ded6d1;
  --neutral-100: #1e202c;
  --neutral-30: #c1c7de;
  --neutral-300: #b8c0cc;
  --neutral-40: #a6acc4;
  --neutral-50: #8c92ab;
  --neutral-500: #64748b;
  --neutral-60: #737992;
  --neutral-800: #191d23;
  --paragraph-MED-font-family: "Inter-Medium", Helvetica;
  --paragraph-MED-font-size: 16px;
  --paragraph-MED-font-style: normal;
  --paragraph-MED-font-weight: 500;
  --paragraph-MED-letter-spacing: -.032px;
  --paragraph-MED-line-height: 120%;
  --paragraph-REG-font-family: "Inter-Regular", Helvetica;
  --paragraph-REG-font-size: 16px;
  --paragraph-REG-font-style: normal;
  --paragraph-REG-font-weight: 400;
  --paragraph-REG-letter-spacing: -.064px;
  --paragraph-REG-line-height: 120%;
  --primary-030: #fff;
  --primary-0340: #929185;
  --primary-50: #fece00;
  --primary-60: #fed833;
  --primary-70: #fee266;
  --primary-80: #ffeb99;
  --primary-90: #fff0b2;
  --primary-95: #fff5cc;
  --primary-98: #fffae5;
  --secondary-10: #182543;
  --secondary-100: #fff;
  --secondary-20: #203159;
  --secondary-30: #2f4884;
  --secondary-40: #3f60b0;
  --secondary-50: #4f78db;
  --secondary-60: #7293e2;
  --secondary-70: #95aee9;
  --secondary-80: #b9c9f1;
  --secondary-90: #cad6f4;
  --secondary-95: #dce4f8;
  --secondary-98: #edf1fb;
  --shadesblack: #000;
  --shadeswhite: #fff;
  --subhead-MED-font-family: "Inter-SemiBold", Helvetica;
  --subhead-MED-font-size: 18px;
  --subhead-MED-font-style: normal;
  --subhead-MED-font-weight: 600;
  --subhead-MED-letter-spacing: -.072px;
  --subhead-MED-line-height: 120%;
  --tertiary-10: #b36753;
  --tertiary-20: #d4836c;
  --tertiary-30: #f69e86;
  --tertiary-40: #f8b19e;
  --tertiary-50: #fac5b6;
  --tertiary-60: #fbd8cf;
  --tertiary-70: #fce2db;
  --tertiary-80: #fdece7;
  --tertiary-90: #fef5f3;
  --tiny-BLD-font-family: "Inter-Bold", Helvetica;
  --tiny-BLD-font-size: 12px;
  --tiny-BLD-font-style: normal;
  --tiny-BLD-font-weight: 700;
  --tiny-BLD-letter-spacing: -.048px;
  --tiny-BLD-line-height: 120%;
  --tiny-MED-font-family: "Inter-SemiBold", Helvetica;
  --tiny-MED-font-size: 12px;
  --tiny-MED-font-style: normal;
  --tiny-MED-font-weight: 600;
  --tiny-MED-letter-spacing: -.048px;
  --tiny-MED-line-height: 120%;
  --tiny-REG-font-family: "Inter-Regular", Helvetica;
  --tiny-REG-font-size: 12px;
  --tiny-REG-font-style: normal;
  --tiny-REG-font-weight: 400;
  --tiny-REG-letter-spacing: -.048px;
  --tiny-REG-line-height: 120%;
  --ui-essentialsneutralntrl100: #fff;
  --white: #fff;
  --x01-neutral10: #202020;
  --x01-neutral100: #fff;
  --x01-neutral20: #2b2b2b;
  --x01-neutral30: #363636;
  --x01-neutral40: #5e5e5e;
  --x01-neutral50: #868686;
  --x01-neutral60: #afafaf;
  --x01-neutral70: #c3c3c3;
  --x01-neutral80: #d7d7d7;
  --x01-neutral90: #ebebeb;
  --x01-neutral98: #f8f8f8;
  --x02-neutral80: #e5e2db;
  --x02-neutral90: #eae8e2;
  --x02-neutral95: #efeee9;
}

.file-name {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.file-name .frame-34 {
  background-image: url("rectangle-5-1.7a6f50ac.png");
  background-position: 50%;
  background-size: cover;
  width: 340px;
  height: 200px;
  position: relative;
}

.file-name .frame-35 {
  align-items: center;
  display: inline-flex;
  position: relative;
  top: 24px;
  left: 24px;
}

.file-name .frame-36 {
  align-items: flex-start;
  gap: 12px;
  width: 260px;
  display: flex;
  position: relative;
}

.file-name .frame-37 {
  align-items: flex-start;
  gap: 11px;
  width: 127px;
  display: flex;
  position: relative;
}

.file-name .text-and-supporting-wrapper {
  align-items: flex-start;
  gap: 12px;
  width: 128px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.file-name .text-and-supporting {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2px;
  display: flex;
  position: relative;
}

.file-name .frame-38 {
  flex: none;
  align-items: center;
  gap: 4px;
  margin-right: -8px;
  display: inline-flex;
  position: relative;
}

.file-name .text {
  color: var(--secondary-100);
  font-family: var(--paragraph-REG-font-family);
  font-size: var(--paragraph-REG-font-size);
  font-style: var(--paragraph-REG-font-style);
  font-weight: var(--paragraph-REG-font-weight);
  letter-spacing: var(--paragraph-REG-letter-spacing);
  line-height: var(--paragraph-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.file-name .frame-39 {
  background-color: var(--accent-50);
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
  position: relative;
}

.file-name .text-wrapper-15 {
  color: #f5f5f5;
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.file-name .star-2 {
  width: 16px;
  height: 16px;
  position: relative;
}

.file-name .frame-40 {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.file-name .element-4 {
  background-color: #ffeaea;
  border-radius: 24.75px;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}

.file-name .overlap-group-5 {
  border-radius: 24.75px;
  height: 24px;
  position: relative;
}

.file-name .img-7 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.file-name .img-wrapper {
  background-color: #f5f5f5;
  border-radius: 24.75px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.file-name .ellipse-10 {
  background-color: var(--secondary-100);
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: relative;
}

.file-name .supporting-text-2 {
  color: var(--secondary-100);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.file-name .icon-button {
  width: 32px;
  height: 32px;
  position: relative;
}

.all-files {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.all-files .class-13 {
  background-image: url("rectangle-5-12.98506b4d.png") !important;
}

.all-files .class-14 {
  background-image: url("rectangle-5-11.6aac348a.png") !important;
}

.all-files .class-15 {
  background-image: url("rectangle-5-8.8d2d2f6a.png") !important;
}

.all-files .class-16 {
  background-image: url("rectangle-5-5.714b6979.png") !important;
  box-shadow: 0 6px #008555 !important;
}

.all-files .class-17 {
  background-image: url("rectangle-5-4.e1e664fe.png") !important;
  box-shadow: 0 6px #008555 !important;
}

.all-files .class-18 {
  background-image: url("rectangle-5-3.e3a29818.png") !important;
  box-shadow: 0 6px #008555 !important;
}

.all-files .class-19 {
  background-image: url("rectangle-5-2.2b4bc237.png") !important;
  box-shadow: 0 6px #008555 !important;
}

.all-files .class-20 {
  margin-right: unset !important;
}

.all-files .file-name-instance {
  flex: none !important;
  align-self: stretch !important;
}

.all-files .class-21 {
  margin-top: -1px !important;
}

.all-files .file-name-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.all-files .frame-41 {
  background-position: 50%;
  background-size: cover;
  width: 340px;
  height: 200px;
  position: relative;
}

.all-files .frame-42 {
  flex-direction: column;
  align-items: flex-start;
  gap: 91px;
  width: 292px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 24px;
}

.all-files .frame-43 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.all-files .text-wrapper-16 {
  color: var(--secondary-100);
  font-family: var(--h6-MED-font-family);
  font-size: var(--h6-MED-font-size);
  font-style: var(--h6-MED-font-style);
  font-weight: var(--h6-MED-font-weight);
  letter-spacing: var(--h6-MED-letter-spacing);
  line-height: var(--h6-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.all-files .more-horiz {
  width: 32px;
  height: 32px;
  position: relative;
}

.all-files.property-1-variant-8, .all-files.property-1-variant-13 {
  box-shadow: 0 6px #008555;
}

.all-files.property-1-variant-8 .frame-41 {
  background-image: url("rectangle-5-7.564302aa.png");
}

.all-files.property-1-variant-10 .frame-41, .all-files.property-1-variant-11 .frame-41, .all-files.property-1-variant-4 .frame-41, .all-files.property-1-variant-9 .frame-41, .all-files.property-1-file-default .frame-41, .all-files.property-1-variant-3 .frame-41, .all-files.property-1-variant-5 .frame-41, .all-files.property-1-variant-6 .frame-41 {
  background-image: url("rectangle-5-10.4b9804e9.png");
}

.all-files.property-1-variant-13 .frame-41 {
  background-image: url("rectangle-5-6.48b2eaab.png");
}

.all-files.property-1-variant-12 .frame-41 {
  background-image: url("rectangle-5-9.64551672.png");
}

.all-files.property-1-variant-7 .frame-41, .all-files.property-1-default .frame-41 {
  background-image: url("rectangle-5-10.4b9804e9.png");
}

.endpoint .shape-triangle {
  width: 8px !important;
  height: 8px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.endpoint.shape-rectangle {
  background-color: #000;
  width: 8px;
  height: 8px;
}

.endpoint.shape-measure {
  width: 1px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.endpoint.circle {
  border-radius: 100px;
}

.midsection.type-equal-2 {
  position: absolute;
  top: 0;
  left: 0;
}

.midsection.type-number {
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  display: inline-flex;
  position: relative;
}

.midsection .number {
  color: var(--darkblack-100);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.midsection.type-0-dotted {
  object-fit: cover;
  width: 16px;
  height: 1px;
}

.midsection.type-0-number {
  width: 16px;
  height: 8px;
}

.midsection.type-0-cut {
  width: 16px;
  height: 9px;
}

.midsection.type-0-equal {
  width: 4px;
  height: 8px;
}

.midsection.type-0-squiggle {
  width: 36px;
  height: 4px;
}

.midsection.type-0-zigzag {
  width: 36px;
  height: 5px;
}

.line .class {
  position: relative !important;
}

.line .class-2 {
  position: relative !important;
  transform: rotate(90deg) !important;
}

.line .class-3 {
  height: unset !important;
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  margin-bottom: -.5px !important;
  position: relative !important;
}

.line .class-4 {
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  width: 100% !important;
  height: 1px !important;
  position: relative !important;
}

.line .class-5 {
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  width: 100% !important;
  height: 1px !important;
  margin-right: -.5px !important;
  position: relative !important;
}

.line .class-6 {
  height: unset !important;
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  position: relative !important;
}

.line .img {
  object-fit: cover;
  position: absolute;
}

.line .overlap-group-2 {
  position: relative;
}

.line .element-2 {
  object-fit: cover;
  position: absolute;
}

.line .endpoint-2-4 {
  width: 8px !important;
  height: 8px !important;
  position: absolute !important;
  top: 124px !important;
  left: 64px !important;
}

.line .endpoint-2-66 {
  width: 8px !important;
  height: 4px !important;
  position: absolute !important;
  top: 0 !important;
  left: 124px !important;
}

.line .class-7 {
  position: absolute !important;
  top: 60px !important;
  left: 0 !important;
}

.line .class-8 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transform: rotate(90deg) !important;
}

.line .img-2, .line .img-3, .line .img-4 {
  object-fit: cover;
  flex: 1;
  position: relative;
}

.line .endpoint-2-5 {
  width: 8px !important;
  height: 8px !important;
  position: relative !important;
}

.line.dotted, .line.cut, .line.numbers {
  align-items: center;
  display: flex;
  position: relative;
}

.line.arrow {
  align-items: center;
  position: relative;
}

.line.equal, .line.squiggle, .line.measure-gap, .line.measure {
  align-items: center;
  display: flex;
  position: relative;
}

.line.dashed, .line.solid {
  position: relative;
}

.line.zigzag {
  align-items: center;
  display: flex;
  position: relative;
}

.line.horizontal {
  width: 132px;
}

.line.vertical {
  height: 132px;
}

.line.measure-gap.horizontal {
  height: 16px;
}

.line.dotted.vertical {
  flex-direction: column;
  width: 16px;
}

.line.horizontal.measure {
  height: 16px;
}

.line.horizontal.arrow {
  display: flex;
}

.line.cut.horizontal, .line.horizontal.equal, .line.horizontal.numbers, .line.squiggle.horizontal {
  height: 16px;
}

.line.ninety.vertical {
  width: 68px;
}

.line.arrow.vertical {
  flex-direction: column;
  display: inline-flex;
}

.line.horizontal.zigzag {
  height: 16px;
}

.line.cut.vertical, .line.measure.vertical, .line.measure-gap.vertical {
  flex-direction: column;
  width: 16px;
}

.line.horizontal.dotted {
  height: 16px;
}

.line.zigzag.vertical, .line.numbers.vertical {
  flex-direction: column;
  width: 16px;
}

.line.ninety.horizontal {
  height: 68px;
}

.line.vertical.equal, .line.squiggle.vertical {
  flex-direction: column;
  width: 16px;
}

.line.vertical .img {
  width: 60px;
  height: 120px;
  top: 4px;
  left: 8px;
}

.line.horizontal .img {
  width: 120px;
  height: 60px;
  top: 0;
  left: 4px;
}

.line.vertical .overlap-group-2 {
  width: 72px;
  height: 132px;
}

.line.horizontal .overlap-group-2 {
  height: 68px;
}

.line.vertical .element-2 {
  width: 61px;
  height: 121px;
  top: 4px;
  left: 8px;
}

.line.horizontal .element-2 {
  width: 121px;
  height: 60px;
  top: 0;
  left: 4px;
}

.line.dotted .img-2, .line.cut .img-2, .line.ninety .img-2, .line.numbers .img-2, .line.arrow .img-2, .line.equal .img-2, .line.squiggle .img-2, .line.measure .img-2, .line.zigzag .img-2 {
  width: 1px;
  margin-top: -316px;
  margin-left: -5955px;
}

.line.horizontal .img-2 {
  width: 1px;
  height: 1px;
  margin-top: -316px;
  margin-left: -5955px;
}

.line.measure-gap.vertical .img-2 {
  width: 132px;
  margin-top: -192px;
  margin-left: -5947px;
}

.line.measure.dotted.vertical .img-2 {
  margin-bottom: -.5px;
}

.line.horizontal.dotted.measure .img-2 {
  margin-right: -.5px;
}

.line.vertical .img-3 {
  width: 1px;
}

.line.horizontal .img-3, .line.horizontal .img-4 {
  height: 1px;
}

.line.vertical .img-4 {
  width: 1px;
}

.line.horizontal.dotted .img-4 {
  margin-right: -.5px;
}

.line.dotted.vertical .img-4 {
  margin-bottom: -.5px;
}

.annotation-text {
  position: relative;
}

.annotation-text .note {
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  margin-top: -1px;
  position: relative;
}

.annotation-text .class-9 {
  height: unset !important;
  flex: 1 !important;
}

.annotation-text .class-10 {
  width: unset !important;
  flex: 1 !important;
  transform: rotate(180deg) !important;
}

.annotation-text .class-11 {
  transform: rotate(-180deg) !important;
}

.annotation-text .endpoint-2 {
  width: 8px !important;
  height: 8px !important;
  position: relative !important;
}

.annotation-text .endpoint-2-10 {
  width: 8px !important;
  height: 8px !important;
  position: relative !important;
  transform: rotate(-180deg) !important;
}

.annotation-text .note-wrapper {
  border-radius: 12px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 1.5px 12px 2.5px;
  display: inline-flex;
  position: relative;
}

.annotation-text .text-wrapper {
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.annotation-text .class-12 {
  width: unset !important;
  flex: 1 !important;
}

.annotation-text.direction-left {
  justify-content: flex-end;
  display: flex;
}

.annotation-text.large {
  border-radius: 6px;
  align-items: flex-start;
  gap: 10px;
  width: 272px;
  padding: 8px;
}

.annotation-text.direction-up {
  flex-direction: column;
  justify-content: flex-end;
  height: 156px;
  display: inline-flex;
}

.annotation-text.small {
  align-items: center;
}

.annotation-text.direction-right {
  display: flex;
}

.annotation-text.direction-down {
  flex-direction: column;
  height: 156px;
  display: inline-flex;
}

.annotation-text.direction-left.small {
  width: 226px;
}

.annotation-text.direction-default.large {
  display: flex;
}

.annotation-text.direction-default.light {
  background-color: var(--lightwhite-100);
}

.annotation-text.direction-right.small {
  width: 226px;
}

.annotation-text.dark.direction-default {
  background-color: var(--darkblack-100);
}

.annotation-text.direction-default.small {
  border-radius: 12px;
  gap: 8px;
  padding: 1.5px 12px 2.5px;
  display: inline-flex;
}

.annotation-text.light .note {
  color: var(--darkblack-100);
}

.annotation-text.large .note {
  flex: 1;
}

.annotation-text.dark .note {
  color: var(--lightwhite-100);
}

.annotation-text.small .note {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.annotation-text.light .note-wrapper {
  background-color: var(--lightwhite-100);
}

.annotation-text.dark .note-wrapper {
  background-color: var(--darkblack-100);
}

.annotation-text.light .text-wrapper {
  color: var(--darkblack-100);
}

.annotation-text.dark .text-wrapper {
  color: var(--lightwhite-100);
}

.button {
  border-radius: 3.93px;
  justify-content: center;
  align-items: center;
  gap: 6.56px;
  display: inline-flex;
  position: relative;
}

.button .div-2 {
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.button .instance-node-3 {
  width: 13.11px !important;
  height: 13.11px !important;
  position: relative !important;
}

.button .div-3 {
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.button.secondary {
  background-color: #f0f2f6;
}

.button.outline {
  border: .66px solid #4f78db;
}

.button.right.size-0-small {
  padding: 5.9px 9.18px 7.21px;
}

.button.left.size-0-large {
  padding: 9.18px 9.18px 9.18px 11.8px;
}

.button.none.size-0-small {
  padding: 7.21px 9.18px 7.87px;
}

.button.size-0-large.primary {
  background-color: #4f78db;
}

.button.icon-only.size-0-large {
  padding: 9.18px;
}

.button.right.size-0-large {
  padding: 9.18px 9.18px 9.18px 11.8px;
}

.button.left.size-0-small {
  padding: 5.9px 9.18px 7.21px;
}

.button.none.size-0-large {
  padding: 9.18px 9.18px 9.18px 11.8px;
}

.button.size-0-small.primary {
  background-color: #203159;
}

.button.size-0-small.icon-only {
  padding: 6.23px 6.56px 6.88px;
}

.button.size-0-large .div-2 {
  letter-spacing: 0;
  font-size: 10.5px;
}

.button.none .div-2 {
  margin-top: -.66px;
}

.button.secondary .div-2 {
  color: #1e202c;
}

.button.outline .div-2 {
  color: #4f78db;
  letter-spacing: 0;
}

.button.text .div-2 {
  color: #4f78db;
  letter-spacing: 0;
  font-size: 10.5px;
}

.button.primary .div-2 {
  color: var(--defaultwhite);
  text-align: center;
}

.button.text.right .div-2, .button.right.size-0-large .div-2 {
  margin-top: -.6px;
}

.button.outline.size-0-small .div-2 {
  white-space: nowrap;
  font-size: 9.2px;
}

.button.size-0-small.primary .div-2, .button.size-0-small.secondary .div-2 {
  letter-spacing: -.18px;
  white-space: nowrap;
  font-size: 9.2px;
}

.button.size-0-large .div-3 {
  letter-spacing: 0;
  margin-top: -.6px;
  font-size: 10.5px;
}

.button.secondary .div-3 {
  color: #1e202c;
}

.button.outline .div-3 {
  color: #4f78db;
  letter-spacing: 0;
}

.button.text .div-3 {
  color: #4f78db;
  letter-spacing: 0;
  margin-top: -.6px;
  font-size: 10.5px;
}

.button.primary .div-3 {
  color: var(--defaultwhite);
}

.button.size-0-small.primary .div-3, .button.size-0-small.secondary .div-3 {
  letter-spacing: -.18px;
  white-space: nowrap;
  font-size: 9.2px;
}

.button.outline.size-0-small .div-3 {
  white-space: nowrap;
  font-size: 9.2px;
}

.button-add-team {
  background-color: var(--secondary-50);
  border-radius: 6.67px;
  justify-content: center;
  align-items: center;
  gap: 3.33px;
  width: 168px;
  height: 36.68px;
  padding: 10px 26.68px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-add-team .text-wrapper-2 {
  color: #fff;
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.3px;
  font-weight: 700;
  line-height: 15px;
  position: relative;
}

.button-add-team-wrapper {
  background-color: #1f3058;
  border-radius: 6.67px;
  justify-content: center;
  align-items: center;
  gap: 3.33px;
  width: 168px;
  height: 36.68px;
  padding: 10px 26.68px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-add-team-wrapper .text-wrapper-3 {
  color: #fff;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 800;
  line-height: 15px;
  position: relative;
}

.button-wrapper {
  all: unset;
  background-color: var(--secondary-30);
  box-sizing: border-box;
  border: .5px solid #4b4b4b;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 87px;
  height: 40px;
  padding: 12px 20px 12px 12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-wrapper .frame-2 {
  flex: none;
  align-items: center;
  gap: 4px;
  margin: -4px -3.5px;
  display: inline-flex;
  position: relative;
}

.button-wrapper .add {
  width: 24px;
  height: 24px;
  position: relative;
}

.button-wrapper .new {
  color: #fff;
  letter-spacing: -.4px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.button-default {
  border-radius: 6.67px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button-default .button-instance {
  background-color: var(--secondary-50) !important;
  border: unset !important;
  border-color: unset !important;
  width: unset !important;
  border-radius: 6.67px !important;
  gap: 1.67px !important;
  height: 33.35px !important;
  padding: 10px 16.67px 10px 10px !important;
  display: inline-flex !important;
}

.button-default .design-component-instance-node {
  margin-bottom: -3.33px !important;
  margin-left: unset !important;
  margin-right: unset !important;
  gap: 3.33px !important;
  margin-top: -3.33px !important;
}

.button-default .button-2 {
  width: 20.01px !important;
  height: 20.01px !important;
}

.button-default .button-3 {
  letter-spacing: -.29px !important;
  font-size: 11.7px !important;
  font-weight: 700 !important;
}

.button-hover {
  border-radius: 3.33px;
  flex-direction: column;
  align-items: flex-start;
  height: 33px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button-hover .button-4 {
  background-color: #1f3058 !important;
  border: .42px solid !important;
  border-radius: 6.67px !important;
  gap: 1.67px !important;
  width: 75.03px !important;
  height: 33.35px !important;
  padding: 10px 16.67px 10px 10px !important;
}

.button-hover .button-5 {
  margin-bottom: -3.33px !important;
  margin-left: unset !important;
  margin-right: unset !important;
  gap: 3.33px !important;
  margin-top: -3.33px !important;
}

.button-hover .button-6 {
  width: 20.01px !important;
  height: 20.01px !important;
}

.button-hover .button-7 {
  letter-spacing: -.29px !important;
  font-size: 11.7px !important;
  font-weight: 700 !important;
}

.button-variant {
  background-color: var(--secondary-50);
  border-radius: 6.67px;
  justify-content: center;
  align-items: center;
  gap: 3.33px;
  width: 268px;
  height: 40.02px;
  padding: 10px 26.68px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-variant .text-wrapper-4 {
  color: #fff;
  letter-spacing: -.23px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 15px;
  font-weight: 800;
  line-height: 15px;
  position: relative;
}

.button-variant .arrow-back {
  width: 11.67px;
  height: 11.67px;
  margin-top: -4592.68px;
  margin-left: -6195.75px;
  position: relative;
}

.button-variant10 {
  background-color: #1f3058;
  border-radius: 3.33px;
  justify-content: center;
  align-items: center;
  gap: 8.34px;
  padding: 5px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button-variant10 .icon-1 {
  width: 16.67px !important;
  height: 16.67px !important;
  position: relative !important;
}

.button-variant11 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 25.01px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.67px;
  height: 37px;
  padding: 10px 16.67px 10px 10px;
  display: inline-flex;
  position: relative;
}

.button-variant11 .frame-3 {
  flex: none;
  align-items: center;
  gap: 3.33px;
  margin-top: -1.67px;
  margin-bottom: -1.67px;
  display: inline-flex;
  position: relative;
}

.button-variant11 .add-2 {
  width: 20.01px;
  height: 20.01px;
  position: relative;
}

.button-variant11 .text-wrapper-8 {
  color: #fff;
  letter-spacing: -.33px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.3px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.button-variant12 {
  all: unset;
  box-sizing: border-box;
  background-color: #1f3058;
  border-radius: 25.01px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.67px;
  height: 37px;
  padding: 10px 16.67px 10px 10px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button-variant12 .frame-4 {
  flex: none;
  align-items: center;
  gap: 3.33px;
  margin-top: -1.67px;
  margin-bottom: -1.67px;
  display: inline-flex;
  position: relative;
}

.button-variant12 .add-3 {
  width: 20.01px;
  height: 20.01px;
  position: relative;
}

.button-variant12 .text-wrapper-9 {
  color: #fff;
  letter-spacing: -.33px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.3px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.button-variant8 {
  all: unset;
  box-sizing: border-box;
  background-color: #1f3058;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 3.33px;
  height: 30px;
  padding: 5px 13.34px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button-variant8 .text-wrapper-7 {
  color: #fff;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.button-variant9 {
  background-color: var(--secondary-50);
  border-radius: 3.33px;
  justify-content: center;
  align-items: center;
  gap: 8.34px;
  padding: 5px;
  display: inline-flex;
  position: relative;
}

.button-variant9 .icon {
  width: 16.67px !important;
  height: 16.67px !important;
  position: relative !important;
}

.button-variant-wrapper {
  background-color: #1f3058;
  border-radius: 6.67px;
  justify-content: center;
  align-items: center;
  gap: 3.33px;
  width: 268px;
  height: 40.02px;
  padding: 10px 26.68px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-variant-wrapper .text-wrapper-5 {
  color: #fff;
  letter-spacing: -.23px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 15px;
  font-weight: 800;
  line-height: 15px;
  position: relative;
}

.button-variant-wrapper .arrow-back-2 {
  width: 11.67px;
  height: 11.67px;
  margin-top: -4654.37px;
  margin-left: -6195.75px;
  position: relative;
}

.card-design {
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 91px;
  width: 348px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card-design .folder-card {
  background: linear-gradient(#000000e6 0%, #20315900 100%) 50% / cover;
  border-radius: 8px;
  align-self: stretch;
  width: 100%;
  height: 200px;
  position: relative;
}

.card-design .frame-8 {
  justify-content: space-between;
  align-items: flex-start;
  width: 348px;
  padding: 0 16px;
  display: flex;
  position: absolute;
  top: 24px;
  left: 0;
}

.card-design .frame-9 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.card-design .frame-10 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.card-design .frame-wrapper {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.card-design .frame-11 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.card-design .div-4 {
  color: var(--secondary-100);
  font-family: var(--subhead-MED-font-family);
  font-size: var(--subhead-MED-font-size);
  font-style: var(--subhead-MED-font-style);
  font-weight: var(--subhead-MED-font-weight);
  letter-spacing: var(--subhead-MED-letter-spacing);
  line-height: var(--subhead-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .frame-12 {
  background-color: var(--tertiary-20);
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
  position: relative;
}

.card-design .UNREAD-ITEMS {
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .frame-13 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.card-design .element-3 {
  background-color: #ffeaea;
  border-radius: 24.75px;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}

.card-design .overlap-group-4 {
  border-radius: 24.75px;
  height: 24px;
  position: relative;
}

.card-design .img-5 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.card-design .group-wrapper {
  background-color: #f5f5f5;
  border-radius: 24.75px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.card-design .ellipse-9 {
  background-color: #f1f1f1;
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: relative;
}

.card-design .subheading-wrapper {
  border-radius: 4px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.card-design .subheading {
  color: #047857;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .supporting-text {
  color: var(--x01-neutral100);
  font-family: var(--tiny-REG-font-family);
  font-size: var(--tiny-REG-font-size);
  font-style: var(--tiny-REG-font-style);
  font-weight: var(--tiny-REG-font-weight);
  letter-spacing: var(--tiny-REG-letter-spacing);
  line-height: var(--tiny-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.card-design .img-6 {
  width: 32px;
  height: 16px;
  position: relative;
}

.card-design .folder-card-2 {
  background: linear-gradient(#000000e6 16.2%, #01010100 100%) 50% / cover;
  border-radius: 8px;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.card-design .line-2 {
  width: 348px;
  height: 4px;
  position: absolute;
  top: 196px;
  left: 0;
}

.card-design .line-3 {
  width: 134px;
  height: 4px;
  position: absolute;
  top: 196px;
  left: 0;
}

.card-design .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 16px;
  left: 16px;
}

.card-design .frame-15 {
  flex: none;
  align-items: flex-start;
  gap: 4px;
  width: 308px;
  display: flex;
  position: relative;
}

.card-design .frame-16 {
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.card-design .frame-17 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.card-design .frame-18 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.card-design .frame-19 {
  flex: 1;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.card-design .p {
  color: var(--secondary-100);
  font-family: var(--subhead-MED-font-family);
  font-size: var(--subhead-MED-font-size);
  font-style: var(--subhead-MED-font-style);
  font-weight: var(--subhead-MED-font-weight);
  letter-spacing: var(--subhead-MED-letter-spacing);
  line-height: var(--subhead-MED-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.card-design .frame-20 {
  width: 24px;
  height: 24px;
  position: relative;
}

.card-design .frame-21 {
  width: 26px;
  height: 24px;
  margin-right: -1px;
  position: relative;
}

.card-design .frame-22 {
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.card-design .text-wrapper-13 {
  color: var(--x01-neutral90);
  font-family: var(--caption-REG-font-family);
  font-size: var(--caption-REG-font-size);
  font-style: var(--caption-REG-font-style);
  font-weight: var(--caption-REG-font-weight);
  letter-spacing: var(--caption-REG-letter-spacing);
  line-height: var(--caption-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .frame-23 {
  background-color: var(--secondary-98);
  border-radius: 17px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  display: inline-flex;
  position: relative;
}

.card-design .subheading-2 {
  color: var(--secondary-50);
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .speakers {
  position: absolute;
  top: 132px;
  left: 16px;
}

.card-design .speakers-2 {
  position: relative;
}

.card-design .div-5 {
  color: var(--x01-neutral100);
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .frame-24 {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.card-design .chip-speaker {
  border-radius: 20px;
  flex: none;
  align-items: center;
  gap: 4px;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.card-design .frame-25 {
  background-size: 100% 100%;
  width: 28px;
  height: 28px;
  position: relative;
}

.card-design .name {
  color: var(--secondary-100);
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.card-design .name-wrapper {
  background-color: var(--x01-neutral30);
  border-radius: 20px;
  flex: none;
  align-items: center;
  gap: 4px;
  padding: 4px 8px 4px 4px;
  display: inline-flex;
  position: relative;
}

.card-design .name-2 {
  color: var(--x01-neutral98);
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .frame-26 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.card-design .frame-27 {
  background-image: url("ellipse-1-19.7e44dcbf.svg");
  background-size: 100% 100%;
  width: 28px;
  height: 28px;
  position: relative;
}

.card-design .chip-speaker-2 {
  border-radius: 20px;
  flex: none;
  align-items: center;
  gap: 4px;
  padding: 4px 8px 4px 4px;
  display: inline-flex;
  position: relative;
}

.card-design .frame-28 {
  background-image: url("ellipse-1-14.58f1d663.png");
  background-position: 50%;
  background-size: cover;
  width: 28px;
  height: 28px;
  position: relative;
}

.card-design .register {
  color: var(--x01-neutral100);
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .arrow-forward {
  width: 16px;
  height: 16px;
  position: relative;
}

.card-design .folder-card-3 {
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 200px;
  padding: 16px 20px;
  display: flex;
  position: relative;
}

.card-design .frame-29 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.card-design .speakers-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  position: relative;
}

.card-design .frame-30 {
  background-image: url("ellipse-1-15.5be30b70.svg");
  background-size: 100% 100%;
  width: 28px;
  height: 28px;
  position: relative;
}

.card-design .frame-31 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.card-design .frame-32 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.card-design .text-wrapper-14 {
  color: #fff;
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-design .star-26 {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.card-design .frame-33 {
  background-color: #d7fdf2;
  border-radius: 4px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.card-design.event-02, .card-design.event {
  justify-content: space-around;
  height: 200px;
}

.card-design.state-0-hover.folder {
  box-shadow: 4px 4px 4px #00000040;
}

.card-design.state-0-default.file, .card-design.state-0-default.folder {
  justify-content: space-around;
  height: 200px;
}

.card-design.course .folder-card, .card-design.event-02 .folder-card {
  background-image: url("folder-card-13.0da3e4a4.png");
}

.card-design.file .folder-card {
  background-image: url("folder-card-12.86a21711.png");
}

.card-design.event .folder-card, .card-design.folder .folder-card {
  background-image: url("folder-card-13.0da3e4a4.png");
}

.card-design.course .frame-11, .card-design.event-02 .frame-11 {
  gap: 8px;
}

.card-design.file .frame-11 {
  gap: 12px;
}

.card-design.event .frame-11, .card-design.folder .frame-11 {
  gap: 8px;
}

.card-design.course .UNREAD-ITEMS, .card-design.event-02 .UNREAD-ITEMS {
  color: #f5f5f5;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
}

.card-design.file .UNREAD-ITEMS {
  color: #fff;
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
}

.card-design.event .UNREAD-ITEMS, .card-design.folder .UNREAD-ITEMS {
  color: #f5f5f5;
  font-family: var(--tiny-BLD-font-family);
  font-size: var(--tiny-BLD-font-size);
  font-style: var(--tiny-BLD-font-style);
  font-weight: var(--tiny-BLD-font-weight);
  letter-spacing: var(--tiny-BLD-letter-spacing);
  line-height: var(--tiny-BLD-line-height);
}

.card-design.course .frame-13, .card-design.event-02 .frame-13 {
  gap: 4px;
}

.card-design.file .frame-13 {
  gap: 8px;
}

.card-design.event .frame-13, .card-design.folder .frame-13 {
  gap: 4px;
}

.card-design.course .subheading-wrapper, .card-design.event-02 .subheading-wrapper, .card-design.file .subheading-wrapper, .card-design.event .subheading-wrapper {
  background-color: var(--accent-99);
}

.card-design.folder .subheading-wrapper {
  background-color: #d7fdf2;
}

.card-design.course .folder-card-2 {
  background-image: url("folder-card-9.0fb0b6c8.png");
  flex: none;
}

.card-design.event-02 .folder-card-2 {
  background-image: url("folder-card-10.48e821bb.png");
  height: 200px;
}

.card-design.file .folder-card-2 {
  background-image: url("folder-card-10.48e821bb.png");
}

.card-design.event .folder-card-2 {
  background-image: url("folder-card-10.48e821bb.png");
  height: 200px;
}

.card-design.folder .folder-card-2 {
  background-image: url("folder-card-10.48e821bb.png");
}

.card-design.course .speakers, .card-design.event-02 .speakers {
  justify-content: space-between;
  align-items: center;
  width: 308px;
  display: flex;
}

.card-design.file .speakers {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card-design.event .speakers {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.card-design.folder .speakers {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card-design.course .speakers-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.card-design.event-02 .speakers-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.card-design.event .speakers-2 {
  color: var(--x01-neutral100);
  font-family: var(--tiny-MED-font-family);
  font-size: var(--tiny-MED-font-size);
  font-style: var(--tiny-MED-font-style);
  font-weight: var(--tiny-MED-font-weight);
  letter-spacing: var(--tiny-MED-letter-spacing);
  line-height: var(--tiny-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
}

.card-design.course .frame-25 {
  background-image: url("ellipse-1-16.13afd75c.svg");
}

.card-design.event-02 .frame-25 {
  background-image: url("ellipse-1-17.a5325a86.svg");
}

.card-design.file .frame-25, .card-design.event .frame-25, .card-design.folder .frame-25 {
  background-image: url("ellipse-1-16.13afd75c.svg");
}

.card-design.course .frame-26, .card-design.event-02 .frame-26 {
  background-color: var(--secondary-50);
  border-radius: 4px;
  gap: 2px;
  padding: 12px;
}

.card-design.file .frame-26 {
  gap: 2px;
}

.card-design.event .frame-26 {
  gap: 4px;
}

.card-design.folder .frame-26 {
  gap: 2px;
}

.card-design.course .folder-card-3, .card-design.event-02 .folder-card-3 {
  background: url("folder-card-8.1efbbbf5.png");
}

.card-design.file .folder-card-3 {
  background: url("folder-card-6.69d20a59.png");
  gap: 10px;
}

.card-design.event .folder-card-3 {
  background: url("folder-card-8.1efbbbf5.png");
  border-radius: 8px;
  justify-content: space-between;
}

.card-design.folder .folder-card-3 {
  background: url("folder-card-7.72e4152b.png");
  gap: 10px;
}

.card-design.course .frame-32, .card-design.event-02 .frame-32 {
  gap: 8px;
}

.card-design.file .frame-32 {
  gap: 12px;
}

.card-design.event .frame-32, .card-design.folder .frame-32 {
  gap: 8px;
}

.instance-node {
  width: 80px !important;
  height: 80px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.instance-node-4 {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.day {
  background-color: var(--shadeswhite);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  position: relative;
}

.day .element {
  color: var(--shadesblack);
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.div-wrapper {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 3.33px;
  height: 30px;
  padding: 5px 13.34px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .text-wrapper-6 {
  color: #fff;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.icons .instance-node-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.icons {
  width: 24px;
  height: 24px;
  position: relative;
}

.icons .union {
  width: 16px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 4px;
}

.icons .group {
  width: 15px;
  height: 15px;
  position: relative;
  top: 4px;
  left: 4px;
}

.icons .overlap-group {
  width: 17px;
  height: 17px;
  position: relative;
  top: -1px;
  left: -1px;
}

.icons .ellipse {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  left: 5px;
}

.icons .div {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 5px;
  left: 0;
}

.icons .ellipse-2 {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 5px;
  left: 10px;
}

.icons .ellipse-3 {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 10px;
  left: 5px;
}

.nav-link {
  align-items: center;
  gap: 4px;
  position: relative;
}

.nav-link .dashboard {
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.nav-link .icons-49 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.nav-link .icons-52 {
  width: 32px !important;
  height: 32px !important;
  position: relative !important;
}

.nav-link.default {
  width: 250px;
  padding: 16px 40px;
  display: flex;
}

.nav-link.collapsed {
  border-radius: 8px;
  justify-content: center;
  padding: 16px;
  display: inline-flex;
  overflow: hidden;
}

.nav-link.collapsed.selected {
  background-color: var(--primary-50);
}

.nav-link.hover.collapsed {
  background-color: var(--primary-95);
}

.nav-link.hover.default {
  background-color: var(--primary-98);
}

.nav-link.default.selected {
  background-color: var(--primary-60);
}

.nav-link.selected .dashboard {
  color: var(--x01-neutral20);
}

.nav-link.default .dashboard {
  color: var(--x01-neutral30);
}

.nav-link.hover .dashboard {
  color: var(--x01-neutral40);
}

.menu {
  background-color: var(--x02-neutral95);
  flex-direction: column;
  align-items: flex-start;
  height: 765px;
  position: relative;
}

.menu .frame-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.menu .nav-link-instance {
  flex: none !important;
  gap: 3.24px !important;
  width: 202.7px !important;
  padding: 12.97px 32.43px !important;
}

.menu .nav-link-2 {
  font-family: Inter, Helvetica !important;
  font-size: 13px !important;
  font-style: unset !important;
  letter-spacing: -.03px !important;
  font-weight: 500 !important;
  line-height: 15.6px !important;
}

.menu .nav-link-3 {
  flex: none;
  align-items: center;
  gap: 3.24px;
  width: 202.7px;
  padding: 12.97px 32.43px;
  display: flex;
  position: relative;
}

.menu .icons-instance {
  width: 19.46px !important;
  height: 19.46px !important;
}

.menu .icons-2 {
  width: 13px !important;
  height: 16px !important;
  left: 3px !important;
}

.menu .dashboard-2 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  position: relative;
}

.menu .icons-3 {
  width: 12px !important;
  height: 12px !important;
}

.menu .icons-4 {
  width: 14px !important;
  height: 14px !important;
}

.menu .icons-5 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  width: 5px !important;
  height: 5px !important;
  left: 4px !important;
}

.menu .icons-6 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
}

.menu .icons-7 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
  left: 9px !important;
}

.menu .icons-8 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  width: 5px !important;
  height: 5px !important;
  top: 9px !important;
  left: 4px !important;
}

.menu .frame-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 19.46px;
  display: inline-flex;
  position: relative;
}

.menu .nav-link-4 {
  flex: none !important;
  gap: 3.24px !important;
  width: 200.27px !important;
  padding: 12.97px 32.43px !important;
  overflow: hidden !important;
}

.menu .extra {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.43px;
  width: 100%;
  padding: 3.24px 32.43px;
  display: flex;
  position: relative;
}

.menu .text-wrapper-10 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.81px;
  font-family: Inter, Helvetica;
  font-size: 9.7px;
  font-weight: 400;
  line-height: 11.7px;
  position: relative;
}

.menu .interpunct {
  width: 6.49px;
  height: 6.49px;
  position: relative;
}

.menu .ellipse-8 {
  background-color: #d9d9d9;
  border-radius: 1.62px;
  width: 3px;
  height: 3px;
  position: relative;
  top: 2px;
  left: 2px;
}

.menu .frame-7 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.menu .nav-link-5 {
  border-radius: 6.49px !important;
  flex: none !important;
  gap: 3.24px !important;
  padding: 12.97px !important;
}

.menu .icons-wrapper {
  background-color: var(--primary-50);
  border-radius: 6.49px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.24px;
  padding: 12.97px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.menu .icons-9 {
  width: 25.95px !important;
  height: 25.95px !important;
}

.menu .icons-10 {
  width: 17px !important;
  height: 22px !important;
}

.menu .icons-instance-wrapper {
  border-radius: 6.49px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.24px;
  padding: 12.97px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.menu .icons-11 {
  width: 16px !important;
  height: 16px !important;
  top: 5px !important;
  left: 5px !important;
}

.menu .icons-12 {
  width: 18px !important;
  height: 18px !important;
}

.menu .icons-13 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  left: 6px !important;
}

.menu .icons-14 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  top: 6px !important;
}

.menu .icons-15 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  top: 6px !important;
  left: 11px !important;
}

.menu .icons-16 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  top: 11px !important;
  left: 6px !important;
}

.menu .chevron-down {
  width: 26px;
  position: absolute;
}

.menu .icon-instance-node {
  width: 19.46px !important;
  height: 19.46px !important;
  position: relative !important;
}

.menu .icons-17 {
  width: 25.95px !important;
  height: 25.95px !important;
  position: relative !important;
}

.menu.property-1-default {
  justify-content: space-between;
  width: 203px;
  padding: 9.73px 0;
  display: flex;
}

.menu.property-1-collapsed {
  gap: 25.95px;
  padding: 9.73px 19.46px 9.73px 32.43px;
  display: inline-flex;
}

.menu.property-1-default .chevron-down {
  height: 25px;
  top: 371px;
  left: 190px;
}

.menu.property-1-collapsed .chevron-down {
  height: 26px;
  top: 370px;
  left: 91px;
}

.namecandidate-name-menu-score-1000-1 {
  background-color: var(--x02-neutral95);
  flex-direction: column;
  align-items: flex-start;
  height: 944px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .frame-44 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .nav-link-6 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 16px 40px;
  display: flex;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .dashboard-3 {
  color: var(--x01-neutral30);
  font-family: var(--paragraph-MED-font-family);
  font-size: var(--paragraph-MED-font-size);
  font-style: var(--paragraph-MED-font-style);
  font-weight: var(--paragraph-MED-font-weight);
  letter-spacing: var(--paragraph-MED-letter-spacing);
  line-height: var(--paragraph-MED-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .frame-45 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .extra-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3px;
  width: 100%;
  padding: 4px 40px;
  display: flex;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .text-wrapper-17 {
  color: #000;
  font-family: var(--tiny-REG-font-family);
  font-size: var(--tiny-REG-font-size);
  font-style: var(--tiny-REG-font-style);
  font-weight: var(--tiny-REG-font-weight);
  letter-spacing: var(--tiny-REG-letter-spacing);
  line-height: var(--tiny-REG-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .ellipse-wrapper {
  width: 8px;
  height: 8px;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .ellipse-11 {
  background-color: #d9d9d9;
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 2px;
  left: 2px;
}

.namecandidate-name-menu-score-1000-1 .frame-46 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.namecandidate-name-menu-score-1000-1 .icons-18 {
  width: 32px !important;
  height: 32px !important;
  position: relative !important;
}

.namecandidate-name-menu-score-1000-1 .nav-link-7 {
  background-color: var(--primary-50);
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.namecandidate-name-menu-score-1000-1 .icons-19 {
  width: 32px !important;
  height: 32px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-20 {
  width: 21px !important;
  height: 27px !important;
  top: 3px !important;
  left: 5px !important;
}

.namecandidate-name-menu-score-1000-1 .nav-link-8 {
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.namecandidate-name-menu-score-1000-1 .icons-21 {
  width: 20px !important;
  height: 20px !important;
  top: 6px !important;
  left: 6px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-22 {
  width: 22px !important;
  height: 22px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-23 {
  border-radius: 4px !important;
  width: 8px !important;
  height: 8px !important;
  left: 7px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-24 {
  border-radius: 4px !important;
  width: 8px !important;
  height: 8px !important;
  top: 7px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-25 {
  border-radius: 4px !important;
  width: 8px !important;
  height: 8px !important;
  top: 7px !important;
  left: 14px !important;
}

.namecandidate-name-menu-score-1000-1 .icons-26 {
  border-radius: 4px !important;
  width: 8px !important;
  height: 8px !important;
  top: 14px !important;
  left: 7px !important;
}

.namecandidate-name-menu-score-1000-1 .nav-link-9 {
  flex: none !important;
}

.namecandidate-name-menu-score-1000-1 .chevron-down-2 {
  width: 32px;
  position: absolute;
}

.namecandidate-name-menu-score-1000-1 .icons-27 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.namecandidate-name-menu-score-1000-1.property-1-3-default {
  justify-content: space-between;
  width: 250px;
  padding: 12px 0;
  display: flex;
}

.namecandidate-name-menu-score-1000-1.property-1-3-collapsed {
  gap: 32px;
  padding: 12px 24px 12px 40px;
  display: inline-flex;
}

.namecandidate-name-menu-score-1000-1.property-1-3-default .chevron-down-2 {
  height: 31px;
  top: 457px;
  left: 234px;
}

.namecandidate-name-menu-score-1000-1.property-1-3-collapsed .chevron-down-2 {
  height: 32px;
  top: 456px;
  left: 112px;
}

.property-default-wrapper, .property-default-wrapper .add-team {
  position: relative;
}

.property-default-wrapper .add-4 {
  width: 24px;
  height: 24px;
  position: relative;
}

.property-default-wrapper .text-wrapper-11 {
  color: #fff;
  letter-spacing: -.4px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.property-default-wrapper .text-wrapper-12 {
  color: #fff;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  position: relative;
}

.property-default-wrapper .arrow-back-3 {
  width: 14px;
  height: 14px;
  position: relative;
}

.property-default-wrapper .icon-14 {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.property-default-wrapper.property-1-1-variant-8 {
  background-color: var(--secondary-20);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 40px;
  padding: 6px 16px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-variant-10 {
  background-color: var(--secondary-20);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-add-team-default {
  background-color: var(--secondary-50);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 202px;
  height: 40px;
  padding: 12px 32px;
  display: flex;
  overflow: hidden;
}

.property-default-wrapper.property-1-1-variant-11 {
  background-color: var(--secondary-50);
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 12px 20px 12px 12px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-variant-9 {
  background-color: var(--secondary-50);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-variant-5 {
  background-color: var(--secondary-50);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 280px;
  height: 40px;
  padding: 12px 32px;
  display: flex;
  overflow: hidden;
}

.property-default-wrapper.property-1-1-variant-6 {
  background-color: var(--secondary-20);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 280px;
  height: 40px;
  padding: 12px 32px;
  display: flex;
  overflow: hidden;
}

.property-default-wrapper.property-1-1-variant-12 {
  background-color: var(--secondary-20);
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 12px 20px 12px 12px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-variant-7 {
  background-color: var(--secondary-50);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 40px;
  padding: 6px 16px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-hover, .property-default-wrapper.property-1-1-default {
  flex-direction: column;
  align-items: flex-start;
  height: 40px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-add-team-default-hover {
  background-color: var(--secondary-20);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 202px;
  height: 40px;
  padding: 12px 32px;
  display: flex;
  overflow: hidden;
}

.property-default-wrapper.property-1-1-variant-8 .add-team {
  color: #fff;
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.property-default-wrapper.property-1-1-add-team-default .add-team {
  color: #fff;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
}

.property-default-wrapper.property-1-1-variant-11 .add-team, .property-default-wrapper.property-1-1-variant-12 .add-team {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
}

.property-default-wrapper.property-1-1-variant-7 .add-team {
  color: #fff;
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.property-default-wrapper.property-1-1-add-team-default-hover .add-team {
  color: #fff;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
}

.radio {
  border-radius: 8px;
  width: 16px;
  height: 16px;
}

.radio .ellipse-4 {
  border: 3px solid;
  border-color: var(--defaultwhite);
  border-radius: 7px;
  width: 14px;
  height: 14px;
  position: relative;
  top: 1px;
  left: 1px;
}

.radio.checked-false {
  background-color: #8b91ab;
}

.radio.checked-true {
  background-color: #1746ec;
}

.radio.checked-false .ellipse-4 {
  background-color: var(--defaultwhite);
}

.radio.checked-true .ellipse-4 {
  background-color: var(--defaultprimary-light);
}

.radio-false {
  background-color: #8b91ab;
  border-radius: 100px;
  width: 42px;
  height: 22px;
}

.radio-false .ellipse-6 {
  background-color: var(--defaultwhite);
  border-radius: 8.08px / 7.86px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
  left: 3px;
}

.radio-no {
  background-color: #fff;
  border: 1px solid #8b91ab;
  border-radius: 4px;
  width: 16px;
  height: 16px;
}

.radio-true {
  background-color: #1746ec;
  border-radius: 100px;
  width: 42px;
  height: 22px;
}

.radio-true .ellipse-5 {
  background-color: var(--defaultwhite);
  border-radius: 8.08px / 7.86px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
  left: 23px;
}

.slider-false {
  width: 152px;
  height: 20px;
}

.slider-false .overlap {
  height: 20px;
  position: relative;
}

.slider-false .rectangle {
  background-color: var(--neutral-30);
  border-radius: 100px;
  width: 152px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.slider-false .overlap-group-wrapper {
  width: 84px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-false .overlap-group-3 {
  width: 80px;
  height: 16px;
  position: relative;
  top: 3px;
}

.slider-false .rectangle-2 {
  background-color: var(--defaultprimary-light);
  border-radius: 100px;
  width: 74px;
  height: 4px;
  position: absolute;
  top: 6px;
  left: 0;
}

.slider-false .ellipse-7 {
  background-color: var(--defaultwhite);
  border: 1px solid;
  border-color: var(--neutral-30);
  box-shadow: var(--box-shadows-x-small);
  border-radius: 8px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 64px;
}

.spacer {
  background-color: #fb68ff4c;
}

.spacer.sixty-four {
  width: 62px;
  height: 62px;
}

.spacer.ninety-six {
  width: 93px;
  height: 93px;
}

.spacer.thirty-two {
  width: 31px;
  height: 31px;
}

.spacer.eight {
  width: 8px;
  height: 8px;
}

.spacer.forty {
  width: 39px;
  height: 39px;
}

.spacer.seventy-two {
  width: 70px;
  height: 70px;
}

.spacer.eighty {
  width: 77px;
  height: 77px;
}

.spacer.fifty-six {
  width: 54px;
  height: 54px;
}

.spacer.sixteen {
  width: 15px;
  height: 15px;
}

.spacer.forty-eight {
  width: 46px;
  height: 46px;
}

.spacer.twenty-four {
  width: 23px;
  height: 23px;
}

.spacer.eighty-eight {
  width: 85px;
  height: 85px;
}

.frame {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.frame .div-6 {
  background-color: #fff;
  width: 1912px;
  height: 6700px;
  position: relative;
}

.frame .overlap-2 {
  width: 1920px;
  height: 1440px;
  position: absolute;
  top: 0;
  left: -6px;
}

.frame .background {
  background-color: #f9f9f9;
  width: 1920px;
  height: 1440px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame .overlap-3 {
  width: 2119px;
  height: 1722px;
  position: relative;
}

.frame .components {
  width: 1754px;
  height: 1270px;
  position: absolute;
  top: 452px;
  left: 365px;
  overflow: hidden;
}

.frame .overlap-4 {
  width: 1562px;
  height: 1184px;
  position: relative;
}

.frame .line-4 {
  width: 832px;
  height: 801px;
  position: absolute;
  top: 187px;
  left: 433px;
}

.frame .line-5 {
  width: 765px;
  height: 767px;
  position: absolute;
  top: 221px;
  left: 788px;
}

.frame .line-6 {
  width: 512px;
  height: 664px;
  position: absolute;
  top: 324px;
  left: 1041px;
}

.frame .line-7 {
  width: 1071px;
  height: 313px;
  position: absolute;
  top: 391px;
  left: 482px;
}

.frame .line-8 {
  width: 836px;
  height: 229px;
  position: absolute;
  top: 736px;
  left: 717px;
}

.frame .line-9 {
  width: 1200px;
  height: 308px;
  position: absolute;
  top: 680px;
  left: 0;
}

.frame .card-design-option {
  border: .57px solid #f8f8f88f;
  border-radius: 4.59px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 52.21px;
  width: 208px;
  height: 115px;
  display: flex;
  position: absolute;
  top: 411px;
  left: 940px;
  overflow: hidden;
  transform: rotate(15.75deg);
}

.frame .folder-card-4 {
  background: url("folder-card-5.75e73457.png") 50% / cover;
  border-radius: 4.59px;
  align-self: stretch;
  width: 100%;
  height: 114.74px;
  position: relative;
}

.frame .div-7 {
  justify-content: space-between;
  align-items: flex-start;
  width: 200px;
  padding: 0 9.18px;
  display: flex;
  position: absolute;
  top: 14px;
  left: 0;
}

.frame .div-8 {
  flex: none;
  align-items: center;
  gap: 4.59px;
  display: inline-flex;
  position: relative;
}

.frame .div-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.88px;
  display: inline-flex;
  position: relative;
}

.frame .frame-wrapper-2 {
  flex: none;
  align-items: center;
  gap: 9.18px;
  display: inline-flex;
  position: relative;
}

.frame .div-10 {
  flex: none;
  align-items: center;
  gap: 6.88px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-18 {
  color: var(--secondary-100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  line-height: 12.4px;
  position: relative;
}

.frame .star-3 {
  width: 11.04px;
  height: 14.59px;
  margin-top: -1.29px;
  margin-bottom: -1.29px;
  position: relative;
  transform: rotate(-15.75deg);
}

.frame .element-5 {
  background-color: #ffeaea;
  border-radius: 14.2px;
  width: 13.77px;
  height: 13.77px;
  position: relative;
  overflow: hidden;
}

.frame .overlap-group-6 {
  border-radius: 14.2px;
  width: 14px;
  height: 14px;
  position: relative;
}

.frame .vector {
  width: 7px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: 4px;
  transform: rotate(-15.75deg);
}

.frame .element-6 {
  background-color: #f5f5f5;
  border-radius: 14.2px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame .group-2 {
  width: 7px;
  height: 9px;
  position: absolute;
  top: 3px;
  left: 4px;
  transform: rotate(-15.75deg);
}

.frame .ellipse-12 {
  background-color: #f1f1f1;
  border-radius: 1.15px;
  width: 2.29px;
  height: 2.29px;
  position: relative;
}

.frame .subheading-wrapper-2 {
  background-color: var(--accent-99);
  border-radius: 2.29px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.74px;
  padding: 1.15px 2.29px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .subheading-3 {
  color: #047857;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 700;
  line-height: 8.3px;
  position: relative;
}

.frame .supporting-text-3 {
  color: var(--x01-neutral100);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.9px;
  font-weight: 400;
  line-height: 8.3px;
  position: relative;
}

.frame .more-horiz-2 {
  width: 17.67px;
  height: 14.16px;
  margin-top: -2.05px;
  position: relative;
  transform: rotate(-15.75deg);
}

.frame .file-details {
  background-color: #d3d3d33d;
  border: .65px solid #f8f8f88f;
  border-radius: 5.19px;
  width: 258px;
  height: 271px;
  position: absolute;
  top: 592px;
  left: 1047px;
  transform: rotate(15deg);
}

.frame .file-details-2 {
  background-color: #fff;
  border: .65px solid #f8f8f8;
  border-radius: 5.19px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15.56px;
  width: 250px;
  height: 271px;
  padding: 17.82px 20.75px 20.75px;
  display: flex;
  position: absolute;
  top: 580px;
  left: 1051px;
  overflow: hidden;
  transform: rotate(15deg);
  box-shadow: 0 14.11px 3.89px -1.3px #10182808, 0 5.94px 10.38px -2.59px #10182814;
}

.frame .div-11 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-12 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10.38px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .img-8 {
  align-self: stretch;
  width: 100%;
  height: 125.42px;
  margin-top: -7.75px;
  margin-left: -15.9px;
  margin-right: -48.54px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .div-13 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.19px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .details-basic-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.78px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .details-basic {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.59px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-19 {
  color: #000;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  position: relative;
}

.frame .div-14 {
  flex: none;
  align-items: center;
  gap: 5.19px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-20 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 10.9px;
  position: relative;
}

.frame .ellipse-13 {
  background-color: var(--x01-neutral70);
  border-radius: 1.3px;
  width: 2.59px;
  height: 2.59px;
  position: relative;
}

.frame .label {
  background-color: var(--x01-neutral98);
  border-radius: 20.75px;
  flex: none;
  align-items: center;
  gap: 2.59px;
  padding: 2.59px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .file-type {
  width: 17.36px;
  height: 10.02px;
  margin-left: -3.49px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .text-2 {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 7.8px;
  font-weight: 600;
  line-height: 9.3px;
  position: relative;
}

.frame .text-wrapper-21 {
  color: var(--x01-neutral10);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 10.9px;
  position: relative;
}

.frame .div-15 {
  flex: none;
  align-items: center;
  gap: 2.59px;
  display: inline-flex;
  position: relative;
}

.frame .div-16 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.59px;
  display: inline-flex;
  position: relative;
}

.frame .avatar-w-photo {
  object-fit: cover;
  width: 26.04px;
  height: 15.03px;
  margin-left: -5.24px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .div-17 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-22 {
  color: var(--x01-neutral60);
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.8px;
  font-weight: 700;
  line-height: 9.3px;
  position: relative;
}

.frame .frame-wrapper-3 {
  flex: none;
  align-items: center;
  gap: .65px;
  display: inline-flex;
  position: relative;
}

.frame .div-18 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.frame .outside-stroke-wrapper {
  background-image: url("avatar-w-photo-9.f07952d9.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 647.83px;
  width: 23.35px;
  height: 23.35px;
  position: relative;
}

.frame .outside-stroke {
  border: 1.3px solid;
  border-color: var(--x02-neutral95);
  border-radius: 12.97px;
  width: 26px;
  height: 26px;
  position: relative;
  top: -1px;
  left: -1px;
}

.frame .avatar-w-photo-2 {
  background-image: url("avatar-w-photo-8.59295b28.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 647.83px;
  width: 23.35px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
}

.frame .avatar-w-photo-3 {
  background-image: url("avatar-w-photo-7.c204427c.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 647.83px;
  width: 23.35px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
}

.frame .avatar-w-photo-4 {
  background-image: url("avatar-w-photo-6.c97505c9.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 647.83px;
  width: 23.35px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
}

.frame .avatar-w-photo-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 5.19px;
  margin-left: -3.89px;
  display: inline-flex;
  position: relative;
}

.frame .avatar-w-photo-5 {
  background-image: url("avatar-w-photo-5.effcc018.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 647.83px;
  width: 23.35px;
  height: 23.35px;
  position: relative;
}

.frame .overflow {
  background-color: var(--x01-neutral10);
  border: 1.3px solid;
  border-color: var(--x02-neutral95);
  border-radius: 647.83px;
  width: 23.35px;
  height: 23.35px;
  margin-left: -3.89px;
  position: relative;
}

.frame .number-2 {
  color: var(--x01-neutral100);
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 7.8px;
  font-weight: 700;
  line-height: 9.3px;
  position: absolute;
  top: 6px;
  left: 5px;
}

.frame .element-notification-tab {
  background-color: var(--x01-neutral100);
  border: .53px solid #f8f8f88f;
  border-radius: 4.23px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12.7px;
  width: 215px;
  height: 232px;
  padding: 12.7px 16.93px;
  display: flex;
  position: absolute;
  top: 858px;
  left: 509px;
  overflow: hidden;
  transform: rotate(15deg);
  box-shadow: 0 2.12px 3.17px -1.06px #10182808, 0 6.35px 8.47px -2.12px #10182814;
}

.frame .div-19 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.47px;
  width: 100%;
  margin-top: -.15px;
  display: flex;
  position: relative;
}

.frame .title {
  flex: none;
  align-items: center;
  gap: 4.23px;
  width: 187.82px;
  margin-right: -6.35px;
  display: flex;
  position: relative;
}

.frame .div-wrapper-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4.23px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-23 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.53px;
  font-family: Inter, Helvetica;
  font-size: 10.6px;
  font-weight: 600;
  line-height: 12.7px;
  position: relative;
}

.frame .line-10 {
  align-self: stretch;
  width: 100%;
  height: 47.15px;
  margin-top: -3.66px;
  margin-bottom: -22.03px;
  margin-left: -2.3px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .div-20 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.35px;
  width: 100%;
  margin-bottom: -.15px;
  display: flex;
  position: relative;
}

.frame .notification {
  -webkit-backdrop-filter: blur(28.76px) brightness();
  backdrop-filter: blur(28.76px) brightness();
  border: .53px solid;
  border-color: var(--x01-neutral90);
  border-radius: 8.47px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .content-wrapper {
  -webkit-backdrop-filter: blur(42.33px) brightness();
  backdrop-filter: blur(42.33px) brightness();
  background-color: var(--x01-neutral98);
  border-radius: 2.12px 2.12px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.29px;
  width: 100%;
  padding: 5.29px 8.47px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .content {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4.23px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .notification-2 {
  border-radius: 5.29px;
  width: 21.16px;
  height: 21.16px;
  position: relative;
  overflow: hidden;
}

.frame .overlap-group-7 {
  width: 29px;
  height: 22px;
  position: relative;
  left: -4px;
}

.frame .icon-2 {
  object-fit: cover;
  width: 25px;
  height: 16px;
  position: absolute;
  top: 3px;
  left: 2px;
  transform: rotate(-15deg);
}

.frame .ellipse-14 {
  background-color: var(--accent-50);
  border: 1.06px solid;
  border-color: var(--x01-neutral100);
  border-radius: 2.65px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 16px;
  left: 20px;
}

.frame .notification-content {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2.12px;
  display: flex;
  position: relative;
}

.frame .title-time {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.29px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .title-2 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  flex: 1;
  margin-top: -.53px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  line-height: 10.2px;
  position: relative;
}

.frame .time {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.53px;
  font-family: Inter, Helvetica;
  font-size: 6.3px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .description {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.frame .image {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 63.82px;
  margin-left: -28.47px;
  margin-right: -5.48px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .notification-3 {
  -webkit-backdrop-filter: blur(28.76px) brightness();
  backdrop-filter: blur(28.76px) brightness();
  background-color: var(--x01-neutral98);
  border: .53px solid;
  border-color: var(--x01-neutral90);
  border-radius: 2.12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  height: 73.95px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 4.23px 7.41px 7.41px #1557d80a;
}

.frame .notification-4 {
  -webkit-backdrop-filter: blur(42.33px) brightness();
  backdrop-filter: blur(42.33px) brightness();
  background-color: var(--x01-neutral98);
  border-radius: 2.12px;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 8.47px;
  width: 100%;
  height: 71.95px;
  padding: 8.47px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .content-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4.23px;
  width: 100%;
  margin-top: -3.19px;
  display: flex;
  position: relative;
}

.frame .icon-3 {
  object-fit: cover;
  width: 25px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: -3px;
  transform: rotate(-15deg);
}

.frame .ellipse-15 {
  background-color: var(--accent-50);
  border: 1.06px solid;
  border-color: var(--x01-neutral100);
  border-radius: 2.65px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.frame .div-wrapper-3 {
  background-color: var(--x01-neutral100);
  border: .53px solid;
  border-color: var(--x01-neutral80);
  border-radius: 4.23px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 5.29px 6.35px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-24 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  flex: 1;
  margin-top: -.53px;
  font-family: Inter, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.frame .image-2 {
  border-radius: 0 0 4.23px 4.23px;
  align-self: stretch;
  width: 100%;
  height: 62.43px;
  position: relative;
}

.frame .members {
  background-color: var(--x01-neutral100);
  border: .68px solid #f8f8f88f;
  border-radius: 8.14px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16.27px;
  width: 260px;
  height: 418px;
  padding: 10.85px 0;
  display: flex;
  position: absolute;
  top: 189px;
  left: 1160px;
  overflow: hidden;
  transform: rotate(15deg);
}

.frame .container {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 15.56px 0 7.78px;
  display: flex;
  position: relative;
}

.frame .column {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.frame .table-cell {
  border-bottom-style: solid;
  border-bottom-width: .68px;
  border-color: var(--x01-neutral90);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.14px;
  width: 100%;
  padding: 8.14px;
  display: flex;
  position: relative;
}

.frame .avatar {
  background-image: url("avatar-12.abc36384.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .div-21 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.frame .text-3 {
  color: var(--x01-neutral20);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.68px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  line-height: 11.4px;
  position: relative;
}

.frame .supporting-text-4 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  line-height: 11.4px;
  position: relative;
}

.frame .avatar-2 {
  background-image: url("avatar-11.acf47e85.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .avatar-3 {
  background-color: var(--secondary-50);
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .text-4 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  text-align: center;
  width: 22px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  line-height: 13.6px;
  position: absolute;
  top: 4px;
  left: 0;
}

.frame .avatar-4 {
  background-image: url("avatar-10.31de09bd.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .avatar-5 {
  background-image: url("avatar-9.678b552c.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .avatar-6 {
  background-image: url("avatar-8.6744071a.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .avatar-7 {
  background-image: url("avatar-7.5b45307e.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .table-cell-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8.14px;
  width: 100%;
  padding: 8.14px;
  display: flex;
  position: relative;
}

.frame .avatar-8 {
  background-image: url("avatar-6.27b86322.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 135.59px;
  width: 21.69px;
  height: 21.69px;
  position: relative;
}

.frame .column-2 {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.frame .table-cell-3 {
  all: unset;
  border-bottom-style: solid;
  border-bottom-width: .68px;
  border-color: var(--x01-neutral90);
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 8.14px 10.85px;
  display: flex;
  position: relative;
}

.frame .button-base-wrapper {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.frame .button-base {
  all: unset;
  box-sizing: border-box;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.71px;
  display: inline-flex;
  position: relative;
}

.frame .delete {
  width: 15.72px;
  height: 20.48px;
  margin-top: -2.11px;
  margin-bottom: -2.11px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .text-5 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  line-height: 11.4px;
  position: relative;
}

.frame .table-cell-4 {
  all: unset;
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 8.14px 10.85px;
  display: flex;
  position: relative;
}

.frame .widget-leaderboard {
  border: .32px solid #f8f8f88f;
  border-radius: 7.78px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20.75px;
  width: 213px;
  height: 295px;
  display: flex;
  position: absolute;
  top: 508px;
  left: 829px;
  overflow: hidden;
  transform: rotate(15deg);
}

.frame .container-2 {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15.56px;
  width: 100%;
  padding: 15.56px 12.97px;
  display: flex;
  position: relative;
}

.frame .div-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.19px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-25 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  position: relative;
}

.frame .bar {
  border: .32px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.59px;
  align-items: center;
  gap: 1.3px;
  width: 45.39px;
  padding: 5.19px;
  display: flex;
  position: relative;
}

.frame .text-6 {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.8px;
  font-weight: 600;
  line-height: 9.3px;
  position: relative;
}

.frame .chevron {
  width: 16.9px;
  height: 9.56px;
  margin-right: -7.92px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .line-11 {
  align-self: stretch;
  width: 100%;
  height: 48.57px;
  margin-right: -37.38px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .container-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 181.57px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .contestant {
  background-color: #fff;
  border-radius: 5.19px;
  width: 186.76px;
  height: 36.31px;
  position: relative;
}

.frame .contestant-2 {
  border-radius: 7.78px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 5.19px 7.78px;
  display: flex;
  position: relative;
}

.frame .div-23 {
  width: 25.94px;
  height: 25.94px;
  position: relative;
}

.frame .ellipse-16 {
  object-fit: cover;
  width: 31px;
  height: 20px;
  position: absolute;
  top: 3px;
  left: -3px;
  transform: rotate(-15deg);
}

.frame .text-wrapper-26 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 10.9px;
  position: relative;
}

.frame .expand-less {
  width: 17.36px;
  height: 10.02px;
  margin-right: -3.49px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .text-wrapper-27 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  line-height: 9.3px;
  position: relative;
}

.frame .overlap-group-8 {
  width: 34px;
  height: 25px;
  position: relative;
  left: -4px;
}

.frame .polygon {
  width: 30px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 2px;
  transform: rotate(-15deg);
}

.frame .polygon-2 {
  width: 31px;
  height: 11px;
  position: absolute;
  top: 4px;
  left: 1px;
  transform: rotate(-15deg);
}

.frame .text-wrapper-28 {
  color: var(--x01-neutral100);
  letter-spacing: -.17px;
  height: 15px;
  font-family: Manrope, Helvetica;
  font-size: 11.1px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 14px;
}

.frame .overlap-group-9 {
  width: 35px;
  height: 27px;
  position: relative;
  left: -5px;
}

.frame .ellipse-17 {
  object-fit: cover;
  width: 31px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 2px;
  transform: rotate(-15deg);
}

.frame .text-wrapper-29 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 12px;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  left: 11px;
}

.frame .contestant-3 {
  background-color: var(--primary-98);
  border-radius: 5.19px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 5.19px 7.78px;
  display: flex;
  position: relative;
}

.frame .ellipse-18 {
  background-color: var(--accent-50);
  border: .93px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.24px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 21px;
  left: 24px;
}

.frame .text-wrapper-30 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  height: 12px;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  left: 10px;
}

.frame .text-wrapper-31 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  white-space: nowrap;
  height: 14px;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 14px;
  position: absolute;
  top: 4px;
  left: 9px;
}

.frame .ellipse-19 {
  width: 31px;
  height: 20px;
  position: absolute;
  top: 3px;
  left: -3px;
  transform: rotate(-15deg);
}

.frame .button-8 {
  background-color: var(--secondary-50);
  border-radius: 2.59px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 2.59px;
  width: 100%;
  height: 25.94px;
  padding: 7.78px 20.75px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-32 {
  color: var(--x01-neutral100);
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.46px;
  margin-bottom: -.16px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 800;
  line-height: 11.7px;
  position: relative;
}

.frame .arrow-back-4 {
  width: 15.19px;
  height: 8.77px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .menu-2 {
  background-color: var(--x02-neutral95);
  border-color: #f8f8f88f;
  border-right-style: solid;
  border-right-width: .32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 162px;
  height: 604px;
  padding: 7.66px 0;
  display: flex;
  position: absolute;
  top: 489px;
  left: 1325px;
  transform: rotate(15deg);
}

.frame .div-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .nav-link-10 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.55px;
  width: 100%;
  padding: 10.21px 25.52px;
  display: flex;
  position: relative;
}

.frame .icons-28 {
  width: 10.05px;
  height: 14.79px;
  margin-bottom: -1.75px;
  margin-left: -4.89px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .text-wrapper-33 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  line-height: 12.3px;
  position: relative;
}

.frame .icons-29 {
  width: 25.62px;
  height: 14.79px;
  margin-left: -5.15px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .div-25 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.31px;
  display: inline-flex;
  position: relative;
}

.frame .extra-3 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 100%;
  padding: 2.55px 25.52px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-34 {
  color: #000;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.64px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 400;
  line-height: 9.2px;
  position: relative;
}

.frame .interpunct-2 {
  width: 5.1px;
  height: 5.1px;
  position: relative;
}

.frame .ellipse-20 {
  background-color: #d9d9d9;
  border-radius: 1.28px;
  width: 3px;
  height: 3px;
  position: relative;
  top: 1px;
  left: 1px;
}

.frame .chevron-down-3 {
  width: 34px;
  height: 19px;
  position: absolute;
  top: 292px;
  left: 142px;
  transform: rotate(-15deg);
}

.frame .div-26 {
  background-color: var(--x01-neutral98);
  border: .65px solid #f8f8f88f;
  border-radius: 7.78px;
  align-items: center;
  gap: 12.97px;
  width: 483.58px;
  height: 159px;
  padding: 15.56px 20.75px;
  display: flex;
  position: absolute;
  top: 784px;
  left: 658px;
  transform: rotate(15deg);
}

.frame .overlap-wrapper {
  width: 132.29px;
  height: 127.75px;
  position: relative;
}

.frame .overlap-5 {
  width: 101px;
  height: 101px;
  position: relative;
  top: 12px;
  left: 10px;
}

.frame .overlap-group-wrapper-2 {
  width: 101px;
  height: 101px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .overlap-group-10 {
  width: 157px;
  height: 119px;
  position: relative;
  top: -8px;
  left: -28px;
}

.frame .ellipse-21 {
  width: 98px;
  height: 33px;
  position: absolute;
  top: 17px;
  left: 15px;
  transform: rotate(-15deg);
}

.frame .ellipse-22 {
  width: 140px;
  height: 86px;
  position: absolute;
  top: 17px;
  left: 9px;
  transform: rotate(-15deg);
}

.frame .text-wrapper-35 {
  color: var(--accent-50);
  letter-spacing: -.47px;
  text-align: center;
  white-space: nowrap;
  height: 54px;
  font-family: Inter, Helvetica;
  font-size: 38.9px;
  font-weight: 700;
  line-height: 54.5px;
  position: absolute;
  top: 22px;
  left: 25px;
}

.frame .div-27 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 20.75px;
  display: flex;
  position: relative;
}

.frame .div-28 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.13px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-36 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  align-self: stretch;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 15.6px;
  font-weight: 600;
  line-height: 18.7px;
  position: relative;
}

.frame .text-wrapper-37 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 400;
  line-height: 12.5px;
  position: relative;
}

.frame .div-wrapper-4 {
  border: .65px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.59px;
  flex: none;
  align-items: center;
  gap: 5.19px;
  padding: 7.78px 10.38px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-38 {
  color: var(--x01-neutral40);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  line-height: 12.5px;
  position: relative;
}

.frame .calendar-container {
  background-color: var(--shadeswhite);
  border: .65px solid #f8f8f88f;
  border-radius: 2.59px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.38px;
  width: 208px;
  height: 272px;
  padding: 10.38px;
  display: flex;
  position: absolute;
  top: 125px;
  left: 941px;
  transform: rotate(15.75deg);
}

.frame .change-month {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 20.75px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .icon-wrapper {
  border: .65px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.59px;
  justify-content: center;
  align-items: center;
  gap: 6.48px;
  width: 15.56px;
  height: 15.56px;
  padding: 5.19px;
  display: flex;
  position: relative;
}

.frame .icon-13 {
  width: 9.99px !important;
  height: 13.19px !important;
  margin: -4px -2.4px !important;
  position: relative !important;
  transform: rotate(-15.75deg) !important;
}

.frame .month {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  text-align: center;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 12.5px;
  position: relative;
}

.frame .icon-12-wrapper {
  border: .65px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.59px;
  justify-content: center;
  align-items: center;
  gap: 6.48px;
  width: 15.56px;
  height: 15.56px;
  padding: 5.19px;
  display: flex;
  position: relative;
  transform: rotate(180deg);
}

.frame .icon-12 {
  width: 9.99px !important;
  height: 13.19px !important;
  margin: -4px -2.4px !important;
  position: relative !important;
  transform: rotate(-195.75deg) !important;
}

.frame .div-29 {
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .time-picker {
  flex: 1;
  align-items: center;
  gap: 25.94px;
  display: flex;
  position: relative;
}

.frame .div-30 {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.frame .calendar-timepicker {
  background-color: var(--x01-neutral98);
  border-radius: 3.89px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.48px;
  padding: 2.59px;
  display: inline-flex;
  position: relative;
}

.frame .center {
  width: 42.35px;
  height: 18.16px;
  position: relative;
}

.frame .div-31 {
  align-items: center;
  gap: 2.59px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-39 {
  color: var(--secondary-50);
  letter-spacing: -.03px;
  text-align: right;
  white-space: nowrap;
  width: 18.16px;
  height: 18.16px;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  position: relative;
}

.frame .text-wrapper-40 {
  color: var(--secondary-50);
  letter-spacing: -.03px;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  position: relative;
}

.frame .segmented-picker {
  background-color: var(--x01-neutral98);
  border-radius: 5.78px;
  align-self: stretch;
  align-items: flex-start;
  width: 62.25px;
  padding: 1.3px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .segmentedpicker {
  background-color: var(--secondary-50);
  border-radius: 4.49px;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.frame .label-2 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: 19px;
  height: 10px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 10.9px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.frame .segmentedpicker-2 {
  flex: 1;
  align-self: stretch;
  position: relative;
}

.frame .label-3 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: 20px;
  height: 10px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 10.9px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.frame .container-4 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .weekdays {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.89px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .divider {
  align-self: stretch;
  width: 100%;
  height: 51.52px;
  margin-top: -25.51px;
  margin-left: -.62px;
  position: relative;
  transform: rotate(-15.75deg);
}

.frame .day-instance {
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  width: 26.77px !important;
  height: 25.94px !important;
  padding: 5.19px !important;
}

.frame .day-2 {
  color: var(--x01-neutral50) !important;
  font-family: Inter, Helvetica !important;
  font-size: 9.7px !important;
  font-weight: 500 !important;
}

.frame .month-2 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.59px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .week {
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: .65px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .day-3 {
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  width: 26.22px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
}

.frame .day-4 {
  color: var(--x01-neutral70) !important;
  letter-spacing: -.04px !important;
  white-space: nowrap !important;
  margin-top: -.75px !important;
  font-family: Inter, Helvetica !important;
  font-size: 10.4px !important;
  line-height: 12.5px !important;
}

.frame .day-5 {
  color: var(--x01-neutral40) !important;
  letter-spacing: -.04px !important;
  white-space: nowrap !important;
  margin-top: -.75px !important;
  font-family: Inter, Helvetica !important;
  font-size: 10.4px !important;
  line-height: 12.5px !important;
}

.frame .day-6 {
  background-color: var(--secondary-98) !important;
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  width: 26.22px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
}

.frame .day-7 {
  color: var(--secondary-50) !important;
  letter-spacing: -.04px !important;
  white-space: nowrap !important;
  margin-top: -.75px !important;
  font-family: Inter, Helvetica !important;
  font-size: 10.4px !important;
  font-weight: 700 !important;
  line-height: 12.5px !important;
}

.frame .day-8 {
  background-color: unset !important;
  border-radius: unset !important;
  gap: 5.19px !important;
  width: 26.22px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
}

.frame .day-9 {
  background-color: unset !important;
  border-radius: 2.59px !important;
  gap: 5.19px !important;
  width: 26.22px !important;
  height: 22.18px !important;
  padding: 5.19px !important;
}

.frame .buttons {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10.38px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .cancel {
  background-color: var(--x01-neutral98);
  border-radius: 2.59px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 7.78px;
  display: flex;
  position: relative;
}

.frame .label-4 {
  color: var(--x01-neutral50);
  letter-spacing: -.05px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 14px;
  position: relative;
}

.frame .set-date {
  background-color: var(--accent-50);
  border-radius: 2.59px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 2.59px;
  padding: 7.78px 5.19px;
  display: flex;
  position: relative;
}

.frame .label-5 {
  color: var(--shadeswhite);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.7px;
  font-weight: 600;
  line-height: 14px;
  position: relative;
}

.frame .line-12 {
  width: 1px;
  height: 516px;
  position: absolute;
  top: 0;
  left: 937px;
}

.frame .line-13 {
  width: 1px;
  height: 576px;
  position: absolute;
  top: 0;
  left: 1153px;
}

.frame .line-14 {
  width: 1px;
  height: 652px;
  position: absolute;
  top: 0;
  left: 1429px;
}

.frame .contestant-4 {
  background-color: #fff;
  border: .65px solid #f8f8f88f;
  border-radius: 5.19px;
  justify-content: space-between;
  align-items: center;
  width: 186.76px;
  height: 36.31px;
  padding: 5.19px 7.78px;
  display: flex;
  position: absolute;
  top: 576px;
  left: 895px;
  transform: rotate(15deg);
  box-shadow: 3.89px 13.62px #f4f4f4, 0 5.94px 10.38px -2.59px #10182814;
}

.frame .text-wrapper-41 {
  color: var(--x01-neutral100);
  letter-spacing: -.17px;
  height: 15px;
  font-family: Manrope, Helvetica;
  font-size: 11.1px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 15px;
}

.frame .frame-wrapper-4 {
  background-color: var(--x01-neutral20);
  border: .65px solid #f8f8f88f;
  border-radius: 5.19px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15.56px;
  width: 243px;
  display: flex;
  position: absolute;
  top: 121px;
  left: 680px;
  transform: rotate(14.25deg);
}

.frame .div-32 {
  border: .65px solid #6b6b6b;
  border-radius: 5.19px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14.32px;
  width: 244.53px;
  margin: -1px;
  padding: 14.32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-33 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.3px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-42 {
  color: #fff;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  position: relative;
}

.frame .text-wrapper-43 {
  color: #979797;
  letter-spacing: 0;
  width: 211.4px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 15.6px;
  position: relative;
}

.frame .div-34 {
  opacity: .8;
  background-color: #393939;
  border-radius: 2.59px;
  flex: none;
  align-items: flex-end;
  gap: 5.19px;
  width: 211.4px;
  padding: 7.78px 5.19px 7.78px 10.38px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 2.97px 2.97px 2.97px #00000040;
}

.frame .checkbox-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 6.48px;
  padding: 1.95px 0;
  display: inline-flex;
  position: relative;
}

.frame .checkbox {
  width: 10.38px;
  height: 10.38px;
  position: relative;
}

.frame .fill {
  border: .65px solid #bcbcbc;
  border-radius: 1.3px;
  width: 12px;
  height: 12px;
  position: relative;
  top: -1px;
  left: -1px;
}

.frame .text-wrapper-44 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 500;
  line-height: 15.6px;
  position: relative;
}

.frame .div-35 {
  opacity: .8;
  background-color: #393939;
  border-radius: 2.59px;
  flex: none;
  align-items: flex-end;
  gap: 5.19px;
  width: 211.4px;
  padding: 7.78px 5.19px 7.78px 10.38px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-36 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10.38px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .search-bar {
  border: .65px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.59px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.19px;
  padding: 7.78px 10.38px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-45 {
  color: var(--x01-neutral80);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 13px;
  position: relative;
}

.frame .search-bar-2 {
  background-color: #fff;
  border-radius: 2.59px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.19px;
  padding: 7.78px 10.38px;
  display: flex;
  position: relative;
  box-shadow: 9.08px 2.59px 9.08px #0000008f;
}

.frame .text-wrapper-46 {
  color: #575757;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.65px;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  line-height: 13px;
  position: relative;
}

.frame .element-7 {
  background-color: #fff;
  border: .56px solid #efede9;
  border-radius: 4.51px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.37px;
  width: 433px;
  padding: 7.16px 0;
  display: flex;
  position: absolute;
  top: 303px;
  left: 490px;
  transform: rotate(15deg);
  box-shadow: 14.66px -.56px 18.04px #0000000a;
}

.frame .div-37 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.51px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-38 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.77px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-39 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 13.53px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-47 {
  color: #676768;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  line-height: 12.2px;
  position: relative;
}

.frame .img-9 {
  flex: none;
  margin-top: -5.55px;
  margin-bottom: -5.55px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .line-15 {
  align-self: stretch;
  width: 100%;
  height: 112.35px;
  margin-top: -32.06px;
  margin-bottom: -55.48px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .frame-wrapper-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.77px;
  width: 100%;
  padding: 0 13.53px;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-6 {
  border-color: #d4d4d452;
  border-bottom-style: solid;
  border-bottom-width: .56px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 9.02px;
  width: 100%;
  padding: 6.77px 0;
  display: flex;
  position: relative;
}

.frame .div-40 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 13.53px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-41 {
  flex: 1;
  align-items: center;
  gap: 13.53px;
  display: flex;
  position: relative;
}

.frame .div-42 {
  flex: 1;
  align-items: center;
  gap: 7.33px;
  display: flex;
  position: relative;
}

.frame .div-43 {
  background-color: #efede9;
  border-radius: 3.38px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 31.57px;
  padding: 4.51px 6.77px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-48 {
  color: #676768;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Inter, Helvetica;
  font-size: 11.3px;
  font-weight: 600;
  line-height: 13.5px;
  position: relative;
}

.frame .text-wrapper-49 {
  color: #676768;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: 8.1px;
  position: relative;
}

.frame .div-44 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2.26px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-50 {
  color: #676768;
  letter-spacing: -.02px;
  align-self: stretch;
  height: 13.53px;
  margin-top: -.56px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.frame .text-wrapper-51 {
  color: var(--x01-neutral70);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 600;
  line-height: 8.1px;
  position: relative;
}

.frame .div-45 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 1.13px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-52 {
  color: #676768;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: 8.1px;
  position: relative;
}

.frame .div-46 {
  flex: none;
  align-items: center;
  gap: 2.26px;
  display: inline-flex;
  position: relative;
}

.frame .chip-speaker-3 {
  border-radius: 11.28px;
  flex: none;
  align-items: center;
  gap: 2.26px;
  padding: 2.26px;
  display: inline-flex;
  position: relative;
}

.frame .ellipse-wrapper-2 {
  width: 13.53px;
  height: 13.53px;
  position: relative;
}

.frame .ellipse-23 {
  object-fit: cover;
  width: 13px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-15deg);
}

.frame .name-3 {
  color: #676768;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 600;
  line-height: 8.1px;
  position: relative;
}

.frame .chip-speaker-4 {
  background-color: #f1f1f1;
  border-radius: 11.28px;
  flex: none;
  align-items: center;
  gap: 2.26px;
  padding: 2.26px 4.51px 2.26px 2.26px;
  display: inline-flex;
  position: relative;
}

.frame .name-4 {
  color: #6c6c6c;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: 8.1px;
  position: relative;
}

.frame .div-47 {
  flex: none;
  align-items: center;
  gap: 6.77px;
  display: inline-flex;
  position: relative;
}

.frame .div-wrapper-5 {
  background-color: var(--secondary-50);
  border-radius: 2.26px;
  justify-content: center;
  align-items: center;
  gap: 5.64px;
  width: 51.87px;
  padding: 6.77px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-53 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Inter, Helvetica;
  font-size: 7.9px;
  font-weight: 700;
  line-height: 9.5px;
  position: relative;
}

.frame .more-vert {
  width: 13.07px;
  height: 17.03px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .frame-wrapper-7 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 9.02px;
  width: 100%;
  padding: 6.77px 0;
  display: flex;
  position: relative;
}

.frame .add-session {
  background-color: var(--x01-neutral100);
  border: .89px solid #f5f5f5;
  border-radius: 10.74px;
  justify-content: center;
  align-items: flex-start;
  gap: 6.11px;
  height: 572px;
  padding: 34.2px;
  display: inline-flex;
  position: absolute;
  top: 380px;
  left: 281px;
  transform: rotate(15deg);
  box-shadow: 3.89px 13.62px #f4f4f4;
}

.frame .container-content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.66px;
  display: inline-flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.frame .header-create {
  align-items: center;
  gap: 4.89px;
  width: 366.42px;
  height: 25.65px;
  padding: 2.44px 0;
  display: flex;
  position: relative;
}

.frame .text-wrapper-54 {
  color: var(--x01-neutral30);
  letter-spacing: -.15px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .container-input-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24.43px;
  display: inline-flex;
  position: relative;
}

.frame .container-input {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14.66px;
  display: inline-flex;
  position: relative;
}

.frame .container-session {
  align-items: center;
  gap: 9.77px;
  width: 366.42px;
  height: 21.98px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-55 {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 10.3px;
  position: relative;
}

.frame .container-types {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.33px;
  display: inline-flex;
  position: relative;
}

.frame .option-live {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4.89px;
  width: 68.4px;
  padding: 2.44px 0;
  display: flex;
  position: relative;
}

.frame .checkbox-base-wrapper {
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.frame .checkbox-base {
  background-color: var(--x01-neutral100);
  border: .61px solid;
  border-color: var(--x01-neutral50);
  border-radius: 4.89px;
  width: 9.77px;
  height: 9.77px;
  position: relative;
}

.frame .text-7 {
  color: var(--x01-neutral30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 12.2px;
  position: relative;
}

.frame .option-recorded {
  background-color: var(--secondary-98);
  border: .61px solid;
  border-color: var(--secondary-50);
  border-radius: 2.44px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4.89px;
  width: 70.84px;
  padding: 2.44px 7.33px;
  display: flex;
  position: relative;
}

.frame .check-wrapper {
  background-color: var(--secondary-98);
  border: .61px solid;
  border-color: var(--secondary-50);
  border-radius: 4.89px;
  width: 9.77px;
  height: 9.77px;
  position: relative;
  overflow: hidden;
}

.frame .check {
  background-color: var(--secondary-50);
  border-radius: 3.05px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 2px;
  left: 2px;
}

.frame .input-field-title {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 7.33px;
  width: 366.42px;
  padding: 6.11px 7.33px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-56 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  align-self: stretch;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.3px;
  position: relative;
}

.frame .input-field-desc {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 366.42px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .input-field-text {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 6.11px 7.33px;
  display: flex;
  position: relative;
}

.frame .text-8 {
  color: var(--x01-neutral50);
  flex: 1;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 8.5px;
  position: relative;
}

.frame .span {
  letter-spacing: -.03px;
  line-height: 10.3px;
}

.frame .text-wrapper-57 {
  letter-spacing: 0;
  line-height: 12.2px;
}

.frame .toolbar-text {
  background-color: var(--secondary-98);
  border-color: var(--x01-neutral60);
  border-top-style: solid;
  border-top-width: .61px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 4.89px 7.33px;
  display: flex;
  position: relative;
}

.frame .div-48 {
  flex: none;
  align-items: flex-start;
  gap: 4.89px;
  display: inline-flex;
  position: relative;
}

.frame .icon-4 {
  flex: none;
  margin-left: -3.7px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .icon-5 {
  flex: none;
  position: relative;
  transform: rotate(-15deg);
}

.frame .format-list-bulleted {
  width: 18.39px;
  height: 10.62px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .format-list-numbered {
  width: 18.39px;
  height: 10.62px;
  margin-right: -3.7px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .label-char-limit {
  background-color: var(--secondary-70);
  border-radius: 13.44px;
  flex: none;
  align-items: flex-start;
  gap: 6.11px;
  padding: 2.44px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-58 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 7.3px;
  font-weight: 600;
  line-height: 8.5px;
  position: relative;
}

.frame .div-49 {
  flex: none;
  align-items: center;
  gap: 14.66px;
  width: 366.42px;
  display: flex;
  position: relative;
}

.frame .div-50 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4.89px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-59 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 10.3px;
  position: relative;
}

.frame .input-field-date {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.33px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-51 {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 24.43px;
  padding: 7.33px 9.77px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-60 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.3px;
  position: relative;
}

.frame .chevron-down-4 {
  width: 20.43px;
  height: 11.8px;
  margin-top: -1.01px;
  margin-bottom: -1.01px;
  margin-right: -4.11px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .input-field-time {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  justify-content: space-around;
  align-items: center;
  gap: 6.11px;
  width: 65.34px;
  height: 24.43px;
  padding: 7.33px 9.77px;
  display: flex;
  position: relative;
}

.frame .chevron-down-5 {
  width: 32.26px;
  height: 18.44px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .container-field {
  align-self: stretch;
  align-items: flex-start;
  gap: 7.33px;
  width: 100%;
  height: 24.43px;
  display: flex;
  position: relative;
}

.frame .div-52 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.66px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .container-timezone {
  flex-direction: column;
  align-items: flex-start;
  gap: 4.89px;
  width: 106.26px;
  display: flex;
  position: relative;
}

.frame .input-field-timezone {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.11px 7.33px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-61 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.3px;
  position: relative;
}

.frame .chevron-down-6 {
  width: 20.43px;
  height: 11.8px;
  margin-right: -4.11px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .container-5 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 7.33px;
  display: flex;
  position: relative;
}

.frame .container-wrapper {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex: none;
  align-items: center;
  gap: 6.11px;
  width: 366.42px;
  padding: 4.89px 7.33px;
  display: flex;
  position: relative;
}

.frame .chip-speaker-5 {
  background-color: var(--primary-98);
  border-radius: 12.21px;
  flex: none;
  align-items: center;
  gap: 2.44px;
  padding: 2.44px 4.89px 2.44px 2.44px;
  display: inline-flex;
  position: relative;
}

.frame .div-53 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.44px;
  display: inline-flex;
  position: relative;
}

.frame .div-54 {
  width: 14.66px;
  height: 14.66px;
  position: relative;
}

.frame .ellipse-24 {
  object-fit: cover;
  width: 25px;
  height: 14px;
  position: absolute;
  top: 0;
  left: -5px;
  transform: rotate(-15deg);
}

.frame .name-5 {
  color: var(--x01-neutral30);
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 11px;
  position: relative;
}

.frame .close {
  width: 16.35px;
  height: 9.44px;
  margin-right: -3.29px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .text-wrapper-62 {
  color: var(--secondary-50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 10.3px;
  position: relative;
}

.frame .container-embed-link {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.33px;
  width: 366.42px;
  height: 43.97px;
  display: flex;
  position: relative;
}

.frame .input-field-link {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex: 1;
  align-items: center;
  gap: 6.11px;
  width: 366.42px;
  padding: 7.33px;
  display: flex;
  position: relative;
}

.frame .container-text {
  flex: 1;
  align-items: center;
  gap: 4.89px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-63 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.3px;
  position: relative;
}

.frame .container-auditorium {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 7.33px;
  width: 366.42px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .top {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.33px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .title-3 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  flex: 1;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 500;
  line-height: 10.3px;
  position: relative;
}

.frame .container-6 {
  background-color: var(--x01-neutral98);
  border: .61px dashed;
  border-color: var(--x01-neutral80);
  border-radius: 2.44px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4.89px;
  width: 100%;
  height: 53.74px;
  padding: 9.77px 14.66px;
  display: flex;
  position: relative;
}

.frame .content-3 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.11px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .icon-6 {
  width: 36.78px;
  height: 21.24px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .paragraph {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  width: 158.17px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.3px;
  position: relative;
}

.frame .container-video {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 366.42px;
  display: flex;
  position: relative;
}

.frame .record {
  flex: none;
  align-items: center;
  gap: 4.89px;
  display: inline-flex;
  position: relative;
}

.frame .switch {
  width: 24.43px;
  height: 13.44px;
  position: relative;
}

.frame .overlap-group-11 {
  width: 52px;
  height: 38px;
  position: relative;
  top: -12px;
  left: -9px;
}

.frame .color-l-track {
  background-color: var(--secondary-50);
  border-radius: 6.72px;
  width: 26px;
  height: 15px;
  position: absolute;
  top: 11px;
  left: 8px;
}

.frame .states {
  width: 47px;
  height: 26px;
  position: absolute;
  top: 6px;
  left: 3px;
  transform: rotate(-15deg);
}

.frame .color-l-thumb {
  width: 18px;
  height: 11px;
  position: absolute;
  top: 14px;
  left: 17px;
  transform: rotate(-15deg);
}

.frame .text-wrapper-64 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  line-height: 11.7px;
  position: relative;
}

.frame .div-55 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4.89px;
  display: inline-flex;
  position: relative;
}

.frame .icons-outline {
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  left: 2px;
}

.frame .shape-wrapper {
  background-color: #4f78db;
  border-radius: 1.22px;
  width: 13px;
  height: 13px;
  position: relative;
  top: -2px;
  left: -2px;
}

.frame .shape {
  width: 11px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 1px;
  transform: rotate(-15deg);
}

.frame .label-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.05px;
  display: inline-flex;
  position: relative;
}

.frame .icons-outline-2 {
  border: .61px solid;
  border-color: var(--x01-neutral80);
  border-radius: 1.22px;
  width: 13px;
  height: 13px;
  position: relative;
  top: 1px;
  left: 1px;
}

.frame .shape-2 {
  width: 15px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: -1px;
  transform: rotate(-15deg);
}

.frame .overlap-group-12 {
  width: 18px;
  height: 13px;
  position: relative;
  top: 1px;
  left: -2px;
}

.frame .fill-2 {
  background-color: var(--secondary-50);
  border-radius: 1.22px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 4px;
}

.frame .icons-outline-3 {
  width: 16px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 1px;
  transform: rotate(-15deg);
}

.frame .div-56 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 9.77px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .CTA {
  all: unset;
  border: .61px solid;
  border-color: var(--x01-neutral40);
  box-sizing: border-box;
  border-radius: 2.44px;
  justify-content: center;
  align-items: center;
  gap: 4.89px;
  width: 64.12px;
  padding: 7.33px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-9 {
  color: var(--x01-neutral40);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 11px;
  font-weight: 700;
  line-height: 15.9px;
  position: relative;
}

.frame .CTA-2 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 2.44px;
  justify-content: center;
  align-items: center;
  gap: 4.89px;
  width: 64.12px;
  padding: 7.33px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-10 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 11px;
  font-weight: 700;
  line-height: 15.9px;
  position: relative;
}

.frame .sessions-dropdown {
  background-color: var(--white);
  border: .83px solid #f2f3f6;
  border-radius: 6.61px;
  align-items: flex-start;
  width: 168px;
  display: flex;
  position: absolute;
  top: 66px;
  left: 497px;
  overflow: hidden;
  transform: rotate(15deg);
  box-shadow: 0 3.3px 4.96px -1.65px #10182808, 14.32px 1.79px 14.32px -3.3px #10182814;
}

.frame .menu-items {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.frame .input-dropdown-menu {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.61px;
  width: 100%;
  padding: 13.22px;
  display: flex;
  position: relative;
}

.frame .img-10 {
  width: 19.15px;
  height: 24.96px;
  margin-top: -2.48px;
  margin-bottom: -2.48px;
  position: relative;
  transform: rotate(-15deg);
}

.frame .content-4 {
  flex: 1;
  align-items: center;
  gap: 6.61px;
  display: flex;
  position: relative;
}

.frame .text-11 {
  color: #575757;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.83px;
  font-family: Inter, Helvetica;
  font-size: 13.2px;
  font-weight: 500;
  line-height: 19.8px;
  position: relative;
}

.frame .div-57 {
  background-color: var(--x01-neutral20);
  border-radius: 7.43px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.2px;
  width: 235px;
  height: 235px;
  padding: 12.39px;
  display: flex;
  position: absolute;
  top: 921px;
  left: 737px;
  transform: rotate(15.75deg);
}

.frame .div-58 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 2.48px 0;
  display: flex;
  position: relative;
}

.frame .text-wrapper-65 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.2px;
  font-weight: 600;
  line-height: 13.4px;
  position: relative;
}

.frame .dots-horizontal {
  width: 14.87px;
  height: 14.87px;
  margin-bottom: -30.12px;
  margin-left: -11382.5px;
  position: relative;
  transform: rotate(-15.75deg);
}

.frame .div-59 {
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.frame .overlap-6 {
  width: 200px;
  height: 59px;
  position: absolute;
  top: 4px;
  left: -11px;
}

.frame .overlap-7 {
  width: 200px;
  height: 59px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .overlap-group-13 {
  width: 96px;
  height: 29px;
  position: absolute;
  top: 2px;
  left: 98px;
}

.frame .chair-9 {
  width: 58px !important;
  height: 13px !important;
  position: absolute !important;
  top: 8px !important;
  left: 1px !important;
  transform: rotate(-15.75deg) !important;
}

.frame .table {
  width: 207px;
  height: 3px;
  position: absolute;
  top: 28px;
  left: -3px;
  transform: rotate(-15.75deg);
}

.frame .chair-5 {
  width: 58px !important;
  height: 5px !important;
  position: absolute !important;
  top: 37px !important;
  left: 30px !important;
  transform: rotate(-15.75deg) !important;
}

.frame .gradient {
  background: linear-gradient(#efeee9 0%, #f1f1f100 86.54%);
  width: 1920px;
  height: 1440px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-60 {
  align-items: flex-start;
  width: 1680px;
  display: flex;
  position: absolute;
  top: 119px;
  left: 120px;
}

.frame .project-title {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 88px;
  display: flex;
  position: relative;
}

.frame .project-title-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-66 {
  color: #999;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .internal-SAAS-app {
  color: #000;
  letter-spacing: -.4px;
  width: 860px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 80px;
  font-weight: 400;
  line-height: 96px;
  position: relative;
}

.frame .scope-of-work {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-67 {
  color: #676768;
  letter-spacing: -.39px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}

.frame .labels {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame .UIX {
  width: 149px;
  height: 56px;
  position: relative;
}

.frame .frame-wrapper-8 {
  background-color: #000;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.frame .div-61 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.frame .group-3 {
  background-color: #f8f8f8;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.frame .overlap-group-14 {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.frame .line-16 {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.frame .line-17 {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.frame .text-wrapper-68 {
  color: #fff;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.frame .UIX-2 {
  width: 173px;
  height: 56px;
  position: relative;
}

.frame .project-info {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 576.5px;
  height: 92px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-69 {
  color: #484848;
  letter-spacing: -.39px;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-70 {
  color: #484848;
  letter-spacing: -.39px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .about {
  background-color: #1e1e1e;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
  width: 1920px;
  padding: 88px 120px 100px;
  display: flex;
  position: absolute;
  top: 1440px;
  left: -6px;
}

.frame .container-7 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 1678px;
  display: flex;
  position: relative;
}

.frame .about-the-client {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-71 {
  color: #fff;
  letter-spacing: .48px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-72 {
  color: #f8f8f8;
  letter-spacing: 0;
  width: 637px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.frame .the-challenge {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  width: 773px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-73 {
  color: #fff;
  letter-spacing: .48px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  position: relative;
}

.frame .text-wrapper-74 {
  color: #f8f8f8;
  letter-spacing: -.19px;
  align-self: stretch;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.frame .overlap-8 {
  width: 2454px;
  height: 3621px;
  position: absolute;
  top: 1858px;
  left: -65px;
}

.frame .overlap-9 {
  width: 2454px;
  height: 3621px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .section-wireframes {
  background: linear-gradient(#1e1e1e 0%, #1c1b1f 100%);
  width: 1920px;
  height: 2250px;
  position: absolute;
  top: 0;
  left: 59px;
  overflow: hidden;
}

.frame .resources-drafts {
  background-color: #fff;
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 705px;
  height: 506px;
  position: absolute;
  top: 1660px;
  left: -181px;
  overflow: hidden;
}

.frame .overlap-10 {
  width: 688px;
  height: 503px;
  position: absolute;
  top: 41px;
  left: -1px;
}

.frame .rectangle-3 {
  background-color: #fbfaf8;
  width: 553px;
  height: 449px;
  position: absolute;
  top: 1px;
  left: 136px;
}

.frame .rectangle-4 {
  background-color: #f6f6f6;
  width: 553px;
  height: 165px;
  position: absolute;
  top: 338px;
  left: 136px;
}

.frame .div-62 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.45px;
  display: inline-flex;
  position: absolute;
  top: 351px;
  left: 151px;
}

.frame .frame-wrapper-9 {
  flex: none;
  align-items: center;
  gap: 201.39px;
  width: 511.59px;
  display: flex;
  position: relative;
}

.frame .div-wrapper-6 {
  flex: none;
  align-items: center;
  gap: 68.72px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-75 {
  color: #626262;
  letter-spacing: -.24px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .frame-wrapper-10 {
  width: 518.27px;
  height: 102.13px;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

.frame .div-63 {
  align-items: center;
  gap: 7.64px;
  width: 518px;
  height: 95.45px;
  display: flex;
  position: relative;
  top: 3px;
}

.frame .frame-wrapper-11 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 162.26px;
  height: 95.45px;
  padding: 3.82px 11.45px 9.54px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .more-horiz-wrapper {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 68.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .img-11 {
  width: 15.27px;
  height: 15.27px;
  position: relative;
}

.frame .frame-wrapper-12 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.73px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-13 {
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.25px;
  display: flex;
  position: relative;
}

.frame .text-and-supporting-wrapper-2 {
  align-self: stretch;
  align-items: flex-start;
  gap: 5.73px;
  width: 61.09px;
  display: flex;
  position: relative;
}

.frame .text-and-supporting-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: .95px;
  margin-bottom: -.62px;
  display: flex;
  position: relative;
}

.frame .div-64 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  margin-right: -4.19px;
  display: inline-flex;
  position: relative;
}

.frame .text-12 {
  color: var(--gray-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 400;
  line-height: 11.7px;
  position: relative;
}

.frame .div-wrapper-7 {
  background-color: #000;
  border-radius: 1.91px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  padding: .95px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-76 {
  color: #f5f5f5;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  line-height: 7.6px;
  position: relative;
}

.frame .img-12 {
  width: 7.64px;
  height: 7.64px;
  position: relative;
}

.frame .div-65 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .element-8 {
  background-color: #ffeaea;
  border-radius: 11.81px;
  width: 11.45px;
  height: 11.45px;
  position: relative;
  overflow: hidden;
}

.frame .overlap-group-15 {
  border-radius: 11.81px;
  width: 11px;
  height: 11px;
  position: relative;
}

.frame .img-13 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.frame .element-9 {
  background-color: #f5f5f5;
  border-radius: 11.81px;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame .ellipse-25 {
  background-color: #f1f1f1;
  border-radius: .95px;
  width: 1.91px;
  height: 1.91px;
  position: relative;
}

.frame .supporting-text-5 {
  color: #a4a4a4;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: 11.5px;
  position: relative;
}

.frame .frame-wrapper-14 {
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 164.17px;
  height: 95.45px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: .48px .48px #2b2b2b;
}

.frame .frame-wrapper-15 {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 43.43px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-77 {
  color: #000;
  letter-spacing: -.24px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .frame-wrapper-16 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 162.26px;
  height: 95.45px;
  padding: 9.54px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .frame-wrapper-17 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 43.43px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .name-date {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.11px;
  display: inline-flex;
  position: relative;
}

.frame .text-13 {
  color: var(--gray-900);
  letter-spacing: 0;
  width: 139.35px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: 11.5px;
  position: relative;
}

.frame .div-66 {
  flex: none;
  align-items: flex-start;
  gap: 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-78 {
  color: #575757;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .div-67 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: .95px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-79 {
  color: #000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .chip-speaker-6 {
  border-radius: 9.54px;
  flex: none;
  align-items: center;
  gap: 1.91px;
  padding: 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .div-68 {
  background-image: url("ellipse-1-8.0705c9a6.png");
  background-size: 100% 100%;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .name-6 {
  color: #464646;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .chip-speaker-7 {
  border-radius: 9.54px;
  flex: none;
  align-items: center;
  gap: 1.91px;
  padding: 1.91px 3.82px 1.91px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .div-69 {
  background-image: url("ellipse-1-7.4e1e0435.png");
  background-position: 50%;
  background-size: cover;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .chip-speaker-8 {
  background-color: #fff;
  border-radius: 9.54px;
  flex: none;
  align-items: center;
  gap: 1.91px;
  padding: 1.91px 3.82px 1.91px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .name-7 {
  color: #464646;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .frame-wrapper-18 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 162.26px;
  height: 95.45px;
  margin-right: -155.58px;
  padding: 9.54px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-14 {
  color: var(--gray-900);
  letter-spacing: 0;
  width: 139.35px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: 11.7px;
  position: relative;
}

.frame .chip-speaker-9 {
  border-radius: 9.54px;
  width: 47.25px;
  height: 15.27px;
  position: relative;
}

.frame .div-70 {
  background-image: url("ellipse-1-6.ac5688fa.png");
  background-size: 100% 100%;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.frame .name-8 {
  color: #464646;
  letter-spacing: -.17px;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: absolute;
  top: 2px;
  left: 15px;
}

.frame .div-71 {
  background-image: url("ellipse-1-5.93148d96.png");
  background-position: 50%;
  background-size: cover;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .name-9 {
  color: #464646;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .name-10 {
  color: #464646;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .line-18 {
  width: 311px;
  height: 1px;
  position: absolute;
  top: 49px;
  left: 180px;
}

.frame .div-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.64px;
  display: inline-flex;
  position: absolute;
  top: 61px;
  left: 151px;
  overflow: hidden;
}

.frame .div-73 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.27px;
  width: 334.06px;
  display: flex;
  position: relative;
}

.frame .div-74 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 22.91px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-75 {
  align-self: stretch;
  align-items: flex-start;
  width: 146.03px;
  display: flex;
  position: relative;
}

.frame .div-wrapper-8 {
  border-color: #575757;
  border-bottom-style: solid;
  border-bottom-width: .95px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  padding: 3.82px 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-80 {
  color: #575757;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.95px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-wrapper-9 {
  opacity: .32;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  padding: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-81 {
  color: #575757;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .div-wrapper-10 {
  opacity: .32;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  margin-right: -.69px;
  padding: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .div-76 {
  flex: 1;
  align-items: center;
  gap: 11.45px;
  display: flex;
  position: relative;
}

.frame .text-input {
  border: .24px solid #9f9f9f;
  border-radius: 1.91px;
  flex: 1;
  align-items: center;
  gap: 2.86px;
  padding: 3.82px 5.73px;
  display: flex;
  position: relative;
}

.frame .img-14 {
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .value {
  color: #9f9f9f;
  letter-spacing: -.04px;
  width: 73.49px;
  margin-top: -.24px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: 11.5px;
  position: relative;
}

.frame .frame-wrapper-19 {
  background-color: #4b4b4b;
  border: .24px solid #4b4b4b;
  border-radius: 14.32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .95px;
  width: 41.52px;
  height: 19.09px;
  padding: 5.73px 9.54px 5.73px 5.73px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-77 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  margin: -1.91px -2.06px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-82 {
  color: #fff;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .line-19 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.76px;
  position: relative;
}

.frame .frame-wrapper-20 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.45px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-78 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  gap: 5.73px;
  width: 100%;
  height: 230.98px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .dropdown {
  border-radius: 2.86px;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 7.64px;
  padding: 1.91px 0;
  display: inline-flex;
  position: relative;
}

.frame .div-79 {
  flex: none;
  align-items: center;
  gap: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-83 {
  color: #9f9f9f;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 11.5px;
  position: relative;
}

.frame .div-80 {
  flex: none;
  align-items: center;
  gap: .95px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-84 {
  color: #828282;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: 11.5px;
  position: relative;
}

.frame .img-15 {
  flex: none;
  position: relative;
}

.frame .div-81 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.54px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-82 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.54px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-21 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.73px;
  height: 95.45px;
  padding: 3.82px 11.45px 9.54px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .frame-wrapper-22 {
  align-items: flex-start;
  gap: 5.25px;
  width: 60.61px;
  display: flex;
  position: relative;
}

.frame .text-and-supporting-wrapper-3 {
  align-items: flex-start;
  gap: 5.73px;
  width: 61.09px;
  margin-right: -.48px;
  display: flex;
  position: relative;
}

.frame .text-and-supporting-3 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: .95px;
  display: flex;
  position: relative;
}

.frame .img-16 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -3882px;
  left: -4446px;
}

.frame .frame-wrapper-23 {
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.73px;
  height: 95.45px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.91px 1.91px #ff6c7d;
}

.frame .img-17 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -3988px;
  left: -4446px;
}

.frame .more-horiz-3 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -4024.53px;
  margin-left: -4325.22px;
  position: relative;
}

.frame .star-4 {
  width: 7.64px;
  height: 7.64px;
  margin-top: -4080.1px;
  margin-left: -4391.67px;
  position: relative;
}

.frame .img-18 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -4093px;
  left: -4446px;
}

.frame .more-horiz-4 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -4024.53px;
  margin-left: -4497.03px;
  position: relative;
}

.frame .star-5 {
  width: 7.64px;
  height: 7.64px;
  margin-top: -4080.1px;
  margin-left: -4563.47px;
  position: relative;
}

.frame .img-19 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -4093px;
  left: -4618px;
}

.frame .frame-wrapper-24 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.73px;
  height: 95.45px;
  padding: 9.54px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .more-horiz-5 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -4135.25px;
  margin-left: -4325.22px;
  position: relative;
}

.frame .frame-wrapper-25 {
  align-self: stretch;
  align-items: flex-start;
  gap: 5.25px;
  width: 60.61px;
  display: flex;
  position: relative;
}

.frame .text-and-supporting-wrapper-4 {
  align-self: stretch;
  align-items: flex-start;
  gap: 5.73px;
  width: 61.09px;
  margin-right: -.48px;
  display: flex;
  position: relative;
}

.frame .star-6 {
  width: 7.64px;
  height: 7.64px;
  margin-top: -4184.47px;
  margin-left: -4391.67px;
  position: relative;
}

.frame .img-20 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -4197px;
  left: -4446px;
}

.frame .more-horiz-6 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -4135.25px;
  margin-left: -4497.03px;
  position: relative;
}

.frame .star-7 {
  width: 7.64px;
  height: 7.64px;
  margin-top: -4184.47px;
  margin-left: -4563.47px;
  position: relative;
}

.frame .img-21 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -4197px;
  left: -4618px;
}

.frame .frame-wrapper-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15.11px;
  height: 276px;
  display: inline-flex;
  position: absolute;
  top: 50px;
  left: 516px;
}

.frame .frame-wrapper-27 {
  border: .24px solid #9f9f9f;
  border-radius: 5.66px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 26.44px;
  width: 154.84px;
  padding: 11.33px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-83 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.55px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-wrapper-11 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.78px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-85 {
  color: #555;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.47px;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 600;
  line-height: 15.1px;
  position: relative;
}

.frame .bar-2 {
  border: .24px solid #555;
  border-radius: 1.89px;
  align-items: center;
  gap: .94px;
  width: 33.04px;
  padding: 3.78px;
  display: flex;
  position: relative;
}

.frame .text-15 {
  color: #555;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.2px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .chevron-2 {
  width: 7.55px;
  height: 7.08px;
  margin-right: -3.01px;
  position: relative;
}

.frame .line-20 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.frame .div-84 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: .94px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-85 {
  border-radius: 5.66px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 3.78px 5.66px;
  display: flex;
  position: relative;
}

.frame .div-86 {
  flex: none;
  align-items: center;
  gap: 3.78px;
  display: inline-flex;
  position: relative;
}

.frame .div-87 {
  background-color: #0003;
  border-radius: 9.44px;
  width: 18.88px;
  height: 18.88px;
  margin-left: -.09px;
  position: relative;
}

.frame .ellipse-26 {
  background-color: #047857;
  border: .67px solid #fff;
  border-radius: 2.36px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 15px;
  left: 14px;
}

.frame .div-88 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.89px;
  display: inline-flex;
  position: relative;
}

.frame .div-89 {
  flex: none;
  align-items: center;
  gap: 1.89px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-86 {
  color: #555;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.47px;
  font-family: Inter, Helvetica;
  font-size: 6.6px;
  font-weight: 600;
  line-height: 8.5px;
  position: relative;
}

.frame .expand-less-2 {
  width: 7.55px;
  height: 7.55px;
  position: relative;
}

.frame .text-wrapper-87 {
  color: #818181;
  letter-spacing: -.08px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .div-wrapper-12 {
  width: 18.88px;
  height: 18.88px;
  position: relative;
}

.frame .overlap-group-16 {
  background-image: url("polygon-2-9.aa0095c4.svg");
  background-size: 100% 100%;
  width: 16px;
  height: 18px;
  position: relative;
  left: 1px;
}

.frame .polygon-3 {
  width: 16px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-88 {
  color: #fff;
  letter-spacing: -.12px;
  height: 11px;
  font-family: Manrope, Helvetica;
  font-size: 8.1px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 6px;
}

.frame .text-wrapper-89 {
  color: gray;
  letter-spacing: -.08px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .overlap-group-17 {
  background-image: url("polygon-2-8.bad10d82.svg");
  background-size: 100% 100%;
  width: 16px;
  height: 18px;
  position: relative;
  left: 1px;
}

.frame .text-wrapper-90 {
  color: #fff;
  letter-spacing: -.12px;
  height: 11px;
  font-family: Manrope, Helvetica;
  font-size: 8.1px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 5px;
}

.frame .text-wrapper-91 {
  color: #fff;
  letter-spacing: -.09px;
  height: 8px;
  font-family: Manrope, Helvetica;
  font-size: 6.1px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 6px;
}

.frame .overlap-group-18 {
  background-image: url("polygon-2-7.123df2b7.svg");
  background-size: 100% 100%;
  width: 16px;
  height: 18px;
  position: relative;
  left: 1px;
}

.frame .div-90 {
  background-color: #f4f4f4;
  border-radius: 3.78px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 3.78px 5.66px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-92 {
  color: #555;
  letter-spacing: -.12px;
  height: 11px;
  font-family: Manrope, Helvetica;
  font-size: 8.1px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 7px;
}

.frame .div-91 {
  background-color: #555;
  border-radius: 1.89px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.89px;
  width: 100%;
  height: 18.88px;
  padding: 5.66px 15.11px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-93 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.2px;
  margin-bottom: -.25px;
  font-family: Inter, Helvetica;
  font-size: 6.6px;
  font-weight: 800;
  line-height: 8.5px;
  position: relative;
}

.frame .arrow-back-5 {
  width: 6.61px;
  height: 6.61px;
  position: relative;
}

.frame .div-92 {
  align-items: center;
  gap: 163.21px;
  display: inline-flex;
  position: absolute;
  top: 17px;
  left: 157px;
}

.frame .div-93 {
  justify-content: space-between;
  align-items: center;
  width: 334.06px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-94 {
  color: #626262;
  letter-spacing: -.38px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 15.3px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-94 {
  border-radius: 1.91px;
  flex: none;
  align-items: center;
  gap: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .div-wrapper-13 {
  border: .48px solid #626262;
  border-radius: 1.91px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  padding: 5.73px 7.64px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-95 {
  color: #626262;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: 9.5px;
  position: relative;
}

.frame .icon-button-2 {
  width: 21px;
  height: 21px;
  position: relative;
}

.frame .div-95 {
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: .24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19.09px;
  width: 137px;
  height: 449px;
  padding: 5.73px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-96 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  width: 134.58px;
  padding: 7.64px 19.09px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .img-22 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3692.72px;
  margin-left: -4288px;
  position: relative;
}

.frame .text-wrapper-96 {
  color: #575757;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-97 {
  background-color: #f5f5f5;
  flex: none;
  align-items: center;
  gap: 3.82px;
  width: 134.58px;
  padding: 7.64px 19.09px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .img-23 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3723.27px;
  margin-left: -4288px;
  position: relative;
}

.frame .div-98 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  width: 134.58px;
  padding: 7.64px 19.09px;
  display: flex;
  position: relative;
}

.frame .rectangle-5 {
  border: .95px solid #575757;
  border-radius: .95px;
  width: 12px;
  height: 11px;
  position: absolute;
  top: 2px;
  left: 1px;
}

.frame .polygon-4 {
  width: 3px;
  height: 4px;
  position: absolute;
  top: -3756px;
  left: -4300px;
}

.frame .img-24 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3784.35px;
  margin-left: -4288px;
  position: relative;
}

.frame .group-4 {
  width: 10px;
  height: 10px;
  position: relative;
  top: 3px;
  left: 3px;
}

.frame .overlap-group-19 {
  width: 12px;
  height: 12px;
  position: relative;
  top: -1px;
  left: -1px;
}

.frame .ellipse-27 {
  border: .95px solid #575757;
  border-radius: 2.43px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  left: 3px;
}

.frame .ellipse-28 {
  border: .95px solid #575757;
  border-radius: 2.43px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 3px;
  left: 0;
}

.frame .ellipse-29 {
  border: .95px solid #575757;
  border-radius: 2.43px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.frame .ellipse-30 {
  border: .95px solid #575757;
  border-radius: 2.43px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 3px;
}

.frame .group-5 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 11px;
  height: 11px;
  position: relative;
  top: -3851px;
  left: -4305px;
}

.frame .div-99 {
  background-color: #f3f3f3;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 15.27px;
  width: 100%;
  padding: 11.45px 19.09px 15.27px;
  display: flex;
  position: relative;
}

.frame .div-100 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .div-101 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  width: 96.4px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-97 {
  color: #555;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .arrow-drop-down {
  width: 11.45px;
  height: 11.45px;
  margin-top: -3906.52px;
  margin-left: -4277.18px;
  position: relative;
}

.frame .div-102 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 7.64px;
  display: inline-flex;
  position: relative;
}

.frame .frame-wrapper-28 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  width: 96.4px;
  padding: 0 5.73px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-98 {
  color: #555;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .arrow-drop-down-2 {
  width: 11.45px;
  height: 11.45px;
  margin-top: -3923.7px;
  margin-left: -4278.91px;
  position: relative;
}

.frame .img-25 {
  flex: none;
  width: 96.4px;
  margin-top: -3906.52px;
  margin-left: -4307.09px;
  position: relative;
}

.frame .arrow-drop-down-3 {
  width: 9.54px;
  height: 9.54px;
  margin-top: -3965.7px;
  margin-left: -4293.91px;
  position: relative;
}

.frame .img-26 {
  flex: none;
  width: 96.4px;
  margin-top: -3950.43px;
  margin-left: -4307.09px;
  position: relative;
}

.frame .div-wrapper-14 {
  background-color: #555;
  border-radius: 1.91px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 100%;
  height: 19.09px;
  padding: 5.73px 15.27px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-99 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.16px;
  margin-bottom: -.2px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 800;
  line-height: 8.6px;
  position: relative;
}

.frame .div-103 {
  background-color: #fff;
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .24px;
  justify-content: space-between;
  align-items: center;
  width: 687px;
  padding: 7.64px 19.09px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-wrapper-15 {
  border-radius: 2.52px;
  width: 37.17px;
  height: 23.76px;
  position: relative;
}

.frame .text-wrapper-100 {
  color: #000;
  letter-spacing: -.3px;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 15.1px;
  position: absolute;
  top: 4px;
  left: 0;
}

.frame .div-104 {
  flex: none;
  align-items: center;
  gap: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .div-105 {
  flex: none;
  align-items: center;
  gap: 3.82px;
  padding: 0 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .ellipse-wrapper-3 {
  background-color: #d9d9d9;
  border-radius: 11.45px;
  width: 22.91px;
  height: 22.91px;
  position: relative;
}

.frame .ellipse-31 {
  background-color: #000;
  border: .48px solid #fff;
  border-radius: 2.86px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 17px;
  left: 17px;
}

.frame .text-wrapper-101 {
  color: #575757;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .resources-user-view {
  background-color: #fff;
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 705px;
  height: 506px;
  position: absolute;
  top: 1656px;
  left: 822px;
  overflow: hidden;
}

.frame .overlap-11 {
  width: 689px;
  height: 449px;
  position: absolute;
  top: 41px;
  left: -1px;
}

.frame .rectangle-6 {
  background-color: #fbfaf8;
  width: 553px;
  height: 447px;
  position: absolute;
  top: 1px;
  left: 136px;
}

.frame .div-106 {
  background-color: #fff;
  border: .24px solid #d9d9d9;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 11.46px;
  width: 156.6px;
  padding: 7.64px 7.64px 11.46px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 514px;
  overflow: hidden;
}

.frame .div-107 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-102 {
  color: #000;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .subtitle-wrapper {
  background-color: #a4a4a4;
  border-radius: 15.28px;
  flex: none;
  align-items: flex-start;
  gap: 4.77px;
  padding: 2.86px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .subtitle {
  color: #fff;
  letter-spacing: -.11px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .frame-wrapper-29 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.73px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-30 {
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 19.1px;
  display: flex;
  position: relative;
}

.frame .div-108 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.frame .avatar-w-photo-6 {
  background-image: url("avatar-w-photo-4.27984aa2.png");
  background-position: 50%;
  background-size: cover;
  border: .48px solid #0000001a;
  border-radius: 476.96px;
  width: 17.19px;
  height: 17.19px;
  position: relative;
}

.frame .outside-stroke-2 {
  border: .95px solid;
  border-color: var(--black-white-white);
  border-radius: 9.55px;
  width: 19px;
  height: 19px;
  position: relative;
  top: -1px;
  left: -1px;
}

.frame .avatar-w-photo-7 {
  background-image: url("avatar-w-photo-3.1cd8085b.png");
  background-position: 50%;
  background-size: cover;
  border: .48px solid #0000001a;
  border-radius: 476.96px;
  width: 17.19px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
}

.frame .avatar-w-photo-8 {
  background-image: url("avatar-w-photo-2.18fbbe4d.png");
  background-position: 50%;
  background-size: cover;
  border: .48px solid #0000001a;
  border-radius: 476.96px;
  width: 17.19px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
}

.frame .avatar-w-photo-9 {
  background-image: url("avatar-w-photo-1.f4a43f94.png");
  background-position: 50%;
  background-size: cover;
  border: .48px solid #0000001a;
  border-radius: 476.96px;
  width: 17.19px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
}

.frame .avatar-w-photo-wrapper-2 {
  flex: none;
  align-items: flex-start;
  gap: 3.82px;
  margin-left: -2.86px;
  display: inline-flex;
  position: relative;
}

.frame .avatar-w-photo-10 {
  background-image: url("avatar-w-photo.f4e268a0.png");
  background-position: 50%;
  background-size: cover;
  border: .48px solid #0000001a;
  border-radius: 476.96px;
  width: 17.19px;
  height: 17.19px;
  position: relative;
}

.frame .number-wrapper {
  border: .48px solid;
  border-color: var(--light-gray-light-gray-1);
  background-color: #fff;
  border-radius: 476.96px;
  width: 17.19px;
  height: 17.19px;
  margin-left: -2.86px;
  position: relative;
}

.frame .number-3 {
  color: var(--dark-gray-dark-gray-1);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  line-height: 7.6px;
  position: absolute;
  top: 4px;
  left: 1px;
}

.frame .frame-wrapper-31 {
  background-color: #4b4b4b;
  border: .24px solid #4b4b4b;
  border-radius: 14.32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .95px;
  width: 41.54px;
  height: 19.1px;
  padding: 5.73px 9.55px 5.73px 5.73px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-109 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  margin: -1.91px -1.05px;
  display: inline-flex;
  position: relative;
}

.frame .add-5 {
  width: 11.46px;
  height: 11.46px;
  position: relative;
}

.frame .comments {
  border-radius: 5.73px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.64px;
  width: 157px;
  padding: 7.64px 5.73px;
  display: flex;
  position: absolute;
  top: 178px;
  left: 514px;
  overflow: hidden;
}

.frame .div-110 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.55px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .title-wrapper {
  flex: none;
  align-items: center;
  gap: 5.73px;
  width: 141.32px;
  display: flex;
  position: relative;
}

.frame .title-4 {
  color: var(--neutral-800);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .div-111 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.64px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .thread-line {
  width: 1px;
  height: 161px;
  position: absolute;
  top: 18px;
  left: 7px;
}

.frame .div-112 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.82px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .comment {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 1.91px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .avatar-9 {
  object-fit: cover;
  width: 15.28px;
  height: 15.28px;
  position: relative;
}

.frame .content-5 {
  background-color: var(--neutral-50);
  border-radius: 4.77px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.73px;
  padding: 3.82px 7.64px;
  display: flex;
  position: relative;
}

.frame .paragraph-2 {
  color: var(--neutral-800);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .text-wrapper-103 {
  color: var(--neutral-500);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.2px;
  font-family: Manrope, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .settings {
  width: 14.8px;
  height: 9.55px;
  position: relative;
}

.frame .group-6 {
  width: 10px;
  height: 2px;
  position: relative;
  top: 3px;
  left: 2px;
}

.frame .ellipse-32 {
  background-color: var(--neutral-300);
  border-radius: 1.19px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .ellipse-33 {
  background-color: var(--neutral-300);
  border-radius: 1.19px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 4px;
}

.frame .ellipse-34 {
  background-color: var(--neutral-300);
  border-radius: 1.19px;
  width: 2px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 8px;
}

.frame .dropdown-2 {
  border-radius: 3.82px;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 15.28px;
  display: flex;
  position: relative;
}

.frame .label-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 4.77px;
  padding: 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .label-7 {
  color: #818181;
  letter-spacing: .11px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .icons-30 {
  width: 9.55px;
  height: 9.55px;
  position: relative;
}

.frame .comment-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 1.91px;
  width: 100%;
  padding: 0 0 0 11.46px;
  display: flex;
  position: relative;
}

.frame .input-field {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.82px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .search-bar-3 {
  border: .48px solid #d9d9d9;
  border-radius: 1.91px;
  flex: 1;
  align-items: center;
  gap: 3.82px;
  height: 19.1px;
  padding: 3.82px 5.73px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-104 {
  color: #909090;
  letter-spacing: 0;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .CTA-3 {
  background-color: #575757;
  border-radius: 2.86px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.91px;
  height: 19.1px;
  padding: 2.86px 7.64px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-105 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  line-height: 7.6px;
  position: relative;
}

.frame .slider {
  width: 3px;
  height: 122px;
  position: absolute;
  top: -1px;
  left: 153px;
}

.frame .rectangle-7 {
  background-color: #b8c0cc;
  border-radius: 47.74px;
  width: 1px;
  height: 20px;
  position: relative;
  top: 28px;
  left: 1px;
}

.frame .div-113 {
  justify-content: space-between;
  align-items: center;
  width: 339px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 151px;
}

.frame .div-wrapper-16 {
  flex: none;
  align-items: center;
  gap: 68.75px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-106 {
  color: #626262;
  letter-spacing: -.29px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .img-27 {
  width: 15.28px;
  height: 15.28px;
  position: relative;
}

.frame .div-114 {
  justify-content: space-between;
  align-items: center;
  width: 63.02px;
  display: flex;
  position: relative;
}

.frame .icon-button-3 {
  width: 21.01px;
  height: 21.01px;
  position: relative;
}

.frame .rectangle-8 {
  background-color: #d9d9d9;
  border-radius: 5.73px;
  width: 339px;
  height: 354px;
  position: absolute;
  top: 60px;
  left: 151px;
}

.frame .line-21 {
  width: 1px;
  height: 447px;
  position: absolute;
  top: 1px;
  left: 502px;
}

.frame .line-22 {
  width: 366px;
  height: 1px;
  position: absolute;
  top: 49px;
  left: 136px;
}

.frame .div-115 {
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: .24px;
  flex-direction: column;
  align-items: flex-start;
  width: 137px;
  height: 449px;
  padding: 5.73px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-116 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 100%;
  padding: 7.64px 19.1px;
  display: flex;
  position: relative;
}

.frame .div-117 {
  background-color: #f5f5f5;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.82px;
  width: 100%;
  padding: 7.64px 19.1px;
  display: flex;
  position: relative;
}

.frame .polygon-wrapper {
  border: .95px solid #575757;
  border-radius: .95px;
  width: 13px;
  height: 11px;
  position: relative;
  top: 2px;
  left: 1px;
}

.frame .polygon-5 {
  width: 3px;
  height: 3px;
  position: absolute;
  top: 3px;
  left: 4px;
}

.frame .div-118 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.46px;
  width: 157px;
  padding: 0 7.64px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 514px;
  overflow: hidden;
}

.frame .top-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 141.32px;
  display: flex;
  position: relative;
}

.frame .title-5 {
  color: var(--neutral-800);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .paragraph-3 {
  color: var(--neutral-800);
  letter-spacing: 0;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: 9.5px;
  position: relative;
}

.frame .text-wrapper-107 {
  color: #9f9f9f;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: 9.5px;
  position: relative;
}

.frame .base-status-badge {
  background-color: var(--infosurface);
  border-radius: 47.74px;
  flex: none;
  align-items: flex-start;
  gap: 4.77px;
  padding: 2.86px 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-108 {
  color: var(--infotext);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: 6.7px;
  position: relative;
}

.frame .div-119 {
  background-color: #fff;
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .24px;
  justify-content: space-between;
  align-items: center;
  width: 688px;
  padding: 7.64px 19.1px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .ellipse-wrapper-4 {
  background-color: #d9d9d9;
  border-radius: 11.46px;
  width: 22.92px;
  height: 22.92px;
  position: relative;
}

.frame .speaker {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 237px;
  height: 506px;
  position: absolute;
  top: 1656px;
  left: 554px;
  overflow: hidden;
}

.frame .overlap-12 {
  width: 220px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .rectangle-9 {
  background-color: var(--x01-neutral10);
  width: 220px;
  height: 391px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-120 {
  background-image: url("base-1.b06eee27.png");
  background-position: 50%;
  background-size: cover;
  width: 220px;
  height: 391px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-121 {
  align-items: flex-start;
  gap: 7.33px;
  display: inline-flex;
  position: absolute;
  top: 15px;
  left: 7px;
}

.frame .frame-wrapper-32 {
  flex: none;
  align-items: flex-start;
  gap: 9.77px;
  display: inline-flex;
  position: relative;
}

.frame .heading-and {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.01px;
  width: 173.64px;
  display: flex;
  position: relative;
}

.frame .heading-and-text {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.01px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .heading-and-icon {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.68px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .heading {
  color: #000;
  letter-spacing: 0;
  flex: 1;
  margin-top: -.42px;
  font-family: Inter, Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  line-height: 13.4px;
  position: relative;
}

.frame .div-122 {
  width: 216px;
  height: 93px;
  position: absolute;
  top: 307px;
  left: 2px;
}

.frame .overlap-13 {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .rectangle-10 {
  background-color: #0003;
  border-radius: 2.44px;
  width: 62px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 7px;
}

.frame .arrow-back-ios-new-wrapper {
  background-color: #2b2b2b;
  border-radius: 9.77px;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  left: 0;
}

.frame .arrow-back-ios-new {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .dots-horizontal-2 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  left: 56px;
}

.frame .dots-horizontal-wrapper {
  background-color: #0003;
  border-radius: 2.44px;
  width: 62px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 77px;
}

.frame .dots-horizontal-3 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  left: 49px;
}

.frame .overlap-14 {
  width: 69px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 147px;
}

.frame .rectangle-11 {
  background-color: #0003;
  border-radius: 2.44px;
  width: 62px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .arrow-back-ios-new-wrapper-2 {
  background-color: var(--x01-neutral20);
  border-radius: 9.77px;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  left: 54px;
  transform: rotate(-180deg);
}

.frame .arrow-back-ios-new-2 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.frame .div-123 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 75px;
  left: 13px;
}

.frame .div-124 {
  flex: none;
  align-items: center;
  gap: .61px;
  display: inline-flex;
  position: relative;
}

.frame .ellipse-wrapper-5 {
  width: 9.77px;
  height: 9.77px;
  position: relative;
}

.frame .ellipse-35 {
  width: 4px;
  height: 4px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.frame .text-wrapper-109 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  line-height: 12.2px;
  position: relative;
}

.frame .text-wrapper-110 {
  color: var(--x01-neutral70);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  line-height: 5.5px;
  position: relative;
}

.frame .div-125 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 75px;
  left: 83px;
}

.frame .div-126 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 75px;
  left: 153px;
}

.frame .div-127 {
  background-color: #0003;
  border-radius: 4.89px;
  width: 200px;
  height: 240px;
  position: absolute;
  top: 54px;
  left: 10px;
}

.frame .div-128 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.44px;
  display: inline-flex;
  position: absolute;
  top: 206px;
  left: 63px;
}

.frame .div-129 {
  flex: none;
  align-items: flex-start;
  gap: 1.07px;
  display: inline-flex;
  position: relative;
}

.frame .ellipse-wrapper-6 {
  width: 10.71px;
  height: 10.71px;
  position: relative;
}

.frame .text-wrapper-111 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.54px;
  font-family: Inter, Helvetica;
  font-size: 7.3px;
  font-weight: 600;
  line-height: 10.7px;
  position: relative;
}

.frame .div-wrapper-17 {
  flex: none;
  align-items: flex-start;
  gap: 5.35px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-112 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.54px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: 10.7px;
  position: relative;
}

.frame .div-wrapper-18 {
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 2.14px;
  justify-content: center;
  align-items: center;
  gap: 5.35px;
  padding: 3.21px 4.28px;
  display: inline-flex;
  position: absolute;
  top: 6px;
  left: 160px;
  overflow: hidden;
}

.frame .text-wrapper-113 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.54px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: 10.7px;
  position: relative;
}

.frame .toolbar {
  background-color: #000000a3;
  align-items: center;
  gap: 7.33px;
  width: 222px;
  height: 40px;
  padding: 7.33px 14.66px;
  display: flex;
  position: absolute;
  top: 450px;
  left: 0;
}

.frame .frame-wrapper-33 {
  background-color: #a3a3a3;
  border-radius: 2.64px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 7.33px 4.76px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-130 {
  width: 11.89px;
  height: 11.91px;
  margin-top: -.62px;
  margin-bottom: -.62px;
  position: relative;
}

.frame .img-28 {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.frame .keyboard-voice-wrapper {
  background-color: #5d5d5d;
  border-radius: 2.64px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  padding: 7.33px 4.76px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .keyboard-voice {
  width: 11.89px;
  height: 11.89px;
  margin-bottom: -1.22px;
  position: relative;
}

.frame .frame-wrapper-34 {
  background-color: #5d5d5d;
  border-radius: 2.64px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 7.33px 4.76px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .overlap-group-20 {
  width: 11px;
  height: 11px;
  position: relative;
  left: 1px;
}

.frame .chat-bubble {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 1px;
  left: 0;
}

.frame .div-wrapper-19 {
  background-color: #d9d9d9;
  border-radius: 2.38px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  left: 6px;
}

.frame .text-wrapper-114 {
  color: #fff;
  letter-spacing: -.01px;
  white-space: nowrap;
  width: 2px;
  height: 3px;
  font-family: Inter, Helvetica;
  font-size: 3.6px;
  font-weight: 700;
  line-height: 3.6px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.frame .div-131 {
  align-items: center;
  gap: 4.89px;
  display: inline-flex;
  position: absolute;
  top: 424px;
  left: 68px;
}

.frame .overlap-group-wrapper-3 {
  background-color: #dededea1;
  border-radius: 6.11px;
  width: 12.22px;
  height: 12.22px;
  position: relative;
}

.frame .overlap-group-21 {
  background-color: #dededea1;
  border-radius: 5.09px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  left: 1px;
}

.frame .ellipse-36 {
  background-color: #000;
  border-radius: 3.56px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 2px;
  left: 2px;
}

.frame .div-132 {
  flex: none;
  align-items: center;
  gap: 3.05px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-115 {
  color: #575757;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 7.3px;
  font-weight: 700;
  line-height: 8.8px;
  position: relative;
}

.frame .course-details {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 249px;
  height: 506px;
  position: absolute;
  top: 1112px;
  left: 1696px;
  overflow: hidden;
}

.frame .div-133 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.15px;
  width: 211px;
  display: flex;
  position: absolute;
  top: 11px;
  left: 10px;
  overflow: hidden;
}

.frame .frame-wrapper-35 {
  flex: none;
  align-items: flex-start;
  gap: 10.3px;
  display: inline-flex;
  position: relative;
}

.frame .arrow-back-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 5.15px;
  display: inline-flex;
  position: relative;
}

.frame .arrow-back-6 {
  width: 15.45px;
  height: 15.45px;
  position: relative;
}

.frame .div-134 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.14px;
  width: 210.74px;
  padding: 0 4.07px;
  display: flex;
  position: relative;
}

.frame .div-wrapper-20 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.15px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-116 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  width: 190.58px;
  margin-top: -.64px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  line-height: 14.4px;
  position: relative;
}

.frame .content-6 {
  flex: none;
  align-items: center;
  gap: 2.58px;
  display: inline-flex;
  position: relative;
}

.frame .avatar-10 {
  background-image: url("subtract.d11e82f1.svg");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.frame .name-11 {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 9.3px;
  position: relative;
}

.frame .container-menu {
  align-items: center;
  width: 207px;
  height: 21px;
  display: flex;
  position: absolute;
  top: 227px;
  left: 11px;
  overflow-x: scroll;
}

.frame .navlink {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.15px;
  padding: 5.15px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-117 {
  color: var(--x01-neutral70);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 9.3px;
  position: relative;
}

.frame .navlink-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 2.58px;
  padding: 5.15px;
  display: inline-flex;
  position: relative;
}

.frame .navlink-3 {
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1.29px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.15px;
  padding: 5.15px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-118 {
  color: #1c1b1f;
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.25px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 9.3px;
  position: relative;
}

.frame .frame-wrapper-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10.3px;
  width: 207px;
  display: flex;
  position: absolute;
  top: 256px;
  left: 12px;
}

.frame .div-135 {
  background-color: var(--x01-neutral98);
  border: .64px solid;
  border-color: var(--x02-neutral80);
  border-radius: 7.73px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12.88px;
  width: 100%;
  padding: 15.45px 24.59px;
  display: flex;
  position: relative;
}

.frame .overlap-wrapper-2 {
  width: 121.04px;
  height: 116.89px;
  position: relative;
}

.frame .overlap-15 {
  width: 92px;
  height: 89px;
  position: relative;
  top: 13px;
  left: 14px;
}

.frame .overlap-group-wrapper-4 {
  width: 92px;
  height: 89px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .overlap-group-22 {
  width: 107px;
  height: 107px;
  position: relative;
  top: -7px;
  left: -7px;
}

.frame .ellipse-37 {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .ellipse-38 {
  width: 107px;
  height: 107px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-119 {
  color: #000;
  letter-spacing: -.43px;
  text-align: center;
  white-space: nowrap;
  width: 45px;
  height: 48px;
  font-family: Inter, Helvetica;
  font-size: 36.1px;
  font-weight: 700;
  line-height: 50.5px;
  position: absolute;
  top: 19px;
  left: 24px;
}

.frame .div-136 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10.3px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-137 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.08px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-120 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  text-align: center;
  align-self: stretch;
  margin-top: -.64px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 600;
  line-height: 12.4px;
  position: relative;
}

.frame .text-wrapper-121 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  text-align: center;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 400;
  line-height: 9.3px;
  position: relative;
}

.frame .div-wrapper-21 {
  border: .64px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.58px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.15px;
  width: 100%;
  padding: 10.3px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-122 {
  color: var(--x01-neutral40);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.64px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 500;
  line-height: 10.8px;
  position: relative;
}

.frame .video-player {
  background-color: #0000000a;
  border-radius: 5.27px;
  width: 207px;
  height: 120px;
  position: absolute;
  top: 95px;
  left: 12px;
  overflow: hidden;
}

.frame .div-138 {
  align-items: center;
  gap: 5.15px;
  display: inline-flex;
  position: absolute;
  top: 100px;
  left: 10px;
}

.frame .pause {
  width: 10.3px;
  height: 10.3px;
  position: relative;
}

.frame .overlap-group-wrapper-5 {
  background-color: #5555554c;
  border-radius: 2.64px;
  width: 177.27px;
  height: 3.86px;
  position: relative;
}

.frame .overlap-group-23 {
  border-radius: 2.58px;
  width: 177px;
  height: 4px;
  position: relative;
}

.frame .buffering {
  border-radius: 2.58px;
  width: 177px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .buffering-progress {
  opacity: .5;
  background-color: #555;
  border-radius: 2.58px;
  width: 133px;
  height: 4px;
}

.frame .progress-line {
  background-color: #555;
  border-radius: 2.58px;
  width: 80px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-139 {
  align-items: center;
  gap: 5.15px;
  display: inline-flex;
  position: absolute;
  top: 5px;
  left: 129px;
}

.frame .div-wrapper-22 {
  background-color: var(--x01-neutral40);
  border-radius: 16.74px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.44px;
  padding: 3.86px 5.15px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-123 {
  color: var(--x01-neutral100);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.64px;
  font-family: Inter, Helvetica;
  font-size: 6.4px;
  font-weight: 500;
  line-height: 7.7px;
  position: relative;
}

.frame .open-in-full-wrapper {
  background-color: var(--x01-neutral40);
  border-radius: 15.94px;
  width: 15.45px;
  height: 15.45px;
  position: relative;
}

.frame .open-in-full {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.frame .auditorium-sessions {
  background-color: var(--x01-neutral10);
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 705px;
  height: 506px;
  position: absolute;
  top: 1656px;
  left: 1557px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.frame .overlap-16 {
  width: 568px;
  height: 1047px;
  position: absolute;
  top: 38px;
  left: 119px;
}

.frame .base {
  background-color: #fff;
  width: 568px;
  height: 912px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .header {
  align-items: center;
  gap: 88.29px;
  width: 526px;
  display: flex;
  position: absolute;
  top: 19px;
  left: 23px;
  overflow: hidden;
}

.frame .text-wrapper-124 {
  color: #575757;
  letter-spacing: 0;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 15.3px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .div-140 {
  flex: none;
  align-items: flex-start;
  gap: 7.64px;
  display: inline-flex;
  position: relative;
}

.frame .text-input-2 {
  border: .48px solid #a3a3a3;
  border-radius: 1.91px;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 5.73px;
  display: flex;
  position: relative;
}

.frame .div-141 {
  flex: none;
  align-items: flex-start;
  gap: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .search {
  width: 9.54px;
  height: 9.54px;
  position: relative;
}

.frame .value-2 {
  color: #a3a3a3;
  letter-spacing: -.03px;
  width: 73.49px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 400;
  line-height: 9.2px;
  position: relative;
}

.frame .holder-wrapper {
  background-color: #000;
  border-radius: 2.39px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 74.45px;
  height: 22.91px;
  padding: 7.64px 9.54px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .holder {
  flex: none;
  align-items: center;
  gap: .95px;
  margin: -1.91px -2.02px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-125 {
  color: #fff;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .div-142 {
  flex-direction: column;
  align-items: center;
  gap: 11.45px;
  width: 526px;
  height: 990px;
  display: flex;
  position: absolute;
  top: 57px;
  left: 23px;
}

.frame .nav-bar {
  border-color: #f1f1f1;
  border-bottom-style: solid;
  border-bottom-width: .48px;
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 21.95px;
  display: flex;
  position: relative;
}

.frame .nav-link-11 {
  border-color: #575757;
  border-bottom-style: solid;
  border-bottom-width: .95px;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  width: 58.22px;
  height: 21.95px;
  padding: 3.82px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-126 {
  color: #575757;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: 10.3px;
  position: relative;
}

.frame .nav-link-12 {
  opacity: .5;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  width: 58.22px;
  height: 21.95px;
  padding: 3.82px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-127 {
  color: #555;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: 10.3px;
  position: relative;
}

.frame .nav-link-13 {
  opacity: .5;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  height: 21.95px;
  padding: 3.82px 7.64px;
  display: inline-flex;
  position: relative;
}

.frame .nav-link-14 {
  opacity: .5;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.82px;
  padding: 5.73px 11.45px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-128 {
  color: #575757;
  letter-spacing: -.03px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.95px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: 10.3px;
  position: relative;
}

.frame .div-143 {
  border-radius: 1.91px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 1.91px;
  padding: 2.86px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-129 {
  color: #c6c6c6;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  line-height: 8px;
  position: relative;
}

.frame .div-144 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.73px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .dropdown-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 5.73px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-130 {
  color: #a1a1a1;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 11.5px;
  position: relative;
}

.frame .text-wrapper-131 {
  color: #575757;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: 11.5px;
  position: relative;
}

.frame .container-events {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 11.45px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .event-2 {
  background-color: #f1f1f1;
  border-radius: 5.73px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4.77px;
  width: 100%;
  padding: 15.27px;
  display: flex;
  position: relative;
}

.frame .element-10 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .div-wrapper-23 {
  flex: none;
  align-items: flex-start;
  gap: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .live {
  background-color: #000;
  border-radius: 2.39px;
  flex: none;
  align-items: flex-start;
  gap: 4.77px;
  padding: .95px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-132 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  line-height: 7.6px;
  position: relative;
}

.frame .holder-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.27px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-133 {
  color: #575757;
  letter-spacing: -.05px;
  width: 181.35px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-134 {
  color: #575757;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .speakers-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-135 {
  color: #575757;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .div-145 {
  background-color: #d9d9d9;
  border-radius: 5.73px;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.frame .name-12 {
  color: #575757;
  letter-spacing: -.17px;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: absolute;
  top: 2px;
  left: 15px;
}

.frame .div-146 {
  background-color: #d9d9d9;
  border-radius: 5.73px;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .name-13 {
  color: #575757;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .chip-speaker-10 {
  background-color: #575757;
  border-radius: 9.54px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 15.27px;
  padding: 1.91px 3.82px 1.91px 1.91px;
  display: flex;
  position: relative;
}

.frame .name-14 {
  color: #fff;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .div-147 {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  display: inline-flex;
  position: relative;
}

.frame .div-148 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 5.73px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .calendar-add-on-wrapper {
  background-color: #a4a4a41f;
  border-radius: 18.13px;
  width: 19.09px;
  height: 19.09px;
  position: relative;
}

.frame .calendar-add-on {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.frame .CTA-4 {
  all: unset;
  box-sizing: border-box;
  border: .48px solid #a3a3a3;
  border-radius: 1.91px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  padding: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-136 {
  color: #a3a3a3;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  line-height: 9.2px;
  position: relative;
}

.frame .CTA-5 {
  all: unset;
  box-sizing: border-box;
  background-color: #1c1b1f;
  border-radius: 1.91px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  padding: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-137 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 700;
  line-height: 9.2px;
  position: relative;
}

.frame .speakers-5 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .attending {
  align-items: center;
  height: 15.27px;
  display: inline-flex;
  position: relative;
}

.frame .avatar-w-photo-11 {
  background-color: #575757;
  border: .95px solid #f1f1f1;
  border-radius: 476.75px;
  width: 12.89px;
  height: 13.36px;
  position: relative;
}

.frame .avatar-w-photo-12 {
  background-color: #575757;
  border: .95px solid #f1f1f1;
  border-radius: 476.75px;
  width: 13.36px;
  height: 13.36px;
  margin-left: -1.91px;
  position: relative;
}

.frame .avatar-w-photo-13 {
  background-color: #575757;
  border: .95px solid #f1f1f1;
  border-radius: 476.75px;
  width: 12.89px;
  height: 13.36px;
  margin-left: -1.91px;
  position: relative;
}

.frame .avatar-w-photo-14 {
  background-color: #a3a3a3;
  border: .95px solid #f1f1f1;
  border-radius: 476.75px;
  width: 15.36px;
  height: 15.36px;
  margin: -.05px -1px -.05px -1.91px;
  position: relative;
}

.frame .text-wrapper-138 {
  color: #fff;
  letter-spacing: -.21px;
  height: 6px;
  font-family: Inter, Helvetica;
  font-size: 4.8px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 1px;
  left: 2px;
}

.frame .menu-3 {
  background-color: #f1f1f1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 119px;
  height: 451px;
  padding: 0 0 11.45px;
  display: flex;
  position: absolute;
  top: 38px;
  left: 0;
}

.frame .div-149 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 119.31px;
  display: flex;
  position: relative;
}

.frame .nav-link-15 {
  flex: none !important;
  gap: 2.04px !important;
  width: 119.31px !important;
  padding: 7.64px 18.33px !important;
}

.frame .nav-link-16 {
  font-family: Inter, Helvetica !important;
  font-size: 7.6px !important;
  font-style: unset !important;
  letter-spacing: -.02px !important;
  font-weight: 500 !important;
  line-height: 9.2px !important;
}

.frame .nav-link-17 {
  flex: none;
  align-items: center;
  gap: 2.04px;
  width: 119.31px;
  padding: 7.64px 18.33px;
  display: flex;
  position: relative;
}

.frame .icons-31 {
  width: 11.45px !important;
  height: 11.45px !important;
}

.frame .icons-32 {
  width: 8px !important;
  height: 10px !important;
  top: 1px !important;
  left: 2px !important;
}

.frame .dashboard-4 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  line-height: 9.2px;
  position: relative;
}

.frame .nav-link-18 {
  background-color: #555 !important;
  flex: none !important;
  gap: 2.04px !important;
  width: 119.31px !important;
  padding: 7.64px 18.33px !important;
}

.frame .nav-link-19 {
  color: #fff !important;
  font-family: Inter, Helvetica !important;
  font-size: 7.6px !important;
  font-style: unset !important;
  letter-spacing: -.02px !important;
  font-weight: 500 !important;
  line-height: 9.2px !important;
}

.frame .icons-33 {
  width: 9px !important;
  height: 9px !important;
}

.frame .icons-34 {
  border: .95px solid !important;
  border-radius: 2.07px !important;
  width: 4px !important;
  height: 4px !important;
  left: 3px !important;
}

.frame .icons-35 {
  border: .95px solid !important;
  border-radius: 2.07px !important;
  width: 4px !important;
  height: 4px !important;
  top: 3px !important;
}

.frame .icons-36 {
  border: .95px solid !important;
  border-radius: 2.07px !important;
  width: 4px !important;
  height: 4px !important;
  top: 3px !important;
  left: 5px !important;
}

.frame .icons-37 {
  border: .95px solid !important;
  border-radius: 2.07px !important;
  width: 4px !important;
  height: 4px !important;
  top: 5px !important;
  left: 3px !important;
}

.frame .icons-38 {
  width: 7px !important;
  height: 7px !important;
  top: 2px !important;
  left: 2px !important;
}

.frame .nav-link-20 {
  flex: none !important;
  gap: 2.04px !important;
  width: 117.88px !important;
  padding: 7.64px 18.33px !important;
}

.frame .extra-4 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.43px;
  width: 100%;
  padding: 1.91px 19.09px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-139 {
  color: #000;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 6.9px;
  position: relative;
}

.frame .interpunct-3 {
  width: 3.82px;
  height: 3.82px;
  position: relative;
}

.frame .ellipse-39 {
  background-color: #d9d9d9;
  border-radius: .95px;
  width: 2px;
  height: 2px;
  position: relative;
  top: 1px;
  left: 1px;
}

.frame .div-150 {
  background-color: #f1f1f1;
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .24px;
  justify-content: space-between;
  align-items: center;
  width: 687px;
  padding: 9.54px 19.09px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-140 {
  color: #000;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 15.1px;
  position: relative;
}

.frame .div-151 {
  justify-content: flex-end;
  align-items: center;
  gap: 11.45px;
  width: 101.17px;
  padding: 1.91px 0;
  display: flex;
  position: relative;
}

.frame .div-152 {
  background-color: #0003;
  border-radius: 7.64px;
  width: 15.27px;
  height: 15.27px;
  position: relative;
}

.frame .text-wrapper-141 {
  color: var(--x01-neutral20);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  line-height: 9.2px;
  position: relative;
}

.frame .dashboard-admin-view {
  background-color: #fff;
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 705px;
  height: 506px;
  position: absolute;
  top: 1114px;
  left: -44px;
  overflow: hidden;
}

.frame .line-23 {
  width: 1px;
  height: 448px;
  position: absolute;
  top: 42px;
  left: 547px;
}

.frame .overlap-17 {
  width: 546px;
  height: 507px;
  position: absolute;
  top: 41px;
  left: -1px;
}

.frame .overlap-18 {
  width: 546px;
  height: 449px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-153 {
  justify-content: space-between;
  align-items: center;
  width: 416px;
  padding: 0 15.27px;
  display: flex;
  position: absolute;
  top: 77px;
  left: 131px;
}

.frame .div-wrapper-24 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  padding: 3.82px 0;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-142 {
  color: #3f394061;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.95px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-wrapper-25 {
  border-color: #3f3a40;
  border-bottom-style: solid;
  border-bottom-width: .95px;
  flex: none;
  align-items: flex-start;
  gap: 4.77px;
  padding: 3.82px 0;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-143 {
  color: #3f3a40;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.95px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-wrapper-26 {
  flex: none;
  align-items: flex-start;
  gap: 4.77px;
  padding: 3.82px 0;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-144 {
  color: #3f394061;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .frame-wrapper-37 {
  align-items: center;
  width: 122.65px;
  display: flex;
  position: relative;
}

.frame .text-input-3 {
  border: .24px solid #3f3a408f;
  border-radius: 1.91px;
  flex: 1;
  align-items: center;
  gap: 2.86px;
  padding: 5.73px 3.82px;
  display: flex;
  position: relative;
}

.frame .value-3 {
  color: #3f3a408f;
  letter-spacing: -.04px;
  width: 73.49px;
  margin-top: -.24px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: 11.5px;
  position: relative;
}

.frame .line-24 {
  width: 415px;
  height: 1px;
  position: absolute;
  top: 237px;
  left: 132px;
}

.frame .line-25 {
  width: 415px;
  height: 1px;
  position: absolute;
  top: 375px;
  left: 132px;
}

.frame .div-154 {
  background-color: #f5f5f5;
  border-color: #dadada;
  border-bottom-style: solid;
  border-bottom-width: .48px;
  align-items: center;
  gap: 4.77px;
  width: 415px;
  padding: 9.54px 15.27px;
  display: flex;
  position: absolute;
  top: 1px;
  left: 131px;
}

.frame .div-155 {
  background-color: #0003;
  border-radius: 39.61px;
  width: 57.27px;
  height: 57.27px;
  position: relative;
}

.frame .intro {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  height: 57.27px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-145 {
  color: #3e3940;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 11.5px;
  font-weight: 800;
  line-height: 16px;
  position: relative;
}

.frame .labels-2 {
  flex: none;
  align-items: flex-start;
  gap: 7.64px;
  width: 280.61px;
  display: flex;
  position: relative;
}

.frame .risk {
  background-color: #3e3940;
  border-radius: 1.43px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4.77px;
  padding: .95px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-146 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-156 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  padding: .95px 0;
  display: inline-flex;
  position: relative;
}

.frame .ellipse-40 {
  background-color: #3e3940;
  border-radius: .95px;
  width: 1.91px;
  height: 1.91px;
  position: relative;
}

.frame .text-wrapper-147 {
  color: #3e3940;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 9.4px;
  position: relative;
}

.frame .div-157 {
  align-self: stretch;
  align-items: center;
  gap: 5.73px;
  width: 100%;
  height: 15.75px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-148 {
  color: #3e3940;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 5.7px;
  font-weight: 800;
  line-height: 8px;
  position: relative;
}

.frame .div-wrapper-27 {
  -webkit-backdrop-filter: blur(1.91px) brightness();
  backdrop-filter: blur(1.91px) brightness();
  background-color: #3f3a4052;
  border-radius: 11.93px;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  gap: 4.77px;
  padding: 1.91px 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-149 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  line-height: 8px;
  position: relative;
}

.frame .text-wrapper-150 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: 8px;
  position: relative;
}

.frame .menu-4 {
  background-color: #e6e6e6;
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: .24px;
  width: 132px;
  height: 449px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-158 {
  flex-direction: column;
  align-items: flex-start;
  width: 129.81px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-159 {
  background-color: #cfcfcf;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 100%;
  padding: 5.73px 11.45px 5.73px 19.09px;
  display: flex;
  position: relative;
}

.frame .img-29 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3141px;
  margin-left: -4425px;
  position: relative;
}

.frame .text-wrapper-151 {
  color: #555;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-160 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.82px;
  width: 100%;
  padding: 5.73px 11.45px 5.73px 19.09px;
  display: flex;
  position: relative;
}

.frame .img-30 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3167.72px;
  margin-left: -4425px;
  position: relative;
}

.frame .text-wrapper-152 {
  color: #575757;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-161 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  width: 100%;
  padding: 5.73px 11.45px 5.73px 19.09px;
  display: flex;
  position: relative;
}

.frame .polygon-6 {
  width: 3px;
  height: 4px;
  position: absolute;
  top: -3194px;
  left: -4437px;
}

.frame .img-31 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3221.17px;
  margin-left: -4425px;
  position: relative;
}

.frame .team {
  background-color: #cfcfcf;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 9.54px;
  width: 129.81px;
  padding: 9.54px 0;
  display: flex;
  position: absolute;
  top: 149px;
  left: 0;
  overflow: hidden;
}

.frame .div-162 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.45px;
  width: 100%;
  padding: 0 11.45px 0 19.09px;
  display: flex;
  position: relative;
}

.frame .div-163 {
  flex: none;
  align-items: center;
  gap: 1.91px;
  width: 111.67px;
  margin-right: -12.41px;
  display: flex;
  position: relative;
}

.frame .div-164 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 1.91px;
  display: flex;
  position: relative;
}

.frame .arrow-drop-down-4 {
  width: 11.45px;
  height: 11.45px;
  margin-top: -3318.52px;
  margin-left: -4446px;
  position: relative;
}

.frame .div-165 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 3.82px;
  display: flex;
  position: relative;
}

.frame .avatar-w-photo-15 {
  background-color: #575757;
  border-radius: 476.75px;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .avatar-w-photo-16 {
  background-color: #555;
  border-radius: 476.75px;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .text-wrapper-153 {
  color: #555;
  letter-spacing: -.06px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.16px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .arrow-drop-down-5 {
  width: 11.45px;
  height: 11.45px;
  margin-top: -3354.79px;
  margin-left: -4446px;
  position: relative;
}

.frame .div-166 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: .95px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .add-6 {
  width: 11.45px;
  height: 11.45px;
  margin-top: -3377.7px;
  margin-left: -4444.09px;
  position: relative;
}

.frame .div-wrapper-28 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: .95px;
  width: 100%;
  padding: 3.82px 11.45px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-154 {
  color: #555;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-167 {
  align-items: center;
  gap: 1.91px;
  width: 130px;
  padding: 7.64px 19.09px;
  display: flex;
  position: absolute;
  top: 395px;
  left: 0;
}

.frame .text-wrapper-155 {
  color: #544d56;
  letter-spacing: -.13px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .div-168 {
  align-items: center;
  gap: 3.82px;
  display: inline-flex;
  position: absolute;
  top: 428px;
  left: 15px;
}

.frame .text-wrapper-156 {
  color: #544d56;
  letter-spacing: -.1px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .ellipse-41 {
  background-color: #544d56;
  border-radius: .95px;
  width: 1.91px;
  height: 1.91px;
  position: relative;
}

.frame .div-169 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.45px;
  display: inline-flex;
  position: absolute;
  top: 388px;
  left: 146px;
  overflow: hidden;
}

.frame .text-wrapper-157 {
  color: #000;
  letter-spacing: -.13px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .frame-wrapper-38 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.45px;
  width: 381.78px;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-39 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  gap: 3.82px;
  width: 100%;
  height: 98.31px;
  display: flex;
  position: relative;
}

.frame .div-170 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 11.45px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-40 {
  background-color: #c4c4c4;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 165.6px;
  height: 95.45px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.91px 1.91px #000;
}

.frame .text-wrapper-158 {
  color: #000;
  letter-spacing: -.24px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-159 {
  color: #c4c4c4;
  letter-spacing: .17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  line-height: 7.6px;
  position: relative;
}

.frame .frame-wrapper-41 {
  background-color: #c4c4c4;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 164.64px;
  height: 95.45px;
  margin-right: -136.96px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  box-shadow: 1.91px 1.91px #c9def8;
}

.frame .more-horiz-7 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3556.68px;
  margin-left: -9649.17px;
  position: relative;
}

.frame .div-171 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.64px;
  display: inline-flex;
  position: absolute;
  top: 250px;
  left: 146px;
}

.frame .frame-wrapper-42 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 3.82px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-43 {
  background-color: #d9d9d9;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 162.26px;
  height: 95.45px;
  padding: 9.54px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-172 {
  background-color: #9f9f9fa3;
  border-radius: 5.73px;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .frame-wrapper-44 {
  background-color: #d9d9d9;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 162.26px;
  height: 95.45px;
  margin-right: -127.9px;
  padding: 9.54px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-173 {
  background-image: url("ellipse-1-3.69090f6a.svg");
  background-size: 100% 100%;
  width: 11.45px;
  height: 11.45px;
  position: relative;
}

.frame .div-174 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 11.45px;
  height: 11.45px;
  margin-top: -3480.33px;
  margin-left: -4973.95px;
  position: relative;
}

.frame .frame-wrapper-45 {
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 165.6px;
  height: 95.45px;
  margin-right: -304.95px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.91px 1.91px #806cff;
}

.frame .text-wrapper-160 {
  color: #f5f5f5;
  letter-spacing: .17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 700;
  line-height: 7.6px;
  position: relative;
}

.frame .more-horiz-8 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3480.33px;
  margin-left: -10016.9px;
  position: relative;
}

.frame .frame-wrapper-46 {
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 165.6px;
  height: 95.45px;
  margin-right: -482px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.91px 1.91px #ff6c7d;
}

.frame .more-horiz-9 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3480.33px;
  margin-left: -10329.3px;
  position: relative;
}

.frame .frame-wrapper-47 {
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 164.64px;
  height: 95.45px;
  margin-right: -658.1px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  box-shadow: 1.91px 1.91px #1658d7;
}

.frame .more-horiz-10 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3480.33px;
  margin-left: -10691.3px;
  position: relative;
}

.frame .div-175 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.64px;
  height: 114px;
  display: inline-flex;
  position: absolute;
  top: 112px;
  left: 146px;
}

.frame .course {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  width: 162.26px;
  height: 95.45px;
  position: relative;
  overflow: hidden;
}

.frame .overlap-19 {
  border-radius: 5.73px 5.73px 0 0;
  width: 163px;
  height: 95px;
  position: relative;
}

.frame .image-3 {
  border-radius: 5.73px 5.73px 0 0;
  width: 163px;
  height: 95px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame .icon-button-4 {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 4px;
  left: 147px;
}

.frame .line-wrapper {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 163px;
  height: 1px;
  position: absolute;
  top: -3155px;
  left: -4570px;
}

.frame .line-26 {
  width: 63px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .frame-wrapper-48 {
  flex-direction: column;
  align-items: flex-start;
  gap: 43.43px;
  width: 139.35px;
  height: 76.36px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 11px;
}

.frame .text-16 {
  color: #575757;
  letter-spacing: 0;
  width: 139.35px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  line-height: 11.5px;
  position: relative;
}

.frame .speakers-6 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 23.38px;
  display: flex;
  position: relative;
}

.frame .div-176 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: .95px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-161 {
  color: #575757;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .chip-speaker-wrapper {
  -webkit-backdrop-filter: blur(1.91px) brightness();
  backdrop-filter: blur(1.91px) brightness();
  background-color: #0000009e;
  border-radius: 11.93px;
  flex: none;
  align-items: center;
  gap: 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .name-15 {
  color: #fff;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .div-wrapper-29 {
  background-color: #1c1b1f;
  border-radius: 3.82px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4.77px;
  padding: 5.73px 9.54px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-162 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .overlap-group-24 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 163px;
  height: 1px;
  position: absolute;
  top: -3155px;
  left: -4744px;
}

.frame .course-2 {
  background-color: #9f9f9f3d;
  border-radius: 3.82px;
  width: 162.26px;
  height: 95.45px;
  margin-right: -127.9px;
  position: relative;
  overflow: hidden;
}

.frame .icon-button-5 {
  width: 11px;
  height: 11px;
  position: absolute;
  top: -3266px;
  left: -4770px;
}

.frame .overlap-group-25 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 163px;
  height: 1px;
  position: absolute;
  top: -3155px;
  left: -4917px;
}

.frame .div-177 {
  background-color: #fbfdfd;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.54px;
  width: 556px;
  padding: 11.45px 19.09px 11.45px 15.27px;
  display: flex;
  position: absolute;
  top: 567px;
  left: 130px;
}

.frame .text-wrapper-163 {
  color: #152728;
  letter-spacing: -.17px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .frame-wrapper-49 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.64px;
  width: 523.52px;
  height: 98.31px;
  margin-right: -1.43px;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-50 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.82px;
  width: 100%;
  height: 95.45px;
  display: flex;
  position: relative;
}

.frame .div-178 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.64px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-51 {
  background-color: #edf3f3;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 165.6px;
  height: 95.45px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.91px 1.91px #3c6e71;
}

.frame .div-wrapper-30 {
  background-color: #3c6e71;
  border-radius: 1.91px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  padding: .95px 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .more-horiz-11 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3708.63px;
  margin-left: -8974.75px;
  position: relative;
}

.frame .frame-wrapper-52 {
  background-color: #edf3f3;
  border-radius: 3.82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.73px;
  width: 162.26px;
  height: 95.45px;
  padding: 9.54px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-17 {
  color: #0f1728;
  letter-spacing: 0;
  width: 139.35px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 600;
  line-height: 11.5px;
  position: relative;
}

.frame .div-179 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 11.45px;
  height: 11.45px;
  margin-top: -3773.53px;
  margin-left: -4756.67px;
  position: relative;
}

.frame .div-wrapper-31 {
  background-color: #3c6e71;
  border-radius: 3.82px;
  flex: none;
  align-items: center;
  gap: 4.77px;
  padding: 5.73px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-164 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 6.7px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .course-3 {
  border-radius: 3.82px;
  width: 162.26px;
  height: 95.45px;
  position: relative;
  overflow: hidden;
}

.frame .image-4 {
  object-fit: cover;
  width: 163px;
  height: 115px;
  position: absolute;
  top: -3720px;
  left: -4913px;
}

.frame .text-18 {
  color: #000;
  letter-spacing: 0;
  width: 139.35px;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  line-height: 11.5px;
  position: relative;
}

.frame .text-wrapper-165 {
  color: #555;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .div-wrapper-32 {
  background-color: #000;
  border-radius: 3.82px;
  flex: none;
  align-items: center;
  gap: 4.77px;
  padding: 5.73px 7.64px;
  display: inline-flex;
  position: relative;
}

.frame .frame-wrapper-53 {
  background-color: #edf3f3;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 165.6px;
  height: 95.45px;
  margin-right: -155.1px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1.91px 1.91px #ff6c7d;
}

.frame .more-horiz-12 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3773.53px;
  margin-left: -9959.13px;
  position: relative;
}

.frame .frame-wrapper-54 {
  background-color: #9f9f9f8f;
  border-radius: 3.82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.73px;
  width: 164.64px;
  height: 95.45px;
  margin-right: -327.38px;
  padding: 7.64px 11.45px;
  display: flex;
  position: relative;
  box-shadow: 1.91px 1.91px #1658d7;
}

.frame .more-horiz-13 {
  width: 15.27px;
  height: 15.27px;
  margin-top: -3773.53px;
  margin-left: -10313.3px;
  position: relative;
}

.frame .div-180 {
  background-color: #575757;
  border-radius: 3.82px;
  width: 115px;
  height: 144px;
  position: absolute;
  top: 268px;
  left: 555px;
  overflow: hidden;
  box-shadow: 0 6.2px 15.27px #9696961f;
}

.frame .div-181 {
  align-items: flex-start;
  gap: 5.73px;
  display: inline-flex;
  position: absolute;
  top: 10px;
  left: 10px;
}

.frame .img-wrapper-2 {
  background-color: #b0b0b0;
  border-radius: 13.36px;
  width: 26.72px;
  height: 26.72px;
  position: relative;
}

.frame .sticky-note {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 9px;
  left: 9px;
}

.frame .div-182 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3.82px;
  width: 67.77px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-166 {
  color: #fff;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .arrow-back-7 {
  width: 9.07px;
  height: 9.07px;
  position: relative;
}

.frame .text-wrapper-167 {
  color: #fff;
  letter-spacing: -.09px;
  width: 69.2px;
  margin-right: -1.43px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .div-183 {
  align-items: center;
  gap: 5.73px;
  width: 99px;
  display: flex;
  position: absolute;
  top: 49px;
  left: 10px;
}

.frame .mark-chat-unread {
  width: 10px;
  height: 11px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.frame .div-184 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-168 {
  color: #fff;
  letter-spacing: -.09px;
  width: 54.88px;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 7.6px;
  position: relative;
}

.frame .frame-wrapper-55 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.27px;
  height: 206px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 555px;
}

.frame .frame-wrapper-56 {
  border: .18px solid #9f9f9f;
  border-radius: 4.23px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 19.72px;
  width: 115.49px;
  padding: 8.45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-185 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.63px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-wrapper-33 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.82px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-169 {
  color: #555;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.35px;
  font-family: Inter, Helvetica;
  font-size: 7px;
  font-weight: 600;
  line-height: 11.3px;
  position: relative;
}

.frame .bar-3 {
  border: .18px solid #555;
  border-radius: 1.41px;
  align-items: center;
  gap: .7px;
  width: 24.65px;
  padding: 2.82px;
  display: flex;
  position: relative;
}

.frame .text-19 {
  color: #555;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.04px;
  font-family: Inter, Helvetica;
  font-size: 4.2px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .chevron-3 {
  width: 5.63px;
  height: 5.28px;
  margin-right: -2.32px;
  position: relative;
}

.frame .div-186 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: .7px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-187 {
  border-radius: 4.23px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 2.82px 4.23px;
  display: flex;
  position: relative;
}

.frame .div-188 {
  flex: none;
  align-items: center;
  gap: 2.82px;
  display: inline-flex;
  position: relative;
}

.frame .div-189 {
  background-color: #0003;
  border-radius: 7.04px;
  width: 14.08px;
  height: 14.08px;
  margin-left: -.07px;
  position: relative;
}

.frame .ellipse-42 {
  background-color: #047857;
  border: .5px solid #fff;
  border-radius: 1.76px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 11px;
  left: 10px;
}

.frame .div-190 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.41px;
  display: inline-flex;
  position: relative;
}

.frame .div-191 {
  flex: none;
  align-items: center;
  gap: 1.41px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-170 {
  color: #555;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.35px;
  font-family: Inter, Helvetica;
  font-size: 4.9px;
  font-weight: 600;
  line-height: 6.3px;
  position: relative;
}

.frame .expand-less-3 {
  width: 5.63px;
  height: 5.63px;
  position: relative;
}

.frame .text-wrapper-171 {
  color: #818181;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 4.2px;
  font-weight: 400;
  line-height: 5.6px;
  position: relative;
}

.frame .div-wrapper-34 {
  width: 14.08px;
  height: 14.08px;
  position: relative;
}

.frame .overlap-group-26 {
  width: 12px;
  height: 15px;
  position: relative;
  top: -1px;
  left: 1px;
}

.frame .polygon-7 {
  width: 12px;
  height: 14px;
  position: absolute;
  top: 1px;
  left: 0;
}

.frame .polygon-8 {
  width: 12px;
  height: 11px;
  position: absolute;
  top: 1px;
  left: 0;
}

.frame .text-wrapper-172 {
  color: #fff;
  letter-spacing: -.09px;
  height: 8px;
  font-family: Manrope, Helvetica;
  font-size: 6px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 5px;
}

.frame .text-wrapper-173 {
  color: gray;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 4.2px;
  font-weight: 400;
  line-height: 5.6px;
  position: relative;
}

.frame .overlap-group-27 {
  width: 12px;
  height: 14px;
  position: relative;
  top: -1px;
  left: 1px;
}

.frame .text-wrapper-174 {
  color: #fff;
  letter-spacing: -.09px;
  height: 8px;
  font-family: Manrope, Helvetica;
  font-size: 6px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 4px;
}

.frame .text-wrapper-175 {
  color: #fff;
  letter-spacing: -.07px;
  height: 6px;
  font-family: Manrope, Helvetica;
  font-size: 4.5px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 4px;
}

.frame .div-192 {
  background-color: #f4f4f4;
  border-radius: 2.82px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 2.82px 4.23px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-176 {
  color: #555;
  letter-spacing: -.09px;
  height: 8px;
  font-family: Manrope, Helvetica;
  font-size: 6px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 5px;
}

.frame .div-193 {
  background-color: #555;
  border-radius: 1.41px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.41px;
  width: 100%;
  height: 14.08px;
  padding: 4.23px 11.27px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-177 {
  color: #fff;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.04px;
  margin-bottom: -.33px;
  font-family: Inter, Helvetica;
  font-size: 4.9px;
  font-weight: 800;
  line-height: 6.3px;
  position: relative;
}

.frame .arrow-back-8 {
  width: 4.93px;
  height: 4.93px;
  position: relative;
}

.frame .menu-5 {
  width: 11.45px;
  height: 11.45px;
  margin-top: -3107.82px;
  margin-left: -4444.09px;
  position: relative;
}

.frame .text-wrapper-178 {
  color: #000;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 15.1px;
  position: relative;
}

.frame .auditorium-analytics {
  background-color: #fff;
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 705px;
  height: 506px;
  position: absolute;
  top: 1112px;
  left: 960px;
  overflow: hidden;
}

.frame .overlap-20 {
  background-image: url("base.a9a081fa.png");
  background-position: 50%;
  background-size: cover;
  width: 689px;
  height: 448px;
  position: absolute;
  top: 42px;
  left: 0;
}

.frame .header-2 {
  align-items: center;
  gap: 88.47px;
  width: 568px;
  display: flex;
  position: absolute;
  top: 22px;
  left: 60px;
}

.frame .controls {
  flex: none;
  align-items: flex-start;
  gap: 7.65px;
  display: inline-flex;
  position: relative;
}

.frame .CTA-6 {
  background-color: #000;
  border: .24px solid #575757;
  border-radius: 2.39px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  height: 22.95px;
  padding: 7.65px 9.56px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .holder-3 {
  flex: none;
  align-items: center;
  gap: .96px;
  margin-top: -1.91px;
  margin-bottom: -1.91px;
  display: inline-flex;
  position: relative;
}

.frame .img-32 {
  width: 11.48px;
  height: 11.48px;
  position: relative;
}

.frame .text-wrapper-179 {
  color: #fff;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .div-194 {
  border: .48px solid #d9d9d9;
  border-radius: 5.26px;
  align-items: center;
  gap: 5.74px;
  width: 568px;
  display: flex;
  position: absolute;
  top: 61px;
  left: 60px;
  overflow: hidden;
}

.frame .div-195 {
  border-radius: 3.83px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 11.48px 15.3px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-180 {
  color: #818181;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 17.2px;
  font-weight: 700;
  line-height: 26.8px;
  position: relative;
}

.frame .text-wrapper-181 {
  color: #818181;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 9.6px;
  position: relative;
}

.frame .line-27 {
  width: 1px;
  height: 50.69px;
  position: relative;
}

.frame .div-196 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 5.74px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-182 {
  color: #818181;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 400;
  line-height: 9.6px;
  position: relative;
}

.frame .text-wrapper-183 {
  color: #818181;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 17.2px;
  font-weight: 600;
  line-height: 26.8px;
  position: relative;
}

.frame .text-wrapper-184 {
  color: #818181;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 9.6px;
  position: relative;
}

.frame .chart {
  background-color: #fff;
  border: .48px solid #d9d9d9;
  border-radius: 4.78px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.74px;
  width: 345px;
  padding: 15.3px;
  display: flex;
  position: absolute;
  top: 137px;
  left: 60px;
}

.frame .div-197 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.74px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .heading-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 3.83px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-185 {
  color: #575757;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-186 {
  color: #a4a4a4;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .divider-2 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.52px;
  position: relative;
}

.frame .chip-speaker-11 {
  background-color: #e0e0e0;
  border-radius: 9.56px;
  flex: none;
  align-items: center;
  gap: 1.91px;
  padding: 1.91px 3.83px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .name-wrapper-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.91px;
  display: inline-flex;
  position: relative;
}

.frame .name-16 {
  color: #575757;
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 500;
  line-height: 8.6px;
  position: relative;
}

.frame .search-bar-4 {
  border: .48px solid #d6d6d6;
  border-radius: 1.91px;
  align-items: center;
  gap: 3.83px;
  width: 151.12px;
  height: 19.13px;
  padding: 3.83px 5.74px;
  display: flex;
  position: relative;
}

.frame .icons-31-instance {
  width: 7.65px !important;
  height: 7.65px !important;
  position: relative !important;
}

.frame .text-wrapper-187 {
  color: #b6b6b6;
  letter-spacing: 0;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .div-198 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.65px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .stats-wrapper {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.83px;
  width: 100%;
  padding: 5.74px;
  display: flex;
  position: relative;
}

.frame .stats {
  flex: 1;
  align-items: center;
  gap: 11.48px;
  display: flex;
  position: relative;
}

.frame .stat {
  flex: 1;
  align-items: center;
  gap: 5.74px;
  display: flex;
  position: relative;
}

.frame .line-28 {
  align-self: stretch;
  width: 1.43px;
  margin-left: -1.43px;
  position: relative;
}

.frame .text-wrapper-188 {
  color: #b0b0b0;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-189 {
  color: #818181;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 10.5px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .graph {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.48px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .data {
  flex: 1;
  height: 120.99px;
  position: relative;
}

.frame .overlap-group-28 {
  width: 298px;
  height: 116px;
  position: absolute;
  top: 6px;
  left: 16px;
}

.frame .lines {
  width: 298px;
  height: 95px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .line-29 {
  object-fit: cover;
  width: 298px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .line-30 {
  object-fit: cover;
  width: 298px;
  height: 1px;
  position: absolute;
  top: 18px;
  left: 0;
}

.frame .line-31 {
  object-fit: cover;
  width: 298px;
  height: 1px;
  position: absolute;
  top: 38px;
  left: 0;
}

.frame .line-32 {
  object-fit: cover;
  width: 298px;
  height: 1px;
  position: absolute;
  top: 57px;
  left: 0;
}

.frame .line-33 {
  object-fit: cover;
  width: 298px;
  height: 1px;
  position: absolute;
  top: 76px;
  left: 0;
}

.frame .line-34 {
  object-fit: cover;
  width: 298px;
  height: 1px;
  position: absolute;
  top: 95px;
  left: 0;
}

.frame .graph-2 {
  align-items: flex-end;
  width: 298px;
  height: 113px;
  display: flex;
  position: absolute;
  top: 3px;
  left: 0;
}

.frame .column-3 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 11.96px;
  padding: 0 5.74px;
  display: flex;
  position: relative;
}

.frame .credit-wrapper {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: .48px;
  width: 100%;
  height: 56.43px;
  display: flex;
  position: relative;
}

.frame .credit {
  background-color: #a1a1a1;
  border-radius: .72px;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.frame .text-wrapper-190 {
  color: #b0b0b0;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .column-4 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 11.96px;
  display: flex;
  position: relative;
}

.frame .data-2 {
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: .48px;
  width: 100%;
  height: 65.52px;
  padding: 0 5.74px;
  display: flex;
  position: relative;
}

.frame .data-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: .48px;
  width: 100%;
  height: 22px;
  display: flex;
  position: relative;
}

.frame .data-4 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: .48px;
  width: 100%;
  height: 42.08px;
  display: flex;
  position: relative;
}

.frame .data-5 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: .48px;
  width: 100%;
  height: 16.74px;
  display: flex;
  position: relative;
}

.frame .y-axis {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 104px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-191 {
  color: #b0b0b0;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-192 {
  color: #b0b0b0;
  letter-spacing: 0;
  text-align: right;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 6.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .key {
  flex-direction: column;
  align-items: flex-end;
  gap: 3.83px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 264px;
}

.frame .ellipse-43 {
  background-color: #a1a1a1;
  border-radius: 1.91px;
  width: 3.83px;
  height: 3.83px;
  position: relative;
}

.frame .text-wrapper-193 {
  color: #818181;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Manrope, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .div-199 {
  border: .48px solid #d9d9d9;
  border-radius: 3.83px;
  flex-direction: column;
  align-items: flex-end;
  width: 207px;
  height: 254px;
  display: flex;
  position: absolute;
  top: 137px;
  left: 423px;
}

.frame .div-200 {
  background-color: #e0e0e0;
  border-radius: 3.83px 3.83px 0 0;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 7.65px 11.48px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-194 {
  color: #818181;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-195 {
  color: #818181;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .div-201 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 226.2px;
  display: flex;
  position: relative;
}

.frame .div-202 {
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.74px;
  width: 100%;
  padding: 8.61px 11.48px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-196 {
  color: #818181;
  letter-spacing: -.11px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 9.6px;
  position: relative;
}

.frame .bar-4 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding: 4.78px 0 .96px;
  display: flex;
  position: relative;
}

.frame .line-35 {
  align-self: stretch;
  width: 100%;
  height: 3.83px;
  margin-top: -1.7px;
  position: relative;
}

.frame .text-wrapper-197 {
  color: #818181;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 9.6px;
  position: relative;
}

.frame .bar-5 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 86.08px;
  padding: 4.78px 0 .96px;
  display: flex;
  position: relative;
}

.frame .bar-6 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 52.6px;
  padding: 4.78px 0 .96px;
  display: flex;
  position: relative;
}

.frame .bar-7 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 39.21px;
  padding: 4.78px 0 .96px;
  display: flex;
  position: relative;
}

.frame .menu-6 {
  background-color: #fff;
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .48px;
  width: 689px;
  height: 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-203 {
  align-items: center;
  gap: 5.74px;
  display: inline-flex;
  position: absolute;
  top: 7px;
  left: 19px;
}

.frame .menu-7 {
  width: 15.3px;
  height: 15.3px;
  position: relative;
}

.frame .div-wrapper-35 {
  background-color: #f9f9f9;
  border: .24px dashed #9f9f9f;
  border-radius: 3.83px;
  width: 70.78px;
  height: 26.78px;
  position: relative;
}

.frame .text-wrapper-198 {
  color: #000;
  letter-spacing: -.19px;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: 11.5px;
  position: absolute;
  top: 7px;
  left: 9px;
}

.frame .frame-wrapper-57 {
  justify-content: flex-end;
  align-items: center;
  gap: 3.83px;
  width: 116px;
  display: flex;
  position: absolute;
  top: 11px;
  left: 554px;
}

.frame .div-204 {
  flex: none;
  align-items: center;
  gap: 3.83px;
  display: inline-flex;
  position: relative;
}

.frame .ellipse-wrapper-7 {
  background-color: #d9d9d9;
  border-radius: 9.56px;
  width: 19.13px;
  height: 19.13px;
  margin-top: -.24px;
  position: relative;
}

.frame .ellipse-44 {
  background-color: #000;
  border: .96px solid #fff;
  border-radius: 2.87px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 13px;
  left: 13px;
}

.frame .text-wrapper-199 {
  color: #575757;
  letter-spacing: -.19px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .div-205 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 274px;
  display: inline-flex;
  position: absolute;
  top: 198px;
  left: 174px;
}

.frame .text-wrapper-200 {
  color: #f1f1f1;
  letter-spacing: -.1px;
  width: 350px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.frame .text-wrapper-201 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.32px;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#ff6c7e 0%, #feb43f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 64px;
  font-weight: 500;
  line-height: 72px;
  position: relative;
  -webkit-background-clip: text !important;
}

.frame .text-wrapper-202 {
  color: #f1f1f1;
  letter-spacing: -.1px;
  width: 350px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .about-the-client-wrapper {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 32px;
  height: 24px;
  display: inline-flex;
  position: absolute;
  top: 142px;
  left: 173px;
}

.frame .first-on-the-list {
  color: #a3a3a3;
  letter-spacing: -.1px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.frame .group-7 {
  width: 1369px;
  height: 972px;
  position: absolute;
  top: 88px;
  left: 589px;
}

.frame .overlap-21 {
  width: 1361px;
  height: 942px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .dashboard-home {
  width: 1208px;
  height: 889px;
  position: absolute;
  top: 53px;
  left: 121px;
}

.frame .line-instance {
  width: 66px !important;
  height: 59px !important;
  position: absolute !important;
  top: 10px !important;
  left: 1197px !important;
  transform: rotate(180deg) !important;
}

.frame .line-36 {
  width: 56px !important;
  height: 53px !important;
  top: 7px !important;
  left: 3px !important;
  transform: rotate(-180deg) !important;
}

.frame .endpoint-2-3 {
  width: 7px !important;
  height: 7px !important;
  position: absolute !important;
  top: 55px !important;
  left: 59px !important;
  transform: rotate(-180deg) !important;
}

.frame .line-37 {
  background-color: #fff !important;
  border-radius: 86.61px !important;
  width: 7px !important;
  height: 7px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.frame .annotation-text-2 {
  flex-direction: column;
  align-items: center;
  width: 81px;
  height: 69px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1280px;
}

.frame .annotation-text-instance {
  background-color: var(--lightwhite-100) !important;
  border-radius: 10.39px !important;
  flex: none !important;
  gap: 6.93px !important;
  padding: 1.3px 10.39px 2.17px !important;
}

.frame .annotation-text-3 {
  color: var(--darkblack-100) !important;
  font-family: Inter, Helvetica !important;
  font-size: 12.1px !important;
  font-style: unset !important;
  letter-spacing: -.24px !important;
  margin-top: -.87px !important;
  font-weight: 400 !important;
  line-height: 17.3px !important;
}

.frame .line-38 {
  object-fit: unset !important;
}

.frame .endpoint-2-2 {
  width: 6.93px !important;
  height: 6.93px !important;
  position: relative !important;
}

.frame .line-39 {
  height: unset !important;
  flex: 1 !important;
}

.frame .annotation-text-4 {
  align-items: center;
  width: 172px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 80px;
  left: 0;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
}

.frame .annotation-text-5 {
  background-color: var(--lightwhite-100) !important;
  border-radius: 10.39px !important;
  flex: none !important;
  gap: 6.93px !important;
  margin-top: -.55px !important;
  margin-bottom: -.55px !important;
  padding: 1.3px 10.39px 2.17px !important;
}

.frame .line-40 {
  width: unset !important;
  flex: 1 !important;
}

.frame .endpoint-instance {
  background-color: #fff !important;
  border-radius: 86.61px !important;
  width: 6.93px !important;
  height: 6.93px !important;
  position: relative !important;
}

.frame .annotation-text-6 {
  align-items: center;
  width: 190px;
  height: 22px;
  display: flex;
  position: absolute;
  top: 549px;
  left: 0;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
}

.frame .annotation-text-7 {
  align-items: center;
  width: 396px;
  height: 22px;
  display: flex;
  position: absolute;
  top: 275px;
  left: 0;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
}

.frame .annotation-text-8 {
  align-items: center;
  width: 405px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 349px;
  left: 0;
  box-shadow: 4.53px 2.26px 18.11px #4f4f4f52;
}

.frame .annotation-text-9 {
  border-radius: 10.39px !important;
  justify-content: center !important;
  gap: 6.93px !important;
  width: 103px !important;
  height: 21px !important;
  padding: 2.35px 9.39px !important;
  display: flex !important;
  position: absolute !important;
  top: 0 !important;
  left: 1093px !important;
}

.frame .annotation-text-10 {
  font-family: Inter, Helvetica !important;
  font-size: 12.1px !important;
  font-style: unset !important;
  letter-spacing: -.24px !important;
  margin-top: -1.65px !important;
  font-weight: 400 !important;
  line-height: 17.3px !important;
}

.frame .measure-2 {
  height: unset !important;
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  margin-left: -.43px !important;
  position: relative !important;
}

.frame .measure-3 {
  color: #a3a3a3 !important;
  margin-top: -.87px !important;
  font-size: 13.6px !important;
}

.frame .measure-4 {
  flex: none !important;
  margin-top: -.96px !important;
  margin-bottom: -.96px !important;
  padding: 0 3.46px !important;
}

.frame .measure-5 {
  height: unset !important;
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  margin-right: -.57px !important;
  position: relative !important;
}

.frame .measure-6 {
  width: 712px !important;
  height: 14px !important;
  position: absolute !important;
  top: 957px !important;
  left: 333px !important;
}

.frame .measure-7 {
  width: 256px !important;
  height: 14px !important;
  position: absolute !important;
  top: 957px !important;
  left: 1077px !important;
}

.frame .measure-8 {
  height: unset !important;
  object-fit: unset !important;
  align-self: stretch !important;
}

.frame .measure-9 {
  color: #9f9f9f !important;
  margin-top: -.87px !important;
  font-size: 13.6px !important;
}

.frame .measure-10 {
  flex: none !important;
  margin-top: -1.21px !important;
  margin-bottom: -1.21px !important;
  padding: 0 3.46px !important;
}

.frame .measure-11 {
  height: unset !important;
  left: unset !important;
  top: unset !important;
  align-self: stretch !important;
  margin-top: -3022.44px !important;
  margin-left: -5870.8px !important;
  position: relative !important;
}

.frame .login {
  background-color: var(--ui-essentialsneutralntrl100);
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 236px;
  height: 506px;
  position: absolute;
  top: 1112px;
  left: 692px;
  overflow: hidden;
}

.frame .frame-wrapper-58 {
  background-color: #f5f5f5;
  width: 220px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-wrapper-36 {
  border-radius: 2.52px;
  width: 37px;
  height: 24px;
  position: relative;
  top: 5px;
  left: 12px;
}

.frame .div-206 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4.88px;
  width: 186px;
  display: flex;
  position: absolute;
  top: 127px;
  left: 16px;
}

.frame .text-wrapper-203 {
  color: var(--x01-neutral10);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 14.6px;
  font-weight: 700;
  line-height: 20.5px;
  position: relative;
}

.frame .text-wrapper-204 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.2px;
  position: relative;
}

.frame .div-207 {
  flex-direction: column;
  align-items: flex-start;
  gap: 14.63px;
  width: 186.43px;
  display: flex;
  position: absolute;
  top: 183px;
  left: 16px;
}

.frame .div-208 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.93px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .text-wrapper-205 {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  line-height: 10.2px;
  position: relative;
}

.frame .text-input-4 {
  background-color: var(--primary-030);
  border: .61px solid;
  border-color: var(--x01-neutral70);
  border-radius: 1.22px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.1px;
  width: 100%;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  padding: 8.54px 9.76px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-206 {
  flex: none;
  align-items: center;
  gap: 6.1px;
  display: inline-flex;
  position: relative;
}

.frame .text-20 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 7.3px;
  font-weight: 400;
  line-height: 8.8px;
  position: relative;
}

.frame .text-input-5 {
  background-color: var(--primary-030);
  border: .61px solid;
  border-color: var(--x01-neutral70);
  border-radius: 1.22px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.1px;
  width: 100%;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  padding: 8.54px 9.76px 7.32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-21 {
  color: var(--x01-neutral50);
  letter-spacing: -.03px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 7.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .div-209 {
  justify-content: space-between;
  align-items: center;
  width: 186px;
  display: flex;
  position: absolute;
  top: 298px;
  left: 16px;
}

.frame .div-210 {
  align-items: center;
  gap: 4.88px;
  width: 77.44px;
  display: flex;
  position: relative;
}

.frame .checkbox-2 {
  background-color: var(--shadeswhite);
  width: 12.19px;
  height: 12.19px;
  position: relative;
}

.frame .icons-outline-wrapper {
  border: .76px solid #e5e2db;
  border-radius: 1.52px;
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
  left: -1px;
}

.frame .icons-outline-checkmark {
  width: 12px !important;
  height: 12px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.frame .text-22 {
  color: var(--x01-neutral40);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 10.2px;
  position: relative;
}

.frame .text-23 {
  color: #a3a3a3;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 600;
  line-height: 10.2px;
  position: relative;
}

.frame .button-9 {
  all: unset;
  box-sizing: border-box;
  background-color: #000;
  border-radius: 2.04px;
  justify-content: center;
  align-items: center;
  gap: 2.44px;
  width: 186px;
  padding: 7.32px 19.51px;
  display: flex;
  position: absolute;
  top: 418px;
  left: 16px;
  overflow: hidden;
}

.frame .text-wrapper-207 {
  color: var(--secondary-100);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.61px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 700;
  line-height: 10.2px;
  position: relative;
}

.frame .text-24 {
  color: #0000;
  width: 135px;
  font-family: Inter, Helvetica;
  font-size: 8.5px;
  font-weight: 400;
  line-height: 8.5px;
  position: absolute;
  top: 452px;
  left: 42px;
}

.frame .text-wrapper-208 {
  color: #363636;
  letter-spacing: -.02px;
  font-weight: 500;
  line-height: 10.2px;
}

.frame .text-wrapper-209 {
  color: #929185;
  letter-spacing: -.17px;
  font-weight: 500;
  line-height: 13.4px;
}

.frame .text-wrapper-210 {
  color: #676768;
  letter-spacing: -.02px;
  font-weight: 700;
  line-height: 10.2px;
}

.frame .verify-e-mail {
  background-color: #fff;
  border: 8px solid #ffffff52;
  border-radius: 12px;
  width: 704px;
  height: 506px;
  position: absolute;
  top: 568px;
  left: -165px;
  overflow: hidden;
}

.frame .overlap-22 {
  width: 340px;
  height: 492px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .rectangle-12 {
  background-color: #d9d9d9;
  width: 337px;
  height: 488px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .overlap-group-wrapper-6 {
  width: 339px;
  height: 492px;
  position: absolute;
  top: 0;
  left: 1px;
}

.frame .overlap-group-29 {
  background-color: #c7c7c7;
  width: 340px;
  height: 492px;
  position: relative;
  left: -1px;
}

.frame .line-41 {
  width: 177px;
  height: 479px;
  position: absolute;
  top: 11px;
  left: 163px;
}

.frame .line-42 {
  width: 174px;
  height: 477px;
  position: absolute;
  top: 8px;
  left: 163px;
}

.frame .text-wrapper-211 {
  color: #000;
  letter-spacing: 0;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 900;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 18px;
}

.frame .div-211 {
  width: 219px;
  height: 213px;
  position: absolute;
  top: 138px;
  left: 404px;
}

.frame .div-212 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5.72px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-212 {
  color: #444;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 16.4px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-213 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .div-213 {
  justify-content: center;
  align-items: center;
  gap: 8.1px;
  width: 213px;
  display: flex;
  position: absolute;
  top: 61px;
  left: 0;
}

.frame .text-input-6 {
  background-color: var(--primary-030);
  border: .48px solid;
  border-color: var(--neutral-03);
  border-radius: 2.86px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 12.28px 16.37px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-214 {
  flex: none;
  align-items: center;
  gap: 4.77px;
  display: inline-flex;
  position: relative;
}

.frame .text-25 {
  color: var(--primary-0340);
  letter-spacing: -.15px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  line-height: 10.5px;
  position: relative;
}

.frame .text-input-7 {
  background-color: var(--primary-030);
  border: .48px solid;
  border-color: var(--neutral-03);
  border-radius: 2.86px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 12.28px 16.37px;
  display: flex;
  position: relative;
}

.frame .text-26 {
  color: var(--primary-0340);
  letter-spacing: -.15px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.48px;
  font-family: General Sans-Medium, Helvetica;
  font-size: 7.6px;
  font-weight: 500;
  line-height: 10.5px;
  position: relative;
}

.frame .text-input-8 {
  background-color: var(--primary-030);
  border: .48px solid;
  border-color: var(--neutral-03);
  border-radius: 2.86px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4.77px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 12.28px 16.37px;
  display: flex;
  position: relative;
}

.frame .text-27 {
  color: var(--primary-0340);
  letter-spacing: -.15px;
  text-align: center;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 7.6px;
  font-weight: 400;
  line-height: 10.5px;
  position: absolute;
  top: 202px;
  left: -1px;
}

.frame .text-wrapper-215 {
  font-weight: 500;
}

.frame .text-wrapper-216 {
  font-weight: 700;
}

.frame .text-28 {
  color: var(--primary-0340);
  letter-spacing: -.16px;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 8.2px;
  font-weight: 400;
  line-height: 10.5px;
  position: absolute;
  top: 108px;
  left: 0;
}

.frame .div-wrapper-37 {
  background-color: #707070;
  border-radius: 4.09px;
  width: 213px;
  height: 27px;
  position: absolute;
  top: 166px;
  left: 0;
}

.frame .text-wrapper-217 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 8.6px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 8px;
  left: 51px;
}

.frame .group-8 {
  width: 1920px;
  height: 1020px;
  position: absolute;
  top: 1789px;
  left: 60px;
}

.frame .div-214 {
  justify-content: space-between;
  align-items: center;
  width: 1681px;
  display: flex;
  position: absolute;
  top: 2346px;
  left: 178px;
}

.frame .div-215 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-218 {
  color: #676768;
  letter-spacing: -.12px;
  width: 513px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .component-based {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.52px;
  text-fill-color: transparent;
  background: linear-gradient(#ff6c7e 0%, #1658d8 100%);
  -webkit-background-clip: text;
  background-clip: text;
  align-self: stretch;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 52px;
  font-weight: 500;
  line-height: 56px;
  position: relative;
  -webkit-background-clip: text !important;
}

.frame .that-s-responsive {
  color: #555;
  letter-spacing: .43px;
  width: 524px;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.frame .typography {
  background-color: #fff;
  border: .5px solid #c6c6c6;
  border-radius: 6.85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 31.95px;
  padding: 55.72px 54.78px;
  display: inline-flex;
  position: absolute;
  top: 2579px;
  left: 0;
  overflow: hidden;
}

.frame .div-216 {
  align-self: stretch;
  width: 100%;
  height: 140.94px;
  position: relative;
}

.frame .div-217 {
  width: 132px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-219 {
  color: #000;
  letter-spacing: -.18px;
  height: 22px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 18.3px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.frame .text-wrapper-220 {
  color: #a3a3a3;
  letter-spacing: -1.07px;
  white-space: nowrap;
  height: 115px;
  font-family: Inter, Helvetica;
  font-size: 107.3px;
  font-weight: 400;
  line-height: 114.1px;
  position: absolute;
  top: 43px;
  left: 0;
}

.frame .div-218 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 141px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 196px;
}

.frame .aa-bb-cc-dd-ee-ff-gg {
  color: #a2a2a2;
  letter-spacing: -.23px;
  width: 388.02px;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 22.8px;
  font-weight: 300;
  line-height: normal;
  position: relative;
}

.frame .div-219 {
  flex: none;
  align-items: flex-start;
  gap: 20.54px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-221 {
  color: #a2a2a2;
  letter-spacing: -.18px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 18.3px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-222 {
  color: #a2a2a2;
  letter-spacing: -.18px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 18.3px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-223 {
  color: #a2a2a2;
  letter-spacing: -.18px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 18.3px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .line-43 {
  width: 587.22px;
  height: 1px;
  position: relative;
}

.frame .div-220 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-221 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 27.39px;
  display: inline-flex;
  position: relative;
}

.frame .div-222 {
  background-color: #fff;
  flex: none;
  align-items: center;
  gap: 18.26px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .div-223 {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-end;
  gap: 2.28px;
  width: 36.52px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-224 {
  color: #707070;
  letter-spacing: 0;
  opacity: .4;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-225 {
  color: #707070;
  letter-spacing: 0;
  opacity: .4;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 6.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .type {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 34.2px;
  font-weight: 500;
  line-height: 41.1px;
  position: relative;
}

.frame .type-2 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 27.4px;
  font-weight: 400;
  line-height: 32.9px;
  position: relative;
}

.frame .type-3 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 22.3px;
  font-weight: 400;
  line-height: 26.7px;
  position: relative;
}

.frame .div-224 {
  justify-content: space-between;
  align-items: flex-start;
  width: 344.08px;
  display: flex;
  position: relative;
}

.frame .type-4 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 17.7px;
  font-weight: 400;
  line-height: 21.2px;
  position: relative;
}

.frame .div-225 {
  background-color: #fff;
  flex: none;
  align-items: center;
  gap: 18.26px;
  display: inline-flex;
  position: relative;
}

.frame .type-5 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14.3px;
  font-weight: 400;
  line-height: 17.1px;
  position: relative;
}

.frame .type-6 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  line-height: 13.7px;
  position: relative;
}

.frame .type-7 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.frame .type-8 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  line-height: 8.9px;
  position: relative;
}

.frame .type-9 {
  color: #707070;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.7px;
  font-weight: 400;
  line-height: 6.8px;
  position: relative;
}

.frame .button-10 {
  background-color: var(--neutral-0);
  border: .5px solid #c6c6c6;
  border-radius: 11.31px;
  width: 620px;
  height: 475px;
  position: absolute;
  top: 2579px;
  left: 741px;
  overflow: hidden;
  box-shadow: 0 2.83px 31.1px #1822310a;
}

.frame .overlap-23 {
  height: 409px;
  position: relative;
  top: 33px;
}

.frame .key-2 {
  width: 507px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 41px;
}

.frame .text-wrapper-226 {
  color: var(--neutral-40);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  left: 75px;
}

.frame .text-wrapper-227 {
  color: var(--neutral-40);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  left: 200px;
}

.frame .text-wrapper-228 {
  color: var(--neutral-40);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  left: 324px;
}

.frame .text-wrapper-229 {
  color: var(--neutral-40);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  line-height: 15.7px;
  position: absolute;
  top: 0;
  left: 440px;
}

.frame .text-wrapper-230 {
  color: var(--neutral-100);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  font-family: Helvetica Neue-Bold, Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  line-height: 21px;
  position: absolute;
  top: 39px;
  left: 0;
}

.frame .button-11 {
  border-radius: 3.28px;
  width: 620px;
  height: 384px;
  position: absolute;
  top: 26px;
  left: 0;
  overflow: hidden;
}

.frame .type-primary-size {
  position: absolute !important;
  top: 222px !important;
  left: 117px !important;
}

.frame .type-primary-size-2 {
  position: absolute !important;
  top: 14px !important;
  left: 117px !important;
}

.frame .type-primary-size-3 {
  position: absolute !important;
  top: 262px !important;
  left: 117px !important;
}

.frame .type-primary-size-4 {
  position: absolute !important;
  top: 52px !important;
  left: 117px !important;
}

.frame .type-primary-size-5 {
  position: absolute !important;
  top: 301px !important;
  left: 117px !important;
}

.frame .type-primary-size-6 {
  position: absolute !important;
  top: 92px !important;
  left: 117px !important;
}

.frame .type-primary-size-7 {
  position: absolute !important;
  top: 340px !important;
  left: 118px !important;
}

.frame .type-primary-size-8 {
  position: absolute !important;
  top: 131px !important;
  left: 117px !important;
}

.frame .type-secondary-size {
  position: absolute !important;
  top: 340px !important;
  left: 241px !important;
}

.frame .type-secondary-size-2 {
  position: absolute !important;
  top: 131px !important;
  left: 241px !important;
}

.frame .type-secondary-size-3 {
  position: absolute !important;
  top: 222px !important;
  left: 241px !important;
}

.frame .type-secondary-size-4 {
  position: absolute !important;
  top: 13px !important;
  left: 241px !important;
}

.frame .type-secondary-size-5 {
  position: absolute !important;
  top: 262px !important;
  left: 241px !important;
}

.frame .type-secondary-size-6 {
  position: absolute !important;
  top: 52px !important;
  left: 241px !important;
}

.frame .type-secondary-size-7 {
  position: absolute !important;
  top: 301px !important;
  left: 241px !important;
}

.frame .type-secondary-size-8 {
  position: absolute !important;
  top: 92px !important;
  left: 241px !important;
}

.frame .type-outline-size {
  position: absolute !important;
  top: 222px !important;
  left: 366px !important;
}

.frame .type-outline-size-2 {
  position: absolute !important;
  top: 13px !important;
  left: 366px !important;
}

.frame .type-outline-size-3 {
  position: absolute !important;
  top: 262px !important;
  left: 366px !important;
}

.frame .type-outline-size-4 {
  position: absolute !important;
  top: 52px !important;
  left: 366px !important;
}

.frame .type-outline-size-5 {
  position: absolute !important;
  top: 301px !important;
  left: 366px !important;
}

.frame .type-outline-size-6 {
  position: absolute !important;
  top: 92px !important;
  left: 366px !important;
}

.frame .type-outline-size-7 {
  position: absolute !important;
  top: 340px !important;
  left: 366px !important;
}

.frame .type-outline-size-8 {
  position: absolute !important;
  top: 131px !important;
  left: 366px !important;
}

.frame .type-text-size-small {
  position: absolute !important;
  top: 340px !important;
  left: 471px !important;
}

.frame .type-text-size-large {
  position: absolute !important;
  top: 131px !important;
  left: 471px !important;
}

.frame .type-text-size-small-2 {
  position: absolute !important;
  top: 222px !important;
  left: 471px !important;
}

.frame .type-text-size-large-2 {
  position: absolute !important;
  top: 13px !important;
  left: 471px !important;
}

.frame .type-text-size-small-3 {
  position: absolute !important;
  top: 262px !important;
  left: 471px !important;
}

.frame .type-text-size-large-3 {
  position: absolute !important;
  top: 52px !important;
  left: 471px !important;
}

.frame .type-text-size-small-4 {
  position: absolute !important;
  top: 301px !important;
  left: 471px !important;
}

.frame .type-text-size-large-4 {
  position: absolute !important;
  top: 92px !important;
  left: 471px !important;
}

.frame .element-11 {
  border: .5px solid #c6c6c6;
  border-radius: 6.67px;
  width: 832px;
  height: 165px;
  position: absolute;
  top: 2579px;
  left: 1393px;
}

.frame .button-default-instance {
  position: absolute !important;
  top: 41px !important;
  left: 129px !important;
}

.frame .button-hover-instance {
  position: absolute !important;
  top: 103px !important;
  left: 129px !important;
}

.frame .button-add-team-default {
  position: absolute !important;
  top: 41px !important;
  left: 218px !important;
}

.frame .button-add-team-default-hover {
  position: absolute !important;
  top: 103px !important;
  left: 218px !important;
}

.frame .button-variant5 {
  position: absolute !important;
  top: 41px !important;
  left: 539px !important;
}

.frame .button-variant6 {
  position: absolute !important;
  top: 103px !important;
  left: 539px !important;
}

.frame .button-variant7 {
  position: absolute !important;
  top: 41px !important;
  left: 64px !important;
}

.frame .button-variant-instance {
  position: absolute !important;
  top: 103px !important;
  left: 64px !important;
}

.frame .button-variant9-instance {
  position: absolute !important;
  top: 41px !important;
  left: 24px !important;
}

.frame .button-variant10-instance {
  position: absolute !important;
  top: 103px !important;
  left: 24px !important;
}

.frame .button-variant11-instance {
  position: absolute !important;
  top: 41px !important;
  left: 399px !important;
}

.frame .button-variant12-instance {
  position: absolute !important;
  top: 103px !important;
  left: 399px !important;
}

.frame .text-wrapper-231 {
  color: #555;
  letter-spacing: -.2px;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 24px;
}

.frame .text-wrapper-232 {
  color: #555;
  letter-spacing: -.2px;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 85px;
  left: 24px;
}

.frame .spacer-2 {
  border: .5px solid #c6c6c6;
  border-radius: 4.84px;
  width: 133px;
  height: 856px;
  position: absolute;
  top: 2765px;
  left: 1395px;
  overflow: hidden;
}

.frame .size {
  position: absolute !important;
  top: 19px !important;
  left: 19px !important;
}

.frame .spacer-instance {
  position: absolute !important;
  top: 46px !important;
  left: 19px !important;
}

.frame .size-2 {
  position: absolute !important;
  top: 81px !important;
  left: 19px !important;
}

.frame .size-3 {
  position: absolute !important;
  top: 124px !important;
  left: 19px !important;
}

.frame .size-4 {
  position: absolute !important;
  top: 174px !important;
  left: 19px !important;
}

.frame .size-5 {
  position: absolute !important;
  top: 232px !important;
  left: 19px !important;
}

.frame .size-6 {
  position: absolute !important;
  top: 298px !important;
  left: 19px !important;
}

.frame .size-7 {
  position: absolute !important;
  top: 371px !important;
  left: 19px !important;
}

.frame .size-8 {
  position: absolute !important;
  top: 453px !important;
  left: 19px !important;
}

.frame .size-9 {
  position: absolute !important;
  top: 542px !important;
  left: 19px !important;
}

.frame .size-10 {
  position: absolute !important;
  top: 638px !important;
  left: 19px !important;
}

.frame .size-11 {
  position: absolute !important;
  top: 743px !important;
  left: 19px !important;
}

.frame .checkbox-3 {
  background-color: var(--neutral-0);
  border: .5px solid #c6c6c6;
  border-radius: 16px;
  width: 892px;
  height: 377px;
  position: absolute;
  top: 2765px;
  left: 1562px;
  overflow: hidden;
  box-shadow: 0 4px 44px #1822310a;
}

.frame .key-3 {
  width: 93px;
  height: 32px;
  position: absolute;
  top: 41px;
  left: 65px;
}

.frame .text-wrapper-233 {
  color: var(--neutral-100);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .key-4 {
  width: 158px;
  height: 106px;
  position: absolute;
  top: 214px;
  left: 65px;
}

.frame .group-9 {
  width: 58px;
  height: 60px;
  position: absolute;
  top: 46px;
  left: 0;
}

.frame .radio-instance {
  position: absolute !important;
  top: 0 !important;
  left: 20px !important;
}

.frame .text-wrapper-234 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 36px;
  left: 0;
}

.frame .text-wrapper-235 {
  color: var(--neutral-100);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .group-10 {
  width: 85px;
  height: 60px;
  position: absolute;
  top: 46px;
  left: 73px;
}

.frame .radio-2 {
  width: 20px !important;
  height: 20px !important;
  top: -2px !important;
  left: -2px !important;
}

.frame .radio-3 {
  position: absolute !important;
  top: 0 !important;
  left: 34px !important;
}

.frame .text-wrapper-236 {
  color: var(--neutral-100);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 88px;
  left: 65px;
}

.frame .group-11 {
  width: 201px;
  height: 53px;
  position: absolute;
  top: 129px;
  left: 65px;
}

.frame .radio-partial {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 98px !important;
}

.frame .text-wrapper-237 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 29px;
  left: 88px;
}

.frame .radio-no-instance {
  position: absolute !important;
  top: 1px !important;
  left: 170px !important;
}

.frame .text-wrapper-238 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 29px;
  left: 160px;
}

.frame .radio-yes {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 2px !important;
  left: 17px !important;
}

.frame .text-wrapper-239 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 29px;
  left: 0;
}

.frame .text-wrapper-240 {
  color: var(--neutral-100);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 86px;
  left: 311px;
}

.frame .text-wrapper-241 {
  color: var(--neutral-100);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 214px;
  left: 311px;
}

.frame .group-12 {
  width: 44px;
  height: 56px;
  position: absolute;
  top: 126px;
  left: 311px;
}

.frame .design-component-instance-node-2 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.frame .text-wrapper-242 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 32px;
  left: 11px;
}

.frame .group-13 {
  width: 44px;
  height: 56px;
  position: absolute;
  top: 126px;
  left: 385px;
}

.frame .slider-false-instance {
  position: absolute !important;
  top: 264px !important;
  left: 311px !important;
}

.frame .slider-true {
  width: 152px;
  height: 52px;
  position: absolute;
  top: 232px;
  left: 474px;
}

.frame .overlap-24 {
  height: 52px;
  position: relative;
}

.frame .rectangle-13 {
  background-color: var(--neutral-30);
  border-radius: 100px;
  width: 152px;
  height: 4px;
  position: absolute;
  top: 41px;
  left: 0;
}

.frame .div-226 {
  width: 88px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .overlap-group-30 {
  width: 80px;
  height: 16px;
  position: absolute;
  top: 36px;
  left: 0;
}

.frame .rectangle-14 {
  background-color: var(--defaultprimary-light);
  border-radius: 100px;
  width: 74px;
  height: 4px;
  position: absolute;
  top: 5px;
  left: 0;
}

.frame .ellipse-45 {
  background-color: var(--defaultwhite);
  border: 1px solid;
  border-color: var(--neutral-30);
  box-shadow: var(--box-shadows-x-small);
  border-radius: 8px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 64px;
}

.frame .div-227 {
  width: 29px;
  height: 28px;
  position: absolute;
  top: 4px;
  left: 58px;
}

.frame .div-wrapper-38 {
  background-color: var(--neutral-100);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 6px 1px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-243 {
  color: var(--defaultwhite);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.frame .polygon-9 {
  width: 9px;
  height: 6px;
  position: absolute;
  top: -4950px;
  left: -6477px;
}

.frame .text-wrapper-244 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 296px;
  left: 367px;
}

.frame .text-wrapper-245 {
  color: var(--neutral-40);
  font-family: var(--default-body-small-font-family);
  font-size: var(--default-body-small-font-size);
  font-style: var(--default-body-small-font-style);
  font-weight: var(--default-body-small-font-weight);
  letter-spacing: var(--default-body-small-letter-spacing);
  line-height: var(--default-body-small-line-height);
  white-space: nowrap;
  position: absolute;
  top: 296px;
  left: 497px;
}

.frame .colour-palate {
  background-color: #fff;
  border: .5px solid #c6c6c6;
  border-radius: 9.56px;
  width: 498px;
  height: 546px;
  position: absolute;
  top: 3075px;
  left: 2px;
  overflow: hidden;
}

.frame .overlap-25 {
  width: 437px;
  height: 483px;
  position: relative;
  top: 31px;
  left: 31px;
}

.frame .overlap-26 {
  width: 437px;
  height: 441px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-228 {
  width: 437px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-229 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.99px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-246 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.42px;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .div-230 {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 127.43px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .element-efeee {
  background-color: #efede9;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .div-231 {
  justify-content: space-between;
  align-items: center;
  width: 127px;
  height: 21px;
  padding: 0 6.8px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-247 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .element-eaee {
  background-color: #eae7e2;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-eedb {
  background-color: #e5e2db;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .div-232 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.99px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 155px;
}

.frame .element-fffae {
  background-color: #fefae5;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fffcc {
  background-color: #fef5cc;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fffb {
  background-color: #fef0b2;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ffeb {
  background-color: #feeb99;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fee {
  background-color: #fee166;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fed {
  background-color: #fed733;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fece {
  background-color: #fece00;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .div-233 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.99px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 309px;
}

.frame .tertiary {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  width: 127.43px;
  height: 191.14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .element-feff {
  background-color: #fef5f2;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fdece {
  background-color: #fdebe6;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fcedb {
  background-color: #fce1da;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fbdcf {
  background-color: #fbd8ce;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-facb {
  background-color: #f9c4b6;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fbe {
  background-color: #f7b19e;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fe {
  background-color: #f69e86;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-dc {
  background-color: #d4826c;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-b {
  background-color: #b26752;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .text-wrapper-248 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 5.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .div-234 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.99px;
  display: inline-flex;
  position: absolute;
  top: 199px;
  left: 155px;
}

.frame .element-ffffff {
  background-color: #fff;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ebebeb {
  background-color: #ebebeb;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ddd {
  background-color: #d6d6d6;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ccc {
  background-color: #c2c2c2;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-afafaf {
  background-color: #aeaeae;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-12 {
  background-color: #868686;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-eee {
  background-color: #5d5d5d;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-13 {
  background-color: #363636;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-bbb {
  background-color: #2b2b2b;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-14 {
  background-color: #1f1f1f;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .div-235 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.99px;
  display: inline-flex;
  position: absolute;
  top: 135px;
  left: 0;
}

.frame .element-edffb {
  background-color: #edf1fb;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-dcef {
  background-color: #dbe4f7;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .div-236 {
  justify-content: space-between;
  align-items: flex-start;
  width: 114px;
  display: flex;
  position: relative;
  top: 8px;
  left: 7px;
}

.frame .text-wrapper-249 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.42px;
  font-family: Inter, Helvetica;
  font-size: 5.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .element-cadf {
  background-color: #cad6f4;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-bcf {
  background-color: #b8c9f0;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-aee {
  background-color: #95aee9;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-e {
  background-color: #7293e2;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fdb {
  background-color: #4f78db;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fb {
  background-color: #3f60af;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-f {
  background-color: #2f4884;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-15 {
  background-color: #1f3058;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-16 {
  background-color: #172443;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .div-237 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16.99px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 309px;
}

.frame .element-efcf {
  background-color: #e5fbf3;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ccfe {
  background-color: #ccf8e8;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-bfdd {
  background-color: #b2f4dc;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-fd {
  background-color: #99f1d1;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ebbb {
  background-color: #66eaba;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-ea {
  background-color: #33e3a3;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .label-ddd-wrapper {
  background-color: #00dd8d;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .label-b-wrapper {
  background-color: #00b070;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-17 {
  background-color: #008454;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .element-18 {
  background-color: #005838;
  width: 127.43px;
  height: 21.24px;
  position: relative;
}

.frame .colour-palate-2 {
  background-color: #fff;
  border: .5px solid #c6c6c6;
  border-radius: 9.98px;
  width: 837px;
  height: 538px;
  position: absolute;
  top: 3083px;
  left: 524px;
  overflow: hidden;
}

.frame .element-19 {
  flex-direction: column;
  align-items: flex-start;
  gap: 26.61px;
  display: inline-flex;
  position: absolute;
  top: 80px;
  left: 43px;
}

.frame .div-238 {
  flex: none;
  align-items: flex-start;
  gap: 33.26px;
  display: inline-flex;
  position: relative;
}

.frame .div-239 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  display: flex;
  position: relative;
}

.frame .div-240 {
  flex: none;
  align-items: flex-start;
  gap: 4.16px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-250 {
  color: var(--neutral-100);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.83px;
  font-family: Helvetica Neue-Medium, Helvetica;
  font-size: 13.3px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.frame .div-241 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.65px 9.15px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 .83px #cdd0db;
}

.frame .div-242 {
  flex: none;
  align-items: center;
  gap: 8.32px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-251 {
  color: var(--neutral-30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.83px;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 13.3px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame .div-243 {
  flex: none;
  align-items: flex-start;
  gap: 8.32px;
  display: inline-flex;
  position: relative;
}

.frame .div-244 {
  flex: none;
  align-items: center;
  gap: 4.99px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-252 {
  color: var(--neutral-60);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.83px;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame .div-245 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.65px 9.15px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 .83px #fb2047;
}

.frame .element-general-alert {
  width: 13.3px !important;
  height: 13.3px !important;
  position: relative !important;
}

.frame .text-wrapper-253 {
  color: #f69e86;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.83px;
  font-family: Helvetica Neue-Regular, Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame .div-246 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 33.26px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-247 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.65px 9.15px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.66px 3.33px #0510370f, inset 0 0 0 .83px #cdd0dc;
}

.frame .element-arrow-chevron {
  width: 16.63px !important;
  height: 16.63px !important;
  position: relative !important;
}

.frame .div-248 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.65px 9.15px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.66px 4.99px #0510371a, 0 0 0 .83px #fb2047;
}

.frame .text-area {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 88.14px;
  display: flex;
  position: relative;
}

.frame .overlap-group-31 {
  border-radius: 4.99px;
  width: 163px;
  height: 64px;
  position: relative;
}

.frame .frame-wrapper-59 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 64.2px;
  padding: 6.65px 9.15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 0 .83px #cdd0db;
}

.frame .div-249 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.32px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .img-33 {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 54px;
  left: 154px;
}

.frame .text-area-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 113.09px;
  display: flex;
  position: relative;
}

.frame .overlap-group-32 {
  border-radius: 4.99px;
  width: 163px;
  height: 60px;
  position: relative;
}

.frame .frame-wrapper-60 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 59.89px;
  padding: 6.65px 9.15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 0 .83px #cdd0db;
}

.frame .img-34 {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50px;
  left: 154px;
}

.frame .frame-wrapper-61 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 59.89px;
  padding: 6.65px 9.15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 0 .83px #fb2047;
}

.frame .text-area-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 138.04px;
  display: flex;
  position: relative;
}

.frame .overlap-group-33 {
  border-radius: 4.99px;
  width: 163px;
  height: 57px;
  position: relative;
}

.frame .frame-wrapper-62 {
  background-color: var(--defaultwhite);
  border-radius: 4.99px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.32px;
  width: 162.98px;
  height: 56.59px;
  padding: 6.65px 9.15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 0 .83px #fb2047;
}

.frame .img-35 {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 47px;
  left: 154px;
}

.frame .text-wrapper-254 {
  color: var(--neutral-100);
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  font-family: Helvetica Neue-Bold, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 17.4px;
  position: absolute;
  top: 38px;
  left: 42px;
}

.frame .menu-8 {
  border: .5px solid #c6c6c6;
  border-radius: 4.05px;
  width: 360px;
  height: 445px;
  position: absolute;
  top: 3176px;
  left: 1562px;
  overflow: hidden;
}

.frame .property-default {
  position: absolute !important;
  top: 16px !important;
  left: 16px !important;
}

.frame .icons-39 {
  width: 19.46px !important;
  height: 19.46px !important;
  position: relative !important;
}

.frame .icons-40 {
  width: 25.95px !important;
  height: 25.95px !important;
  position: relative !important;
}

.frame .property-collapsed {
  position: absolute !important;
  top: 16px !important;
  left: 240px !important;
}

.frame .div-250 {
  background-color: #1c1b1f;
  width: 1920px;
  height: 1062px;
  position: absolute;
  top: 5575px;
  left: -6px;
}

.frame .overlap-27 {
  width: 2274px;
  height: 827px;
  position: absolute;
  top: 298px;
  left: 394px;
}

.frame .resources-all {
  background-color: var(--x01-neutral100);
  border: 12px solid #8181818f;
  border-radius: 8px;
  width: 1118px;
  height: 802px;
  position: absolute;
  top: 6px;
  left: 1156px;
  overflow: hidden;
}

.frame .div-251 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18.23px;
  width: 564px;
  display: flex;
  position: absolute;
  top: 144px;
  left: 214px;
}

.frame .frame-wrapper-63 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24.31px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-252 {
  align-items: center;
  gap: 12.16px;
  width: 566.79px;
  height: 36.47px;
  margin-right: -3.04px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-input-9 {
  border: .76px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.04px;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 9.12px;
  display: flex;
  position: relative;
}

.frame .div-253 {
  flex: none;
  align-items: flex-start;
  gap: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .star-8 {
  width: 15.2px;
  height: 15.2px;
  position: relative;
}

.frame .value-4 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  width: 117px;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 400;
  line-height: 14.6px;
  position: relative;
}

.frame .tune {
  width: 18.23px;
  height: 18.23px;
  margin-top: -6153.6px;
  margin-left: -11755px;
  position: relative;
}

.frame .button-12 {
  flex: none !important;
  height: 36.47px !important;
}

.frame .button-13 {
  width: 18.23px !important;
  height: 18.23px !important;
  margin-top: -6162.71px !important;
  margin-left: -6720.77px !important;
}

.frame .button-14 {
  background-color: var(--secondary-50) !important;
  border: unset !important;
  border-color: unset !important;
  height: unset !important;
  border-radius: 6.08px !important;
  flex: 1 !important;
  align-self: stretch !important;
  gap: 1.52px !important;
  width: 100% !important;
  padding: 9.12px 9.12px 9.12px 6.08px !important;
}

.frame .button-15 {
  letter-spacing: -.3px !important;
  white-space: unset !important;
  font-size: 12.2px !important;
  font-weight: 700 !important;
}

.frame .button-16 {
  margin-bottom: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: unset !important;
  gap: 3.04px !important;
}

.frame .div-254 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15.2px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .container-nav-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 18.23px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .container-nav {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .container-menu-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.frame .navlink-4 {
  border-bottom-style: solid;
  border-bottom-width: 1.52px;
  border-color: var(--secondary-50);
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.08px;
  padding: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-255 {
  color: var(--secondary-40);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2.52px;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 500;
  line-height: 14.6px;
  position: relative;
}

.frame .navlink-5 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 3.04px;
  padding: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-256 {
  color: var(--x01-neutral70);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.76px;
  margin-bottom: -.24px;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 500;
  line-height: 14.6px;
  position: relative;
}

.frame .div-wrapper-39 {
  background-color: #eb5757;
  border-radius: 6.08px;
  width: 12.16px;
  height: 12.16px;
  position: relative;
}

.frame .text-wrapper-257 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: 5px;
  height: 7px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 700;
  line-height: 9.1px;
  position: absolute;
  top: 2px;
  left: 4px;
}

.frame .navlink-6 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.08px;
  padding: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .div-255 {
  border: .76px solid;
  border-color: var(--x01-neutral50);
  border-radius: 6.08px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.52px;
  padding: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .value-5 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 10.6px;
  font-weight: 700;
  line-height: 12.8px;
  position: relative;
}

.frame .chevron-4 {
  width: 12.16px;
  height: 12.16px;
  margin-top: -6207.88px;
  margin-left: -6745.77px;
  position: relative;
}

.frame .div-256 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 15.2px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .card-design-instance {
  border-radius: 6.08px !important;
  gap: 69.14px !important;
  width: 274.28px !important;
  height: 151.95px !important;
}

.frame .card-design-2 {
  background-image: url("folder-card-4.f316be2a.png") !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-3 {
  width: 264px !important;
  padding: 0 12.16px !important;
  top: 18px !important;
}

.frame .card-design-4 {
  gap: 6.08px !important;
}

.frame .card-design-5 {
  gap: 9.12px !important;
}

.frame .card-design-6 {
  gap: 12.16px !important;
}

.frame .card-design-7 {
  font-family: Inter, Helvetica !important;
  font-size: 13.7px !important;
  font-style: unset !important;
  letter-spacing: -.05px !important;
  margin-top: -.76px !important;
  font-weight: 600 !important;
  line-height: 16.4px !important;
}

.frame .card-design-8 {
  border-radius: 3.04px !important;
  gap: 7.6px !important;
  padding: 1.52px 3.04px !important;
}

.frame .card-design-9 {
  font-family: Inter, Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  margin-top: -.76px !important;
  font-weight: 700 !important;
  line-height: 10.9px !important;
}

.frame .card-design-10 {
  gap: 3.04px !important;
}

.frame .card-design-11 {
  border-radius: 1.52px !important;
  width: 3.04px !important;
  height: 3.04px !important;
}

.frame .card-design-12 {
  font-family: Inter, Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  font-weight: 400 !important;
  line-height: 10.9px !important;
}

.frame .card-design-13 {
  width: 24.31px !important;
  height: 12.16px !important;
  margin-top: -6266.88px !important;
  margin-left: -12212px !important;
}

.frame .card-design-14 {
  background-image: url("arrow-back-3.bc340a45.png") !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-option-2 {
  width: 24.31px !important;
  height: 12.16px !important;
  margin-top: -6266.88px !important;
  margin-left: -12701.3px !important;
}

.frame .card-design-wrapper {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 18.23px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .card-design-15 {
  border-radius: 6.08px !important;
  gap: 69.14px !important;
  width: 275.79px !important;
  height: 151.95px !important;
}

.frame .card-design-16 {
  background-image: url("folder-card-2.772b0172.png") !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-17 {
  width: 24.31px !important;
  height: 12.16px !important;
  margin-top: -6434.03px !important;
  margin-left: -12121.8px !important;
}

.frame .widget-leaderboard-2 {
  border: .38px solid;
  border-color: var(--x01-neutral70);
  border-radius: 9.12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24.31px;
  width: 249px;
  display: flex;
  position: absolute;
  top: 141px;
  left: 814px;
  overflow: hidden;
}

.frame .container-8 {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 18.23px;
  width: 100%;
  padding: 18.23px 15.2px;
  display: flex;
  position: relative;
}

.frame .main-container {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12.16px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-wrapper-40 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-258 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 600;
  line-height: 18.2px;
  position: relative;
}

.frame .bar-8 {
  border: .38px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.04px;
  align-items: center;
  gap: 1.52px;
  width: 53.18px;
  padding: 6.08px;
  display: flex;
  position: relative;
}

.frame .text-29 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.18px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  line-height: 10.9px;
  position: relative;
}

.frame .chevron-5 {
  width: 12.16px;
  height: 11.4px;
  margin-top: -6157.39px;
  margin-left: -6972.74px;
  position: relative;
}

.frame .line-44 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -6133.08px;
  margin-left: -6852.79px;
  position: relative;
}

.frame .contestant-5 {
  border-radius: 9.12px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.08px 9.12px;
  display: flex;
  position: relative;
}

.frame .div-257 {
  flex: none;
  align-items: center;
  gap: 6.08px;
  display: inline-flex;
  position: relative;
}

.frame .image-5 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6222.73px;
  margin-left: -6861.91px;
  position: relative;
}

.frame .ellipse-46 {
  background-color: var(--accent-50);
  border: 1.09px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.8px;
  width: 8px;
  height: 8px;
  position: relative;
  top: 24px;
  left: 23px;
}

.frame .div-258 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.04px;
  display: inline-flex;
  position: relative;
}

.frame .div-259 {
  flex: none;
  align-items: center;
  gap: 3.04px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-259 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 10.6px;
  font-weight: 500;
  line-height: 12.8px;
  position: relative;
}

.frame .expand-less-4 {
  width: 12.16px;
  height: 12.16px;
  margin-top: -6223.99px;
  margin-left: -6847.34px;
  position: relative;
}

.frame .text-wrapper-260 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 10.9px;
  position: relative;
}

.frame .position {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6222.73px;
  margin-left: -13822.6px;
  position: relative;
}

.frame .polygon-10 {
  width: 26px;
  height: 23px;
  position: absolute;
  top: -6223px;
  left: -7030px;
}

.frame .text-wrapper-261 {
  color: var(--x01-neutral100);
  letter-spacing: -.2px;
  height: 18px;
  font-family: Manrope, Helvetica;
  font-size: 13px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 12px;
}

.frame .div-260 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6265.28px;
  margin-left: -6861.91px;
  position: relative;
}

.frame .expand-less-5 {
  width: 12.16px;
  height: 12.16px;
  margin-top: -6254.38px;
  margin-left: -6859.34px;
  position: relative;
}

.frame .div-261 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6265.28px;
  margin-left: -13810.6px;
  position: relative;
}

.frame .polygon-11 {
  width: 26px;
  height: 23px;
  position: absolute;
  top: -6265px;
  left: -7030px;
}

.frame .text-wrapper-262 {
  color: var(--x01-neutral100);
  letter-spacing: -.2px;
  height: 18px;
  font-family: Manrope, Helvetica;
  font-size: 13px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 4px;
  left: 11px;
}

.frame .div-wrapper-41 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6307.83px;
  margin-left: -6861.91px;
  position: relative;
}

.frame .text-wrapper-263 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  white-space: nowrap;
  height: 15px;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 14.6px;
  position: absolute;
  top: 7px;
  left: 8px;
}

.frame .expand-less-6 {
  width: 12.16px;
  height: 12.16px;
  margin-top: -6309.08px;
  margin-left: -6862.18px;
  position: relative;
}

.frame .div-262 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6307.83px;
  margin-left: -13810.4px;
  position: relative;
}

.frame .polygon-12 {
  width: 26px;
  height: 23px;
  position: absolute;
  top: -6308px;
  left: -7030px;
}

.frame .contestant-6 {
  background-color: var(--primary-98);
  border-radius: 6.08px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.08px 9.12px;
  display: flex;
  position: relative;
}

.frame .div-263 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6350.38px;
  margin-left: -6861.91px;
  position: relative;
}

.frame .ellipse-47 {
  background-color: var(--accent-50);
  border: 1.09px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.8px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 24px;
  left: 23px;
}

.frame .text-wrapper-264 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  white-space: nowrap;
  height: 15px;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 700;
  line-height: 14.6px;
  position: absolute;
  top: 8px;
  left: 6px;
}

.frame .expand-less-7 {
  width: 12.16px;
  height: 12.16px;
  margin-top: -6339.47px;
  margin-left: -6853.34px;
  position: relative;
}

.frame .div-wrapper-42 {
  width: 30.39px;
  height: 30.39px;
  position: relative;
}

.frame .text-wrapper-265 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  white-space: nowrap;
  height: 16px;
  font-family: Inter, Helvetica;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 16.4px;
  position: absolute;
  top: 5px;
  left: 11px;
}

.frame .div-264 {
  background-image: url("arrow-back-3.bc340a45.png");
  background-size: 100% 100%;
  width: 30.39px;
  height: 30.39px;
  margin-top: -6392.92px;
  margin-left: -6861.91px;
  position: relative;
}

.frame .expand-less-8 {
  width: 12.16px;
  height: 12.16px;
  margin-top: -6394.18px;
  margin-left: -6856.34px;
  position: relative;
}

.frame .button-17 {
  color: var(--x01-neutral100) !important;
  letter-spacing: -.16px !important;
  margin-top: -1.68px !important;
  margin-bottom: -.16px !important;
  font-size: 10.6px !important;
  line-height: 13.7px !important;
}

.frame .button-18 {
  width: 10.64px !important;
  height: 10.64px !important;
  margin-top: -6446.87px !important;
  margin-left: -6743.86px !important;
}

.frame .button-19 {
  border-radius: 3.04px !important;
  gap: 3.04px !important;
  width: 218.81px !important;
  height: 30.39px !important;
  padding: 9.12px 24.31px !important;
}

.frame .div-265 {
  background-color: var(--x01-neutral98);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7.6px;
  width: 606px;
  padding: 24.31px 24.31px 36.47px;
  display: flex;
  position: absolute;
  top: 580px;
  left: 190px;
}

.frame .div-266 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.2px;
  width: 581.98px;
  margin-right: -24.31px;
  display: flex;
  position: relative;
}

.frame .frame-wrapper-64 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 320.62px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-wrapper-43 {
  flex: none;
  align-items: center;
  gap: 109.41px;
  display: inline-flex;
  position: relative;
}

.frame .div-267 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12.16px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .card-design-option-3 {
  border-radius: 18.8px !important;
  width: 18.23px !important;
  height: 18.23px !important;
}

.frame .card-design-option-4 {
  border-radius: 18.8px !important;
  width: 18px !important;
  height: 18px !important;
}

.frame .card-design-option-5 {
  width: 9px !important;
  height: 9px !important;
  top: 5px !important;
  left: 5px !important;
}

.frame .card-design-option-6 {
  background-image: url("folder-card-1.0eeb4b78.png") !important;
  border-radius: 6.08px !important;
  height: 151.95px !important;
}

.frame .card-design-option-7 {
  width: 24.31px !important;
  height: 12.16px !important;
  margin-top: -6665.44px !important;
  margin-left: -12161.3px !important;
}

.frame .card-design-18 {
  gap: 15.2px !important;
  top: 12px !important;
  left: 12px !important;
}

.frame .card-design-19 {
  gap: 3.04px !important;
  width: 234.01px !important;
}

.frame .card-design-20 {
  width: 24.31px !important;
  height: 12.16px !important;
  margin-top: -6659.37px !important;
  margin-left: -6327.79px !important;
}

.frame .card-design-21 {
  font-family: Inter, Helvetica !important;
  font-size: 10.6px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  margin-top: -.76px !important;
  font-weight: 400 !important;
  line-height: 12.8px !important;
}

.frame .card-design-22 {
  gap: 1.52px !important;
  top: 100px !important;
  left: 12px !important;
}

.frame .card-design-23 {
  font-family: Inter, Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  margin-top: -.76px !important;
  font-weight: 600 !important;
  line-height: 10.9px !important;
}

.frame .card-design-24 {
  border-radius: 15.2px !important;
  gap: 3.04px !important;
  padding: 3.04px !important;
}

.frame .card-design-25 {
  background-image: url("arrow-back-3.bc340a45.png") !important;
  width: 21.27px !important;
  height: 21.27px !important;
  margin-top: -6763.06px !important;
  margin-left: -6540.53px !important;
}

.frame .card-design-26 {
  letter-spacing: -.23px !important;
  font-size: 9.1px !important;
  line-height: 13.7px !important;
}

.frame .card-design-27 {
  border-radius: 15.2px !important;
  gap: 3.04px !important;
  padding: 3.04px 6.08px 3.04px 3.04px !important;
}

.frame .card-design-28 {
  background-image: url("arrow-back-3.bc340a45.png") !important;
  width: 21.27px !important;
  height: 21.27px !important;
  margin-top: -6763.06px !important;
  margin-left: -6612.96px !important;
}

.frame .card-design-29 {
  border-radius: 6.08px !important;
  gap: 69.14px !important;
  width: 264.4px !important;
  height: 151.95px !important;
}

.frame .all-files-instance {
  flex: none !important;
}

.frame .all-files-2 {
  background-image: url("arrow-back-3.bc340a45.png") !important;
  width: 258.32px !important;
  height: 151.95px !important;
  margin-top: -6647.21px !important;
  margin-left: -6801.88px !important;
}

.frame .all-files-3 {
  gap: 69.14px !important;
  width: 222px !important;
  top: 12px !important;
  left: 18px !important;
}

.frame .all-files-4 {
  font-family: Inter, Helvetica !important;
  font-size: 15.2px !important;
  font-style: unset !important;
  letter-spacing: -.03px !important;
  font-weight: 600 !important;
  line-height: 18.2px !important;
}

.frame .all-files-5 {
  width: 24.31px !important;
  height: 24.31px !important;
  margin-top: -6659.37px !important;
  margin-left: -13284.6px !important;
}

.frame .arrow-back-ios-new-wrapper-3 {
  background-color: var(--x01-neutral100);
  border-radius: 24.31px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 112px;
  left: 564px;
  transform: rotate(180deg);
  box-shadow: -3.04px -.76px 18.23px #9393931f;
}

.frame .arrow-back-ios-new-3 {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 6702px;
  left: 6777px;
  transform: rotate(-180deg);
}

.frame .comments-2 {
  background-color: var(--secondary-100);
  border: .76px solid;
  border-color: var(--x01-neutral90);
  border-radius: 6.08px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15.2px;
  width: 249px;
  height: 289px;
  padding: 15.2px;
  display: flex;
  position: absolute;
  top: 504px;
  left: 814px;
  overflow-x: scroll;
}

.frame .top-3 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.12px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .title-6 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  flex: 1;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 15.2px;
  font-weight: 600;
  line-height: 18.2px;
  position: relative;
}

.frame .container-9 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-end;
  gap: 15.2px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .thread-line-2 {
  width: 1px;
  height: 476px;
  position: absolute;
  top: 18px;
  left: 9px;
}

.frame .comment-3 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.04px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .div-268 {
  background-image: url("avatar-2.09d5c50e.png");
  background-size: 100% 100%;
  width: 18.23px;
  height: 18.23px;
  position: relative;
}

.frame .content-7 {
  background-color: var(--x01-neutral98);
  border-radius: 7.6px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 6.08px;
  padding: 6.08px 9.12px;
  display: flex;
  position: relative;
}

.frame .paragraph-4 {
  color: var(--x01-neutral20);
  align-self: stretch;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 10.6px;
  position: relative;
}

.frame .text-wrapper-266 {
  color: #2b2b2b;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 18.2px;
  text-decoration: underline;
}

.frame .text-wrapper-267 {
  color: #2b2b2b;
  letter-spacing: 0;
  font-family: Manrope, Helvetica;
  line-height: 18.2px;
}

.frame .text-wrapper-268 {
  color: #2b2b2b;
  letter-spacing: -.04px;
  line-height: 12.8px;
}

.frame .rectangle-wrapper {
  align-self: stretch;
  width: 100%;
  height: 46.35px;
  position: relative;
}

.frame .rectangle-15 {
  object-fit: cover;
  width: 176px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-269 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  line-height: 10.9px;
  position: relative;
}

.frame .text-wrapper-270 {
  color: var(--x01-neutral50);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.76px;
  font-family: Manrope, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 13.7px;
  position: relative;
}

.frame .text-wrapper-271 {
  color: var(--x01-neutral50);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.26px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  line-height: 12.8px;
  text-decoration: underline;
  position: relative;
}

.frame .settings-2 {
  width: 18.23px;
  height: 15.2px;
  position: relative;
}

.frame .group-14 {
  width: 12px;
  height: 3px;
  position: relative;
  top: 6px;
  left: 3px;
}

.frame .ellipse-48 {
  background-color: var(--x01-neutral50);
  border-radius: 1.45px;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .ellipse-49 {
  background-color: var(--x01-neutral50);
  border-radius: 1.45px;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 5px;
}

.frame .ellipse-50 {
  background-color: var(--x01-neutral50);
  border-radius: 1.45px;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 9px;
}

.frame .div-269 {
  background-image: url("avatar-1.087f4087.png");
  background-size: 100% 100%;
  width: 18.23px;
  height: 18.23px;
  position: relative;
}

.frame .comment-4 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.04px;
  width: 100%;
  padding: 0 0 0 21.27px;
  display: flex;
  position: relative;
}

.frame .avatar-11 {
  object-fit: cover;
  width: 18.23px;
  height: 18.23px;
  position: relative;
}

.frame .content-8 {
  background-color: var(--x02-neutral90);
  border-radius: 7.6px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 9.12px;
  padding: 6.08px 9.12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .paragraph-5 {
  color: #191d23;
  align-self: stretch;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 10.6px;
  position: relative;
}

.frame .text-wrapper-272 {
  letter-spacing: 0;
  font-weight: 600;
  line-height: 18.2px;
  text-decoration: underline;
}

.frame .text-wrapper-273 {
  letter-spacing: 0;
  line-height: 18.2px;
}

.frame .text-wrapper-274 {
  letter-spacing: -.04px;
  line-height: 12.8px;
}

.frame .div-wrapper-44 {
  background-color: var(--x01-neutral100);
  border: .38px solid #d9d9d9;
  border-radius: 3.04px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 7.6px 9.12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .text-wrapper-275 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  flex: 1;
  margin-top: -.38px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 400;
  line-height: 10.9px;
  position: relative;
}

.frame .dropdown-3 {
  border-radius: 6.08px;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 44.07px;
  display: flex;
  position: relative;
}

.frame .label-wrapper-2 {
  flex: none;
  align-items: flex-start;
  gap: 7.6px;
  padding: 3.04px;
  display: inline-flex;
  position: relative;
}

.frame .label-8 {
  color: var(--x01-neutral10);
  letter-spacing: .21px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.76px;
  font-family: Manrope, Helvetica;
  font-size: 10.6px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .overlap-28 {
  width: 904px;
  height: 65px;
  position: absolute;
  top: 61px;
  left: 190px;
}

.frame .line-45 {
  width: 166px;
  height: 1px;
  position: absolute;
  top: 64px;
  left: 0;
}

.frame .div-270 {
  border-bottom-style: solid;
  border-bottom-width: .76px;
  border-color: var(--x01-neutral98);
  justify-content: space-between;
  align-items: center;
  width: 904px;
  padding: 15.2px 30.39px 15.2px 24.31px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame .text-wrapper-276 {
  color: var(--x01-neutral10);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.76px;
  font-family: Inter, Helvetica;
  font-size: 24.3px;
  font-weight: 700;
  line-height: 34px;
  position: relative;
}

.frame .img-36 {
  width: 24.31px;
  height: 24.31px;
  margin-top: -6065.94px;
  margin-left: -10961px;
  position: relative;
}

.frame .line-46 {
  width: 166px;
  height: 1px;
  position: absolute;
  top: 578px;
  left: 190px;
}

.frame .line-47 {
  width: 704px;
  height: 1px;
  position: absolute;
  top: -5181px;
  left: -5227px;
}

.frame .menu-instance {
  width: 190px !important;
  height: 717px !important;
  padding: 9.12px 0 !important;
  position: absolute !important;
  top: 61px !important;
  left: 0 !important;
}

.frame .menu-9 {
  flex: none !important;
  align-self: stretch !important;
  gap: 3.04px !important;
  width: 100% !important;
  padding: 12.16px 30.39px !important;
}

.frame .icons-41 {
  width: 18.23px !important;
  height: 18.23px !important;
  position: relative !important;
}

.frame .menu-10 {
  font-family: Inter, Helvetica !important;
  font-size: 12.2px !important;
  font-style: unset !important;
  letter-spacing: -.02px !important;
  font-weight: 500 !important;
  line-height: 14.6px !important;
}

.frame .menu-11 {
  gap: 3.04px !important;
  padding: 12.16px 30.39px !important;
}

.frame .menu-12 {
  width: 12px !important;
  height: 15px !important;
  left: 3px !important;
}

.frame .menu-13 {
  width: 18.23px !important;
  height: 18.23px !important;
}

.frame .menu-14 {
  width: 11px !important;
  height: 11px !important;
  top: 3px !important;
  left: 3px !important;
}

.frame .menu-15 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  width: 5px !important;
  height: 5px !important;
  top: 8px !important;
  left: 4px !important;
}

.frame .menu-16 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  width: 5px !important;
  height: 5px !important;
  left: 4px !important;
}

.frame .menu-17 {
  width: 13px !important;
  height: 13px !important;
}

.frame .menu-18 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
  left: 8px !important;
}

.frame .menu-19 {
  border: 1.52px solid !important;
  border-radius: 2.47px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
}

.frame .menu-20 {
  gap: 18.23px !important;
}

.frame .menu-21 {
  gap: 2.28px !important;
  padding: 3.04px 30.39px !important;
}

.frame .menu-22 {
  font-family: Inter, Helvetica !important;
  font-size: 9.1px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  margin-top: -.76px !important;
  font-weight: 400 !important;
  line-height: 10.9px !important;
}

.frame .menu-23 {
  width: 6.08px !important;
  height: 6.08px !important;
}

.frame .menu-24 {
  border-radius: 1.52px !important;
  width: 3px !important;
  height: 3px !important;
}

.frame .menu-25 {
  width: 24px !important;
  height: 24px !important;
  top: 347px !important;
  left: 178px !important;
}

.frame .div-271 {
  background-color: var(--x02-neutral95);
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .38px;
  justify-content: space-between;
  align-items: center;
  width: 1094px;
  padding: 15.2px 30.39px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .div-272 {
  background-image: url("group-417.42683c93.png");
  background-size: 100% 100%;
  width: 98.77px;
  height: 27.33px;
  position: relative;
}

.frame .div-273 {
  justify-content: flex-end;
  align-items: center;
  gap: 18.23px;
  width: 161.07px;
  padding: 3.04px 0;
  display: flex;
  position: relative;
}

.frame .div-274 {
  flex: none;
  align-items: flex-start;
  gap: 6.08px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame .icon-7 {
  width: 24.31px;
  height: 24.31px;
  margin-top: -6028.23px;
  margin-left: -6938.51px;
  position: relative;
}

.frame .notifications {
  width: 24.31px;
  height: 24.31px;
  margin-top: -6028.23px;
  margin-left: -6908.12px;
  position: relative;
}

.frame .ellipse-wrapper-8 {
  background-image: url("ellipse-5.2c029b01.png");
  background-position: 50%;
  background-size: cover;
  width: 24.31px;
  height: 24.31px;
  margin-top: -6028.23px;
  margin-left: -7011.45px;
  position: relative;
}

.frame .ellipse-51 {
  background-color: var(--accent-50);
  border: .76px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.04px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 18px;
  left: 18px;
}

.frame .text-wrapper-277 {
  color: var(--x01-neutral20);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.2px;
  font-weight: 500;
  line-height: 14.6px;
  position: relative;
}

.frame .dashboard-home-2 {
  width: 1224px;
  height: 827px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-278 {
  color: #fff;
  letter-spacing: -.28px;
  text-align: center;
  width: 614px;
  height: 128px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  position: absolute;
  top: 75px;
  left: 687px;
}

.frame .text-wrapper-279 {
  color: #d9d9d9;
  letter-spacing: -.1px;
  text-align: center;
  width: 567px;
  height: 24px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 227px;
  left: 711px;
}

.instance-node-2 {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.apple-iphone-pro {
  width: 439px;
  height: 855px;
}

.apple-iphone-pro .overlap-group-3 {
  height: 855px;
  position: relative;
}

.apple-iphone-pro .manage-users {
  background-color: var(--ui-essentialsneutralntrl100);
  width: 356px;
  height: 778px;
  position: absolute;
  top: 41px;
  left: 41px;
  overflow: hidden;
}

.apple-iphone-pro .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15.83px;
  width: 327.46px;
  display: flex;
  position: absolute;
  top: 79px;
  left: 13px;
}

.apple-iphone-pro .text-wrapper-6 {
  color: var(--x01-neutral10);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 19.8px;
  font-weight: 700;
  line-height: 27.7px;
  position: relative;
}

.apple-iphone-pro .frame-6 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 11.87px;
  width: 100%;
  display: flex;
  position: relative;
}

.apple-iphone-pro .text-input {
  border: .99px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.96px;
  flex: 1;
  align-items: center;
  gap: 3.96px;
  padding: 7.91px 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .frame-7 {
  flex: none;
  align-items: flex-start;
  gap: 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .search {
  width: 19.79px;
  height: 19.79px;
  position: relative;
}

.apple-iphone-pro .value {
  color: var(--x01-neutral40);
  letter-spacing: -.06px;
  width: 152.35px;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 16.6px;
  position: relative;
}

.apple-iphone-pro .sort-wrapper {
  border: .99px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.96px;
  flex: none;
  align-items: center;
  gap: 3.96px;
  padding: 5.94px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .img {
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .frame-wrapper {
  background-color: #efede9;
  width: 356px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.apple-iphone-pro .frame-8 {
  justify-content: space-between;
  align-items: center;
  width: 324px;
  display: flex;
  position: relative;
  top: 12px;
  left: 16px;
}

.apple-iphone-pro .frame-9 {
  flex: none;
  align-items: center;
  gap: 11.87px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .frame-10 {
  flex: none;
  align-items: center;
  gap: 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .ellipse-wrapper {
  background-image: url("ellipse-5.2c029b01.png");
  background-position: 50%;
  background-size: cover;
  width: 31.66px;
  height: 31.66px;
  position: relative;
}

.apple-iphone-pro .ellipse-5 {
  border: .99px solid;
  border-color: var(--x01-neutral100);
  background-color: #00b070;
  border-radius: 3.96px;
  width: 8px;
  height: 8px;
  position: relative;
  top: 24px;
  left: 24px;
}

.apple-iphone-pro .frame-11 {
  align-items: center;
  gap: 42.54px;
  display: inline-flex;
  position: absolute;
  top: 174px;
  left: 14px;
}

.apple-iphone-pro .container-menu {
  flex: none;
  align-items: flex-start;
  height: 32.65px;
  display: inline-flex;
  position: relative;
  overflow-x: scroll;
}

.apple-iphone-pro .navlink {
  border-color: #4f78db;
  border-bottom-style: solid;
  border-bottom-width: 1.98px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.91px;
  padding: 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-7 {
  color: #3f60af;
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.57px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .badge-base {
  background-color: #4f78db;
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-top: -2.05px;
  margin-bottom: -2.05px;
  padding: 3.96px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 10.9px;
  font-weight: 600;
  line-height: 13.1px;
  position: relative;
}

.apple-iphone-pro .navlink-wrapper {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.91px;
  width: 87.06px;
  padding: 7.91px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .navlink-2 {
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 7.91px;
  margin-top: -11.38px;
  margin-bottom: -11.38px;
  padding: 7.91px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-8 {
  color: var(--x01-neutral70);
  letter-spacing: -.02px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -3.63px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .div-wrapper {
  background-color: #868686;
  border-radius: 11.87px;
  width: 23.74px;
  height: 23.74px;
  margin-right: -3.63px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-9 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  white-space: nowrap;
  height: 12px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 11.9px;
  position: absolute;
  top: 3px;
  left: 5px;
}

.apple-iphone-pro .frame-12 {
  border-radius: 3.96px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.96px;
  padding: 5.94px 3.96px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .img-2 {
  width: 15.83px;
  height: 15.83px;
  position: relative;
}

.apple-iphone-pro .text-wrapper-10 {
  color: #363636;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.07px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .frame-13 {
  align-items: flex-start;
  width: 324px;
  display: flex;
  position: absolute;
  top: 223px;
  left: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.apple-iphone-pro .column {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  align-items: flex-start;
  width: 210.72px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .table-header-cell {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  background-color: #e5fbf3;
  align-self: stretch;
  align-items: center;
  gap: 11.87px;
  width: 100%;
  height: 43.53px;
  padding: 11.87px 23.74px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .div-3 {
  flex: none;
  align-items: center;
  gap: 3.96px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-2 {
  color: #363636;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  line-height: 16.6px;
  position: relative;
}

.apple-iphone-pro .table-cell {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 11.87px;
  width: 100%;
  padding: 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .avatar {
  background-image: url("avatar-12.abc36384.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .div-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-3 {
  color: var(--x01-neutral20);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .supporting-text {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.9px;
  font-weight: 400;
  line-height: 13.1px;
  position: relative;
}

.apple-iphone-pro .avatar-2 {
  background-image: url("avatar-10.31de09bd.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .avatar-3 {
  background-color: #4f78db;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .text-4 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  text-align: center;
  width: 24px;
  font-family: Inter, Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  line-height: 14.8px;
  position: absolute;
  top: 4px;
  left: 0;
}

.apple-iphone-pro .avatar-4 {
  background-image: url("avatar-8.6744071a.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .avatar-5 {
  background-image: url("avatar-6.27b86322.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .avatar-6 {
  background-image: url("avatar-4.9efdea48.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .avatar-7 {
  background-image: url("avatar-2.0c9f752c.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .avatar-8 {
  background-image: url("avatar.3083a62a.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 148.39px;
  width: 23.74px;
  height: 23.74px;
  position: relative;
}

.apple-iphone-pro .table-header-wrapper {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  background-color: #e5fbf3;
  align-self: stretch;
  align-items: center;
  gap: 11.87px;
  width: 100%;
  height: 43.53px;
  padding: 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .text-5 {
  color: #363636;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  line-height: 17.8px;
  position: relative;
}

.apple-iphone-pro .table-cell-2 {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  align-items: center;
  height: 50.45px;
  padding: 13.85px 11.87px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .badge-base-2 {
  background-color: #4f78db;
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 3.96px 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-6 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .badge-base-3 {
  background-color: var(--tertiary-30);
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 3.96px 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .badge-wrapper {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 50.45px;
  padding: 2.97px 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .badge {
  mix-blend-mode: multiply;
  flex: 1;
  align-items: flex-start;
  gap: 11.87px;
  margin-top: -.57px;
  margin-bottom: -.57px;
  padding: 11.87px 0;
  display: flex;
  position: relative;
}

.apple-iphone-pro .badge-base-4 {
  background-color: var(--primary-50);
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 3.96px 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .badge-base-5 {
  background-color: var(--x02-neutral80);
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 3.96px 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-7 {
  color: #363636;
  letter-spacing: -.05px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 700;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .table-cell-3 {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 50.45px;
  padding: 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .badge-base-wrapper {
  mix-blend-mode: multiply;
  flex: 1;
  align-items: center;
  margin-top: -9.47px;
  margin-bottom: -9.47px;
  padding: 11.87px 0;
  display: flex;
  position: relative;
}

.apple-iphone-pro .badge-2 {
  mix-blend-mode: multiply;
  flex: 1;
  align-items: flex-start;
  margin-top: -9.47px;
  margin-bottom: -9.47px;
  padding: 11.87px 0;
  display: flex;
  position: relative;
}

.apple-iphone-pro .badge-base-6 {
  background-color: #00b070;
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 3.96px 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .badge-base-7 {
  background-color: var(--x01-neutral98);
  border-radius: 15.83px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 3.96px 7.91px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-8 {
  color: #363636;
  letter-spacing: -.05px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.99px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .column-2 {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .table-header-cell-2 {
  background-color: #e5fbf3;
  border-color: #e5fbf3;
  border-bottom-style: solid;
  border-bottom-width: .99px;
  align-self: stretch;
  align-items: center;
  gap: 11.87px;
  width: 100%;
  height: 43.53px;
  padding: 11.87px 31.66px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .table-cell-4 {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 50.45px;
  padding: 11.87px 31.66px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .text-9 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .column-3 {
  background-color: var(--x01-neutral100);
  flex-direction: column;
  align-items: flex-start;
  width: 150.37px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .table-header-cell-3 {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  background-color: #e5fbf3;
  align-self: stretch;
  align-items: center;
  gap: 11.87px;
  width: 100%;
  height: 43.53px;
  padding: 11.87px 31.66px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .table-cell-5 {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 50.45px;
  padding: 7.91px 31.66px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .frame-14 {
  background-color: var(--secondary-98);
  border-radius: 3.96px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 7.91px 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .element-hours-ago {
  color: #3f60af;
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  line-height: 16.6px;
  position: relative;
}

.apple-iphone-pro .vector {
  width: 6.93px;
  height: 3.96px;
  position: relative;
}

.apple-iphone-pro .table-cell-6 {
  border-bottom-style: solid;
  border-bottom-width: .99px;
  border-color: var(--x01-neutral90);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 50.45px;
  padding: 11.87px 31.66px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .frame-15 {
  background-color: var(--secondary-98);
  border-radius: 3.96px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: -3.06px;
  margin-bottom: -3.06px;
  padding: 7.91px 11.87px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .element-hours-ago-2 {
  color: #3f60af;
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -1.69px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 500;
  line-height: 16.6px;
  position: relative;
}

.apple-iphone-pro .vector-2 {
  width: 6.93px;
  height: 3.96px;
  margin-left: -8.61px;
  position: relative;
}

.apple-iphone-pro .frame-16 {
  background-color: #e5fbf3;
  flex-direction: column;
  align-items: flex-start;
  gap: 15.83px;
  padding: 15.83px;
  display: inline-flex;
  position: absolute;
  top: 794px;
  left: 0;
}

.apple-iphone-pro .frame-17 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 324.49px;
  display: flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-11 {
  color: #363636;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 15.8px;
  font-weight: 600;
  line-height: 19px;
  position: relative;
}

.apple-iphone-pro .frame-18 {
  border-radius: 3.96px;
  flex: none;
  align-items: center;
  gap: 1.98px;
  padding: 3.96px 1.98px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-12 {
  color: #4f78db;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.07px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 500;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .arrow-forward {
  width: 15.83px;
  height: 15.83px;
  margin-top: -5400.66px;
  margin-right: -501.02px;
  position: relative;
}

.apple-iphone-pro .frame-19 {
  flex: none;
  align-items: flex-start;
  gap: 11.87px;
  width: 324.49px;
  display: flex;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

.apple-iphone-pro .card-design {
  border-radius: 5.94px;
  flex-direction: column;
  align-items: flex-start;
  gap: 61.03px;
  width: 241.39px;
  height: 154.33px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.apple-iphone-pro .folder-card {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.apple-iphone-pro .frame-20 {
  justify-content: space-between;
  align-items: flex-start;
  width: 241px;
  padding: 0 7.91px 0 11.87px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 0;
}

.apple-iphone-pro .frame-21 {
  flex: none;
  align-items: center;
  gap: 5.36px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .frame-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.05px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .frame-23 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-13 {
  color: var(--secondary-100);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 600;
  line-height: 16.6px;
  position: relative;
}

.apple-iphone-pro .frame-24 {
  background-color: var(--tertiary-20);
  border-radius: 1.98px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 2.97px 3.96px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-14 {
  color: #f5f5f5;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 8px;
  font-weight: 700;
  line-height: 9.7px;
  position: relative;
}

.apple-iphone-pro .frame-25 {
  flex: none;
  align-items: center;
  gap: 2.68px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .subheading-wrapper {
  background-color: #d7fdf2;
  border-radius: 1.98px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  padding: 3.96px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.apple-iphone-pro .subheading {
  color: #047857;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  line-height: 11.9px;
  position: relative;
}

.apple-iphone-pro .ellipse-6 {
  background-color: #f1f1f1;
  border-radius: 1.34px;
  width: 2.68px;
  height: 2.68px;
  position: relative;
}

.apple-iphone-pro .supporting-text-2 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .more-horiz {
  width: 21.76px;
  height: 10.88px;
  position: relative;
}

.apple-iphone-pro .frame-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10.88px;
  display: inline-flex;
  position: absolute;
  top: 1044px;
  left: 16px;
}

.apple-iphone-pro .arrow-forward-2 {
  width: 15.83px;
  height: 15.83px;
  margin-top: -5634.13px;
  margin-right: -501.02px;
  position: relative;
}

.apple-iphone-pro .card-design-2 {
  border-radius: 5.94px;
  flex-direction: column;
  align-items: flex-start;
  gap: 63.12px;
  width: 241.39px;
  height: 154.33px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.apple-iphone-pro .frame-27 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.87px;
  width: 241px;
  padding: 0 11.87px;
  display: flex;
  position: absolute;
  top: 11px;
  left: 0;
}

.apple-iphone-pro .frame-28 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 2.77px;
  width: 100%;
  display: flex;
  position: relative;
}

.apple-iphone-pro .frame-29 {
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.apple-iphone-pro .p {
  color: var(--secondary-100);
  letter-spacing: -.05px;
  flex: 1;
  margin-top: -.69px;
  font-family: Inter, Helvetica;
  font-size: 11.9px;
  font-weight: 600;
  line-height: 14.2px;
  position: relative;
}

.apple-iphone-pro .frame-30 {
  width: 22.2px;
  height: 11.1px;
  position: relative;
}

.apple-iphone-pro .frame-31 {
  flex: none;
  align-items: flex-start;
  gap: 2.77px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .text-wrapper-15 {
  color: var(--x01-neutral90);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 400;
  line-height: 11.9px;
  position: relative;
}

.apple-iphone-pro .speakers {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.39px;
  display: inline-flex;
  position: absolute;
  top: 86px;
  left: 11px;
}

.apple-iphone-pro .text-wrapper-16 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Inter, Helvetica;
  font-size: 10.9px;
  font-weight: 600;
  line-height: 13.1px;
  position: relative;
}

.apple-iphone-pro .chip-speaker {
  border-radius: 13.87px;
  flex: none;
  align-items: center;
  gap: 3.96px;
  padding: 1.98px 3.96px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .frame-32 {
  background-image: url("ellipse-1-2.2a386974.png");
  background-size: 100% 100%;
  width: 19.79px;
  height: 19.79px;
  position: relative;
}

.apple-iphone-pro .name {
  color: var(--secondary-100);
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.3px;
  font-weight: 500;
  line-height: 12.5px;
  position: relative;
}

.apple-iphone-pro .frame-33 {
  background-image: url("ellipse-1.25798e73.png");
  background-position: 50%;
  background-size: cover;
  width: 19.79px;
  height: 19.79px;
  position: relative;
}

.apple-iphone-pro .name-wrapper {
  background-color: #363636;
  border-radius: 11.87px;
  flex: none;
  align-items: center;
  gap: 2.77px;
  padding: 1.98px 3.96px;
  display: inline-flex;
  position: relative;
}

.apple-iphone-pro .name-2 {
  color: var(--x01-neutral98);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.69px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  line-height: 11.9px;
  position: relative;
}

.apple-iphone-pro .img-3 {
  object-fit: cover;
  width: 439px;
  height: 855px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-progress {
  border-radius: 8px;
  width: 528px;
  height: 16px;
  position: relative;
}

.video-progress .overlap-group-2 {
  width: 532px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 28px;
}

.video-progress .background {
  background-color: var(--white);
  opacity: .3;
  border-radius: 4px;
  width: 500px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-progress .buffering {
  width: 532px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-progress .buffering-progress {
  background-color: var(--white);
  opacity: .5;
  border-radius: 4px;
  height: 8px;
}

.video-progress .progress-line {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-progress .play {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-progress .playing-true {
  width: 400px;
}

.video-progress .playing-false {
  width: 80px;
}

.video-progress .playing-0-true {
  width: 200px;
}

.video-progress .playing-0-false {
  width: 8px;
}

.chipsoft-case-study {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.chipsoft-case-study .div-5 {
  background-color: #fff;
  width: 1902px;
  height: 6556px;
  position: relative;
}

.chipsoft-case-study .overlap {
  width: 2061px;
  height: 3021px;
  position: absolute;
  top: 1186px;
  left: -18px;
}

.chipsoft-case-study .overlap-2 {
  width: 2061px;
  height: 3021px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .overlap-wrapper {
  width: 1920px;
  height: 1996px;
  position: absolute;
  top: 1025px;
  left: 0;
}

.chipsoft-case-study .overlap-3 {
  width: 2269px;
  height: 2005px;
  position: relative;
  left: -279px;
}

.chipsoft-case-study .rectangle {
  background-color: #f5f5f5;
  width: 1920px;
  height: 2005px;
  position: absolute;
  top: 0;
  left: 279px;
}

.chipsoft-case-study .meetings-company {
  color: #676768;
  letter-spacing: -.28px;
  width: 669px;
  height: 128px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  position: absolute;
  top: 203px;
  left: 405px;
}

.chipsoft-case-study .auditorium-sessions {
  background-color: var(--x01-neutral10);
  border: 12px solid #8181818f;
  border-radius: 8px;
  width: 1135px;
  height: 814px;
  position: absolute;
  top: 224px;
  left: 1134px;
  overflow: hidden;
}

.chipsoft-case-study .overlap-4 {
  width: 886px;
  height: 1049px;
  position: absolute;
  top: 62px;
  left: 193px;
}

.chipsoft-case-study .base {
  object-fit: cover;
  width: 860px;
  height: 728px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .header {
  align-items: center;
  gap: 142.72px;
  width: 849px;
  display: flex;
  position: absolute;
  top: 31px;
  left: 37px;
}

.chipsoft-case-study .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 24.7px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .div-6 {
  flex: none;
  align-items: flex-start;
  gap: 12.34px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-input-2 {
  border: .77px solid;
  border-color: var(--x01-neutral70);
  border-radius: 3.09px;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 9.26px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-34 {
  flex: none;
  align-items: flex-start;
  gap: 6.17px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .search-2 {
  width: 15.43px;
  height: 15.43px;
  position: relative;
}

.chipsoft-case-study .value-2 {
  color: var(--x01-neutral70);
  letter-spacing: -.05px;
  width: 118.81px;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .tune {
  width: 1.47px;
  height: 18.52px;
  position: relative;
}

.chipsoft-case-study .frame-35 {
  flex-direction: column;
  align-items: center;
  gap: 18.52px;
  width: 849px;
  display: flex;
  position: absolute;
  top: 93px;
  left: 37px;
}

.chipsoft-case-study .nav-bar {
  border-color: #464646;
  border-bottom-style: solid;
  border-bottom-width: .77px;
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 35.49px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .nav-bar-2 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-top: -.01px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-7 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .nav-link-3 {
  border-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 1.54px;
  justify-content: center;
  align-items: center;
  gap: 6.17px;
  width: 94.12px;
  height: 35.49px;
  padding: 6.17px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-18 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .nav-link-4 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.17px;
  height: 35.49px;
  padding: 6.17px 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-19 {
  color: var(--x01-neutral60);
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .nav-link-5 {
  justify-content: center;
  align-items: center;
  gap: 6.17px;
  width: 94.12px;
  height: 35.49px;
  padding: 6.17px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .nav-link-6 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.17px;
  height: 35.49px;
  padding: 6.17px 12.34px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .nav-link-7 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.17px;
  padding: 9.26px 18.52px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-20 {
  color: var(--x01-neutral60);
  letter-spacing: -.35px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.54px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-36 {
  background-color: var(--x01-neutral30);
  border-radius: 3.09px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.09px;
  padding: 4.63px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .icons-11 {
  width: 18.52px;
  height: 18.52px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-21 {
  color: var(--x02-neutral80);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 700;
  line-height: 13px;
  position: relative;
}

.chipsoft-case-study .frame-37 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.26px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .dropdown-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 9.26px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .dropdown {
  border-radius: 4.63px;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 12.34px;
  padding: 3.09px 0;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-38 {
  flex: none;
  align-items: center;
  gap: 6.17px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-22 {
  color: #a1a1a1;
  letter-spacing: -.27px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  line-height: 18.5px;
  position: relative;
}

.chipsoft-case-study .frame-39 {
  flex: none;
  align-items: center;
  gap: 1.54px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-23 {
  color: #fff;
  letter-spacing: -.27px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  line-height: 18.5px;
  position: relative;
}

.chipsoft-case-study .chevron-right {
  width: 12.34px;
  height: 12.34px;
  margin-top: -2115.06px;
  margin-left: -779.53px;
  position: relative;
}

.chipsoft-case-study .container-events {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 18.52px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .event {
  background-color: #3a3a3a;
  border-radius: 9.26px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.71px;
  width: 100%;
  padding: 24.69px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-8 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .element {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-40 {
  flex: none;
  align-items: flex-start;
  gap: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .live {
  background-color: #eb5757;
  border-radius: 3.86px;
  flex: none;
  align-items: flex-start;
  gap: 7.71px;
  padding: 1.54px 3.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-24 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 9.3px;
  font-weight: 700;
  line-height: 12.3px;
  position: relative;
}

.chipsoft-case-study .holder {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24.69px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-41 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-25 {
  color: #fff;
  letter-spacing: -.08px;
  width: 293.16px;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 15.4px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .div-9 {
  flex: none;
  align-items: flex-start;
  gap: 3.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-26 {
  color: #fff;
  letter-spacing: -.16px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .speakers-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.17px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-27 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-42 {
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .chip-speaker-2 {
  border-radius: 15.43px;
  flex: none;
  align-items: center;
  gap: 3.09px;
  padding: 0 9.26px 0 0;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-43 {
  background-image: url("ellipse-1-42.f820875b.svg");
  background-size: 100% 100%;
  width: 21.6px;
  height: 21.6px;
  position: relative;
}

.chipsoft-case-study .name-3 {
  color: #fff;
  letter-spacing: -.27px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  line-height: 13.9px;
  position: relative;
}

.chipsoft-case-study .frame-44 {
  background-image: url("ellipse-1-41.794ad70a.svg");
  background-size: 100% 100%;
  width: 21.6px;
  height: 21.6px;
  position: relative;
}

.chipsoft-case-study .frame-45 {
  background-image: url("ellipse-1-40.1db7e78b.svg");
  background-size: 100% 100%;
  width: 21.6px;
  height: 21.6px;
  position: relative;
}

.chipsoft-case-study .chip-speaker-3 {
  background-color: var(--accent-50);
  border-radius: 15.43px;
  align-items: center;
  gap: 3.09px;
  width: 24.69px;
  height: 23.92px;
  padding: 3.09px 6.17px 3.09px 3.09px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-46 {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-47 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 6.17px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .CTA {
  all: unset;
  border: .77px solid;
  border-color: var(--x01-neutral90);
  box-sizing: border-box;
  border-radius: 3.09px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.71px;
  padding: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-28 {
  color: var(--x01-neutral90);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 700;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .CTA-2 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 3.09px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.71px;
  padding: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-29 {
  color: #fff;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 700;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .speakers-3 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 6.17px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .attending {
  align-items: flex-start;
  height: 24.69px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo {
  background-image: url("avatar-w-photo-40.843f23e6.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.92px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .outside-stroke {
  border: 1.54px solid #fff;
  border-radius: 13.5px / 13.89px;
  width: 27px;
  height: 28px;
  position: relative;
  top: -2px;
  left: -2px;
}

.chipsoft-case-study .outside-stroke-wrapper {
  background-image: url("avatar-w-photo-39.adc1974c.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.14px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
}

.chipsoft-case-study .outside-stroke-2 {
  border: 1.54px solid #fff;
  border-radius: 13.12px / 13.89px;
  width: 26px;
  height: 28px;
  position: relative;
  top: -2px;
  left: -2px;
}

.chipsoft-case-study .avatar-w-photo-2 {
  background-image: url("avatar-w-photo-38.35a5d96d.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.14px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-3 {
  background-color: #555;
  border: 1.54px solid #fff;
  border-radius: 770.71px;
  width: 26.23px;
  height: 26.23px;
  margin: -.77px -.77px -.77px -3.09px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-30 {
  color: #fff;
  letter-spacing: -.35px;
  white-space: nowrap;
  height: 9px;
  font-family: Inter, Helvetica;
  font-size: 7.7px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 5px;
}

.chipsoft-case-study .live-2 {
  background-color: #0060ff;
  border-radius: 3.86px;
  flex: none;
  align-items: flex-start;
  gap: 7.71px;
  padding: 1.54px 3.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-31 {
  color: #fff;
  letter-spacing: -.16px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .speakers-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 6.17px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .div-10 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .chip-speaker-4 {
  border-radius: 15.43px;
  width: 76.38px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .frame-48 {
  background-image: url("ellipse-1-39.e84f7ae0.svg");
  background-size: 100% 100%;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.chipsoft-case-study .name-4 {
  color: #fff;
  letter-spacing: -.27px;
  white-space: nowrap;
  height: 14px;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  line-height: 13.9px;
  position: absolute;
  top: 3px;
  left: 25px;
}

.chipsoft-case-study .chip-speaker-5 {
  border-radius: 15.43px;
  flex: none;
  align-items: center;
  gap: 3.09px;
  padding: 3.09px 6.17px 3.09px 3.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-49 {
  background-image: url("ellipse-1-38.fef3f11e.png");
  background-position: 50%;
  background-size: cover;
  width: 18.52px;
  height: 18.52px;
  position: relative;
}

.chipsoft-case-study .frame-50 {
  background-image: url("ellipse-1-37.c0ec3b5c.png");
  background-position: 50%;
  background-size: cover;
  width: 18.52px;
  height: 18.52px;
  position: relative;
}

.chipsoft-case-study .chip-speaker-6 {
  background-color: #575757;
  border-radius: 15.43px;
  align-self: stretch;
  align-items: center;
  gap: 3.09px;
  width: 24.69px;
  padding: 3.09px 6.17px 3.09px 3.09px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-51 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 9.26px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-52 {
  flex: none;
  align-items: center;
  gap: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .calendar-add-on-wrapper {
  background-color: #ffffff1f;
  border-radius: 29.32px;
  width: 30.86px;
  height: 30.86px;
  position: relative;
}

.chipsoft-case-study .calendar-add-on {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.chipsoft-case-study .dots-horizontal {
  width: 23.07px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-4 {
  background-image: url("avatar-w-photo-37.e4fc4b53.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.92px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-5 {
  background-image: url("avatar-w-photo-36.e9ccf1f4.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.14px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-6 {
  background-image: url("avatar-w-photo-35.4237b822.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.14px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
}

.chipsoft-case-study .div-11 {
  flex: none;
  align-items: center;
  gap: 3.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-32 {
  color: #fff;
  letter-spacing: -.11px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .ellipse-7 {
  background-color: #fff;
  border-radius: 1.54px;
  width: 3.09px;
  height: 3.09px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-33 {
  color: #fff;
  letter-spacing: -.16px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-53 {
  background-image: url("arrow-forward.efc3815b.png");
  background-size: 100% 100%;
  width: 21.6px;
  height: 21.6px;
  margin-top: -2748.34px;
  margin-left: -142.59px;
  position: relative;
}

.chipsoft-case-study .frame-54 {
  background-image: url("arrow-forward.efc3815b.png");
  background-size: 100% 100%;
  width: 21.6px;
  height: 21.6px;
  margin-top: -2748.34px;
  margin-left: -222.54px;
  position: relative;
}

.chipsoft-case-study .frame-55 {
  background-image: url("arrow-forward.efc3815b.png");
  background-size: 100% 100%;
  width: 21.6px;
  height: 21.6px;
  margin-top: -2748.34px;
  margin-left: -279.48px;
  position: relative;
}

.chipsoft-case-study .frame-56 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 61.72px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-7 {
  background-image: url("arrow-forward.efc3815b.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.92px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-8 {
  background-image: url("arrow-forward.efc3815b.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 770.71px;
  width: 23.14px;
  height: 24.69px;
  margin-left: -3.09px;
  position: relative;
}

.chipsoft-case-study .star-2 {
  width: 18.52px;
  height: 18.52px;
  margin-top: -2864.34px;
  position: relative;
}

.chipsoft-case-study .frame-57 {
  background-image: url("arrow-forward.efc3815b.png");
  background-size: 100% 100%;
  width: 19px;
  height: 19px;
  position: absolute;
  top: -2968px;
  left: -143px;
}

.chipsoft-case-study .frame-58 {
  background-image: url("arrow-forward.efc3815b.png");
  background-position: 50%;
  background-size: cover;
  width: 18.52px;
  height: 18.52px;
  margin-top: -2971.54px;
  margin-left: -222.05px;
  position: relative;
}

.chipsoft-case-study .frame-59 {
  background-image: url("arrow-forward.efc3815b.png");
  background-position: 50%;
  background-size: cover;
  width: 18.52px;
  height: 18.52px;
  margin-top: -2971.54px;
  margin-left: -275.91px;
  position: relative;
}

.chipsoft-case-study .frame-60 {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  width: 128.84px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-61 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.26px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-62 {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 9.26px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .calendar-add-on-2 {
  width: 19px;
  height: 19px;
  position: absolute;
  top: -2836px;
  left: -826px;
}

.chipsoft-case-study .CTA-3 {
  all: unset;
  box-sizing: border-box;
  background-color: #555;
  border-radius: 3.09px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.71px;
  padding: 9.26px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-34 {
  color: #fff;
  letter-spacing: -.21px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 13.9px;
  font-weight: 700;
  line-height: 18.5px;
  position: relative;
}

.chipsoft-case-study .menu-2 {
  background-color: var(--x02-neutral95);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 193px;
  height: 728px;
  padding: 0 0 18.52px;
  display: flex;
  position: absolute;
  top: 62px;
  left: 0;
}

.chipsoft-case-study .frame-63 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 192.87px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .design-component-instance-node {
  flex: none !important;
  gap: 3.09px !important;
  width: 192.87px !important;
  padding: 12.34px 30.86px !important;
}

.chipsoft-case-study .nav-link-8 {
  font-family: Inter, Helvetica !important;
  font-size: 12.3px !important;
  font-style: unset !important;
  letter-spacing: -.02px !important;
  font-weight: 500 !important;
  line-height: 14.8px !important;
}

.chipsoft-case-study .nav-link-9 {
  flex: none;
  align-items: center;
  gap: 3.09px;
  width: 192.87px;
  padding: 12.34px 30.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icons-13 {
  width: 18.52px !important;
  height: 18.52px !important;
}

.chipsoft-case-study .icons-14 {
  width: 12px !important;
  height: 15px !important;
  left: 3px !important;
}

.chipsoft-case-study .dashboard-2 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .design-component-instance-node-2 {
  width: 13px !important;
  height: 13px !important;
}

.chipsoft-case-study .icons-15 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  width: 5px !important;
  height: 5px !important;
  left: 4px !important;
}

.chipsoft-case-study .icons-16 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
}

.chipsoft-case-study .icons-17 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
  left: 8px !important;
}

.chipsoft-case-study .icons-18 {
  border: 1.54px solid !important;
  border-radius: 2.51px !important;
  width: 5px !important;
  height: 5px !important;
  top: 8px !important;
  left: 4px !important;
}

.chipsoft-case-study .icons-19 {
  width: 12px !important;
  height: 12px !important;
  top: 3px !important;
  left: 3px !important;
}

.chipsoft-case-study .nav-link-10 {
  flex: none !important;
  gap: 3.09px !important;
  width: 190.56px !important;
  padding: 12.34px 30.86px !important;
}

.chipsoft-case-study .extra-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.31px;
  width: 100%;
  padding: 3.09px 30.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-35 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 9.3px;
  font-weight: 400;
  line-height: 11.1px;
  position: relative;
}

.chipsoft-case-study .interpunct-2 {
  width: 6.17px;
  height: 6.17px;
  position: relative;
}

.chipsoft-case-study .ellipse-8 {
  background-color: #d9d9d9;
  border-radius: 1.54px;
  width: 3px;
  height: 3px;
  position: relative;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .frame-64 {
  background-color: var(--x02-neutral95);
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .39px;
  justify-content: space-between;
  align-items: center;
  width: 1111px;
  padding: 15.43px 30.86px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-65 {
  background-image: url("group-417-3.dd0b140b.png");
  background-size: 100% 100%;
  width: 100.29px;
  height: 27.75px;
  position: relative;
}

.chipsoft-case-study .frame-66 {
  justify-content: flex-end;
  align-items: center;
  gap: 18.52px;
  width: 163.55px;
  padding: 3.09px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .notifications {
  width: 24.69px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .frame-67 {
  background-image: url("ellipse-5-5.0e3af427.png");
  background-position: 50%;
  background-size: cover;
  width: 24.69px;
  height: 24.69px;
  position: relative;
}

.chipsoft-case-study .ellipse-9 {
  background-color: var(--accent-50);
  border: .77px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.09px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 19px;
  left: 19px;
}

.chipsoft-case-study .text-wrapper-36 {
  color: var(--x01-neutral20);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .speaker {
  background-color: #fff;
  border: 12px solid #8181818f;
  border-radius: 8px;
  width: 1069px;
  height: 767px;
  position: absolute;
  top: 404px;
  left: 0;
  overflow: hidden;
}

.chipsoft-case-study .overlap-5 {
  background-color: #1f1f1f;
  width: 1045px;
  height: 743px;
  position: relative;
}

.chipsoft-case-study .overlap-6 {
  width: 954px;
  height: 743px;
  position: absolute;
  top: 0;
  left: 91px;
}

.chipsoft-case-study .frame-68 {
  background-image: url("frame-237598.627aae74.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 10.89px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 864px;
  height: 524px;
  padding: 17.42px;
  display: flex;
  position: absolute;
  top: 190px;
  left: 0;
  overflow: hidden;
  box-shadow: 0 4.35px 15.24px #000000d6;
}

.chipsoft-case-study .frame-69 {
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  align-items: flex-start;
  gap: 7.26px;
  width: 59.91px;
  height: 29.03px;
  padding: 7.26px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-wrapper-37 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  margin-right: -1.6px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .speaker-name {
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 7.26px;
  padding: 11.61px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .frame-70 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.9px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-71 {
  flex: none;
  align-items: flex-start;
  gap: 1.45px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .img-wrapper {
  width: 14.51px;
  height: 14.51px;
  position: relative;
}

.chipsoft-case-study .ellipse-10 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -2735px;
  left: 1130px;
}

.chipsoft-case-study .text-wrapper-38 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .frame-72 {
  flex: none;
  align-items: flex-start;
  gap: 7.26px;
  padding: 0 0 0 15.97px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-39 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .frame-73 {
  background-image: url("frame-237603.40f0ea8a.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 5.81px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 238px;
  height: 147px;
  padding: 5.81px;
  display: flex;
  position: absolute;
  top: 29px;
  left: 565px;
  box-shadow: 0 4.35px 15.24px #000000d6;
}

.chipsoft-case-study .frame-74 {
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  flex: none;
  align-items: flex-start;
  gap: 7.26px;
  padding: 2.9px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-wrapper-40 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 600;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .speaker-name-2 {
  background-blend-mode: multiply;
  background-color: #000000a3;
  border-radius: 5.81px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 7.26px;
  padding: 5.81px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .frame-75 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 2.9px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-76 {
  width: 10.16px;
  height: 10.16px;
  position: relative;
}

.chipsoft-case-study .ellipse-11 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .text-wrapper-41 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 8.7px;
  font-weight: 600;
  line-height: 10.5px;
  position: relative;
}

.chipsoft-case-study .toolbar {
  background-color: #000000a3;
  border-radius: 5.81px;
  justify-content: center;
  align-items: center;
  gap: 5.81px;
  padding: 5.81px;
  display: inline-flex;
  position: absolute;
  top: 646px;
  left: 168px;
  box-shadow: 0 4.35px 15.24px #000000d6;
}

.chipsoft-case-study .keyboard-voice-wrapper {
  background-color: var(--x01-neutral30);
  border-radius: 5.81px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  padding: 11.61px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .keyboard-voice {
  width: 20.52px;
  height: 29.03px;
  position: relative;
}

.chipsoft-case-study .frame-77 {
  background-color: var(--x01-neutral30);
  border-radius: 5.81px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 11.61px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .frame-78 {
  width: 29.03px;
  height: 29.03px;
  position: relative;
}

.chipsoft-case-study .img-4 {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.chipsoft-case-study .overlap-group-4 {
  width: 26px;
  height: 26px;
  position: relative;
  left: 3px;
}

.chipsoft-case-study .chat-bubble {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 3px;
  left: 0;
}

.chipsoft-case-study .ellipse-12 {
  background-color: #eb5757;
  border-radius: 5.81px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 15px;
}

.chipsoft-case-study .text-wrapper-42 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: 4px;
  height: 7px;
  font-family: Inter, Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  line-height: 8.7px;
  position: absolute;
  top: 1px;
  left: 18px;
}

.chipsoft-case-study .frame-79 {
  flex: none;
  align-self: stretch;
  position: relative;
}

.chipsoft-case-study .frame-80 {
  background-color: #eb5757;
  border-radius: 5.81px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 11.61px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .arrow-back-ios-new-wrapper {
  background-color: var(--x01-neutral20);
  border-radius: 23.22px;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 85px;
  left: 820px;
  transform: rotate(180deg);
}

.chipsoft-case-study .arrow-back-ios-new {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

.chipsoft-case-study .polls {
  background-color: var(--x01-neutral30);
  flex-direction: column;
  align-items: flex-start;
  gap: 209.73px;
  height: 743px;
  padding: 29.03px 29.03px 58.06px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 624px;
  box-shadow: -2.9px 2.9px 9.43px #0000001a;
}

.chipsoft-case-study .frame-81 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 23.22px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-82 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 29.03px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-83 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 271.41px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-43 {
  color: var(--x01-neutral100);
  letter-spacing: -.35px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.21px;
  font-family: Inter, Helvetica;
  font-size: 23.2px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-84 {
  border-bottom-style: solid;
  border-bottom-width: .73px;
  border-color: var(--x01-neutral50);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 18.87px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-85 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-86 {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.81px;
  padding: 8.71px 5.81px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-44 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.23px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-87 {
  border-bottom-style: solid;
  border-bottom-width: .73px;
  border-color: var(--x01-neutral100);
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.81px;
  padding: 8.71px 5.81px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-45 {
  color: var(--x01-neutral100);
  letter-spacing: -.29px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .frame-88 {
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.81px;
  padding: 8.71px 5.81px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-46 {
  color: var(--x01-neutral60);
  letter-spacing: -.29px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 600;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .frame-89 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17.42px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-90 {
  border: .73px solid #6b6b6b;
  border-radius: 5.81px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 17.42px;
  width: 273.41px;
  margin: -1px;
  padding: 17.42px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .frame-91 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1.45px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-47 {
  color: #fff;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 14.5px;
  font-weight: 600;
  line-height: 17.4px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-48 {
  color: #979797;
  letter-spacing: 0;
  width: 236.58px;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  line-height: 17.4px;
  position: relative;
}

.chipsoft-case-study .frame-92 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 5.81px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-93 {
  opacity: .8;
  background-color: #393939;
  border-radius: 2.9px;
  flex: none;
  align-items: flex-end;
  gap: 5.81px;
  width: 236.58px;
  padding: 8.71px 5.81px 8.71px 11.61px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .checkbox-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 7.26px;
  padding: 2.18px 0;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .checkbox {
  width: 11.61px;
  height: 11.61px;
  position: relative;
}

.chipsoft-case-study .fill {
  border: .73px solid #bcbcbc;
  border-radius: 1.45px;
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
  left: -1px;
}

.chipsoft-case-study .text-wrapper-49 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 500;
  line-height: 17.4px;
  position: relative;
}

.chipsoft-case-study .frame-94 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.61px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .search-bar {
  border: .73px solid;
  border-color: var(--x01-neutral60);
  border-radius: 2.9px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.81px;
  padding: 8.71px 11.61px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-50 {
  color: var(--x01-neutral80);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 700;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .search-bar-2 {
  background-color: #fff;
  border-radius: 2.9px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.81px;
  padding: 8.71px 11.61px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-51 {
  color: #575757;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.73px;
  font-family: Inter, Helvetica;
  font-size: 11.6px;
  font-weight: 700;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .frame-95 {
  background-image: url("frame-237601.7163a5d0.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 5.81px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 238px;
  height: 147px;
  padding: 5.81px;
  display: flex;
  position: absolute;
  top: 29px;
  left: 151px;
  box-shadow: 0 4.35px 15.24px #000000d6;
}

.chipsoft-case-study .ellipse-13 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: -2239px;
  left: 1086px;
}

.chipsoft-case-study .overlap-7 {
  width: 238px;
  height: 152px;
  position: absolute;
  top: 23px;
  left: 403px;
}

.chipsoft-case-study .frame-96 {
  background-image: url("frame-237602.f46c1423.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 5.81px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 238px;
  height: 147px;
  padding: 5.81px;
  display: flex;
  position: absolute;
  top: 6px;
  left: 0;
  overflow: hidden;
  box-shadow: 0 4.35px 15.24px #000000d6;
}

.chipsoft-case-study .recording {
  background-color: #575757;
  border-radius: 5.81px;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 53px;
  overflow: hidden;
  box-shadow: -2.18px 0 10.16px #4d4d4d52;
}

.chipsoft-case-study .frame-97 {
  background-color: #575757;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.81px;
  padding: 5.81px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .overlap-group-wrapper {
  background-color: #dededea1;
  border-radius: 8.71px;
  width: 17.42px;
  height: 17.42px;
  position: relative;
}

.chipsoft-case-study .overlap-group-5 {
  background-color: #dededea1;
  border-radius: 7.26px;
  width: 15px;
  height: 15px;
  position: relative;
  top: 1px;
  left: 1px;
}

.chipsoft-case-study .ellipse-14 {
  background-color: #eb5757;
  border-radius: 5.08px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .text-wrapper-52 {
  color: #fff;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.2px;
  font-weight: 700;
  line-height: 12.2px;
  position: relative;
}

.chipsoft-case-study .frame-98 {
  background-color: #575757;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.26px;
  padding: 5.81px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-99 {
  background-color: var(--x01-neutral20);
  border-radius: 23.22px;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 85px;
  left: 99px;
}

.chipsoft-case-study .arrow-back-ios-new-2 {
  width: 35px;
  height: 35px;
  position: absolute;
  top: -2172px;
  left: 1147px;
}

.chipsoft-case-study .meeting-tables {
  background-color: var(--x01-neutral10);
  border: 12px solid #8181818f;
  border-radius: 8px;
  width: 1136px;
  height: 814px;
  position: absolute;
  top: 1088px;
  left: 1132px;
  overflow: hidden;
}

.chipsoft-case-study .frame-100 {
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.72px;
  width: 293px;
  height: 293px;
  padding: 15.44px;
  display: flex;
  position: absolute;
  top: 187px;
  left: 409px;
}

.chipsoft-case-study .frame-101 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.72px;
  width: 100%;
  padding: 3.09px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-53 {
  color: var(--x01-neutral100);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.77px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 600;
  line-height: 16.7px;
  position: relative;
}

.chipsoft-case-study .frame-102 {
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.chipsoft-case-study .overlap-group-6 {
  width: 213px;
  height: 213px;
  position: relative;
  top: 7px;
  left: 24px;
}

.chipsoft-case-study .table {
  background-color: var(--x01-neutral30);
  border-radius: 9.27px;
  align-items: flex-start;
  gap: 7.72px;
  padding: 3.86px;
  display: inline-flex;
  position: absolute;
  top: 32px;
  left: 35px;
  box-shadow: 0 3.09px 9.27px #393839b0;
}

.chipsoft-case-study .logo-container-wrapper {
  background-color: var(--x01-neutral10);
  border-radius: 8.61px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.72px;
  width: 137.46px;
  height: 145.95px;
  padding: 55.6px 30.89px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .logo-container {
  background-color: var(--secondary-98);
  border-radius: 3.09px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.72px;
  width: 100%;
  padding: 6.18px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .accenture-png {
  object-fit: cover;
  width: 60.45px;
  height: 15.83px;
  position: relative;
}

.chipsoft-case-study .avatar-9 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-43.18c7587e.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 176px;
  left: 66px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-10 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-42.12425b5b.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 176px;
  left: 112px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-11 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-color: #da8d6a;
  background-image: url("avatar-41.17794153.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  display: flex;
  position: absolute;
  top: 113px;
  left: 176px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .text-10 {
  color: var(--x01-neutral100);
  letter-spacing: -.54px;
  text-align: center;
  width: 30.89px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 700;
  line-height: 19.5px;
  position: relative;
}

.chipsoft-case-study .avatar-12 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-40.5b8e7601.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 66px;
  left: 176px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-13 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-39.9a7a2caf.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 39px;
  height: 39px;
  position: absolute;
  top: 66px;
  left: 0;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-14 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-38.bb238e9f.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 39px;
  height: 39px;
  position: absolute;
  top: 112px;
  left: 0;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-15 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-37.398d39ec.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 112px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-16 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-36.f2dec372.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 66px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .frame-103 {
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.72px;
  width: 293px;
  height: 293px;
  padding: 15.44px;
  display: flex;
  position: absolute;
  top: 187px;
  left: 98px;
}

.chipsoft-case-study .overlap-group-7 {
  width: 213px;
  height: 219px;
  position: relative;
  top: 7px;
  left: 24px;
}

.chipsoft-case-study .chair {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 176px !important;
  left: 108px !important;
}

.chipsoft-case-study .avatar-17 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-35.2c24cdf1.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 176px;
  left: 66px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-18 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-color: #da8d6a;
  background-image: url("avatar-34.cdc8805f.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  display: flex;
  position: absolute;
  top: 113px;
  left: 176px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-19 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-33.1aa24d09.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 66px;
  left: 176px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-20 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-32.7fd120cf.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 39px;
  height: 39px;
  position: absolute;
  top: 66px;
  left: 0;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-21 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-31.ba8ccaed.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 39px;
  height: 39px;
  position: absolute;
  top: 112px;
  left: 0;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-22 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-30.79eca063.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 112px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .avatar-23 {
  border: 1.54px solid;
  border-color: var(--x01-neutral40);
  background-image: url("avatar-29.186f8a63.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.18px;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 0;
  left: 66px;
  box-shadow: 0 0 12.36px #0000006e;
}

.chipsoft-case-study .frame-104 {
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.72px;
  width: 293px;
  height: 293px;
  padding: 15.44px;
  display: flex;
  position: absolute;
  top: 499px;
  left: 98px;
}

.chipsoft-case-study .overlap-group-8 {
  width: 221px;
  height: 230px;
  position: relative;
  left: 21px;
}

.chipsoft-case-study .chair-21 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 0 !important;
  left: 62px !important;
}

.chipsoft-case-study .chair-4 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 187px !important;
  left: 63px !important;
}

.chipsoft-case-study .chair-23 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 118px !important;
  left: 0 !important;
}

.chipsoft-case-study .chair-24 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 118px !important;
  left: 178px !important;
}

.chipsoft-case-study .chair-2 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 69px !important;
  left: 0 !important;
}

.chipsoft-case-study .chair-22 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 69px !important;
  left: 178px !important;
}

.chipsoft-case-study .chair-27 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 0 !important;
  left: 111px !important;
}

.chipsoft-case-study .chair-18 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 187px !important;
  left: 112px !important;
}

.chipsoft-case-study .table-2 {
  width: 164px;
  height: 172px;
  position: absolute;
  top: 32px;
  left: 29px;
}

.chipsoft-case-study .frame-105 {
  align-items: center;
  gap: 43.25px;
  width: 917px;
  display: flex;
  position: absolute;
  top: 93px;
  left: 97px;
}

.chipsoft-case-study .text-wrapper-54 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  width: 536.71px;
  font-family: Inter, Helvetica;
  font-size: 24.7px;
  font-weight: 700;
  line-height: 34.6px;
  position: relative;
}

.chipsoft-case-study .text-input-wrapper {
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 397.7px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-input-3 {
  border: .77px solid;
  border-color: var(--x01-neutral50);
  border-radius: 3.09px;
  flex: 1;
  align-items: center;
  gap: 4.63px;
  padding: 10.81px 6.18px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .search-3 {
  width: 15.44px;
  height: 15.44px;
  position: relative;
}

.chipsoft-case-study .value-3 {
  color: var(--x01-neutral70);
  letter-spacing: -.05px;
  width: 118.92px;
  margin-top: -.55px;
  font-family: Inter, Helvetica;
  font-size: 12.4px;
  font-weight: 400;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-55 {
  color: var(--x01-neutral70);
  letter-spacing: -.06px;
  white-space: nowrap;
  height: 17px;
  font-family: Inter, Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  line-height: 16.7px;
  position: absolute;
  top: 139px;
  left: 98px;
}

.chipsoft-case-study .frame-106 {
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.72px;
  width: 293px;
  height: 293px;
  padding: 15.44px;
  display: flex;
  position: absolute;
  top: 499px;
  left: 410px;
}

.chipsoft-case-study .frame-107 {
  align-self: stretch;
  width: 100%;
  height: 222.4px;
  position: relative;
}

.chipsoft-case-study .overlap-group-9 {
  width: 222px;
  height: 222px;
  position: relative;
  left: 20px;
}

.chipsoft-case-study .chair-13 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 0 !important;
  left: 90px !important;
}

.chipsoft-case-study .chair-26 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 179px !important;
  left: 90px !important;
}

.chipsoft-case-study .frame-108 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.18px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 0;
}

.chipsoft-case-study .chair-3 {
  width: 43.25px !important;
  height: 43.25px !important;
  position: relative !important;
}

.chipsoft-case-study .frame-109 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.18px;
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 179px;
}

.chipsoft-case-study .table-3 {
  width: 164px;
  height: 172px;
  position: absolute;
  top: 29px;
  left: 29px;
}

.chipsoft-case-study .frame-110 {
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.72px;
  width: 293px;
  height: 293px;
  padding: 15.44px;
  display: flex;
  position: absolute;
  top: 187px;
  left: 721px;
}

.chipsoft-case-study .overlap-group-10 {
  width: 224px;
  height: 221px;
  position: relative;
  top: 4px;
  left: 19px;
}

.chipsoft-case-study .chair-5 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 0 !important;
  left: 65px !important;
}

.chipsoft-case-study .chair-20 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 178px !important;
  left: 65px !important;
}

.chipsoft-case-study .chair-17 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 138px !important;
  left: 0 !important;
}

.chipsoft-case-study .chair-1 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 138px !important;
  left: 181px !important;
}

.chipsoft-case-study .chair-7 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 39px !important;
  left: 0 !important;
}

.chipsoft-case-study .chair-10 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 39px !important;
  left: 181px !important;
}

.chipsoft-case-study .chair-9 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 89px !important;
  left: 0 !important;
}

.chipsoft-case-study .chair-6 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 89px !important;
  left: 181px !important;
}

.chipsoft-case-study .chair-19 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 0 !important;
  left: 114px !important;
}

.chipsoft-case-study .chair-12 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 178px !important;
  left: 114px !important;
}

.chipsoft-case-study .table-4 {
  width: 164px;
  height: 172px;
  position: absolute;
  top: 28px;
  left: 31px;
}

.chipsoft-case-study .frame-111 {
  background-color: var(--x01-neutral20);
  border-radius: 9.27px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7.72px;
  width: 293px;
  height: 293px;
  padding: 15.44px;
  display: flex;
  position: absolute;
  top: 499px;
  left: 722px;
}

.chipsoft-case-study .frame-112 {
  align-self: stretch;
  width: 100%;
  height: 223.95px;
  position: relative;
}

.chipsoft-case-study .overlap-group-11 {
  width: 216px;
  height: 221px;
  position: relative;
  left: 23px;
}

.chipsoft-case-study .chair-13-instance {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 0 !important;
  left: 86px !important;
}

.chipsoft-case-study .chair-16 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 90px !important;
  left: 0 !important;
}

.chipsoft-case-study .chair-8 {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 90px !important;
  left: 173px !important;
}

.chipsoft-case-study .chair-4-instance {
  width: 43px !important;
  height: 43px !important;
  position: absolute !important;
  top: 178px !important;
  left: 86px !important;
}

.chipsoft-case-study .table-5 {
  width: 164px;
  height: 172px;
  position: absolute;
  top: 32px;
  left: 26px;
}

.chipsoft-case-study .frame-113 {
  background-color: var(--x02-neutral95);
  justify-content: space-between;
  align-items: center;
  width: 1112px;
  padding: 15.44px 30.89px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-114 {
  background-image: url("group-417-2.9a636f2d.png");
  background-size: 100% 100%;
  width: 100.39px;
  height: 27.78px;
  position: relative;
}

.chipsoft-case-study .frame-115 {
  justify-content: flex-end;
  align-items: center;
  gap: 18.53px;
  width: 163.71px;
  padding: 3.09px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-116 {
  flex: none;
  align-items: flex-start;
  gap: 6.18px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .img-5 {
  width: 24.71px;
  height: 24.71px;
  position: relative;
}

.chipsoft-case-study .frame-117 {
  background-image: url("ellipse-5-4.c52cf763.png");
  background-position: 50%;
  background-size: cover;
  width: 24.71px;
  height: 24.71px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-56 {
  color: var(--x01-neutral20);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.4px;
  font-weight: 500;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .add-session {
  background-color: var(--x01-neutral100);
  justify-content: center;
  align-items: flex-start;
  gap: 7.95px;
  height: 744px;
  padding: 44.52px;
  display: inline-flex;
  position: absolute;
  top: 1213px;
  left: 279px;
}

.chipsoft-case-study .container-content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 19.08px;
  display: inline-flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chipsoft-case-study .header-create {
  align-items: center;
  gap: 6.36px;
  width: 476.97px;
  height: 33.39px;
  padding: 3.18px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-57 {
  color: var(--x01-neutral30);
  letter-spacing: -.19px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 19.1px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .container {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 31.8px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .container-input {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 19.08px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .container-session {
  align-items: center;
  gap: 12.72px;
  width: 476.97px;
  height: 28.62px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-58 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .container-types {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.54px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .option-live {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.36px;
  width: 89.03px;
  padding: 3.18px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .checkbox-base-wrapper {
  flex: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .checkbox-base {
  background-color: var(--x01-neutral100);
  border: .79px solid;
  border-color: var(--x01-neutral50);
  border-radius: 6.36px;
  width: 12.72px;
  height: 12.72px;
  position: relative;
}

.chipsoft-case-study .text-11 {
  color: var(--x01-neutral30);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 15.9px;
  position: relative;
}

.chipsoft-case-study .option-recorded {
  background-color: var(--secondary-98);
  border: .79px solid;
  border-color: var(--secondary-50);
  border-radius: 3.18px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.36px;
  width: 92.21px;
  padding: 3.18px 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .check-wrapper {
  background-color: var(--secondary-98);
  border: .79px solid;
  border-color: var(--secondary-50);
  border-radius: 6.36px;
  width: 12.72px;
  height: 12.72px;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .check {
  background-color: var(--secondary-50);
  border-radius: 3.97px;
  width: 8px;
  height: 8px;
  position: relative;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .input-field-title {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 9.54px;
  width: 476.97px;
  padding: 7.95px 9.54px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-wrapper-59 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  align-self: stretch;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .input-field-desc {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 476.97px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .input-field-text {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 7.95px 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-12 {
  color: var(--x01-neutral50);
  flex: 1;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 11.1px;
  position: relative;
}

.chipsoft-case-study .span {
  letter-spacing: -.04px;
  line-height: 13.4px;
}

.chipsoft-case-study .text-wrapper-60 {
  letter-spacing: 0;
  line-height: 15.9px;
}

.chipsoft-case-study .toolbar-text {
  background-color: var(--secondary-98);
  border-color: var(--x01-neutral60);
  border-top-style: solid;
  border-top-width: .79px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 6.36px 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-12 {
  flex: none;
  align-items: flex-start;
  gap: 6.36px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .img-6 {
  flex: none;
  position: relative;
}

.chipsoft-case-study .img-7 {
  width: 14.31px;
  height: 14.31px;
  position: relative;
}

.chipsoft-case-study .label-char-limit {
  background-color: var(--secondary-70);
  border-radius: 17.49px;
  flex: none;
  align-items: flex-start;
  gap: 7.95px;
  padding: 3.18px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-61 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  line-height: 11.1px;
  position: relative;
}

.chipsoft-case-study .div-13 {
  flex: none;
  align-items: center;
  gap: 19.08px;
  width: 476.97px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-14 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 6.36px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-62 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .input-field-date {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.54px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-15 {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 31.8px;
  padding: 9.54px 12.72px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-63 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.94px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .chevron-down-2 {
  width: 15.9px;
  height: 15.9px;
  margin-top: -1.59px;
  margin-bottom: -1.59px;
  position: relative;
}

.chipsoft-case-study .input-field-time {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  justify-content: space-around;
  align-items: center;
  gap: 7.95px;
  width: 85.06px;
  height: 31.8px;
  padding: 9.54px 12.72px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .chevron-down-3 {
  width: 25.44px;
  height: 24.64px;
  position: relative;
}

.chipsoft-case-study .container-field {
  align-self: stretch;
  align-items: flex-start;
  gap: 9.54px;
  width: 100%;
  height: 31.8px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-118 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 19.08px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .container-timezone {
  flex-direction: column;
  align-items: flex-start;
  gap: 6.36px;
  width: 138.32px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .input-field-timezone {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 7.95px 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-64 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .chevron-down-4 {
  width: 15.9px;
  height: 15.9px;
  position: relative;
}

.chipsoft-case-study .container-2 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .container-wrapper {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex: none;
  align-items: center;
  gap: 7.95px;
  width: 476.97px;
  padding: 6.36px 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .container-3 {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.chipsoft-case-study .chip-speaker-7 {
  background-color: var(--primary-98);
  border-radius: 15.9px;
  flex: none;
  align-items: center;
  gap: 3.18px;
  padding: 3.18px 6.36px 3.18px 3.18px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-119 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.18px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-120 {
  background-image: url("ellipse-1-30.1f628ddc.png");
  background-position: 50%;
  background-size: cover;
  width: 19.08px;
  height: 19.08px;
  position: relative;
}

.chipsoft-case-study .name-5 {
  color: var(--x01-neutral30);
  letter-spacing: -.28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 14.3px;
  position: relative;
}

.chipsoft-case-study .close {
  width: 12.72px;
  height: 12.72px;
  position: relative;
}

.chipsoft-case-study .frame-121 {
  background-image: url("ellipse-1-29.1f55be6b.png");
  background-position: 50%;
  background-size: cover;
  width: 19.08px;
  height: 19.08px;
  position: relative;
}

.chipsoft-case-study .frame-122 {
  background-image: url("ellipse-1-28.b9e802b5.png");
  background-position: 50%;
  background-size: cover;
  width: 19.08px;
  height: 19.08px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-65 {
  color: var(--secondary-50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .container-embed-link {
  flex-direction: column;
  align-items: flex-start;
  gap: 9.54px;
  width: 476.97px;
  height: 57.24px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .input-field-link {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex: 1;
  align-items: center;
  gap: 7.95px;
  width: 476.97px;
  padding: 9.54px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .container-text {
  flex: 1;
  align-items: center;
  gap: 6.36px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-66 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .container-auditorium {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.54px;
  width: 476.97px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .top {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.54px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  flex: 1;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 500;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .content-wrapper {
  background-color: var(--x01-neutral98);
  border: .79px dashed;
  border-color: var(--x01-neutral80);
  border-radius: 3.18px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.36px;
  width: 100%;
  height: 69.96px;
  padding: 12.72px 19.08px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .content {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.95px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icon {
  width: 28.62px;
  height: 28.62px;
  position: relative;
}

.chipsoft-case-study .paragraph {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  width: 205.89px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.chipsoft-case-study .container-video {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 476.97px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .record {
  flex: none;
  align-items: center;
  gap: 6.36px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .switch {
  width: 31.8px;
  height: 17.49px;
  position: relative;
}

.chipsoft-case-study .overlap-group-12 {
  width: 42px;
  height: 35px;
  position: relative;
  top: -9px;
  left: -1px;
}

.chipsoft-case-study .color-l-track {
  background-color: var(--secondary-50);
  border-radius: 8.74px;
  width: 34px;
  height: 19px;
  position: absolute;
  top: 8px;
  left: 0;
}

.chipsoft-case-study .states {
  width: 37px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 5px;
}

.chipsoft-case-study .color-l-thumb {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 10px;
  left: 17px;
}

.chipsoft-case-study .text-and-supporting {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-67 {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 12.7px;
  font-weight: 400;
  line-height: 15.3px;
  position: relative;
}

.chipsoft-case-study .frame-123 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.36px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .checkbox-icon {
  width: 19.08px;
  height: 19.08px;
  position: relative;
}

.chipsoft-case-study .icons-outline {
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
  left: 3px;
}

.chipsoft-case-study .shape-wrapper {
  background-color: #4f78db;
  border-radius: 1.59px;
  width: 17px;
  height: 17px;
  position: relative;
  top: -2px;
  left: -3px;
}

.chipsoft-case-study .shape {
  width: 10px;
  height: 7px;
  position: absolute;
  top: 6px;
  left: 4px;
}

.chipsoft-case-study .label {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.97px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .icons-outline-2 {
  border: .79px solid;
  border-color: var(--x01-neutral80);
  border-radius: 1.59px;
  width: 17px;
  height: 17px;
  position: relative;
  top: 1px;
  left: 1px;
}

.chipsoft-case-study .shape-2 {
  width: 13px;
  height: 9px;
  position: absolute;
  top: 4px;
  left: 2px;
}

.chipsoft-case-study .overlap-group-13 {
  background-color: #4f78db;
  background-image: url("icons-outline-checkmark-1.7947d4f6.png");
  background-size: 100% 100%;
  border-radius: 1.59px;
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
  left: 3px;
}

.chipsoft-case-study .frame-124 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 12.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .CTA-4 {
  all: unset;
  border: .79px solid;
  border-color: var(--x01-neutral40);
  box-sizing: border-box;
  border-radius: 3.18px;
  justify-content: center;
  align-items: center;
  gap: 6.36px;
  width: 83.47px;
  padding: 9.54px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-13 {
  color: var(--x01-neutral40);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 14.3px;
  font-weight: 700;
  line-height: 20.7px;
  position: relative;
}

.chipsoft-case-study .CTA-5 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 3.18px;
  justify-content: center;
  align-items: center;
  gap: 6.36px;
  width: 83.47px;
  padding: 9.54px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-14 {
  color: var(--x01-neutral100);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-family: Inter, Helvetica;
  font-size: 14.3px;
  font-weight: 700;
  line-height: 20.7px;
  position: relative;
}

.chipsoft-case-study .add-table {
  background-color: var(--x01-neutral30);
  border-radius: 5.91px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23.63px;
  width: 443px;
  padding: 29.53px;
  display: flex;
  position: absolute;
  top: 1215px;
  left: 886px;
  box-shadow: 0 2.95px 4.43px -1.48px #10182808, 24px 24px 99px -2.95px #10132870;
}

.chipsoft-case-study .frame-125 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.81px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-126 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 17.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-16 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.86px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-68 {
  color: var(--x01-neutral100);
  letter-spacing: -.27px;
  flex: 1;
  margin-top: -.74px;
  font-family: Inter, Helvetica;
  font-size: 17.7px;
  font-weight: 700;
  line-height: 23.6px;
  position: relative;
}

.chipsoft-case-study .div-17 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.26px;
  position: relative;
}

.chipsoft-case-study .container-fields {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 23.63px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-127 {
  border: .74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 7.38px;
  width: 100%;
  padding: 8.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-69 {
  color: var(--x01-neutral70);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Inter, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.chipsoft-case-study .frame-128 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.03px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-129 {
  border: .74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  justify-content: space-between;
  align-items: center;
  width: 109.27px;
  padding: 8.86px 11.81px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-70 {
  color: var(--x01-neutral70);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.17px;
  font-family: Inter, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.chipsoft-case-study .frame-130 {
  flex: none;
  align-items: center;
  gap: 6.64px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-71 {
  color: var(--x01-neutral100);
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 11.8px;
  font-weight: 600;
  line-height: 14.8px;
  position: relative;
}

.chipsoft-case-study .unfold-more {
  width: 15.14px;
  height: 15.14px;
  position: relative;
}

.chipsoft-case-study .frame-131 {
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 14.03px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-132 {
  border: .74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 8.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-72 {
  color: var(--x01-neutral70);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.36px;
  font-family: Inter, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: 14.2px;
  position: relative;
}

.chipsoft-case-study .icon-12 {
  width: 14.77px;
  height: 14.77px;
  position: relative;
}

.chipsoft-case-study .container-time {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17.72px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .div-18 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.91px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-73 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 700;
  line-height: 12.4px;
  position: relative;
}

.chipsoft-case-study .input-field-date-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.86px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-19 {
  border: .74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 29.53px;
  padding: 8.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-74 {
  color: var(--x01-neutral70);
  letter-spacing: -.16px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.83px;
  margin-bottom: -.36px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 400;
  line-height: 13.3px;
  position: relative;
}

.chipsoft-case-study .chevron-down-5 {
  width: 14.77px;
  height: 14.77px;
  margin-top: -1.48px;
  margin-bottom: -1.48px;
  position: relative;
}

.chipsoft-case-study .input-field-time-2 {
  border: .74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  justify-content: space-around;
  align-items: center;
  gap: 7.38px;
  width: 79px;
  height: 29.53px;
  padding: 8.86px 11.81px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .chevron-down-6 {
  width: 23.63px;
  height: 22.89px;
  position: relative;
}

.chipsoft-case-study .container-field-2 {
  align-self: stretch;
  align-items: flex-start;
  gap: 8.86px;
  width: 100%;
  height: 29.53px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .input-field-time-3 {
  border: .74px solid;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  justify-content: space-around;
  align-items: center;
  gap: 7.38px;
  width: 79px;
  height: 29.53px;
  padding: 8.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-75 {
  color: var(--x01-neutral70);
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.83px;
  margin-bottom: -.36px;
  font-family: Inter, Helvetica;
  font-size: 9.6px;
  font-weight: 400;
  line-height: 13.3px;
  position: relative;
}

.chipsoft-case-study .title-wrapper {
  flex: none;
  align-items: center;
  gap: 8.86px;
  width: 229.62px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title-3 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  flex: 1;
  margin-top: -.74px;
  font-family: Inter, Helvetica;
  font-size: 10.3px;
  font-weight: 700;
  line-height: 12.4px;
  position: relative;
}

.chipsoft-case-study .container-4 {
  border: .74px dashed;
  border-color: var(--x01-neutral50);
  border-radius: 2.95px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.91px;
  width: 100%;
  padding: 17.72px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .content-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 7.38px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icon-2 {
  width: 17.72px;
  height: 17.72px;
  position: relative;
}

.chipsoft-case-study .paragraph-2 {
  color: var(--x01-neutral70);
  letter-spacing: -.09px;
  text-align: center;
  width: 158.74px;
  font-family: Inter, Helvetica;
  font-size: 8.9px;
  font-weight: 300;
  line-height: 13.3px;
  position: relative;
}

.chipsoft-case-study .icon-wrapper {
  background-color: var(--x01-neutral20);
  border-radius: 2.95px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.38px;
  padding: 4.43px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-133 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 11.81px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .CTA-6 {
  all: unset;
  border: .74px solid;
  border-color: var(--x01-neutral60);
  box-sizing: border-box;
  border-radius: 2.95px;
  justify-content: center;
  align-items: center;
  gap: 5.91px;
  width: 77.53px;
  padding: 8.86px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-15 {
  color: var(--x01-neutral90);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Inter, Helvetica;
  font-size: 13.3px;
  font-weight: 700;
  line-height: 19.2px;
  position: relative;
}

.chipsoft-case-study .CTA-7 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 2.95px;
  justify-content: center;
  align-items: center;
  gap: 5.91px;
  width: 77.53px;
  padding: 8.86px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-16 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.74px;
  font-family: Inter, Helvetica;
  font-size: 13.3px;
  font-weight: 700;
  line-height: 19.2px;
  position: relative;
}

.chipsoft-case-study .pexels-mart {
  object-fit: cover;
  width: 1251px;
  height: 1026px;
  position: absolute;
  top: 0;
  left: 18px;
}

.chipsoft-case-study .course-details {
  background-color: #fff;
  border: 12px solid #8181818f;
  border-radius: 12px;
  width: 1207px;
  height: 865px;
  position: absolute;
  top: 326px;
  left: 854px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chipsoft-case-study .row {
  justify-content: center;
  align-items: flex-start;
  gap: 26.28px;
  width: 999px;
  display: flex;
  position: absolute;
  top: 2406px;
  left: 1296px;
}

.chipsoft-case-study .blog-post-card {
  background-color: var(--white);
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 26.28px;
  height: 476.35px;
  padding: 19.71px 19.71px 26.28px;
  display: flex;
  position: relative;
  box-shadow: 0 3.29px 4.93px -1.64px #10182808, 0 9.86px 13.14px -3.29px #10182814;
}

.chipsoft-case-study .image {
  background-image: url("image-5.cb0b6f9d.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 197.11px;
  position: relative;
}

.chipsoft-case-study .content-3 {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-20 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.86px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .subheading-2 {
  color: var(--primary-700);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 600;
  line-height: 16.4px;
  position: relative;
}

.chipsoft-case-study .heading-and-icon {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.14px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .heading {
  color: var(--gray-900);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 26.3px;
  position: relative;
}

.chipsoft-case-study .supporting-text-3 {
  color: var(--gray-500);
  letter-spacing: 0;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: 19.7px;
  position: relative;
}

.chipsoft-case-study .avatar-label-group {
  flex: none;
  align-items: center;
  gap: 9.86px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-24 {
  background-image: url("avatar-25.f4e72d59.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 164.26px;
  width: 32.85px;
  height: 32.85px;
  position: relative;
}

.chipsoft-case-study .text-17 {
  color: var(--gray-900);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 16.4px;
  position: relative;
}

.chipsoft-case-study .supporting-text-4 {
  color: var(--gray-500);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  line-height: 16.4px;
  position: relative;
}

.chipsoft-case-study .image-2 {
  background-image: url("image-4.8dddaa95.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 197.11px;
  position: relative;
}

.chipsoft-case-study .avatar-25 {
  background-image: url("avatar-24.586a3cb1.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 164.26px;
  width: 32.85px;
  height: 32.85px;
  position: relative;
}

.chipsoft-case-study .image-3 {
  background-image: url("image-3.cb48893b.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 197.11px;
  position: relative;
}

.chipsoft-case-study .avatar-26 {
  background-image: url("avatar-23.1e4cee69.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 164.26px;
  width: 32.85px;
  height: 32.85px;
  position: relative;
}

.chipsoft-case-study .overlap-8 {
  width: 675px;
  height: 459px;
  position: absolute;
  top: 66px;
  left: 205px;
}

.chipsoft-case-study .frame-134 {
  border-color: var(--x01-neutral80);
  border-right-style: solid;
  border-right-width: .82px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 13.14px;
  width: 675px;
  padding: 16.43px 19.71px 16.43px 26.28px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .text-wrapper-76 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  align-self: stretch;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 23.7px;
  position: relative;
}

.chipsoft-case-study .div-21 {
  flex: none;
  align-items: center;
  gap: 3.29px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-27 {
  background-image: url("subtract.c737cbd6.png");
  background-position: 50%;
  background-size: cover;
  width: 18.07px;
  height: 18.07px;
  position: relative;
}

.chipsoft-case-study .name-6 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .video-player {
  border-color: var(--x01-neutral80);
  border-right-style: solid;
  border-right-width: .82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8.21px;
  width: 675px;
  padding: 3.29px 19.71px 3.29px 26.28px;
  display: flex;
  position: absolute;
  top: 88px;
  left: 0;
}

.chipsoft-case-study .video-player-2 {
  background-image: url("video-player.622ebb2a.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 6.73px;
  align-self: stretch;
  width: 100%;
  height: 364.65px;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .video-progress-wrapper {
  background: linear-gradient(#0000 0%, #0003 100%);
  width: 630px;
  height: 98px;
  position: absolute;
  top: 267px;
  left: 0;
}

.chipsoft-case-study .video-progress-instance {
  border-radius: 6.73px !important;
  width: 575px !important;
  height: 13px !important;
  top: 74px !important;
  left: 10px !important;
}

.chipsoft-case-study .video-progress-2 {
  width: 578px !important;
  height: 7px !important;
  top: 3px !important;
  left: 24px !important;
}

.chipsoft-case-study .video-progress-3 {
  border-radius: 3.36px !important;
  width: 551px !important;
  height: 7px !important;
}

.chipsoft-case-study .video-progress-4 {
  width: 578px !important;
  height: 7px !important;
}

.chipsoft-case-study .video-progress-5 {
  border-radius: 3.36px !important;
  width: 435px !important;
  height: 7px !important;
}

.chipsoft-case-study .video-progress-6 {
  border-radius: 3.36px !important;
  width: 168px !important;
  height: 7px !important;
}

.chipsoft-case-study .frame-135 {
  align-items: flex-start;
  gap: 9.86px;
  display: inline-flex;
  position: absolute;
  top: 20px;
  left: 495px;
}

.chipsoft-case-study .frame-136 {
  background-color: var(--x01-neutral40);
  border-radius: 21.35px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8.21px;
  padding: 6.57px 8.21px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-77 {
  color: var(--x01-neutral100);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .open-in-full-wrapper {
  background-color: var(--x01-neutral40);
  border-radius: 27.1px;
  width: 26.28px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .open-in-full {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.chipsoft-case-study .frame-137 {
  background-color: var(--secondary-98);
  flex-direction: column;
  align-items: flex-start;
  gap: 13.14px;
  width: 977px;
  padding: 19.71px 32.85px 52.56px 26.28px;
  display: flex;
  position: absolute;
  top: 1305px;
  left: 205px;
}

.chipsoft-case-study .text-wrapper-78 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 23.7px;
  position: relative;
}

.chipsoft-case-study .row-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 16.43px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .blog-post-card-2 {
  background-color: var(--x01-neutral100);
  border: .82px solid;
  border-color: var(--x02-neutral80);
  border-radius: 6.57px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 26.28px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 0 0 26.28px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3.29px 4.93px -1.64px #10182808, 0 9.86px 13.14px -3.29px #10182814;
}

.chipsoft-case-study .image-4 {
  background: url("image-2.997958d5.png") 50% / cover;
  align-self: stretch;
  width: 100%;
  height: 197.11px;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .icon-button {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 7px;
  left: 262px;
}

.chipsoft-case-study .dots-horizontal-wrapper {
  border-radius: 26.28px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 7px;
  left: 235px;
  overflow: hidden;
}

.chipsoft-case-study .star-wrapper {
  width: 23px;
  height: 23px;
  position: relative;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .line-wrapper {
  background-image: url("line-10.91d81dce.png");
  background-size: 100% 100%;
  width: 280px;
  height: 1px;
  position: absolute;
  top: 197px;
  left: 0;
}

.chipsoft-case-study .line {
  width: 109px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .content-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 26.28px;
  width: 100%;
  padding: 0 19.71px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-138 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.29px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .subheading-3 {
  color: var(--secondary-30);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .ellipse-15 {
  background-color: var(--x02-neutral80);
  border-radius: 1.64px;
  width: 3.29px;
  height: 3.29px;
  position: relative;
}

.chipsoft-case-study .frame-139 {
  background-color: var(--accent-99);
  border-radius: 3.29px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.21px;
  padding: 3.29px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .subheading-4 {
  color: var(--accent-50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  line-height: 11.8px;
  position: relative;
}

.chipsoft-case-study .div-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.86px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .heading-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 13.14px;
  width: 238.72px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .heading-2 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  flex: 1;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 16.4px;
  font-weight: 600;
  line-height: 19.7px;
  position: relative;
}

.chipsoft-case-study .supporting-text-5 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  width: 238.72px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .avatar-label-group-2 {
  flex: none;
  align-items: center;
  gap: 6.57px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-28 {
  background-image: url("avatar-22.02db7800.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 164.26px;
  width: 26.28px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-79 {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .blog-post-card-3 {
  background-color: var(--x01-neutral100);
  border: .82px solid;
  border-color: var(--x02-neutral80);
  border-radius: 6.57px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 26.28px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 0 0 26.28px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3.29px 4.93px -1.64px #10182808, 0 9.86px 13.14px -3.29px #10182814;
}

.chipsoft-case-study .frame-140 {
  border-radius: 9.86px 9.86px 0 0;
  align-self: stretch;
  width: 100%;
  height: 197.11px;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .overlap-group-14 {
  background-image: url("image-1.700e7ee0.png");
  background-position: 50%;
  background-size: cover;
  width: 296px;
  height: 197px;
  position: relative;
}

.chipsoft-case-study .icon-button-2 {
  border-radius: 26.28px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 7px;
  left: 236px;
  overflow: hidden;
}

.chipsoft-case-study .text-wrapper-80 {
  color: var(--x01-neutral40);
  letter-spacing: -.05px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .avatar-29 {
  background-image: url("avatar-21.7a4bbdd2.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 164.26px;
  width: 26.28px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .blog-post-card-4 {
  background-color: var(--x01-neutral100);
  border: .82px solid;
  border-color: var(--x02-neutral80);
  border-radius: 6.57px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 26.28px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 0 0 26.28px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3.29px 4.93px -1.64px #10182808, 0 9.86px 13.14px -3.29px #10182814;
}

.chipsoft-case-study .image-5 {
  background-image: url("image.63f27bf2.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 197.11px;
  position: relative;
}

.chipsoft-case-study .avatar-30 {
  background-image: url("avatar-20.2108a694.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 164.26px;
  width: 26.28px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .overlap-9 {
  width: 302px;
  height: 657px;
  position: absolute;
  top: 66px;
  left: 880px;
}

.chipsoft-case-study .frame-141 {
  background-color: var(--primary-98);
  border-radius: 6.57px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26.28px;
  width: 250px;
  padding: 19.71px 16.43px;
  display: flex;
  position: absolute;
  top: 493px;
  left: 20px;
}

.chipsoft-case-study .frame-142 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 19.71px;
  width: 100%;
  height: 55.85px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-143 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.86px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-81 {
  color: var(--x01-neutral30);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.83px;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .frame-144 {
  background-color: var(--secondary-50);
  border: .41px solid #4b4b4b;
  border-radius: 3.29px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.64px;
  width: 100%;
  height: 42.71px;
  padding: 9.86px 16.43px 9.86px 9.86px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-wrapper-82 {
  color: var(--secondary-100);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  line-height: 15.8px;
  position: relative;
}

.chipsoft-case-study .frame-145 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.14px;
  width: 302px;
  padding: 19.71px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-146 {
  border-bottom-style: solid;
  border-bottom-width: .82px;
  border-color: var(--x01-neutral80);
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 8.21px 32.85px 8.21px 19.71px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-83 {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .frame-147 {
  background-color: var(--x01-neutral30);
  border-radius: 3.29px;
  flex: none;
  align-items: flex-start;
  gap: 8.21px;
  padding: 3.29px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-84 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  line-height: 11.8px;
  position: relative;
}

.chipsoft-case-study .frame-148 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.57px;
  width: 100%;
  padding: 0 32.85px 0 19.71px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-85 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  line-height: 11.8px;
  position: relative;
}

.chipsoft-case-study .frame-149 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-150 {
  border-bottom-style: solid;
  border-bottom-width: .82px;
  border-color: var(--x01-neutral90);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 9.86px;
  width: 100%;
  padding: 6.57px 0 9.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-151 {
  flex: 1;
  align-items: center;
  gap: 6.57px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-152 {
  background-color: var(--tertiary-90);
  border-radius: 39.42px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12.32px;
  width: 39.42px;
  height: 39.42px;
  padding: 12.32px 13.55px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-86 {
  color: var(--x01-neutral30);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin: -2.84px -2.84px -.38px;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .frame-153 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 3.29px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .play-circle {
  width: 11.5px;
  height: 11.5px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-87 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  line-height: 11.8px;
  position: relative;
}

.chipsoft-case-study .check-circle {
  width: 19.71px;
  height: 19.71px;
  position: relative;
}

.chipsoft-case-study .frame-154 {
  border-bottom-style: solid;
  border-bottom-width: .82px;
  border-color: var(--x01-neutral90);
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 13.14px;
  width: 100%;
  padding: 6.57px 0 9.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-155 {
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 6.57px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-88 {
  color: var(--x01-neutral30);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin: -2.84px -4.34px -.38px;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .frame-156 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 3.29px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .become-happier-by {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  white-space: nowrap;
  align-self: stretch;
  height: 15.6px;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 500;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .frame-157 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 13.14px;
  width: 100%;
  padding: 6.57px 0 9.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-89 {
  color: var(--x01-neutral30);
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin: -2.84px -3.84px -.38px;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .overlap-10 {
  width: 675px;
  height: 291px;
  position: absolute;
  top: 525px;
  left: 205px;
}

.chipsoft-case-study .frame-158 {
  border-color: var(--x01-neutral70);
  border-right-style: solid;
  border-right-width: .82px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19.71px;
  width: 675px;
  padding: 9.86px 19.71px 9.86px 26.28px;
  display: flex;
  position: absolute;
  top: 51px;
  left: 0;
}

.chipsoft-case-study .text-wrapper-90 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 23.7px;
  position: relative;
}

.chipsoft-case-study .frame-159 {
  background-color: var(--secondary-30);
  border-radius: 3.29px;
  flex: none;
  align-items: center;
  gap: 3.29px;
  padding: 9.86px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .edit {
  width: 15.6px;
  height: 15.6px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-91 {
  color: var(--secondary-100);
  letter-spacing: -.05px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 13.1px;
  font-weight: 700;
  line-height: 15.8px;
  position: relative;
}

.chipsoft-case-study .container-5 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.14px;
  width: 100%;
  height: 105.12px;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chipsoft-case-study .thread-line {
  width: 1px;
  height: 38px;
  position: absolute;
  top: 125px;
  left: 13px;
}

.chipsoft-case-study .frame-160 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.57px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .comment {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3.29px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .avatar-31 {
  object-fit: cover;
  width: 26.28px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .content-5 {
  background-color: var(--x01-neutral98);
  border-radius: 8.21px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 9.86px;
  padding: 6.57px 13.14px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .paragraph-3 {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  align-self: stretch;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 11.5px;
  font-weight: 400;
  line-height: 13.8px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-92 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  line-height: 11.8px;
  position: relative;
}

.chipsoft-case-study .settings {
  width: 25.46px;
  height: 16.43px;
  position: relative;
}

.chipsoft-case-study .group-2 {
  width: 17px;
  height: 4px;
  position: relative;
  top: 6px;
  left: 4px;
}

.chipsoft-case-study .ellipse-16 {
  background-color: var(--x01-neutral50);
  border-radius: 2.05px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .ellipse-17 {
  background-color: var(--x01-neutral50);
  border-radius: 2.05px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 7px;
}

.chipsoft-case-study .ellipse-18 {
  background-color: var(--x01-neutral50);
  border-radius: 2.05px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 13px;
}

.chipsoft-case-study .dropdown-2 {
  border-radius: 6.57px;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 26.28px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .label-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 8.21px;
  padding: 3.29px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .label-2 {
  color: var(--secondary-50);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 9.9px;
  font-weight: 600;
  line-height: 11.8px;
  position: relative;
}

.chipsoft-case-study .icons-20 {
  width: 16.43px;
  height: 16.43px;
  position: relative;
}

.chipsoft-case-study .frame-161 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.57px;
  width: 629.11px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .comment-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.57px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .dropdown-3 {
  border-radius: 6.57px;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 32.85px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .comment-3 {
  flex: none;
  align-items: flex-start;
  gap: 3.29px;
  width: 629.11px;
  padding: 0 0 0 12.32px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-162 {
  width: 8.21px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .line-2 {
  width: 8px;
  height: 1px;
  position: absolute;
  top: 13px;
  left: 0;
}

.chipsoft-case-study .frame-163 {
  flex: 1;
  align-items: flex-start;
  gap: 6.57px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .input-field {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.57px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .search-bar-3 {
  background-color: var(--x01-neutral98);
  border: .82px solid;
  border-color: var(--x01-neutral90);
  border-radius: 3.29px;
  flex: 1;
  align-items: center;
  gap: 6.57px;
  padding: 11.5px 9.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-93 {
  color: var(--x01-neutral50);
  letter-spacing: 0;
  flex: 1;
  margin-top: -.82px;
  font-family: Inter, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: 16.4px;
  position: relative;
}

.chipsoft-case-study .CTA-8 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 4.93px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 3.29px;
  padding: 13.14px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-94 {
  color: var(--x01-neutral98);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.1px;
  font-weight: 700;
  line-height: 13.1px;
  position: relative;
}

.chipsoft-case-study .frame-164 {
  border-color: var(--x01-neutral80);
  border-right-style: solid;
  border-right-width: .82px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 19.71px;
  width: 675px;
  padding: 9.86px 0 6.57px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .links-wrapper {
  border-bottom-style: solid;
  border-bottom-width: .82px;
  border-color: var(--x01-neutral70);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.86px;
  width: 100%;
  height: 39.42px;
  padding: 0 19.71px 0 26.28px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .links {
  flex: 1;
  align-items: flex-start;
  width: 629.11px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .link {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.57px;
  width: 95.27px;
  padding: 6.57px 9.86px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-95 {
  color: var(--x01-neutral60);
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-96 {
  color: var(--x01-neutral60);
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 700;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .q-a-wrapper {
  border-bottom-style: solid;
  border-bottom-width: .82px;
  border-color: var(--secondary-50);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.57px;
  width: 95.27px;
  padding: 6.57px 26.28px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .q-a {
  color: var(--secondary-50);
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .link-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.57px;
  padding: 6.57px 26.28px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .link-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.57px;
  width: 95.27px;
  padding: 6.57px 3.29px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-97 {
  color: var(--x01-neutral60);
  letter-spacing: -.06px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -.15px;
  margin-right: -.15px;
  font-family: Inter, Helvetica;
  font-size: 14.8px;
  font-weight: 600;
  line-height: 17.7px;
  position: relative;
}

.chipsoft-case-study .menu-instance {
  background-color: #e5e2db52 !important;
  width: 205px !important;
  height: 775px !important;
  padding: 9.86px 0 19.71px !important;
  position: absolute !important;
  top: 66px !important;
  left: 0 !important;
}

.chipsoft-case-study .menu-3 {
  font-family: Inter, Helvetica !important;
  font-size: 13.1px !important;
  font-style: unset !important;
  letter-spacing: -.03px !important;
  font-weight: 500 !important;
  line-height: 15.8px !important;
}

.chipsoft-case-study .menu-4 {
  flex: none !important;
  align-self: stretch !important;
  gap: 3.29px !important;
  width: 100% !important;
  padding: 13.14px 32.85px !important;
}

.chipsoft-case-study .menu-5 {
  gap: 3.29px !important;
  padding: 13.14px 32.85px !important;
}

.chipsoft-case-study .menu-6 {
  width: 19.71px !important;
  height: 19.71px !important;
}

.chipsoft-case-study .menu-7 {
  width: 13px !important;
  height: 16px !important;
  left: 3px !important;
}

.chipsoft-case-study .menu-8 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
}

.chipsoft-case-study .menu-9 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
  left: 9px !important;
}

.chipsoft-case-study .menu-10 {
  width: 12px !important;
  height: 12px !important;
}

.chipsoft-case-study .menu-11 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  width: 5px !important;
  height: 5px !important;
  left: 4px !important;
}

.chipsoft-case-study .menu-12 {
  border: 1.64px solid !important;
  border-radius: 2.67px !important;
  width: 5px !important;
  height: 5px !important;
  top: 9px !important;
  left: 4px !important;
}

.chipsoft-case-study .menu-13 {
  width: 14px !important;
  height: 14px !important;
}

.chipsoft-case-study .menu-14 {
  gap: 19.71px !important;
}

.chipsoft-case-study .menu-15 {
  gap: 2.46px !important;
  padding: 3.29px 32.85px !important;
}

.chipsoft-case-study .menu-16 {
  color: var(--x01-neutral30) !important;
  font-family: Inter, Helvetica !important;
  font-size: 9.9px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  margin-top: -.82px !important;
  font-weight: 400 !important;
  line-height: 11.8px !important;
}

.chipsoft-case-study .menu-17 {
  width: 6.57px !important;
  height: 6.57px !important;
}

.chipsoft-case-study .menu-18 {
  background-color: var(--x01-neutral50) !important;
  border-radius: 1.64px !important;
  width: 3px !important;
  height: 3px !important;
}

.chipsoft-case-study .menu-19 {
  width: 26px !important;
  height: 25px !important;
  top: 375px !important;
  left: 192px !important;
}

.chipsoft-case-study .frame-165 {
  background-color: #e5e2db52;
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .41px;
  justify-content: space-between;
  align-items: center;
  width: 1183px;
  padding: 16.43px 32.85px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-166 {
  background-image: url("group-417-1.7672183a.png");
  background-size: 100% 100%;
  width: 106.77px;
  height: 29.55px;
  position: relative;
}

.chipsoft-case-study .frame-167 {
  justify-content: flex-end;
  align-items: center;
  gap: 19.71px;
  width: 174.11px;
  padding: 3.29px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-168 {
  background-image: url("ellipse-5-3.47048b25.png");
  background-position: 50%;
  background-size: cover;
  width: 26.28px;
  height: 26.28px;
  position: relative;
}

.chipsoft-case-study .ellipse-19 {
  background-color: var(--accent-50);
  border: .82px solid;
  border-color: var(--x01-neutral100);
  border-radius: 3.29px;
  width: 7px;
  height: 7px;
  position: relative;
  top: 20px;
  left: 20px;
}

.chipsoft-case-study .text-wrapper-98 {
  color: var(--x01-neutral20);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  line-height: 15.8px;
  position: relative;
}

.chipsoft-case-study .new-course {
  background-color: var(--x01-neutral100);
  border-radius: 5.37px;
  justify-content: center;
  align-items: center;
  gap: 21.5px;
  width: 445px;
  padding: 21.5px 32.25px 37.62px;
  display: flex;
  position: absolute;
  top: 640px;
  left: 619px;
  overflow: hidden;
  box-shadow: 0 2.69px 4.03px -1.34px #10182808, 16px 8.06px 32px -2.69px #10182829;
}

.chipsoft-case-study .frame-169 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 32.25px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-170 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.44px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-171 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.06px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-99 {
  color: var(--x01-neutral30);
  letter-spacing: -.13px;
  align-self: stretch;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 16.1px;
  font-weight: 700;
  line-height: 19.3px;
  position: relative;
}

.chipsoft-case-study .line-3 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.33px;
  position: relative;
}

.chipsoft-case-study .frame-172 {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 249.92px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-173 {
  border: .67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.72px;
  width: 100%;
  padding: 8.06px 10.75px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-100 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  line-height: 11.3px;
  position: relative;
}

.chipsoft-case-study .input-field-desc-2 {
  border: .67px solid;
  border-color: var(--x01-neutral80);
  border-radius: 2.69px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .input-field-text-2 {
  border: .67px solid;
  border-color: var(--x01-neutral70);
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 6.72px 8.06px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-18 {
  color: #0000;
  letter-spacing: -.04px;
  flex: 1;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  line-height: 11.3px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-101 {
  color: #868686;
}

.chipsoft-case-study .text-wrapper-102 {
  color: #64748b;
}

.chipsoft-case-study .toolbar-text-2 {
  background-color: var(--secondary-98);
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 5.37px 8.06px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .container-controls {
  flex: none;
  align-items: flex-start;
  gap: 5.37px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .img-8 {
  width: 12.09px;
  height: 12.09px;
  position: relative;
}

.chipsoft-case-study .label-char-limit-2 {
  background-color: var(--secondary-60);
  border-radius: 14.78px;
  flex: none;
  align-items: flex-start;
  gap: 6.72px;
  padding: 2.69px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-103 {
  color: var(--x01-neutral100);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 600;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .top-2 {
  flex: none;
  align-items: center;
  gap: 8.06px;
  width: 208.94px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title-4 {
  color: var(--x01-neutral20);
  letter-spacing: -.04px;
  flex: 1;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 500;
  line-height: 11.3px;
  position: relative;
}

.chipsoft-case-study .container-6 {
  border: .67px dashed;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.37px;
  width: 100%;
  padding: 16.12px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .content-6 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icon-3 {
  width: 16.12px;
  height: 16.12px;
  position: relative;
}

.chipsoft-case-study .paragraph-4 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  text-align: center;
  width: 144.44px;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .icon-12-wrapper {
  background-color: var(--secondary-40);
  border-radius: 2.69px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6.72px;
  padding: 4.03px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .chevron-down-7 {
  width: 13.44px !important;
  height: 13.44px !important;
  position: relative !important;
}

.chipsoft-case-study .frame-174 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.72px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-175 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10.75px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-176 {
  border: .67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  justify-content: space-between;
  align-items: center;
  width: 347.33px;
  padding: 8.06px 10.75px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-104 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  line-height: 11.3px;
  position: relative;
}

.chipsoft-case-study .frame-177 {
  border: .67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 8.06px 10.75px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-105 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: -23.33px;
  font-family: Inter, Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  line-height: 11.3px;
  position: relative;
}

.chipsoft-case-study .chevron-down-8 {
  width: 13.44px;
  height: 13.44px;
  margin-left: -48.76px;
  position: relative;
}

.chipsoft-case-study .frame-178 {
  border: .67px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.69px;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 29.56px;
  padding: 8.06px 10.75px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-179 {
  background-color: var(--x01-neutral20);
  border-radius: 12.09px;
  flex: none;
  align-items: flex-start;
  gap: 6.72px;
  margin-top: -.97px;
  margin-bottom: -.97px;
  padding: 2.69px 8.06px 2.69px 5.37px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-106 {
  color: var(--secondary-100);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.67px;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 600;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .frame-180 {
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  gap: 10.75px;
  width: 100%;
  height: 37.62px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .add-button {
  background-color: var(--secondary-50);
  border-radius: 2.69px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 5.37px;
  height: 38.29px;
  margin-top: -.67px;
  padding: 8.06px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-19 {
  color: #fff;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .add-button-2 {
  all: unset;
  border: 1.34px solid;
  border-color: var(--x01-neutral50);
  box-sizing: border-box;
  border-radius: 2.69px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.37px;
  width: 102.79px;
  padding: 8.06px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-20 {
  color: var(--x01-neutral50);
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  line-height: 14.5px;
  position: relative;
}

.chipsoft-case-study .no-more-switiching {
  color: #000;
  letter-spacing: -.28px;
  width: 493px;
  height: 256px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  position: absolute;
  top: 35px;
  left: 1295px;
}

.chipsoft-case-study .overlap-11 {
  width: 1804px;
  height: 1135px;
  position: absolute;
  top: 0;
  left: 98px;
}

.chipsoft-case-study .overlap-12 {
  width: 1804px;
  height: 1135px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .pexels-mart-2 {
  width: 871px;
  height: 1135px;
  position: absolute;
  top: 0;
  left: 933px;
}

.chipsoft-case-study .resources-all {
  background-color: #ffffff52;
  border: 12px solid #8181818f;
  border-radius: 9.01px;
  width: 1105px;
  height: 793px;
  position: absolute;
  top: 202px;
  left: 0;
  overflow: hidden;
}

.chipsoft-case-study .overlap-13 {
  width: 894px;
  height: 709px;
  position: absolute;
  top: 60px;
  left: 188px;
}

.chipsoft-case-study .rectangle-2 {
  background-color: var(--x01-neutral40);
  width: 599px;
  height: 709px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .line-4 {
  width: 599px;
  height: 1px;
  position: absolute;
  top: 511px;
  left: 0;
}

.chipsoft-case-study .frame-181 {
  justify-content: space-between;
  align-items: center;
  width: 599px;
  padding: 15.02px 24.03px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-182 {
  flex: none;
  align-items: center;
  gap: 3px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-183 {
  flex: none;
  align-items: center;
  gap: 108.14px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-107 {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  position: relative;
}

.chipsoft-case-study .icon-button-3 {
  width: 24.03px;
  height: 24.03px;
  position: relative;
}

.chipsoft-case-study .frame-184 {
  border-radius: 3px;
  flex: none;
  align-items: center;
  gap: 9.01px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-185 {
  border: .75px solid;
  border-color: var(--x02-neutral80);
  border-radius: 3px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 7.51px;
  padding: 9.01px 12.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-108 {
  color: var(--x02-neutral95);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  position: relative;
}

.chipsoft-case-study .line-5 {
  width: 1px;
  height: 708px;
  position: absolute;
  top: 0;
  left: 599px;
}

.chipsoft-case-study .rectangle-3 {
  object-fit: cover;
  width: 551px;
  height: 631px;
  position: absolute;
  top: 78px;
  left: 24px;
}

.chipsoft-case-study .line-6 {
  width: 599px;
  height: 1px;
  position: absolute;
  top: 62px;
  left: 0;
}

.chipsoft-case-study .frame-186 {
  flex-direction: column;
  align-items: flex-start;
  gap: 18.02px;
  width: 294px;
  height: 708px;
  padding: 36.05px 30.04px 0 24.03px;
  display: flex;
  position: absolute;
  top: 0;
  left: 599px;
}

.chipsoft-case-study .frame-187 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 18.02px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .div-23 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.01px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title-5 {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  align-self: stretch;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 13.5px;
  font-weight: 600;
  line-height: 16.2px;
  position: relative;
}

.chipsoft-case-study .paragraph-5 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 10.5px;
  font-weight: 400;
  line-height: 12.6px;
  position: relative;
}

.chipsoft-case-study .frame-188 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.76px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-189 {
  background-color: var(--primary-98);
  border-radius: 3px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 6.01px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-109 {
  color: var(--primary-50);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
}

.chipsoft-case-study .frame-190 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-191 {
  flex: none;
  align-items: flex-start;
  gap: 7.51px;
  padding: 0 3.75px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-110 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 600;
  line-height: 10.8px;
  position: relative;
}

.chipsoft-case-study .star-3 {
  align-items: flex-start;
  width: 92.37px;
  height: 18.02px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .star-18-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.51px;
  width: 18.77px;
  height: 18.02px;
  padding: 3px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .star-18 {
  width: 18.02px !important;
  height: 18.02px !important;
  margin: -3px -2.63px !important;
  position: relative !important;
}

.chipsoft-case-study .icon-instance-node-2 {
  width: 18.02px !important;
  height: 18.02px !important;
  position: relative !important;
}

.chipsoft-case-study .text-wrapper-111 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 10.5px;
  font-weight: 500;
  line-height: 12.6px;
  position: relative;
}

.chipsoft-case-study .base-status-badge {
  background-color: var(--accent-50);
  border-radius: 75.1px;
  flex: none;
  align-items: flex-start;
  gap: 7.51px;
  padding: 4.51px 6.01px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-112 {
  color: var(--secondary-100);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 10.5px;
  font-weight: 500;
  line-height: 12.6px;
  position: relative;
}

.chipsoft-case-study .frame-192 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.02px;
  width: 240.31px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-193 {
  background-color: var(--x02-neutral95);
  border: .75px solid;
  border-color: var(--x01-neutral98);
  border-radius: 6.01px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 18.02px;
  width: 100%;
  padding: 12.02px 12.02px 18.02px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .text-wrapper-113 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  position: relative;
}

.chipsoft-case-study .subtitle-wrapper {
  background-color: var(--x01-neutral20);
  border-radius: 24.03px;
  flex: none;
  align-items: flex-start;
  gap: 7.51px;
  padding: 4.51px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .subtitle {
  color: #fff;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 700;
  line-height: 10.8px;
  position: relative;
}

.chipsoft-case-study .frame-194 {
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 30.04px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-195 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24.03px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-9 {
  background-image: url("avatar-w-photo-28.3d20ebea.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 750.23px;
  width: 27.04px;
  height: 27.04px;
  position: relative;
}

.chipsoft-case-study .outside-stroke-3 {
  border: 1.5px solid;
  border-color: var(--x02-neutral95);
  border-radius: 15.02px;
  width: 30px;
  height: 30px;
  position: relative;
  top: -2px;
  left: -2px;
}

.chipsoft-case-study .avatar-w-photo-10 {
  background-image: url("avatar-w-photo-27.d32b2731.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 750.23px;
  width: 27.04px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-11 {
  background-image: url("avatar-w-photo-26.01297f41.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 750.23px;
  width: 27.04px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-12 {
  background-image: url("avatar-w-photo-25.ddcc1c65.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 750.23px;
  width: 27.04px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 6.01px;
  margin-left: -4.51px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-13 {
  background-image: url("avatar-w-photo-24.bf87185f.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 750.23px;
  width: 27.04px;
  height: 27.04px;
  position: relative;
}

.chipsoft-case-study .overflow {
  background-color: var(--x01-neutral10);
  border: 1.5px solid;
  border-color: var(--x02-neutral95);
  border-radius: 750.23px;
  width: 27.04px;
  height: 27.04px;
  margin-left: -4.51px;
  position: relative;
}

.chipsoft-case-study .number {
  color: var(--x01-neutral100);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 700;
  line-height: 10.8px;
  position: absolute;
  top: 7px;
  left: 8px;
}

.chipsoft-case-study .comments {
  border-radius: 9.01px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 18.02px;
  width: 100%;
  padding: 0 9.01px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-196 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15.02px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .top-3 {
  flex: none;
  align-items: center;
  gap: 9.01px;
  width: 222.29px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title-6 {
  color: var(--x01-neutral30);
  letter-spacing: -.05px;
  flex: 1;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  position: relative;
}

.chipsoft-case-study .container-7 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12.02px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .thread-line-2 {
  width: 1px;
  height: 238px;
  position: absolute;
  top: 28px;
  left: 11px;
}

.chipsoft-case-study .frame-197 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.01px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .comment-4 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .avatar-32 {
  object-fit: cover;
  width: 24.03px;
  height: 24.03px;
  position: relative;
}

.chipsoft-case-study .content-7 {
  background-color: var(--x01-neutral98);
  border-radius: 7.51px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 9.01px;
  padding: 6.01px 12.02px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .paragraph-6 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  align-self: stretch;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 10.8px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-114 {
  color: var(--x01-neutral50);
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 10.8px;
  position: relative;
}

.chipsoft-case-study .group-wrapper {
  width: 23.28px;
  height: 15.02px;
  position: relative;
}

.chipsoft-case-study .group-3 {
  width: 16px;
  height: 4px;
  position: relative;
  top: 5px;
  left: 4px;
}

.chipsoft-case-study .ellipse-20 {
  background-color: var(--x01-neutral50);
  border-radius: 1.88px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .ellipse-21 {
  background-color: var(--x01-neutral50);
  border-radius: 1.88px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 6px;
}

.chipsoft-case-study .ellipse-22 {
  background-color: var(--x01-neutral50);
  border-radius: 1.88px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 12px;
}

.chipsoft-case-study .dropdown-4 {
  border-radius: 6.01px;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 0 0 24.03px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-198 {
  flex: none;
  align-items: flex-start;
  gap: 7.51px;
  padding: 3px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .label-3 {
  color: var(--x01-neutral40);
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 600;
  line-height: 10.8px;
  position: relative;
}

.chipsoft-case-study .icons-21 {
  width: 15.02px;
  height: 15.02px;
  position: relative;
}

.chipsoft-case-study .label-4 {
  color: var(--x01-neutral40);
  letter-spacing: .18px;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Manrope, Helvetica;
  font-size: 9px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .comment-5 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
  padding: 0 0 0 18.02px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .input-field-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.01px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .search-bar-4 {
  border: .75px solid;
  border-color: var(--x01-neutral60);
  border-radius: 3px;
  flex: 1;
  align-items: center;
  gap: 6.01px;
  height: 30.04px;
  padding: 6.01px 9.01px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-115 {
  color: #909090;
  letter-spacing: -.04px;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 10.5px;
  font-weight: 400;
  line-height: 12.6px;
  position: relative;
}

.chipsoft-case-study .button-instance {
  border-radius: 4.51px !important;
  flex: none !important;
  gap: 3px !important;
  height: 30.04px !important;
  padding: 4.51px 12.02px !important;
}

.chipsoft-case-study .button-2 {
  font-family: Inter, Helvetica !important;
  font-size: 10.5px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  font-weight: 700 !important;
  line-height: 12.6px !important;
}

.chipsoft-case-study .slider {
  width: 5px;
  height: 192px;
  position: absolute;
  top: -2px;
  left: 235px;
}

.chipsoft-case-study .rectangle-4 {
  background-color: #b8c0cc;
  border-radius: 75.1px;
  width: 2px;
  height: 32px;
  position: relative;
  top: 44px;
  left: 2px;
}

.chipsoft-case-study .overlap-14 {
  width: 188px;
  height: 709px;
  position: absolute;
  top: 60px;
  left: 0;
}

.chipsoft-case-study .mini-side-bar {
  background-color: var(--x02-neutral95);
  border-color: #c6c6c6;
  border-right-style: solid;
  border-right-width: .75px;
  border-radius: .75px;
  align-items: flex-start;
  gap: 7.51px;
  width: 89px;
  height: 709px;
  padding: 9.01px 18.02px 9.01px 30.04px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .nav {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.01px;
  margin-right: -.75px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .dashboard-3 {
  background-color: var(--neutral-100);
  border-radius: 3px;
  flex: none;
  align-items: center;
  gap: 6.01px;
  padding: 12.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .book-wrapper {
  border: 1.5px solid #363636;
  border-radius: .75px;
  width: 12px;
  height: 15px;
  position: relative;
  top: 2px;
  left: 3px;
}

.chipsoft-case-study .book {
  width: 4px;
  height: 5px;
  position: absolute;
  top: -1px;
  left: 4px;
}

.chipsoft-case-study .polygon-wrapper {
  border: 1.5px solid #363636;
  border-radius: 1.5px;
  width: 14px;
  height: 12px;
  position: relative;
  top: 3px;
  left: 2px;
}

.chipsoft-case-study .polygon {
  width: 4px;
  height: 4px;
  position: absolute;
  top: 2px;
  left: 4px;
}

.chipsoft-case-study .menu-20 {
  background-color: var(--x02-neutral95);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 188px;
  height: 709px;
  padding: 9.01px 0 18.02px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-199 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9.01px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-200 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 187.74px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .nav-link-11 {
  flex: none !important;
  gap: 3px !important;
  width: 187.74px !important;
  padding: 12.02px 30.04px !important;
}

.chipsoft-case-study .nav-link-12 {
  font-family: Inter, Helvetica !important;
  font-size: 12px !important;
  font-style: unset !important;
  letter-spacing: -.02px !important;
  font-weight: 500 !important;
  line-height: 14.4px !important;
}

.chipsoft-case-study .nav-link-13 {
  background-color: var(--primary-60);
  flex: none;
  align-items: center;
  gap: 3px;
  width: 187.74px;
  padding: 12.02px 30.04px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icons-22 {
  width: 18.02px !important;
  height: 18.02px !important;
}

.chipsoft-case-study .dashboard-4 {
  color: var(--x01-neutral20);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  position: relative;
}

.chipsoft-case-study .nav-link-14 {
  flex: none;
  align-items: center;
  gap: 3px;
  width: 187.74px;
  padding: 12.02px 30.04px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icons-23 {
  width: 11px !important;
  height: 11px !important;
  top: 3px !important;
  left: 3px !important;
}

.chipsoft-case-study .icons-24 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  width: 5px !important;
  height: 5px !important;
  left: 4px !important;
}

.chipsoft-case-study .icons-25 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
}

.chipsoft-case-study .icons-26 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  width: 5px !important;
  height: 5px !important;
  top: 4px !important;
  left: 8px !important;
}

.chipsoft-case-study .icons-27 {
  border: 1.5px solid !important;
  border-radius: 2.44px !important;
  width: 5px !important;
  height: 5px !important;
  top: 8px !important;
  left: 4px !important;
}

.chipsoft-case-study .nav-link-15 {
  flex: none !important;
  gap: 3px !important;
  width: 185.49px !important;
  padding: 12.02px 30.04px !important;
}

.chipsoft-case-study .extra-3 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 2.25px;
  width: 100%;
  padding: 3px 30.04px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-116 {
  color: #000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.75px;
  font-family: Inter, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 10.8px;
  position: relative;
}

.chipsoft-case-study .interpunct-3 {
  width: 6.01px;
  height: 6.01px;
  position: relative;
}

.chipsoft-case-study .ellipse-23 {
  background-color: #d9d9d9;
  border-radius: 1.5px;
  width: 3px;
  height: 3px;
  position: relative;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .add-resource {
  background-color: #fff;
  border-radius: 5.56px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16.69px;
  width: 283px;
  padding: 16.69px 22.25px 22.25px;
  display: flex;
  position: absolute;
  top: 639px;
  left: 50px;
  overflow: hidden;
  box-shadow: 0 2.78px 4.17px -1.39px #10182808, -16.73px 5.58px 16.73px -2.78px #10182814;
}

.chipsoft-case-study .frame-201 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20.86px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-202 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8.34px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-117 {
  color: var(--x01-neutral30);
  letter-spacing: -.13px;
  align-self: stretch;
  margin-top: -.7px;
  font-family: Inter, Helvetica;
  font-size: 16.7px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.chipsoft-case-study .line-7 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.3px;
  position: relative;
}

.chipsoft-case-study .frame-203 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.21px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-204 {
  border: .7px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.78px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.95px;
  width: 100%;
  padding: 8.34px 11.13px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-118 {
  color: var(--x01-neutral50);
  letter-spacing: -.17px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.7px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.9px;
  position: relative;
}

.chipsoft-case-study .frame-205 {
  border: .7px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.78px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 8.34px 11.13px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .chevron-down-9 {
  width: 13.91px;
  height: 13.91px;
  position: relative;
}

.chipsoft-case-study .top-4 {
  flex: none;
  align-items: center;
  gap: 8.34px;
  width: 216.25px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .title-7 {
  color: var(--x01-neutral30);
  letter-spacing: -.04px;
  flex: 1;
  margin-top: -.7px;
  font-family: Inter, Helvetica;
  font-size: 9.7px;
  font-weight: 500;
  line-height: 11.7px;
  position: relative;
}

.chipsoft-case-study .container-8 {
  border: .7px dashed #d9d9d9;
  border-radius: 2.78px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.56px;
  width: 100%;
  padding: 16.69px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .content-8 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6.95px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icon-4 {
  width: 16.69px;
  height: 16.69px;
  position: relative;
}

.chipsoft-case-study .paragraph-7 {
  color: var(--x01-neutral30);
  letter-spacing: -.03px;
  text-align: center;
  width: 149.5px;
  font-family: Inter, Helvetica;
  font-size: 8.3px;
  font-weight: 300;
  line-height: 10px;
  position: relative;
}

.chipsoft-case-study .button-3 {
  border-radius: 2.78px !important;
  flex: none !important;
  gap: 6.95px !important;
  padding: 4.17px !important;
}

.chipsoft-case-study .frame-206 {
  border: .7px solid;
  border-color: var(--x01-neutral70);
  border-radius: 2.78px;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.95px;
  width: 100%;
  height: 63.97px;
  padding: 8.34px 11.13px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-119 {
  color: var(--x01-neutral50);
  letter-spacing: -.17px;
  flex: 1;
  margin-top: -.7px;
  font-family: Inter, Helvetica;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 13.9px;
  position: relative;
}

.chipsoft-case-study .button-4 {
  height: unset !important;
  border-radius: 2.78px !important;
  flex: none !important;
  gap: 2.78px !important;
  width: 238.5px !important;
  padding: 8.34px 11.13px !important;
  display: flex !important;
}

.chipsoft-case-study .button-5 {
  font-family: Inter, Helvetica !important;
  font-size: 11.1px !important;
  font-style: unset !important;
  letter-spacing: -.04px !important;
  font-weight: 700 !important;
  line-height: 13.4px !important;
}

.chipsoft-case-study .text-wrapper-120 {
  color: #000;
  letter-spacing: -.28px;
  width: 669px;
  height: 128px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  position: absolute;
  top: 16px;
  left: 1px;
}

.chipsoft-case-study .customization {
  width: 1920px;
  height: 800px;
  position: absolute;
  top: 4216px;
  left: -18px;
  overflow: hidden;
}

.chipsoft-case-study .overlap-15 {
  height: 1021px;
  position: relative;
  top: -1px;
}

.chipsoft-case-study .rectangle-5 {
  background-color: #f5f5f5;
  width: 1920px;
  height: 1020px;
  position: absolute;
  top: 1px;
  left: 0;
}

.chipsoft-case-study .rectangle-6 {
  width: 179px;
  height: 404px;
  position: absolute;
  top: 196px;
  left: 18px;
}

.chipsoft-case-study .rectangle-7 {
  width: 197px;
  height: 404px;
  position: absolute;
  top: 237px;
  left: 1723px;
}

.chipsoft-case-study .rectangle-8 {
  object-fit: cover;
  width: 335px;
  height: 404px;
  position: absolute;
  top: 198px;
  left: 229px;
}

.chipsoft-case-study .rectangle-9 {
  width: 335px;
  height: 404px;
  position: absolute;
  top: 239px;
  left: 1356px;
}

.chipsoft-case-study .rectangle-10 {
  border: .91px solid #a3a3a3;
  border-radius: 20px;
  width: 803px;
  height: 600px;
  position: absolute;
  top: 117px;
  left: 558px;
}

.chipsoft-case-study .auditorium-sessions-2 {
  background-color: var(--x01-neutral10);
  border: 6.07px solid #d4d4d4;
  border-radius: 12px;
  width: 741px;
  height: 530px;
  position: absolute;
  top: 152px;
  left: 590px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.chipsoft-case-study .overlap-16 {
  width: 728px;
  height: 1304px;
  position: relative;
}

.chipsoft-case-study .base-2 {
  object-fit: cover;
  width: 602px;
  height: 478px;
  position: absolute;
  top: 40px;
  left: 126px;
}

.chipsoft-case-study .header-2 {
  align-items: center;
  gap: 93.58px;
  width: 557px;
  display: flex;
  position: absolute;
  top: 61px;
  left: 151px;
}

.chipsoft-case-study .text-wrapper-121 {
  color: #fff;
  letter-spacing: 0;
  flex: 1;
  font-family: Inter, Helvetica;
  font-size: 16.2px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .div-24 {
  flex: none;
  align-items: flex-start;
  gap: 8.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-input-4 {
  border: .51px solid #fff;
  border-radius: 2.02px;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6.07px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-207 {
  flex: none;
  align-items: flex-start;
  gap: 4.05px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .search-4 {
  width: 10.12px;
  height: 10.12px;
  position: relative;
}

.chipsoft-case-study .value-4 {
  color: #fff;
  letter-spacing: -.03px;
  width: 77.9px;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .img-9 {
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .holder-wrapper {
  background-color: var(--secondary-50);
  border-radius: 2.53px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 78.91px;
  height: 24.28px;
  padding: 8.09px 10.12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .holder-2 {
  flex: none;
  align-items: center;
  gap: 1.01px;
  margin: -2.02px -1.74px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-122 {
  color: #fff;
  letter-spacing: -.2px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-208 {
  flex-direction: column;
  align-items: center;
  gap: 12.14px;
  width: 557px;
  display: flex;
  position: absolute;
  top: 101px;
  left: 151px;
}

.chipsoft-case-study .nav-bar-wrapper {
  border-color: #d9d9d9;
  border-bottom-style: solid;
  border-bottom-width: .51px;
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 23.27px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chipsoft-case-study .nav-link-16 {
  border-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 1.01px;
  justify-content: center;
  align-items: center;
  gap: 4.05px;
  width: 61.71px;
  height: 23.27px;
  padding: 4.05px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-123 {
  color: #fff;
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  line-height: 10.9px;
  position: relative;
}

.chipsoft-case-study .nav-link-17 {
  opacity: .72;
  justify-content: center;
  align-items: center;
  gap: 4.05px;
  width: 61.71px;
  height: 23.27px;
  padding: 4.05px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .nav-link-18 {
  opacity: .72;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.05px;
  height: 23.27px;
  padding: 4.05px 8.09px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .nav-link-19 {
  opacity: .72;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4.05px;
  padding: 6.07px 12.14px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-124 {
  color: #fff;
  letter-spacing: -.04px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.01px;
  font-family: Inter, Helvetica;
  font-size: 9.1px;
  font-weight: 600;
  line-height: 10.9px;
  position: relative;
}

.chipsoft-case-study .frame-209 {
  border-radius: 2.02px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 2.02px;
  padding: 3.04px 2.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-125 {
  color: #d9d9d9;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 700;
  line-height: 8.5px;
  position: relative;
}

.chipsoft-case-study .frame-210 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.07px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-211 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 6.07px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .dropdown-5 {
  border-radius: 3.04px;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8.09px;
  padding: 2.02px 0;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-212 {
  flex: none;
  align-items: center;
  gap: 4.05px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-126 {
  color: #a1a1a1;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  line-height: 12.1px;
  position: relative;
}

.chipsoft-case-study .frame-213 {
  flex: none;
  align-items: center;
  gap: 1.01px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-127 {
  color: #fff;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 600;
  line-height: 12.1px;
  position: relative;
}

.chipsoft-case-study .chevron-right-2 {
  width: 8.09px;
  height: 8.09px;
  position: relative;
}

.chipsoft-case-study .container-events-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 12.14px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .event-2 {
  background-color: var(--x01-neutral30);
  border-radius: 6.07px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.06px;
  width: 100%;
  padding: 16.19px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .element-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6.07px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-214 {
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .live-3 {
  background-color: #eb5757;
  border-radius: 2.53px;
  flex: none;
  align-items: flex-start;
  gap: 5.06px;
  padding: 1.01px 2.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-128 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 6.1px;
  font-weight: 700;
  line-height: 8.1px;
  position: relative;
}

.chipsoft-case-study .holder-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16.19px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-215 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.07px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-129 {
  color: #fff;
  letter-spacing: -.05px;
  width: 192.23px;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .div-25 {
  flex: none;
  align-items: flex-start;
  gap: 2.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-130 {
  color: #fff;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 7.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .speakers-5 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 4.05px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-131 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .chip-speaker-8 {
  border-radius: 10.12px;
  flex: none;
  align-items: center;
  gap: 2.02px;
  padding: 0 6.07px 0 0;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-216 {
  background-image: url("ellipse-1-27.8c19bb0a.svg");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .name-7 {
  color: #fff;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  line-height: 9.1px;
  position: relative;
}

.chipsoft-case-study .frame-217 {
  background-image: url("ellipse-1-26.d3943589.svg");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-218 {
  background-image: url("ellipse-1-25.62dc8542.svg");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .chip-speaker-9 {
  background-color: var(--accent-50);
  border-radius: 10.12px;
  align-items: center;
  gap: 2.02px;
  width: 16.19px;
  height: 15.68px;
  padding: 2.02px 4.05px 2.02px 2.02px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-219 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 4.05px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .CTA-9 {
  all: unset;
  border: .51px solid;
  border-color: var(--x01-neutral90);
  box-sizing: border-box;
  border-radius: 2.02px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.06px;
  padding: 6.07px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-132 {
  color: var(--x01-neutral90);
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .CTA-10 {
  all: unset;
  background-color: var(--secondary-50);
  box-sizing: border-box;
  border-radius: 2.02px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.06px;
  padding: 6.07px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-133 {
  color: #fff;
  letter-spacing: -.03px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .img-10 {
  width: 16.19px;
  height: 16.19px;
  position: relative;
}

.chipsoft-case-study .speakers-6 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4.05px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .attending-2 {
  align-items: center;
  height: 16.19px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-14 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-23.50f252de.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-15 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-22.b4739e92.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-16 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-21.936b9342.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-17 {
  background-color: var(--accent-50);
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  border-radius: 505.35px;
  width: 15.18px;
  height: 15.18px;
  margin-left: -2.02px;
  margin-right: -.51px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-134 {
  color: #fff;
  letter-spacing: -.23px;
  white-space: nowrap;
  height: 6px;
  font-family: Inter, Helvetica;
  font-size: 5.1px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: 3px;
}

.chipsoft-case-study .live-4 {
  background-color: #0060ff;
  border-radius: 2.53px;
  flex: none;
  align-items: flex-start;
  gap: 5.06px;
  padding: 1.01px 2.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-135 {
  color: #fff;
  letter-spacing: -.11px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .speakers-7 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 4.05px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .chip-speaker-10 {
  border-radius: 10.12px;
  width: 50.08px;
  height: 16.19px;
  position: relative;
}

.chipsoft-case-study .frame-220 {
  background-image: url("ellipse-1-24.3dfaf31f.svg");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .name-8 {
  color: #fff;
  letter-spacing: -.18px;
  white-space: nowrap;
  height: 10px;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 500;
  line-height: 9.1px;
  position: absolute;
  top: 0;
  left: 16px;
}

.chipsoft-case-study .chip-speaker-11 {
  border-radius: 10.12px;
  flex: none;
  align-items: center;
  gap: 2.02px;
  padding: 2.02px 4.05px 2.02px 2.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-221 {
  background-image: url("ellipse-1-23.ba160988.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .frame-222 {
  background-image: url("ellipse-1-22.04fe953c.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .chip-speaker-12 {
  background-color: #575757;
  border-radius: 10.12px;
  align-self: stretch;
  align-items: center;
  gap: 2.02px;
  width: 16.19px;
  padding: 2.02px 4.05px 2.02px 2.02px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-223 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 6.07px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-224 {
  flex: none;
  align-items: center;
  gap: 6.07px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .frame-225 {
  background-color: #ffffff1f;
  border-radius: 19.22px;
  width: 20.23px;
  height: 20.23px;
  position: relative;
}

.chipsoft-case-study .calendar-add-on-3 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.chipsoft-case-study .avatar-w-photo-18 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-20.f6f776d8.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-19 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-19.cf0c3c6e.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-20 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-18.a7c45896.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .div-26 {
  flex: none;
  align-items: center;
  gap: 2.02px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-136 {
  color: #fff;
  letter-spacing: -.07px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .ellipse-24 {
  background-color: #fff;
  border-radius: 1.01px;
  width: 2.02px;
  height: 2.02px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-137 {
  color: #fff;
  letter-spacing: -.11px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 7.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.chipsoft-case-study .frame-226 {
  background-image: url("ellipse-1-21.cf6b74d7.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-227 {
  background-image: url("ellipse-1-20.aee3a2ed.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-228 {
  background-image: url("ellipse-1-19.086a7e53.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-229 {
  flex-direction: column;
  flex: none;
  align-items: flex-end;
  gap: 40.47px;
  display: inline-flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-21 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-17.25b8cf2b.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-22 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-16.4301183a.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-23 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-15.58423546.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .frame-230 {
  background-image: url("ellipse-1-18.533f108b.png");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .frame-231 {
  background-image: url("ellipse-1-17.9689ec27.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .frame-232 {
  background-image: url("ellipse-1-16.1f0acd88.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .frame-233 {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  width: 121.91px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-234 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6.07px;
  width: 100%;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-235 {
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 6.07px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-24 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-14.901516cd.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-25 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-13.e6740d4f.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-26 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-12.81f3b1b5.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .frame-236 {
  background-image: url("ellipse-1-15.f32e7caa.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-237 {
  background-image: url("ellipse-1-14.58f1d663.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-238 {
  background-image: url("ellipse-1-13.93f533b9.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-27 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-11.bc67b998.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-28 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-10.c8ffc30e.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-29 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-9.f07952d9.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .frame-239 {
  background-image: url("ellipse-1-12.a437bf77.png");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .frame-240 {
  background-image: url("ellipse-1-11.028dc9ab.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .frame-241 {
  background-image: url("ellipse-1-10.dc051a42.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-30 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-8.59295b28.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-31 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-7.c204427c.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-32 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-6.c97505c9.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .frame-242 {
  background-image: url("ellipse-1-9.e8a7cd60.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-243 {
  background-image: url("ellipse-1-8.0705c9a6.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .frame-244 {
  background-image: url("ellipse-1-7.4e1e0435.png");
  background-size: 100% 100%;
  width: 14.16px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-33 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-5.effcc018.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-34 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-4.27984aa2.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-35 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-3.1cd8085b.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .frame-245 {
  background-image: url("ellipse-1-6.ac5688fa.png");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.chipsoft-case-study .frame-246 {
  background-image: url("ellipse-1-5.93148d96.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .frame-247 {
  background-image: url("ellipse-1-4.2a141df7.png");
  background-position: 50%;
  background-size: cover;
  width: 12.14px;
  height: 12.14px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-36 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-2.18fbbe4d.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-37 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo-1.f4a43f94.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 14.16px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .avatar-w-photo-38 {
  border: 1.01px solid;
  border-color: var(--x01-neutral30);
  background-image: url("avatar-w-photo.f4e268a0.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 505.35px;
  width: 13.66px;
  height: 14.16px;
  margin-left: -2.02px;
  position: relative;
}

.chipsoft-case-study .menu-21 {
  background-color: var(--x02-neutral95);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 126px;
  height: 478px;
  padding: 0 0 12.14px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 0;
}

.chipsoft-case-study .frame-248 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 126.46px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .nav-link-20 {
  flex: none !important;
  gap: 2.02px !important;
  width: 126.46px !important;
  padding: 8.09px 20.23px !important;
}

.chipsoft-case-study .nav-link-21 {
  font-family: Inter, Helvetica !important;
  font-size: 8.1px !important;
  font-style: unset !important;
  letter-spacing: -.02px !important;
  font-weight: 500 !important;
  line-height: 9.7px !important;
}

.chipsoft-case-study .nav-link-22 {
  flex: none;
  align-items: center;
  gap: 2.02px;
  width: 126.46px;
  padding: 8.09px 20.23px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .icons-28 {
  width: 12.14px !important;
  height: 12.14px !important;
}

.chipsoft-case-study .icons-29 {
  width: 8px !important;
  height: 10px !important;
  top: 1px !important;
  left: 2px !important;
}

.chipsoft-case-study .dashboard-5 {
  color: var(--x01-neutral30);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .icons-30 {
  width: 8px !important;
  height: 8px !important;
  top: 2px !important;
  left: 2px !important;
}

.chipsoft-case-study .icons-31 {
  width: 9px !important;
  height: 9px !important;
}

.chipsoft-case-study .icons-32 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  width: 3px !important;
  height: 3px !important;
  left: 3px !important;
}

.chipsoft-case-study .icons-33 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  width: 3px !important;
  height: 3px !important;
  top: 3px !important;
}

.chipsoft-case-study .icons-34 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  width: 3px !important;
  height: 3px !important;
  top: 3px !important;
  left: 5px !important;
}

.chipsoft-case-study .icons-35 {
  border: 1.01px solid !important;
  border-radius: 1.64px !important;
  width: 3px !important;
  height: 3px !important;
  top: 5px !important;
  left: 3px !important;
}

.chipsoft-case-study .nav-link-23 {
  flex: none !important;
  gap: 2.02px !important;
  width: 124.95px !important;
  padding: 8.09px 20.23px !important;
}

.chipsoft-case-study .extra-4 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 1.52px;
  width: 100%;
  padding: 2.02px 20.23px;
  display: flex;
  position: relative;
}

.chipsoft-case-study .text-wrapper-138 {
  color: #000;
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.51px;
  font-family: Inter, Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  line-height: 7.3px;
  position: relative;
}

.chipsoft-case-study .interpunct-4 {
  width: 4.05px;
  height: 4.05px;
  position: relative;
}

.chipsoft-case-study .ellipse-25 {
  background-color: #d9d9d9;
  border-radius: 1.01px;
  width: 2px;
  height: 2px;
  position: relative;
  top: 1px;
  left: 1px;
}

.chipsoft-case-study .frame-249 {
  background-color: var(--x02-neutral80);
  border-color: #c6c6c6;
  border-bottom-style: solid;
  border-bottom-width: .25px;
  justify-content: space-between;
  align-items: center;
  width: 728px;
  padding: 10.12px 20.23px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.chipsoft-case-study .frame-250 {
  background-image: url("group-417.42683c93.png");
  background-size: 100% 100%;
  width: 65.76px;
  height: 18.2px;
  position: relative;
}

.chipsoft-case-study .frame-251 {
  justify-content: flex-end;
  align-items: center;
  gap: 12.14px;
  width: 107.24px;
  padding: 2.02px 0;
  display: flex;
  position: relative;
}

.chipsoft-case-study .frame-252 {
  background-image: url("ellipse-5-2.5561878e.png");
  background-position: 50%;
  background-size: cover;
  width: 16.19px;
  height: 16.19px;
  position: relative;
}

.chipsoft-case-study .ellipse-26 {
  background-color: var(--accent-50);
  border: .51px solid;
  border-color: var(--x01-neutral100);
  border-radius: 2.02px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 12px;
  left: 12px;
}

.chipsoft-case-study .text-wrapper-139 {
  color: var(--x01-neutral20);
  letter-spacing: -.02px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  line-height: 9.7px;
  position: relative;
}

.chipsoft-case-study .text-wrapper-140 {
  color: #000;
  letter-spacing: -.24px;
  white-space: nowrap;
  height: 64px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 64px;
  position: absolute;
  top: 0;
  left: 599px;
}

.chipsoft-case-study .overlap-17 {
  background-image: url("pexels-michael-burrows-7129708-1.61ce0280.png");
  background-size: 100% 100%;
  width: 1902px;
  height: 1000px;
  position: absolute;
  top: 5016px;
  left: 0;
}

.chipsoft-case-study .works-on-all {
  color: #fff;
  letter-spacing: -.28px;
  width: 391px;
  height: 256px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  position: absolute;
  top: 371px;
  left: 606px;
}

.chipsoft-case-study .apple-iphone-11-pro-gold {
  position: absolute !important;
  top: 73px !important;
  left: 139px !important;
}

.chipsoft-case-study .apple-iphone-pro-instance {
  border-color: unset !important;
}

.chipsoft-case-study .overlap-18 {
  background-image: url("rectangle-123.b853c46b.svg");
  background-size: 100% 100%;
  width: 1902px;
  height: 540px;
  position: absolute;
  top: 6016px;
  left: 0;
}

.chipsoft-case-study .text-wrapper-141 {
  color: #fff;
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 80px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 179px;
  left: 670px;
}

.chipsoft-case-study .text-wrapper-142 {
  color: #f52f57;
  letter-spacing: -.24px;
  text-align: center;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 46px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  position: absolute;
  top: 287px;
  left: 688px;
}

.chipsoft-case-study .frame-253 {
  width: 212px;
  height: 46px;
  position: absolute;
  top: 394px;
  left: 1555px;
}

/*# sourceMappingURL=index.b2565c1d.css.map */
