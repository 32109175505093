.button {
  align-items: center;
  border-radius: 3.93px;
  display: inline-flex;
  gap: 6.56px;
  justify-content: center;
  position: relative;
}

.button .div-2 {
  font-family: "Inter", Helvetica;
  font-weight: 500;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.button .instance-node-3 {
  height: 13.11px !important;
  position: relative !important;
  width: 13.11px !important;
}

.button .div-3 {
  font-family: "Inter", Helvetica;
  font-weight: 500;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.button.secondary {
  background-color: #f0f2f6;
}

.button.outline {
  border: 0.66px solid;
  border-color: #4f78db;
}

.button.right.size-0-small {
  padding: 5.9px 9.18px 7.21px;
}

.button.left.size-0-large {
  padding: 9.18px 9.18px 9.18px 11.8px;
}

.button.none.size-0-small {
  padding: 7.21px 9.18px 7.87px;
}

.button.size-0-large.primary {
  background-color: #4f78db;
}

.button.icon-only.size-0-large {
  padding: 9.18px;
}

.button.right.size-0-large {
  padding: 9.18px 9.18px 9.18px 11.8px;
}

.button.left.size-0-small {
  padding: 5.9px 9.18px 7.21px;
}

.button.none.size-0-large {
  padding: 9.18px 9.18px 9.18px 11.8px;
}

.button.size-0-small.primary {
  background-color: #203159;
}

.button.size-0-small.icon-only {
  padding: 6.23px 6.56px 6.88px;
}

.button.size-0-large .div-2 {
  font-size: 10.5px;
  letter-spacing: 0;
}

.button.none .div-2 {
  margin-top: -0.66px;
}

.button.secondary .div-2 {
  color: #1e202c;
}

.button.outline .div-2 {
  color: #4f78db;
  letter-spacing: 0;
}

.button.text .div-2 {
  color: #4f78db;
  font-size: 10.5px;
  letter-spacing: 0;
}

.button.primary .div-2 {
  color: var(--defaultwhite);
  text-align: center;
}

.button.text.right .div-2 {
  margin-top: -0.6px;
}

.button.right.size-0-large .div-2 {
  margin-top: -0.6px;
}

.button.outline.size-0-small .div-2 {
  font-size: 9.2px;
  white-space: nowrap;
}

.button.size-0-small.primary .div-2 {
  font-size: 9.2px;
  letter-spacing: -0.18px;
  white-space: nowrap;
}

.button.size-0-small.secondary .div-2 {
  font-size: 9.2px;
  letter-spacing: -0.18px;
  white-space: nowrap;
}

.button.size-0-large .div-3 {
  font-size: 10.5px;
  letter-spacing: 0;
  margin-top: -0.6px;
}

.button.secondary .div-3 {
  color: #1e202c;
}

.button.outline .div-3 {
  color: #4f78db;
  letter-spacing: 0;
}

.button.text .div-3 {
  color: #4f78db;
  font-size: 10.5px;
  letter-spacing: 0;
  margin-top: -0.6px;
}

.button.primary .div-3 {
  color: var(--defaultwhite);
}

.button.size-0-small.primary .div-3 {
  font-size: 9.2px;
  letter-spacing: -0.18px;
  white-space: nowrap;
}

.button.size-0-small.secondary .div-3 {
  font-size: 9.2px;
  letter-spacing: -0.18px;
  white-space: nowrap;
}

.button.outline.size-0-small .div-3 {
  font-size: 9.2px;
  white-space: nowrap;
}
