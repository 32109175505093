.endpoint .shape-triangle {
  height: 8px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 8px !important;
}

.endpoint.shape-rectangle {
  background-color: #000000;
  height: 8px;
  width: 8px;
}

.endpoint.shape-measure {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.endpoint.circle {
  border-radius: 100px;
}
