.button-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--secondary-30);
  border: 0.5px solid;
  border-color: #4b4b4b;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 20px 12px 12px;
  position: relative;
  width: 87px;
}

.button-wrapper .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  margin-bottom: -4px;
  margin-left: -3.5px;
  margin-right: -3.5px;
  margin-top: -4px;
  position: relative;
}

.button-wrapper .add {
  height: 24px;
  position: relative;
  width: 24px;
}

.button-wrapper .new {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
