.button-default {
  align-items: center;
  border-radius: 6.67px;
  display: inline-flex;
  flex-direction: column;
  height: 33px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.button-default .button-instance {
  background-color: var(--secondary-50) !important;
  border: unset !important;
  border-color: unset !important;
  border-radius: 6.67px !important;
  display: inline-flex !important;
  gap: 1.67px !important;
  height: 33.35px !important;
  padding: 10px 16.67px 10px 10px !important;
  width: unset !important;
}

.button-default .design-component-instance-node {
  gap: 3.33px !important;
  margin-bottom: -3.33px !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: -3.33px !important;
}

.button-default .button-2 {
  height: 20.01px !important;
  width: 20.01px !important;
}

.button-default .button-3 {
  font-size: 11.7px !important;
  font-weight: 700 !important;
  letter-spacing: -0.29px !important;
}
