.property-default-wrapper {
  position: relative;
}

.property-default-wrapper .add-team {
  position: relative;
}

.property-default-wrapper .add-4 {
  height: 24px;
  position: relative;
  width: 24px;
}

.property-default-wrapper .text-wrapper-11 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.property-default-wrapper .text-wrapper-12 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.21px;
  line-height: 18px;
  margin-top: -2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.property-default-wrapper .arrow-back-3 {
  height: 14px;
  position: relative;
  width: 14px;
}

.property-default-wrapper .icon-14 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.property-default-wrapper.property-1-1-variant-8 {
  align-items: center;
  background-color: var(--secondary-20);
  border-radius: 6px;
  display: inline-flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  padding: 6px 16px;
}

.property-default-wrapper.property-1-1-variant-10 {
  align-items: center;
  background-color: var(--secondary-20);
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 6px;
}

.property-default-wrapper.property-1-1-add-team-default {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 32px;
  width: 202px;
}

.property-default-wrapper.property-1-1-variant-11 {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 30px;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  padding: 12px 20px 12px 12px;
}

.property-default-wrapper.property-1-1-variant-9 {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 6px;
}

.property-default-wrapper.property-1-1-variant-5 {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 32px;
  width: 280px;
}

.property-default-wrapper.property-1-1-variant-6 {
  align-items: center;
  background-color: var(--secondary-20);
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 32px;
  width: 280px;
}

.property-default-wrapper.property-1-1-variant-12 {
  align-items: center;
  background-color: var(--secondary-20);
  border-radius: 30px;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  padding: 12px 20px 12px 12px;
}

.property-default-wrapper.property-1-1-variant-7 {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 6px;
  display: inline-flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  padding: 6px 16px;
}

.property-default-wrapper.property-1-1-hover {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 40px;
}

.property-default-wrapper.property-1-1-default {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 40px;
}

.property-default-wrapper.property-1-1-add-team-default-hover {
  align-items: center;
  background-color: var(--secondary-20);
  border-radius: 4px;
  display: flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 32px;
  width: 202px;
}

.property-default-wrapper.property-1-1-variant-8 .add-team {
  color: #ffffff;
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
}

.property-default-wrapper.property-1-1-add-team-default .add-team {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.21px;
  line-height: 18px;
  margin-top: -2px;
  white-space: nowrap;
  width: fit-content;
}

.property-default-wrapper.property-1-1-variant-11 .add-team {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
}

.property-default-wrapper.property-1-1-variant-12 .add-team {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
}

.property-default-wrapper.property-1-1-variant-7 .add-team {
  color: #ffffff;
  font-family: var(--caption-BLD-font-family);
  font-size: var(--caption-BLD-font-size);
  font-style: var(--caption-BLD-font-style);
  font-weight: var(--caption-BLD-font-weight);
  letter-spacing: var(--caption-BLD-letter-spacing);
  line-height: var(--caption-BLD-line-height);
  white-space: nowrap;
  width: fit-content;
}

.property-default-wrapper.property-1-1-add-team-default-hover .add-team {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.21px;
  line-height: 18px;
  margin-top: -2px;
  white-space: nowrap;
  width: fit-content;
}
