.icons .instance-node-2 {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.icons {
  height: 24px;
  position: relative;
  width: 24px;
}

.icons .union {
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}

.icons .group {
  height: 15px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 15px;
}

.icons .overlap-group {
  height: 17px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 17px;
}

.icons .ellipse {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  height: 6px;
  left: 5px;
  position: absolute;
  top: 0;
  width: 6px;
}

.icons .div {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 6px;
}

.icons .ellipse-2 {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  height: 6px;
  left: 10px;
  position: absolute;
  top: 5px;
  width: 6px;
}

.icons .ellipse-3 {
  border: 2px solid;
  border-color: var(--x01-neutral30);
  border-radius: 3.25px;
  height: 6px;
  left: 5px;
  position: absolute;
  top: 10px;
  width: 6px;
}
