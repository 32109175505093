.annotation-text {
  position: relative;
}

.annotation-text .note {
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  margin-top: -1px;
  position: relative;
}

.annotation-text .class-9 {
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
}

.annotation-text .class-10 {
  flex: 1 !important;
  flex-grow: 1 !important;
  transform: rotate(180deg) !important;
  width: unset !important;
}

.annotation-text .class-11 {
  transform: rotate(-180deg) !important;
}

.annotation-text .endpoint-2 {
  height: 8px !important;
  position: relative !important;
  width: 8px !important;
}

.annotation-text .endpoint-2-10 {
  height: 8px !important;
  position: relative !important;
  transform: rotate(-180deg) !important;
  width: 8px !important;
}

.annotation-text .note-wrapper {
  align-items: center;
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 1.5px 12px 2.5px;
  position: relative;
}

.annotation-text .text-wrapper {
  font-family: var(--detail-font-family);
  font-size: var(--detail-font-size);
  font-style: var(--detail-font-style);
  font-weight: var(--detail-font-weight);
  letter-spacing: var(--detail-letter-spacing);
  line-height: var(--detail-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.annotation-text .class-12 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.annotation-text.direction-left {
  display: flex;
  justify-content: flex-end;
}

.annotation-text.large {
  align-items: flex-start;
  border-radius: 6px;
  gap: 10px;
  padding: 8px;
  width: 272px;
}

.annotation-text.direction-up {
  display: inline-flex;
  flex-direction: column;
  height: 156px;
  justify-content: flex-end;
}

.annotation-text.small {
  align-items: center;
}

.annotation-text.direction-right {
  display: flex;
}

.annotation-text.direction-down {
  display: inline-flex;
  flex-direction: column;
  height: 156px;
}

.annotation-text.direction-left.small {
  width: 226px;
}

.annotation-text.direction-default.large {
  display: flex;
}

.annotation-text.direction-default.light {
  background-color: var(--lightwhite-100);
}

.annotation-text.direction-right.small {
  width: 226px;
}

.annotation-text.dark.direction-default {
  background-color: var(--darkblack-100);
}

.annotation-text.direction-default.small {
  border-radius: 12px;
  display: inline-flex;
  gap: 8px;
  padding: 1.5px 12px 2.5px;
}

.annotation-text.light .note {
  color: var(--darkblack-100);
}

.annotation-text.large .note {
  flex: 1;
}

.annotation-text.dark .note {
  color: var(--lightwhite-100);
}

.annotation-text.small .note {
  white-space: nowrap;
  width: fit-content;
}

.annotation-text.light .note-wrapper {
  background-color: var(--lightwhite-100);
}

.annotation-text.dark .note-wrapper {
  background-color: var(--darkblack-100);
}

.annotation-text.light .text-wrapper {
  color: var(--darkblack-100);
}

.annotation-text.dark .text-wrapper {
  color: var(--lightwhite-100);
}
