.button-variant9 {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 3.33px;
  display: inline-flex;
  gap: 8.34px;
  justify-content: center;
  padding: 5px;
  position: relative;
}

.button-variant9 .icon {
  height: 16.67px !important;
  position: relative !important;
  width: 16.67px !important;
}
