.button-variant10 {
  align-items: center;
  background-color: #1f3058;
  border-radius: 3.33px;
  display: inline-flex;
  gap: 8.34px;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  position: relative;
}

.button-variant10 .icon-1 {
  height: 16.67px !important;
  position: relative !important;
  width: 16.67px !important;
}
