.menu {
  align-items: flex-start;
  background-color: var(--x02-neutral95);
  flex-direction: column;
  height: 765px;
  position: relative;
}

.menu .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.menu .nav-link-instance {
  flex: 0 0 auto !important;
  gap: 3.24px !important;
  padding: 12.97px 32.43px !important;
  width: 202.7px !important;
}

.menu .nav-link-2 {
  font-family: "Inter", Helvetica !important;
  font-size: 13px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: -0.03px !important;
  line-height: 15.6px !important;
}

.menu .nav-link-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 3.24px;
  padding: 12.97px 32.43px;
  position: relative;
  width: 202.7px;
}

.menu .icons-instance {
  height: 19.46px !important;
  width: 19.46px !important;
}

.menu .icons-2 {
  height: 16px !important;
  left: 3px !important;
  width: 13px !important;
}

.menu .dashboard-2 {
  color: var(--x01-neutral30);
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 15.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menu .icons-3 {
  height: 12px !important;
  width: 12px !important;
}

.menu .icons-4 {
  height: 14px !important;
  width: 14px !important;
}

.menu .icons-5 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  height: 5px !important;
  left: 4px !important;
  width: 5px !important;
}

.menu .icons-6 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  height: 5px !important;
  top: 4px !important;
  width: 5px !important;
}

.menu .icons-7 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  height: 5px !important;
  left: 9px !important;
  top: 4px !important;
  width: 5px !important;
}

.menu .icons-8 {
  border: 1.62px solid !important;
  border-radius: 2.64px !important;
  height: 5px !important;
  left: 4px !important;
  top: 9px !important;
  width: 5px !important;
}

.menu .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19.46px;
  position: relative;
}

.menu .nav-link-4 {
  flex: 0 0 auto !important;
  gap: 3.24px !important;
  overflow: hidden !important;
  padding: 12.97px 32.43px !important;
  width: 200.27px !important;
}

.menu .extra {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 2.43px;
  padding: 3.24px 32.43px;
  position: relative;
  width: 100%;
}

.menu .text-wrapper-10 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 9.7px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 11.7px;
  margin-top: -0.81px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menu .interpunct {
  height: 6.49px;
  position: relative;
  width: 6.49px;
}

.menu .ellipse-8 {
  background-color: #d9d9d9;
  border-radius: 1.62px;
  height: 3px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 3px;
}

.menu .frame-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.menu .nav-link-5 {
  border-radius: 6.49px !important;
  flex: 0 0 auto !important;
  gap: 3.24px !important;
  padding: 12.97px !important;
}

.menu .icons-wrapper {
  align-items: center;
  background-color: var(--primary-50);
  border-radius: 6.49px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.24px;
  justify-content: center;
  overflow: hidden;
  padding: 12.97px;
  position: relative;
}

.menu .icons-9 {
  height: 25.95px !important;
  width: 25.95px !important;
}

.menu .icons-10 {
  height: 22px !important;
  width: 17px !important;
}

.menu .icons-instance-wrapper {
  align-items: center;
  border-radius: 6.49px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.24px;
  justify-content: center;
  overflow: hidden;
  padding: 12.97px;
  position: relative;
}

.menu .icons-11 {
  height: 16px !important;
  left: 5px !important;
  top: 5px !important;
  width: 16px !important;
}

.menu .icons-12 {
  height: 18px !important;
  width: 18px !important;
}

.menu .icons-13 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  left: 6px !important;
}

.menu .icons-14 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  top: 6px !important;
}

.menu .icons-15 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  left: 11px !important;
  top: 6px !important;
}

.menu .icons-16 {
  border: 1.62px solid !important;
  border-radius: 3.24px !important;
  left: 6px !important;
  top: 11px !important;
}

.menu .chevron-down {
  position: absolute;
  width: 26px;
}

.menu .icon-instance-node {
  height: 19.46px !important;
  position: relative !important;
  width: 19.46px !important;
}

.menu .icons-17 {
  height: 25.95px !important;
  position: relative !important;
  width: 25.95px !important;
}

.menu.property-1-default {
  display: flex;
  justify-content: space-between;
  padding: 9.73px 0px;
  width: 203px;
}

.menu.property-1-collapsed {
  display: inline-flex;
  gap: 25.95px;
  padding: 9.73px 19.46px 9.73px 32.43px;
}

.menu.property-1-default .chevron-down {
  height: 25px;
  left: 190px;
  top: 371px;
}

.menu.property-1-collapsed .chevron-down {
  height: 26px;
  left: 91px;
  top: 370px;
}
