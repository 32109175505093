.midsection.type-equal-2 {
  left: 0;
  position: absolute;
  top: 0;
}

.midsection.type-number {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 0px 4px;
  position: relative;
}

.midsection .number {
  color: var(--darkblack-100);
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.midsection.type-0-dotted {
  height: 1px;
  object-fit: cover;
  width: 16px;
}

.midsection.type-0-number {
  height: 8px;
  width: 16px;
}

.midsection.type-0-cut {
  height: 9px;
  width: 16px;
}

.midsection.type-0-equal {
  height: 8px;
  width: 4px;
}

.midsection.type-0-squiggle {
  height: 4px;
  width: 36px;
}

.midsection.type-0-zigzag {
  height: 5px;
  width: 36px;
}
