.button-variant {
  align-items: center;
  background-color: var(--secondary-50);
  border-radius: 6.67px;
  display: flex;
  gap: 3.33px;
  height: 40.02px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 26.68px;
  position: relative;
  width: 268px;
}

.button-variant .text-wrapper-4 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: -0.23px;
  line-height: 15px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button-variant .arrow-back {
  height: 11.67px;
  margin-left: -6195.75px;
  margin-top: -4592.68px;
  position: relative;
  width: 11.67px;
}
