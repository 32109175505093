.button-variant12 {
  all: unset;
  align-items: center;
  background-color: #1f3058;
  border-radius: 25.01px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  gap: 1.67px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 16.67px 10px 10px;
  position: relative;
}

.button-variant12 .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.33px;
  margin-bottom: -1.67px;
  margin-top: -1.67px;
  position: relative;
}

.button-variant12 .add-3 {
  height: 20.01px;
  position: relative;
  width: 20.01px;
}

.button-variant12 .text-wrapper-9 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 13.3px;
  font-weight: 600;
  letter-spacing: -0.33px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
