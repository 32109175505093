.button-hover {
  align-items: flex-start;
  border-radius: 3.33px;
  display: inline-flex;
  flex-direction: column;
  height: 33px;
  overflow: hidden;
  position: relative;
}

.button-hover .button-4 {
  background-color: #1f3058 !important;
  border: 0.42px solid !important;
  border-radius: 6.67px !important;
  gap: 1.67px !important;
  height: 33.35px !important;
  padding: 10px 16.67px 10px 10px !important;
  width: 75.03px !important;
}

.button-hover .button-5 {
  gap: 3.33px !important;
  margin-bottom: -3.33px !important;
  margin-left: unset !important;
  margin-right: unset !important;
  margin-top: -3.33px !important;
}

.button-hover .button-6 {
  height: 20.01px !important;
  width: 20.01px !important;
}

.button-hover .button-7 {
  font-size: 11.7px !important;
  font-weight: 700 !important;
  letter-spacing: -0.29px !important;
}
